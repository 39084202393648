import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {Modal} from 'react-bootstrap';
import videoStyles from '../VideoPlayer.module.scss';
/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export default function VideoPopup({videoURI, closeVideoPopup}) {
  const vimeoId = videoURI.split('/')[2];
  const url = `https://player.vimeo.com/video/${vimeoId}`;

  return (
    <>
      <div className={videoStyles.videoPlayer}>
        <div className={`${videoStyles.shawModalBackdrop}`}></div>
        <div className={videoStyles.modalContentWrapper}>
          <Modal.Dialog
            className={videoStyles.videoPlayerModal}>
            <div className={videoStyles.shawOldModalVideoClose}
              onClick={closeVideoPopup}>
              <img src="/popups/cross.svg" alt="close"/>
            </div>
            <ReactPlayer
              width='100%'
              height='100%'
              url={url}
              controls={true}
            />
          </Modal.Dialog>
        </div>
      </div>
    </>
  );
};

VideoPopup.propTypes = {
  videoURI: PropTypes.string,
  closeVideoPopup: PropTypes.func,
  dataToFireBase: PropTypes.object,
};
