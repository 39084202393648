import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(customParseFormat);


export const buildDayPickerList = () => {
  const days = [];
  let dateStart = dayjs().add(1, 'days');
  const dateEnd = dayjs().add(8, 'days');
  while (dateEnd.diff(dateStart, 'days') > 0) {
    days.push(dateStart.format('dddd'));
    dateStart=dateStart.add(1, 'days');
  }
  return days;
};

export const buildTimePickerList = () => {
  let hour = 6;
  const hours = [];
  while (hour <= 22) {
    hours.push(dayjs().set('hour', hour).set('minute', 0).format('h:mm A'));
    hour++;
  }
  return hours;
};

export const getHourDifference = (schedule1, schedule2, schedule3) => {
  let extraHourCheck;
  let extraSecondHourCheck;
  let hourDiff;
  const objToReturn = {};
  if (schedule2 && schedule1) {
    hourDiff = Math.abs(dayjs.duration(schedule2
        .diff(schedule1)).asHours());
    if (schedule3) {
      extraHourCheck = Math.abs(dayjs.duration(schedule3
          .diff(schedule1)).asHours());
      extraSecondHourCheck = Math.abs(dayjs.duration(schedule3
          .diff(schedule2)).asHours());
    }
  }
  if (Math.round(hourDiff) < 2 || Math.round(extraHourCheck) < 2 ||
    Math.round(extraSecondHourCheck) < 2) {
    objToReturn['showTimeError'] = true;
    objToReturn['showButton'] = false;
  } else {
    objToReturn['showTimeError'] = false;
    objToReturn['showButton'] = true;
  }
  return objToReturn;
};

export const buildScheduleLessonsArray = (schedule1, schedule2, schedule3,
    courseInfo, classesSelected, newTimetableEligibility, courseRegData) => {
  const lessonLength = Math.ceil(courseInfo?.attributes.lessons.length / 2);
  let lessons;
  if (newTimetableEligibility) {
    lessons = getLessonsArray(schedule1, schedule2, schedule3,
        lessonLength, classesSelected, courseInfo, courseRegData);
  } else {
    lessons = getLessons(schedule1, schedule2, schedule3,
        lessonLength, classesSelected);
  }
  if (lessons.length !== courseInfo?.attributes.lessons.length) {
    lessons.pop();
  }
  return lessons;
};

const getLessonsArray = (schedule1, schedule2, schedule3,
    lessonLength, classesSelected, courseFullInfo, courseRegData) => {
  const activeModule=courseRegData?.activeModule;
  const activeModuleLessons=courseRegData?.relationships?.modules?.data?.[
    activeModule-1]?.attributes?.lessons;
  const totalNumberOfLessons= activeModuleLessons?.length;
  const upcomingLessons= courseFullInfo?.upcomingLessons;
  const numberOfUpcomingLessons= upcomingLessons?.length;
  const completedLessons = getCompletedLessonsData(activeModuleLessons);
  const lessonsToReturn=[];
  if (completedLessons?.length>0) {
    completedLessons.forEach((lesson)=>{
      lessonsToReturn.push({
        number: lesson?.number,
        start_time: lesson?.start_time,
      });
    });
  }
  const isCompletedLessonsInOrder = lessonsToReturn?.length>0 ?
   lessonsToReturn.every((lesson, index)=>(
    lesson?.number===index+1
   )) : false;
  if (numberOfUpcomingLessons===0 || numberOfUpcomingLessons===
    totalNumberOfLessons || !isCompletedLessonsInOrder) {
    return getLessons(schedule1, schedule2, schedule3,
        lessonLength, classesSelected);
  } else {
    if (classesSelected===1) {
      for (let i=0; i<numberOfUpcomingLessons; i++) {
        let startTime = schedule1.clone();
        startTime = startTime.add(i*7, 'days').toISOString();
        lessonsToReturn.push({
          number: lessonsToReturn.length + 1,
          start_time: startTime,
        });
      }
    }
    if (classesSelected===2) {
      const modifiedLength=Math.ceil(numberOfUpcomingLessons/2);
      for (let j=0; j<modifiedLength; j++) {
        let startingTime = schedule2.clone();
        startingTime = startingTime.add(j*7, 'days').toISOString();
        lessonsToReturn.push({
          number: lessonsToReturn.length + 1,
          start_time: startingTime,
        });
        lessonsToReturn.push({
          number: lessonsToReturn.length + 1,
          start_time: startingTime,
        });
      }
      lessonsToReturn.splice(totalNumberOfLessons, lessonsToReturn.length);
    }
    if (classesSelected===3) {
      const modifiedLength=Math.ceil(numberOfUpcomingLessons/2);
      for (let k=0; k<modifiedLength; k++) {
        let startingTime = schedule3.clone();
        startingTime = startingTime.add(k*7, 'days').toISOString();
        lessonsToReturn.push({
          number: lessonsToReturn.length + 1,
          start_time: startingTime,
        });
        lessonsToReturn.push({
          number: lessonsToReturn.length + 1,
          start_time: startingTime,
        });
        lessonsToReturn.push({
          number: lessonsToReturn.length + 1,
          start_time: startingTime,
        });
      }
      lessonsToReturn.splice(totalNumberOfLessons, lessonsToReturn.length);
    }
    return lessonsToReturn;
  }
};

const getCompletedLessonsData= (activeModuleLessons) => {
  return activeModuleLessons.filter((lesson)=>
    ((new Date(lesson?.start_time).getTime()+30*60*1000)< new Date().getTime()),
  );
};


/**
 *
 *
 * @param {*} schedule1
 * @param {*} schedule2
 * @param {*} schedule3
 * @param {*} lessonLength
 * @param {*} classesSelected
 * @return {*} lessonArray
 */
function getLessons(schedule1, schedule2, schedule3, lessonLength,
    classesSelected) {
  const lessonsToReturn = [];
  let modifiedLessonLength;
  if (lessonLength) {
    if (classesSelected === 1) {
      modifiedLessonLength = lessonLength * 2;
      if (schedule1) {
        for (let i = 0; i < modifiedLessonLength; i++) {
          let day1 = schedule1.clone();
          day1 = day1.add(i*7, 'days').toISOString();
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day1,
            status: 'scheduled',
          });
        }
        return lessonsToReturn;
      }
    }
    if (classesSelected === 2) {
      modifiedLessonLength = lessonLength;
      if (schedule1 && schedule2) {
        for (let i = 0; i < modifiedLessonLength; i++) {
          let day1 = schedule1.clone();
          day1 = day1.add(i*7, 'days').toISOString();
          let day2 = schedule2.clone();
          day2 = day2.add(i*7, 'days').toISOString();
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day1,
            status: 'scheduled',
          });
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day2,
            status: 'scheduled',
          });
        }
        return lessonsToReturn;
      }
    }
    if (classesSelected === 3) {
      modifiedLessonLength = lessonLength;
      if (schedule1 && schedule2 && schedule3) {
        for (let i = 0; i < modifiedLessonLength; i++) {
          let day1 = schedule1.clone();
          day1 = day1.add(i*7, 'days').toISOString();
          let day2 = schedule2.clone();
          day2 = day2.add(i*7, 'days').toISOString();
          let day3 = schedule3.clone();
          day3 = day3.add(i*7, 'days').toISOString();
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day1,
            status: 'scheduled',
          });
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day2,
            status: 'scheduled',
          });
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day3,
            status: 'scheduled',
          });
        }
        lessonsToReturn.splice(lessonsToReturn.length - 4,
            lessonsToReturn.length);
        return lessonsToReturn;
      }
    }
  }
  return lessonsToReturn;
}

export const getObjToPass = (schedule1, schedule2, schedule3, courseInfo,
    lessonArray, studentId, lessonsPerWeek,
    newTimetableEligibility) => {
  let firstDayOfWeek;
  let secondDayOfWeek;
  let tempSchedule;
  if (schedule1.day() === 0) {
    firstDayOfWeek = 7;
  } else {
    firstDayOfWeek = schedule1.day();
  }
  if (lessonsPerWeek >= 2) {
    tempSchedule = schedule2;
    if (schedule2.day() === 0) {
      secondDayOfWeek = 7;
    } else {
      secondDayOfWeek = schedule2.day();
    }
  } else {
    tempSchedule = schedule1;
    secondDayOfWeek = firstDayOfWeek;
  }

  const activeModule=courseInfo?.activeModule;
  const moduleRegistrationId=courseInfo?.relationships?.modules?.data?.[
    activeModule-1]?.attributes?.registration_id;

  const requestObj = {
    'data': {
      'type': 'registrations',
      'attributes': {
        'schedule': {
          'first_day_of_week': newTimetableEligibility? null :
           firstDayOfWeek,
          'second_day_of_week': newTimetableEligibility? null :
          secondDayOfWeek,
          'third_day_of_week': schedule3 && !newTimetableEligibility ?
           schedule3?.day():null,
          'first_day_hour': newTimetableEligibility? null :
           parseInt(schedule1.format('H')),
          'second_day_hour': newTimetableEligibility ? null :
          parseInt(tempSchedule.format('H')),
          'third_day_hour': newTimetableEligibility? null :
          parseInt(schedule3?.format('H')),
          'lessons': lessonArray,
          'lessons_per_week': lessonsPerWeek,
        },
      },
      'relationships': {
        'student': {
          'data': {
            'type': 'student',
            'id': studentId,
          },
        },
      },
      'id': newTimetableEligibility ? moduleRegistrationId : courseInfo?.id,
    },
  };
  return requestObj;
};
