import React, {useState, useEffect} from 'react';
import styles from '../Flashcards/flashcard.module.scss';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import {popUpAction, ctaClickAction} from '../../Shared/TrackActionService';
import {navigate} from 'gatsby';

const FlashCard = (props) => {
  const {moduleNumber, courseSlug, studentId, locale,
    moduleData, lessonNumber} = props;
  const selectedModuleData=moduleData?.[moduleNumber-1];
  const parsedLessonIndex=parseInt(lessonNumber-1);
  const selectedLessonData=
  selectedModuleData?.attributes?.lessons?.[parsedLessonIndex];
  const flashCards = selectedLessonData?.flashcard?.flashcards || [];
  const [showSlideAnimation, setShowSlideAnimation]=useState(false);
  const [showFlipAnimation, setShowFlipAnimation]=useState(false);
  const [showFlipReverseAnimation, setShowFlipReverseAnimation]=useState(false);
  const [hideStack, setHideStack]=useState(false);
  const [showQuestion, setShowQuestion]=useState(true);
  const [showPreviousContent, setShowPreviousContent]=useState(false);
  const [stacks, setStacks]=useState(flashCards.slice(0, 6));
  const [allStacks, setAllStacks]=useState(flashCards.slice(0, 6));
  // const [earnedPoints, setEarnedPoints]=useState(0);
  const [nextCtaText, setNextCtaText]=useState('');
  const chunk=3;
  const batchNumber=Math.floor((allStacks?.length - stacks?.length)/chunk) + 1;
  const cardNumber = (allStacks?.length-stacks?.length)%chunk+1;
  const cardsInBatch =
  allStacks.slice((batchNumber-1)*chunk, ((batchNumber-1)*chunk+chunk))?.length;

  useEffect(()=>{
    setAllStacks(flashCards.slice(0, 6));
    popUpAction({
      studentId,
      'title': 'FlashCardsPopup',
    });
    ctaClickAction({
      studentId,
      locale,
      courseSlug,
      'moduleNumber': parseInt(moduleNumber),
      'cta': 'Study More',
      'action': 'InitiateFlashCardsPopup',
    });
  }, []);

  useEffect(()=>{
    const moduleRegId =
     moduleData?.[moduleNumber-1]?.attributes?.registration_id;
    const isFlashCardAvailable=
    selectedLessonData?.flashcard?.flashcards?.length;
    if (!moduleRegId || !isFlashCardAvailable) {
      navigate('../../');
    }
  }, [moduleData, lessonNumber]);

  useEffect(()=>{
    const NextButtonText=stacks.length===1 ?
     `Continue to Next Lesson` :
    (stacks.length!==1&&cardNumber===cardsInBatch ?
        `Next Batch` : `Next`);
    setNextCtaText(NextButtonText);
  }, [stacks]);

  useEffect(() => {
    let timeout;
    if (showQuestion) {
      timeout = setTimeout(() => {
        showCardContent('reveal');
      }, 6000);
      return () =>{
        clearTimeout(timeout);
      };
    } else {
      return () =>{
        clearTimeout(timeout);
      };
    }
  }, [stacks, showPreviousContent, showQuestion]);

  const showNextCard = () => {
    if (stacks.length!==1) {
      setShowSlideAnimation(true);
      setShowFlipAnimation(false);
      setShowPreviousContent(false);
      setShowQuestion(true);
      setTimeout(()=>{
        updateStack();
      }, 800);
      ctaClickAction({
        studentId,
        locale,
        'cta': 'Next Card',
        'action': 'NextQuestionShown',
      });
    } else {
      updateStack();
      closeFlashCards();
      ctaClickAction({
        studentId,
        locale,
        'cta': 'Continue to next lesson',
        'action': 'CompleteFlashCardsPopup',
      });
    }
  };

  const updateStack = () => {
    const newStack=stacks;
    newStack.shift();
    setStacks([...newStack]);
    setShowSlideAnimation(false);
  };

  const showStack = () => {
    setHideStack(false);
  };

  const showCardContent = (param) => {
    if (param==='reveal') {
      setShowQuestion(false);
      setShowFlipReverseAnimation(false);
      setShowFlipAnimation(true);
      ctaClickAction({
        studentId,
        locale,
        'cta': 'Reveal Answer',
        'action': 'AnswerShown',
      });
    } else {
      setShowQuestion(true);
      setShowPreviousContent(true);
      setShowFlipReverseAnimation(true);
      setShowFlipAnimation(false);
      ctaClickAction({
        studentId,
        locale,
        'cta': 'Replay',
        'action': 'Replay',
      });
    }
    setShowSlideAnimation(false);
    setHideStack(true);
    setTimeout(()=>{
      showStack();
    }, 600);
  };

  const checkAnimationType = () => {
    if (showSlideAnimation) {
      return styles.slideAnimation;
    }
    if (showFlipReverseAnimation) {
      return styles.flipReverseAnimation;
    }
    if (showFlipAnimation) {
      return styles.flipAnimation;
    }
  };

  const closeCard= () => {
    closeFlashCards();
  };

  const closeFlashCards = () => {
    navigate('../../');
  };

  const TimerRenderer = ({formatted}) => {
    return <span className={styles.timerWrapper}>
      {formatted.seconds}
    </span>;
  };
  TimerRenderer.propTypes = {
    formatted: PropTypes.object,
    completed: PropTypes.bool,
  };

  return (
    <div className={styles.popUpModal}>
      <div className={styles.close}
        onClick={closeCard}>
        <img src='/images/postpopup-close.png'
          datasrc='/images/postpopup-close.png'/>
      </div>
      {allStacks?.length>0 ? <div className={styles.popUpSection}>
        {stacks.map((item, index)=>
          <div
            key={item?._metadata?.uid}
            id={index!==0&&hideStack?styles.hide:''}
            className={!index ? checkAnimationType():''}>
            <h3 className={`${styles.header} ${
              showQuestion ?styles.hideHeader : styles.orientation}`}>
              Solution</h3>
            <div className={styles.popUpContent}>
              <div className={styles.border}>
                <div className={styles.lessonDetails}>
                  <div className={styles.lessonName}>
                    <p>{selectedLessonData?.lessonname}</p>
                    <h3>Batch {
                      batchNumber} - 0{cardNumber}/0{cardsInBatch}</h3>
                    {/* <span className={styles.pointScored}>
                + 10 XP
                    </span> */}
                  </div>
                  {/* {earnedPoints>0 &&
                  <div className={styles.pointsSection}>
                    <p>Earned</p>
                    <span>
                      {earnedPoints} XP
                    </span>
                  </div>} */}
                </div>
                <div className={styles.lessonDescriptionWrapper}>
                  {!showQuestion &&
                <div className={styles.lessonDescription}>
                  {item?.answer}
                </div>}
                  {showQuestion &&
                <div className={styles.lessonDescription}>
                  {item?.question}
                </div>}
                </div>
                {!showQuestion &&
                <div className={`${styles.ctaSection} ${
                  styles.orientation}`}>
                  <button
                    onClick={()=>showCardContent('replay')}
                    className={styles.replayCta}>
                    <div
                      className={`library-shaw-icon-new`}>
                      <i className="icon-new-Reload"></i>
                        Replay
                    </div>
                  </button>
                  <button
                    onClick={showNextCard}
                    className={styles.nextCta}>
                    {nextCtaText}
                  </button>
                </div>}
                {showQuestion && <div className={styles.ctaSection}>
                  <button
                    onClick={()=>showCardContent('reveal')}
                    className={styles.revealAnswerCta}>
                    Reveal Answer</button>
                </div>}
                {showQuestion &&
                <p className={styles.autoTime}>
                  Auto revealing in <Countdown
                    renderer={TimerRenderer}
                    date={Date.now() + 6000}>
                  </Countdown> seconds</p>}
              </div>
            </div>
          </div>)}
      </div> : <></>}
    </div>
  );
};

FlashCard.propTypes = {
  lessonNumber: PropTypes.string,
  moduleNumber: PropTypes.string,
  courseSlug: PropTypes.string,
  studentId: PropTypes.string,
  locale: PropTypes.string,
  setXpObject: PropTypes.func,
  isActiveMember: PropTypes.bool,
  name: PropTypes.object,
  moduleData: PropTypes.array,
};

export default FlashCard;
