import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import HeaderNew from '../Shared/HeaderNew';
import courseStyles from '../../styles/Courses.module.scss';

/**
 *
 *
 * @export
 * @class RegistrationDetailNew
 * @extends {Component}
 */
export default class RegistrationDetailNew extends Component {
  /**
   * Creates an instance of RegistrationDetailNew.
   * @memberof RegistrationDetailNew
   */
  constructor() {
    super();
    this.state = {
      hasMount: false,
    };
  }
  /**
   *
   *
   * @memberof RegistrationDetailNew
   */
  componentDidMount() {
    this.setState({hasMount: true});
  }
  /**
   *
   *
   * @return {*}
   * @memberof RegistrationDetailNew
   */
  render() {
    return (
      this.state.hasMount && <>
        <Helmet>
          <title>Course Detail</title>
        </Helmet>
        <div className="shaw-container flex-space-between"
          style={{minHeight: '100vh'}}>
          <HeaderNew
            registrationData={this.props?.registrationData}
            firstName={this.props?.firstName}
            locale={this.props?.locale}
            courseSelectedOnMobileSwitcher=
              {this.props?.courseSelectedOnMobileSwitcher}/>
          <div className={courseStyles.shawRecordings}>
            {this.props?.children}
          </div>
        </div>
      </>
    );
  }
}

RegistrationDetailNew.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
  registrationData: PropTypes.array,
  courseSelectedOnMobileSwitcher: PropTypes.object,
  credits: PropTypes.number,
  firstName: PropTypes.string,
};
