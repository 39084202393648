import React, {useState} from 'react';
import {Button, Modal, Spinner} from 'react-bootstrap';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import * as apiService from '../../../api';
import {navigate} from 'gatsby';
import deleteCourseStyles from '../DeleteCourse.module.scss';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
const DeleteCourse = (props) => {
  const {onHideModal, locale, courseToDelete} = props;
  const [showLoadingSpinner, triggerLoadingSpinner] = useState(false);
  const deleteCourse = () => {
    let flag = 0;
    courseToDelete.relationships.modules.data.forEach((module, index) => {
      const studentId=cookie.load('shawacademy_leadId');
      const regId=module?.attributes?.registration_id;
      const moduleNumber=index+1;
      const parameter = {
        'data': {
          'deferred_reason': 'user delete course',
        },
      };
      if (regId) {
        triggerLoadingSpinner(true);
        new apiService.default.Schedule(regId).delete(parameter)
            .then((res) => {
              flag = flag + 1;
              if (flag === 1) {
                window['dataLayer'].push({
                  'event': 'registration-action',
                  'registration-param': {
                    studentId,
                    'action': 'CourseRemoved',
                    'courseSlug': courseToDelete.attributes.localizedcourseslug,
                    moduleNumber,
                    'facultySlug': courseToDelete.attributes.tags[0] || '',
                    'rescheduleType': null,
                    'scheduleType': cookie.load('country') === 'gb' ? 'v2' :
                    'v1',
                  },
                });
              }
              navigate(`/app/${locale}/`);
            }, (err) => {
              console.log('34', err);
            });
      }
    });
  };

  return (
    <div className={deleteCourseStyles.deleteModal}>
      <div className={`${deleteCourseStyles.shawModalBackdrop}
      ${deleteCourseStyles.active}`}></div>
      <Modal.Dialog>
        <Modal.Header>
          <button className='close' onClick={onHideModal}>
            <img src="/popups/cross.svg" alt="close"/>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1>Are you sure you want to remove this course?</h1>
          <ul>
            <li>Remember you are on an educational journey.</li>
            <li>The more you learn today, the more success tomorrow brings.</li>
            <li>
              You will no longer be able to get diploma to this course anymore.
            </li>
          </ul>
          <div className='options'>
            <div className='cta-section'>
              <Button onClick={onHideModal}>
                Cancel
              </Button>
              <p onClick={deleteCourse}>
                Remove this course
              </p>
            </div>
            {showLoadingSpinner && <Spinner animation="border" role="status">
            </Spinner>}
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
};

DeleteCourse.propTypes = {
  onHideModal: PropTypes.func,
  courseToDelete: PropTypes.any,
  locale: PropTypes.string,
};

export default DeleteCourse;
