import React, {useState} from 'react';
// import offerTag from '../../../../static/recordings/tag-filled.svg';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import * as purchaseAction from './AddonPurchaseActionService';
import * as trackAction from '../../Shared/TrackActionService';
import * as utils from '../../Shared/Utils';
import AddonsConfirmation from '../../Shared2/Popup/AddonsConfirmation';
import styles from '../AddonThinBanner.module.scss';

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export default function ToolkitThinBanner(props) {
  const studentId = cookie.load('shawacademy_leadId');
  const toolkitPrices = props?.addonPrices;
  const courseSlug = props.courseSlug;
  const moduleNumber = props.moduleNumber;
  const regId = props.registrationId;
  const locale = props.locale;
  const purchased = props.toolkitPurchased;
  const [disabled, setDisabled] = useState(false);
  const [showConsentPopup, showConfirmationPopup] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const isConsentEligible = utils.checkPurchaseConsentEligibility();

  /**
 *
 *
 * @param {*} data
 */
  const purchaseAddon = (data) => {
    const product = 'CourseToolkit';
    const productName = 'TOOLKIT';
    const textInfo = 'Course Materials';
    const objToPass = {
      currencyCode: data?.currency_code,
      activeTierCode: data?.default_tier_code,
      addon: 'toolkit',
      cta: encodeURI(props.cta),
      country: data.country_code,
      slug: courseSlug,
      moduleNumber: parseInt(moduleNumber),
      studentId: studentId,
      courseName: props?.moduleData?.attributes?.modulename,
      regId: regId,
      locale: locale,
      isEqfCourse: props.isEqfCourse,
      moduleSlug: props.moduleSlug,
      pageKind: props.pageKind,
    };
    setDataToPassToConfirmation({
      param: 'toolkit',
      title: 'CourseToolkitTwoClickPopup',
      cta: 'Get your Toolkit-modal',
      courseSlug,
      moduleSlug: props.moduleSlug,
      courseName: props?.moduleData?.attributes.modulename,
      moduleNumber: parseInt(moduleNumber),
      locale,
      pageKind: props.pageKind,
      studentId,
      priceInfo: data,
      product,
      textInfo,
      productName,
      regId,
    });
    setDisabled(true);
    if (isConsentEligible) {
      showConfirmationPopup(true);
      trackAction.ctaClickAction({
        'studentId': studentId,
        'cta': props?.cta,
        'action': 'InitiatePurchaseToolkit',
        'pageKind': props?.pageKind,
        'locale': props?.locale,
        'moduleSlug': props?.moduleSlug,
      });
      return;
    }
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': props?.cta,
      'action': 'PurchaseToolkit',
      'pageKind': props?.pageKind,
      'locale': props?.locale,
      'moduleSlug': props?.moduleSlug,
    });
    purchaseAction.addonPurchaseAction(objToPass);
  };

  const closeModalPopup = () => {
    showConfirmationPopup(false);
    setDisabled(false);
  };

  return (
    <div className={`${styles.shThinBanner} ${styles.blueBanner} ${
        (props.registrationId && !purchased) ? '' : styles.hide}`}>
      <div className={styles.specialOfferSection}>
        <p className={styles.offerText}>
          Unlock All Your Course Materials
        </p>
      </div>
      <div className={styles.ctaSection}>
        {isConsentEligible?'':
      <p className={styles.priceText}>
        {toolkitPrices?.currency_symbol} {toolkitPrices?.default_tier_price}
          &nbsp;only
      </p>}
        <Button
          className={`${styles.getToolkit}
          ${disabled ? 'disabled' : ''}`}
          onClick={() => purchaseAddon(toolkitPrices)}
        >
          Get Your Course Materials
          <p className={styles.oneClickText}>
            {isConsentEligible ? '' : props.oneClickText}
          </p>
        </Button>

      </div>
      {showConsentPopup &&
      <AddonsConfirmation
        dataToPassToConfirmation={dataToPassToConfirmation}
        showAddonsPopUp={closeModalPopup}/>}
    </div>
  );
}

ToolkitThinBanner.propTypes = {
  registrationId: PropTypes.string,
  addonPrices: PropTypes.object,
  courseSlug: PropTypes.string,
  moduleNumber: PropTypes.number,
  registrationId: PropTypes.string,
  locale: PropTypes.string,
  toolkitPurchased: PropTypes.bool,
  isEqfCourse: PropTypes.bool,
  moduleSlug: PropTypes.string,
  pageKind: PropTypes.string,
  oneClickText: PropTypes.string,
  specialOfferText: PropTypes.string,
  cta: PropTypes.string,
  moduleData: PropTypes.object,
};
