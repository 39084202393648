import dayjs from 'dayjs';

export const getLessonsArray = (activeModuleData) => {
  const lessons = activeModuleData?.attributes?.lessons;
  const arrToSend = [];
  for (const lesson of lessons) {
    const obj = {
      number: lesson?.number,
      start_time: lesson?.number === 1 ?
        new Date(new Date().getTime() + 10000).toISOString() :
        lesson?.start_time,
    };
    arrToSend.push(obj);
  }
  return arrToSend;
};


export const getObjToSend = (lessons, activeModuleData, studentId) => {
  const attributes = activeModuleData?.attributes;
  const lesson1StartTime = attributes?.lessons[0]?.start_time;
  const lesson2StartTime = attributes?.lessons[1]?.start_time;
  return {
    data: {
      'type': 'registrations',
      'id': attributes?.registration_id,
      'attributes': {
        'schedule': {
          'first_day_of_week': dayjs(lesson1StartTime).day() === 0 ? 7 :
          dayjs(lesson1StartTime).day() === 1 ? 1 :
          dayjs(lesson1StartTime).day() - 1,
          'second_day_of_week': dayjs(lesson2StartTime).day() === 0 ? 7 :
          dayjs(lesson2StartTime).day() === 1 ? 1 :
          dayjs(lesson2StartTime).day() -1,
          'first_day_hour': dayjs(lesson1StartTime).hour(),
          'second_day_hour': dayjs(lesson2StartTime).hour(),
          'lessons': lessons,
          'lessons_per_week': attributes?.lessons_per_week,
        },
      },
      'relationships': {
        'student': {
          'data': {
            'type': 'student',
            'id': studentId,
          },
        },
      },
    },
  };
};

