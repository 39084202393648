import * as apiService from '../../../api';
// import {navigate} from 'gatsby';
import cookie from 'react-cookies';
import * as trackAction from '../../Shared/TrackActionService';

export const addonPurchaseAction = (data) => {
  const dataToApi = getDataToPass(data);
  const dataToPurchaseAddonApi=getPurchaseAddonData(data);
  buyAddon(dataToApi, data, dataToPurchaseAddonApi);
};

export const addonBuyAction = (data) => {
  const dataToApi = getDataToPass(data);
  const dataToAddonApi=getPurchaseAddonData(data);
  return buy(dataToApi, data, dataToAddonApi);
};

/**
 *
 *
 * @param {*} lang
 * @param {*} regId
 * @param {*} moduleNo
 * @param {*} addonId
 * @return {*} url
 */
function getUrl(lang, regId, moduleNo, addonId) {
  if (addonId === 'toolkit') {
    const url =
    `/app/${lang}/v2/registrations/${regId}/modules/${
      moduleNo}/post-purchase/toolkit/`;
    return url;
  }
  if (addonId === 'hard-copy-diploma' ||
  addonId === 'hcd') {
    const url =
    `/app/${lang}/v2/registrations/${regId}/modules/${
      moduleNo}/post-purchase/hcd/`;
    return url;
  }
  return `/app/${lang}/v2/registrations/${regId}/modules/${
    moduleNo}/post-purchase/ecert/`;
}


/**
 *
 *
 * @param {*} error
 * @return {*}
 */
function getFailureReason(error) {
  let reason='';
  if (error?.response?.data?.errors?.[0]?.title) {
    reason=error?.response?.data?.errors?.[0]?.title;
  }
  return reason;
}

/**
 *
 *
 * @param {*} dataToApi
 * @param {*} dataToNavigate
 * @param {*} purchaseAddonPayload
 *
 */
function buyAddon(dataToApi, dataToNavigate, purchaseAddonPayload) {
  const regId = dataToNavigate.regId;
  const moduleNo = dataToNavigate.moduleNumber;
  const locale = dataToNavigate.locale;
  const slug = dataToNavigate.slug;
  const id = dataToNavigate.studentId;
  const tierCode = dataToNavigate.activeTierCode;
  const domain = process.env.PaymentWebDomain;
  const localeUrl = `${domain}/${locale}/payment`;
  const url = `${domain}/payment`;
  const queryParams =
    `?eqf_slug=${slug}&module_number=${moduleNo}&lead_id=${id}&tc=${tierCode}`;
  let paymentPageUrl = `${localeUrl}/course-toolkit/${queryParams}`;
  let productToTrack = 'CourseToolkit';
  let productCode=`_toolkit_`;
  // let productType='module-toolkit';
  if (locale === 'en') {
    paymentPageUrl = `${url}/course-toolkit/${queryParams}`;
  }
  if (dataToNavigate.addon === 'cert') {
    productToTrack = 'CourseEcert';
    // productType='module-cert';
    productCode=`_cert_`;
    paymentPageUrl =
    `${localeUrl}/course-completion-certificate/${queryParams}`;
    if (locale === 'en') {
      paymentPageUrl = `${url}/course-completion-certificate/${queryParams}`;
    }
  }
  if (dataToNavigate.addon === 'hard-copy-diploma') {
    productToTrack = 'HardCopyDiploma';
    // productType='course-hard-copy-diploma';
    productCode=`_hard-copy-diploma_v1_`;
    paymentPageUrl =
      `${localeUrl}/hard-copy-diploma/${queryParams}`;
    if (locale === 'en') {
      paymentPageUrl = `${url}/hard-copy-diploma/${queryParams}`;
    }
  }

  new apiService.default.Addons().purchaseAddon(purchaseAddonPayload)
      .then((response) => {
        window.dataLayer.push({
          'event': 'addon-purchase-action',
          'event-param': {
            'identity': id,
            'product': productToTrack,
            'courseSlug': dataToNavigate?.slug,
            'moduleNumber': dataToNavigate?.moduleNumber,
            'quantity': 1,
            'pageKind': dataToNavigate?.pageKind,
            'cta': decodeURI(dataToNavigate.cta),
          },
        });
        const dataToOrderApi = getDataToOrdersApi(dataToApi, dataToNavigate);
        new apiService.default.Addons().giftAddon(dataToOrderApi)
            .then((res) => {
              const moduleNum=moduleNo || '1';
              const urlToNavigate =
        getUrl(locale, regId, moduleNum, dataToNavigate.addon);
              setCookies(slug, dataToNavigate);
              setTimeout(() => {
                // navigate(urlToNavigate);
                window.location.href = urlToNavigate;
              }, 5000);
            })
            .catch((err) => {

            });
      })
      .catch((error) => {
        const errBody=error.response.data.meta;
        const reason=getFailureReason(error);
        // const countryCodeTosend=cookie.load('country');
        trackAction.trackPurchaseFailure({
          'identity': id,
          locale,
          'product': productToTrack,
          'courseSlug': dataToNavigate?.slug,
          'moduleNumber': dataToNavigate?.moduleNumber,
          'product_code': productCode,
          'coupon_code': null,
          'quantity': 1,
          'addonId': '',
          'pageKind': dataToNavigate?.pageKind,
          'cta': decodeURI(dataToNavigate.cta),
          'reason': reason,
        });
        if (error?.response?.data?.errors?.[0]?.code === '101') {
          const dataToSend={};
          const tempArray=[];
          const data={};
          data['eqf_slug']=slug;
          data['module_number']=moduleNo;
          data['quantity']=1;
          // data['id']=`${productType}_v1_${countryCodeTosend}`;
          data['id']=dataToApi.addons[0].id;
          tempArray.push(data);
          dataToSend['student_id']=id;
          dataToSend['purchase_type']= 'addon';
          dataToSend['currency_code']= dataToApi.currency_code;
          dataToSend['gateway']=errBody.gateway;
          dataToSend['payment_intent']=errBody.payment_intent;
          dataToSend['addons'] = tempArray;
          dataToSend['source_url'] = dataToApi?.source_url;
          const encodedDataToSend=
          encodeURIComponent(btoa(JSON.stringify(dataToSend)));
          const urlToRedirect=`${url}/authorise/?payload=${encodedDataToSend}`;
          window.open(urlToRedirect, '_self');
        } else {
          setTimeout(() => {
            window.location.href = paymentPageUrl;
          }, 1000);
        }
      });
}

/**
 *
 *
 * @param {*} params1
 * @param {*} params2
 * @return {*} obj
 */
function getDataToOrdersApi(params1, params2) {
  const lineItems = [];
  const moduleNumbers = [1, 2, 3, 4];
  moduleNumbers.splice(moduleNumbers.indexOf(params2.moduleNumber), 1);
  for (let i = 0; i < moduleNumbers.length; i++) {
    let obj = {};
    obj = {
      item_id: `module-${params2.addon}`,
      item_type: 'addon',
      eqf_slug: params2.slug,
      module_number: moduleNumbers[i],
      allow_duplicate: true,
    };
    lineItems.push(obj);
  }
  return {
    student_id: params1.student_id,
    line_items: lineItems,
    gateway: {
      name: 'gift',
    },
  };
}

/**
 *
 *
 * @param {*} dataToNavigate
 * @return {*}
 */
function getPaymentPath(dataToNavigate) {
  const moduleNo = dataToNavigate.moduleNumber;
  const locale = dataToNavigate.locale;
  const slug = dataToNavigate.slug;
  const id = dataToNavigate.studentId;
  const tierCode = dataToNavigate.activeTierCode;
  const domain = process.env.PaymentWebDomain;
  const localeUrl = `${domain}/${locale}/payment`;
  const url = `${domain}/payment`;
  const queryParams =
    `?eqf_slug=${slug}&module_number=${moduleNo}&lead_id=${id}&tc=${tierCode}`;

  let productToTrack = 'CourseToolkit';
  let paymentPageUrl = `${localeUrl}/course-toolkit/${queryParams}`;
  if (locale === 'en') {
    paymentPageUrl = `${url}/course-toolkit/${queryParams}`;
  }

  if (dataToNavigate.addon === 'cert') {
    productToTrack = 'CourseEcert';
    paymentPageUrl =
    `${localeUrl}/course-completion-certificate/${queryParams}`;
    if (locale === 'en') {
      paymentPageUrl = `${url}/course-completion-certificate/${queryParams}`;
    }
  }

  if (dataToNavigate.addon === 'hard-copy-diploma') {
    productToTrack = 'HardCopyDiploma';
    paymentPageUrl =
      `${localeUrl}/hard-copy-diploma/${queryParams}`;
    if (locale === 'en') {
      paymentPageUrl = `${url}/hard-copy-diploma/${queryParams}`;
    }
  }

  if (dataToNavigate.addon === 'retake') {
    productToTrack = 'AssessmentRetake';
    paymentPageUrl =
      `${localeUrl}/assessment-retake/${queryParams}`;
    if (locale === 'en') {
      paymentPageUrl = `${url}/assessment-retake/${queryParams}`;
    }
  }
  return ({
    productToTrack,
    paymentPageUrl,
  });
}

/**
 *
 *
 * @param {*} dataToApi
 * @param {*} dataToNavigate
 * @param {*} dataToAddonApi
 * @return {*}
 */
function buy(dataToApi, dataToNavigate, dataToAddonApi) {
  const {productToTrack, paymentPageUrl} = getPaymentPath(dataToNavigate);
  const slug = dataToNavigate.slug;
  const domain = process.env.PaymentWebDomain;
  const url = `${domain}/payment`;
  return new apiService.default.Addons().purchaseAddon(dataToAddonApi)
      .then((response) => {
        const objToTrack = {
          'identity': dataToNavigate.studentId,
          'product': productToTrack,
          'courseSlug': dataToNavigate?.slug,
          'moduleNumber': dataToNavigate?.moduleNumber,
          'quantity': 1,
          'pageKind': dataToNavigate?.pageKind,
          'cta': decodeURI(dataToNavigate.cta),
        };
        trackAction.addonPurchaseAction(objToTrack);
        setCookies(slug, dataToNavigate);
      })
      .catch((error) => {
        const errBody=error?.response?.data?.meta;
        // const countryCodeTosend=cookie.load('country');
        if (errBody && error?.response?.data?.errors?.[0]?.code === '101') {
          const dataToSend={};
          const tempArray=[];
          const data={};
          data['eqf_slug']=slug;
          data['module_number']=dataToNavigate?.moduleNumber;
          data['quantity']=1;
          // data['id']=`${productType}_v1_${countryCodeTosend}`;
          data['id']=dataToApi.addons[0].id;
          if (dataToNavigate.addon === 'retake') {
            data['allow_duplicate']=true;
          }
          tempArray.push(data);
          dataToSend['student_id']=dataToNavigate.studentId;
          dataToSend['purchase_type']= 'addon';
          dataToSend['currency_code']= dataToApi.currency_code;
          dataToSend['gateway']=errBody.gateway;
          dataToSend['payment_intent']=errBody.payment_intent;
          dataToSend['addons'] = tempArray;
          dataToSend['source_url'] = window.location.href;
          const encodedDataToSend=
          encodeURIComponent(btoa(JSON.stringify(dataToSend)));
          const urlToRedirect=`${url}/authorise/?payload=${encodedDataToSend}`;
          window.open(urlToRedirect, '_self');
        } else {
          window.location.href = paymentPageUrl;
        }
      });
}


/**
 *
 *
 * @param {*} moduleSlug
 * @param {*} data
 */
function setCookies(moduleSlug, data) {
  const expires = new Date;
  expires.setMinutes(expires.getMinutes() + 30);
  if (data.addon === 'toolkit') {
    cookie.save(`toolkit_${moduleSlug}_${data.studentId}`,
        true, {
          path: '/',
          domain: process.env.CookieDomain,
          expires,
        });
  } else if (data.addon === 'cert') {
    cookie.save(`cert_${moduleSlug}_${data.studentId}`,
        true, {
          path: '/',
          domain: process.env.CookieDomain,
          expires,
        });
  } else {
    cookie.save(`hcd_${moduleSlug}_${data.studentId}`,
        true, {
          path: '/',
          domain: process.env.CookieDomain,
          expires,
        });
  }
}


/**
 *
 *
 * @param {*} data
 * @return {*} dataToPass
 */
function getDataToPass(data) {
  const tempArr = [];
  const tempObj = {
    id: `module-${data.addon}_${data.activeTierCode}_${data.country}`,
    eqf_slug: data.slug,
    module_number: data.moduleNumber,
    quantity: 1,
  };
  if (data.addon === 'hard-copy-diploma' || data.addon === 'hcd') {
    data.addon = 'hard-copy-diploma';
    tempObj['id'] =
    `course-${data.addon}_${data.activeTierCode}_${data.country}`;
  }
  if (data.addon==='retake') {
    tempObj['id']= `module-assessment-retake_${
      data.activeTierCode}_${data.country}`;
    tempObj['allow_duplicate'] = true;
  }
  tempArr.push(tempObj);
  const postData = {
    student_id: data.studentId,
    currency_code: data.currencyCode,
    addons: tempArr,
    internal_credits_used: null,
    source_url: data?.sourceUrl || window.location.href,
  };
  return postData;
}

/**
 *
 *
 * @param {*} data
 * @return {*}
 */
function getPurchaseAddonData(data) {
  let id=
  `module-${data.addon}_${data.activeTierCode}_${data.country}`;
  if (data.addon === 'hard-copy-diploma') {
    id =
    `course-${data.addon}_${data.activeTierCode}_${data.country}`;
  }
  let allowDuplicate=false;
  if (data.addon === 'retake') {
    id =
`module-assessment-retake_${data.activeTierCode}_${data.country}`;
    allowDuplicate=true;
  }

  return {
    student_id: data.studentId,
    line_items: [
      {
        item_id: id,
        item_type: 'addon',
        eqf_slug: data.slug,
        module_number: data.moduleNumber,
        allow_duplicate: allowDuplicate,
      },
    ],
    coupon_ids: [],
    currency_code: data.currencyCode,
  };
}
