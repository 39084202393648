import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from '../Shared2/AssessmentsBadgeHolder.module.scss';


/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function AssessmentsBadgeHolder(props) {
  const {courseData}=props;
  const [badgesEarned, setBadgesEarned]=useState([]);
  const [moduleData, setModuleData]=useState([]);

  useEffect(() => {
    getBadgesEarned();
  }, [courseData]);

  /**
   *
   * Sets the state for badges earned by the user for the course
   */
  const getBadgesEarned = () => {
    const tempModuleData=courseData?.relationships?.modules?.data;
    if (tempModuleData) {
      setModuleData(tempModuleData);
      const tempBadgesEarned=tempModuleData.reduce((acc, module) => {
        const moduleResult=module?.attributes?.finalassignment?.result || [];
        acc.push(moduleResult);
        return acc;
      }, []);
      if (tempBadgesEarned.length) {
        setBadgesEarned(tempBadgesEarned);
      }
    }
  };

  return (
    <div className={styles.assessmentsBadgeHolder}>
      <div className={styles.headingSection}>
        <p className={styles.heading}>
          Unlock Your Diploma
        </p>
      </div>
      <div className={styles.bodySection}>
        <div className={styles.badgeInfoSection}>
          <p className={styles.info}>
            Collect 4 Badges
          </p>
          <p className={styles.badgesGained}>
            {badgesEarned.length}/{moduleData.length}
          </p>
        </div>
        <div className={styles.badgeListSection}>
          {moduleData.map((module, moduleIndex) => {
            const formId = module?.attributes?.finalassignment
                ?.modular_blocks?.[0]?.typeform?.formid;
            const isBadgeEarned = formId ===
            badgesEarned[moduleIndex]?.reference_key;
            const assessmentBreakdown =
             isBadgeEarned ? badgesEarned[moduleIndex]?.breakDown : '';
            if (assessmentBreakdown) {
              return (<div key={moduleIndex} className={styles.badge}>
                <img src={`/dashboard-new/assessments/${
                  assessmentBreakdown.toLowerCase()}-badge.svg`}
                alt={assessmentBreakdown}/>
                <p>Module {moduleIndex+1} </p>
              </div>);
            }
            return (<div key={moduleIndex} className={styles.badge}>
              <img src={`/dashboard-new/assessments/badge-placeholder.svg`}
                alt="placeholder"/>
              <p>Module {moduleIndex+1} </p>
            </div>);
          })}
        </div>
      </div>
    </div>
  );
}

AssessmentsBadgeHolder.propTypes = {
  courseData: PropTypes.object,
};

export default AssessmentsBadgeHolder;
