import React, {useRef} from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import {ctaClickAction} from '../Shared/TrackActionService';

const CertificateAction = (props) => {
  const {certificateUrl, generatePDFData, addToLinkedInProfile,
    disableCta} = props;
  const linkRef = useRef(null);

  const shareCertificate= (cta) => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?`;
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite?mini=true`;
    const twitterUrl = `https://twitter.com/intent/tweet?`;
    triggerShareCertificateAction(cta);
    let urlToNavigate=``;
    if (cta==='facebook') {
      urlToNavigate=`${facebookUrl}&u=${certificateUrl}`;
    } else if (cta==='linkedIn') {
      urlToNavigate=`${linkedInUrl}&url=${certificateUrl}`;
    } else if (cta==='twitter') {
      urlToNavigate=`${twitterUrl}&url=${certificateUrl}`;
    }
    window.open(urlToNavigate);
  };

  const handleCopyToClipboard = () => {
    triggerShareCertificateAction('Copy Link');
    const linkRefObject = linkRef?.current;
    linkRefObject.select();
    document.execCommand('copy');
  };

  const triggerShareCertificateAction = (cta) => {
    ctaClickAction({
      studentId,
      cta,
      'action': 'shareCertificate',
    });
  };

  return (
    <div className={styles.shareCertificateContainer}>
      <section>
        <h1>Share your Diploma certificate</h1>
        <h5>Add more value to your profile by sharing verifiable&nbsp;
           diploma on your LinkedIn profile.</h5>
        <div>
          <button className={disableCta ? styles.disabled : ''}
            onClick={generatePDFData}>
            <img src="/images/module-certificate/pdf.svg"/>
               Download PDF
          </button>
          <button className={disableCta ? styles.disabled : ''}
            onClick={addToLinkedInProfile}>
            <img src="/images/social/linkedin-white.png"/>
            Add to Profile
          </button>
        </div>
      </section>
      <section>
        <h1>Share your certificate on</h1>
        <div>
          <img className={disableCta ? styles.disabled : ''}
            onClick={()=>shareCertificate('facebook')}
            src='/images/social/new/facebook.svg' alt='facebook'/>
          <img className={disableCta ? styles.disabled : ''}
            onClick={()=>shareCertificate('linkedIn')}
            src='/images/social/new/linkedin.svg' alt='linkedIn'/>
          <img className={disableCta ? styles.disabled : ''}
            onClick={()=>shareCertificate('twitter')}
            src='/images/social/new/twitter.svg' alt='twitter'/>
        </div>
      </section>
      <section>
        <h1>Share your certificate link</h1>
        <div className={styles.copyContainer}>
          <img src='/images/link.png' alt='link-icon'/>
          <input
            readOnly
            ref={linkRef}
            value={decodeURIComponent(certificateUrl)}
          />
          <button className={disableCta ? styles.disabled : ''}
            onClick={handleCopyToClipboard}>Copy</button>
        </div>
      </section>
    </div>
  );
};

CertificateAction.propTypes = {
  certificateUrl: PropTypes.string,
  generatePDFData: PropTypes.func,
  addToLinkedInProfile: PropTypes.func,
  disableCta: PropTypes.bool,
};

export default CertificateAction;
