import React from 'react';
import Style from '../../TimeTable/index.module.scss';

/**
 *
 *
 * @export
 * @return {*}
 */
export default function TimeTableSkeleton() {
  return (
    <div className={`${Style.timeTableWrapper} ${Style.skeletonWrap}`}>
      <div className={Style.itemContainer}>
        <div className={Style.leftContainer}>
          <p className={Style.title}></p>
          <div className={Style.cardWrapper}>
            <div className={Style.box}></div>
            <div className={Style.box}></div>
            <div className={Style.box}></div>
            <div className={Style.box}></div>
            <div className={Style.box}></div>
          </div>
        </div>
        <div className={Style.rightContainer}>
          <p className={Style.title}></p>
          <div className={Style.yearDropdownFlexWrapper}></div>
          <div className={Style.DayDateWrapper}></div>
          <div className={Style.courseWrapper}>
            <p className={Style.dayname}></p>
            <div className={Style.boxWrapper}>
              <div className={Style.scheduleContainer}>
                <div className={Style.monthDate}></div>
                <div className={Style.details}>
                </div>
              </div>
            </div>
            <div className={Style.boxWrapper}>
              <div className={Style.scheduleContainer}>
                <div className={Style.monthDate}></div>
                <div className={Style.details}>
                </div>
              </div>
            </div>
            <div className={Style.boxWrapper}>
              <div className={Style.scheduleContainer}>
                <div className={Style.monthDate}></div>
                <div className={Style.details}>
                </div>
              </div>
            </div>
            <div className={Style.boxWrapper}>
              <div className={Style.scheduleContainer}>
                <div className={Style.monthDate}></div>
                <div className={Style.details}>
                </div>
              </div>
            </div>
            <div className={Style.boxWrapper}>
              <div className={Style.scheduleContainer}>
                <div className={Style.monthDate}></div>
                <div className={Style.details}>
                </div>
              </div>
            </div>
            <div className={Style.boxWrapper}>
              <div className={Style.scheduleContainer}>
                <div className={Style.monthDate}></div>
                <div className={Style.details}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
