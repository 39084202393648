import React, {useState, useEffect} from 'react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
// import ProgressRing from '../ProgressRing';
import courseStyles from '../../../styles/Courses.module.scss';

/**
 *
 *
 * @return {*}
 */
function OnboardProgressBanner({triggerOnboarding, courseData}) {
  const [showOnboardBanner, setShowOnboardBanner] = useState(true);

  useEffect(() => {
    if (cookie.load('onBoardingSkipped') ||
    courseData?.nextLesson?.number > 2 || courseData?.activeModule > 1) {
      setShowOnboardBanner(false);
    }
  }, []);

  const closeOnboarding = () => {
    setShowOnboardBanner(false);
    cookie.save('onBoardingSkipped', true);
  };

  return (
    <>
      {showOnboardBanner && <div className={courseStyles.onboardProgress}>
        <div className={courseStyles.onboardContent}>
          <p className={courseStyles.onboardStep}>
            Getting started
          </p>
          <p className={courseStyles.onboardInfo}>
            Prepare for your first class.
          </p>
          <button
            onClick={() => triggerOnboarding()}
            className={courseStyles.onboardTrigger}
          >Let&apos;s go!</button>
          <span
            onClick={closeOnboarding}
          >Skip</span>
        </div>
        <div className={courseStyles.progressRing}>
          {/* Eventually replace this component with react-svgmt */}
          {/* <ProgressRing
          progress={20}
          size={102}
          strokeWidth={6}
          textItem={'1/5'}
          circleStroke='#f3d179'
        /> */}
        </div>
        {/* <div className={`${progressRing} ${progressRingMob}`>
        <ProgressRing
          progress={20}
          size={56}
          strokeWidth={5}
          textItem={'1/5'}
          circleStroke='#f3d179'
        />
      </div> */}
      </div>}
    </>
  );
}

OnboardProgressBanner.propTypes = {
  triggerOnboarding: PropTypes.func,
  courseData: PropTypes.object,
};


export default OnboardProgressBanner;
