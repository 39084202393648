import React, {useState, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';
import {handleImageError} from '../Shared/Utils';
import AssetsService from '../Shared/AssetsService';
import browseStyles from './browse.module.scss';
import {ctaClickAction} from '../Shared/TrackActionService';
import lockIcon from '../../../static/dashboard-new/courses/lock-filled.svg';
import UpsellUnlockPopUp from '../Shared2/Popup/UpsellUnlockPopUp';
import {getDataToSalesOffer, shouldLockFaculties} from './AllCoursesService';
import cookie from 'react-cookies';

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export default function CourseCard(props) {
  const courseInfo = props.course;
  const selectedTag= props?.selectedTag;
  const locale = props.locale;
  const [imagePath, setImagePath] = useState(AssetsService.DUMMY_IMG);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [dataToSalesOffer, setDataToSalesOffer] = useState(null);

  useEffect(() => {
    // const studentRegistrations = props.studentRegistrations;
    const courseSlug = courseInfo?.courseslug;
    const assetURL = new AssetsService('MY_COURSES').fetchAssetURL(courseSlug);
    setImagePath(assetURL);
  }, [props.course]);

  /**
   *
   *
   * @param {*} courseInfo
   */
  function navigateToPage(courseInfo) {
    const regId = courseInfo?.registrationId;
    if (isCourseLocked()) {
      handleUpgradeCta();
    } else {
      const courseSlug = courseInfo?.courseslug;
      if (courseInfo?.registrationId) {
        navigate(`/app/${locale}/v2/registrations/${regId}/modules/1`);
      } else {
        if (locale === 'en') {
          navigate(`/app/${locale}/register?course_slug=${courseSlug}`);
        } else {
          navigate(`/app/${locale}/register?course_slug=${
            courseSlug}-${locale}`);
        }
      }
    }
    ctaClickAction({
      'cta': regId ? 'Continue Learning' : 'Enroll Now',
      'action': regId ? 'continueLearning' : 'enrollNow',
      'pageKind': 'BrowseCoursesPage',
      locale,
    });
  }

  /**
   *
   *
   * @param {*} courseInfo
   */
  function navigateToCourseInfoPage(courseInfo) {
    const courseSlug = courseInfo?.courseslug;
    navigate(`/app/${locale}/courses/${courseSlug}`, {
      state: {
        faculty: selectedTag,
      },
    });
    ctaClickAction({
      'cta': 'More Info',
      'action': 'moreInfo',
      'pageKind': 'BrowseCoursesPage',
      locale,
    });
  }

  /**
   * @return {*}
   */
  function isCourseLocked() {
    const studentId = cookie.load('shawacademy_leadId');
    const hasUpgraded = cookie.load(`upgrade_${studentId}`);
    const shouldLockFacultiesValue = shouldLockFaculties(
      props?.subscriptionAttributes, props?.studentInfo?.membership,
      props?.registeredTags, hasUpgraded);
    if (shouldLockFacultiesValue) {
      return !(props.registeredTags?.indexOf(props?.course.tags[0]) > -1);
    }
    return false;
  }

  /**
   */
  function handleHideModal() {
    setShowUnlockModal(false);
  }

  /**
   */
  async function handleUpgradeCta() {
    const dataToSalesOffer = await getDataToSalesOffer(
      props?.studentInfo, props?.leadId);
    setDataToSalesOffer(dataToSalesOffer);
    setShowUnlockModal(true);
    ctaClickAction({
      'studentId': props?.leadId,
      'cta': 'Upgrade',
      'action': 'TriggerSalesOfferModal',
      'pageKind': 'BrowseCoursesPage',
    });
  }

  return (
      courseInfo?.active &&
      <>
        <Card className={browseStyles.courseCard}
        >
          <Card.Img src={imagePath}
            onError={handleImageError}
            className={browseStyles.cardImage}
          >
          </Card.Img>
          <Card.Body
            className={browseStyles.cardBody}
          >
            <Card.Title
              className={browseStyles.cardTitle}
            >
              {courseInfo?.coursename}
            </Card.Title>
            <Card.Subtitle
              className={browseStyles.cardSubtitle}
            >
            </Card.Subtitle>
            <div className={`${browseStyles.ctaFlex}
          ${courseInfo?.registrationId ?
            browseStyles.enrolledCta : ''}`}>
              {props?.studentInfo?.membership?.status === 'cancelled' ?
              (courseInfo?.registrationId ?
                <Card.Link onClick={() => navigateToPage(courseInfo)}
                  className={`${browseStyles.cardLink} ${'enrolled'}`}>
                    Continue Learning
                </Card.Link> :
                <Card.Link
                  className={`${browseStyles.cardLink} ${' enroll-now'}
                  ${browseStyles.disabledButton}`}>
                  <span>Enroll Now</span>
                  <img className={browseStyles?.lockIcon}
                    src={lockIcon} alt='lockIcon' />
                </Card.Link>) :
              <Card.Link onClick={() => navigateToPage(courseInfo)}
                className={`${browseStyles.cardLink} ${
                  (courseInfo?.registrationId ? 'enrolled' : ' enroll-now')} ${
                  isCourseLocked() ?
                    browseStyles.disabledButton : ''}`}>
                {
                courseInfo?.registrationId ?
                `Continue Learning` : <>
                  <span>Enroll Now</span>
                  {isCourseLocked() ? <img className={browseStyles?.lockIcon}
                    src={lockIcon} alt='lockIcon' /> : '' }
                </>
                }
              </Card.Link>
              }
              {!courseInfo?.registrationId &&
            <Card.Link
              className={browseStyles.moreInfoCta}
              onClick={() => navigateToCourseInfoPage(courseInfo)}>
              {props?.learnMore}
            </Card.Link>
              }
            </div>
          </Card.Body>
        </Card>
        {
          showUnlockModal &&
          <UpsellUnlockPopUp
            subscriptionInfo={props?.subscriptionAttributes}
            salesOfferData={dataToSalesOffer}
            handleHideModal={handleHideModal}
            showUnlockModal={showUnlockModal}
            source={'BrowseCoursesPage'}
            courseSlug={courseInfo?.courseslug}
          />
        }
      </>
  );
}

CourseCard.propTypes = {
  course: PropTypes.object,
  locale: PropTypes.string,
  learnMore: PropTypes.string,
  isUserInTrial: PropTypes.bool,
  registeredTags: PropTypes.array,
  selectedTag: PropTypes.string,
  tagName: PropTypes.string,
  studentInfo: PropTypes.object,
  subscriptionAttributes: PropTypes.object,
  leadId: PropTypes.string,
  studentRegistrations: PropTypes.object,
};
