import React, {useState, useEffect, useRef} from 'react';
import {VerticalTimelineElement}
  from 'react-vertical-timeline-component';
import {InView} from 'react-intersection-observer';
// import AddToCalendar from 'react-add-to-calendar';
import ReactPlayer from 'react-player';
import cookie from 'react-cookies';
// This import loads the firebase namespace.
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseService from './FirebaseService';
// import Countdown from 'react-countdown';
import ToolkitThinBanner from '../Shared2/Banner/ToolkitThinBanner';
import EcertThinBanner from '../Shared2/Banner/EcertThinBanner';
import LessonAssessmentItem from './LessonAssessmentItem';
import FinalAssignment from './FinalAssignment';
import styles from '../../styles/Courses.module.scss';
import PropTypes from 'prop-types';
import htmr from 'htmr';
import dayjs from 'dayjs';
import * as apiService from '../../api';
import * as registrationDetailService from './RegistrationDetailService';
import {rescheduleNextLesson} from './RegistrationDetailService';
import {ctaClickAction} from '../Shared/TrackActionService';
import VideoPopup from '../Shared2/Popup/VideoPopup';
import AddonsConfirmation from '../Shared2/Popup/AddonsConfirmation';
import * as lessonsDisplayService from './LessonsDisplayService';
import {navigate} from 'gatsby';

// Firebase initialization
const firebaseConfig={
  apiKey: process.env.FirebaseApiKey,
  databaseURL: process.env.FirebaseDatabaseURL,
  projectId: process.env.FirebaseProjectId,
};
if (!firebase?.apps?.length) {
  firebase.initializeApp(firebaseConfig);
}

/**
 *
 *
 * @export
 * @param {*} {
 *   scrolledToLesson, lessonsDisplayProps,
 *   triggerLessonContent, retakePrice,
 *   trackAddToCalendarEvent, checkLessonEligibility,
 *   navigateToReschedule, getEvent, updateLessonsData,
 *   openAssessment, newTimetableEligibility,
 *   setSessionEndTime, sessionEndTime,
 *   sessionStartTime, setSessionStartTime,
 *   courseData, isActiveMember,
 * }
 * @return {*}
 */
export default function LessonsDisplay({
  scrolledToLesson, lessonsDisplayProps,
  triggerLessonContent, retakePrice,
  trackAddToCalendarEvent, checkLessonEligibility,
  navigateToReschedule, getEvent, updateLessonsData,
  openAssessment, newTimetableEligibility,
  setSessionEndTime, sessionEndTime,
  sessionStartTime, setSessionStartTime,
  courseData, isActiveMember, countryCode,
}) {
  const studentId=cookie.load('shawacademy_leadId');
  const [firebaseAuthentication, setFirebaseAuthentictaion] =
  useState(false);
  const [dataToFireBase, setDataToFireBase] = useState(null);
  const [watchNowEligibility, setWatchNowEligibility]=useState(false);
  const [showVimeoPopup, setShowVimeoPopup]=useState(false);
  const [vimeoId, setVimeoId]=useState(null);
  const [showAddonConfirmationBanner, setShowAddonConfirmationBanner]=
  useState(false);
  const [dataToAddonBanner, setDataToAddonBanner]=useState({});
  const [showWatchNow, setShowWatchNow]=useState(false);
  const resourceItems=lessonsDisplayProps?.lesson?.resources?.items;
  const resourceEligibility=lessonsDisplayProps?.toolkitPurchased;
  const isEligibleToViewFile =lessonsDisplayProps?.toolkitPurchased &&
  lessonsDisplayProps.lessonTimeline!==styles.completed;
  const oneClickText = `one click purchase`;
  const playerRef=useRef(null);
  const videoThumbnail =
  lessonsDisplayProps?.lesson?.lessonvideo?.animated_thumbsets;
  const lessonStartTime =lessonsDisplayProps?.lesson?.start_time &&
   new Date(lessonsDisplayProps?.lesson?.start_time);

  useEffect(()=>{
    if (dataToFireBase && firebaseAuthentication) {
      const data = firebaseService.
          setRequiredData('video_play', dataToFireBase);
      createSession(data);
    }
  }, [dataToFireBase, firebaseAuthentication]);

  /**
   *
   *
   * @param {*} data
   */
  const createSession=(data)=> {
    setSessionEndTime(new Date().getTime() / 1000 / 60);
    const watchedMin = Math.ceil(sessionEndTime - sessionStartTime);
    firebaseService.createSessionData(data, watchedMin).then((data)=>{
      if (data) {
        setSessionStartTime(sessionEndTime);
        setInterval(() => updateSession(data), 600000);
      }
    });
  };

  /**
   *
   *
   * @param {*} data
   */
  const updateSession=(data)=> {
    if (data) {
      const itemName = `${data['slug']}_${data['lessonNumber']}_watched_min`;
      const itemName2 = `${data['slug']}_${data['lessonNumber']}_sessionID`;
      const spentMin = Number(sessionStorage.getItem(itemName));
      const sessionID = sessionStorage.getItem(itemName2);
      if (sessionID) {
        setSessionEndTime((new Date().getTime() / 1000) / 60);
        let sessionTime = Math.ceil(sessionEndTime - sessionStartTime);
        if (Number.isNaN(sessionTime)) {
          sessionTime = 0;
        }
        const durationSpent = spentMin + sessionTime;
        const record = {
          watched: durationSpent,
          registrations_id: data['registrations_id'],
        };
        firebaseService.updateSessionData(record, sessionID)
            .then((data)=>{
              if (data) {
                sessionStorage.setItem(itemName, durationSpent.toString());
                setSessionStartTime(sessionEndTime);
              }
            });
      }
    }
  };

  // TODO: Move this to a separate component
  // const TimerRenderer = ({formatted, completed}) => {
  //   if (completed) {
  //     return <p className={styles.infoText}>
  //       Your Lesson is in progress</p>;
  //   } else {
  //     return <p className={styles.infoText}>
  //     Starts in {formatted.hours}:{formatted.minutes}:{formatted.seconds}
  //     </p>;
  //   }
  // };

  /**
   * Onclick of play button of react-player
   * @param {*} dataToFireBase
   */
  const authenticateUser = (dataToFireBase) =>{
    if (dataToFireBase?.fireStoreAuth) {
      firebase.auth().signInWithCustomToken(dataToFireBase?.fireStoreAuth)
          .then((res) => {
            setFirebaseAuthentictaion(true);
          })
          .catch((err) => {
            setFirebaseAuthentictaion(false);
          });
    }
  };

  const videoPauseHandler=()=> {
    const data = firebaseService.
        setRequiredData('video_pause', dataToFireBase);
    updateSession(data);
  };

  /**
   *
   *
   * @param {*} lesson
   * @param {*} moduleNumber
   * @param {*} moduleRegId
   */
  const videoPlayHandler = (lesson, moduleNumber, moduleRegId) => {
    // get authTokens
    const activeModuleData={};
    new apiService.default.FireBaseAuth().authenticateRecording(courseData?.id)
        .then((res) => {
          activeModuleData['activeModuleInfo'] =
           lessonsDisplayProps?.activeModuleData;
          activeModuleData['moduleNumber'] = moduleNumber;
          const courseRegId =courseData?.id;
          const courseSlugData=courseData?.attributes?.courseslug;
          const authData=registrationDetailService.
              fireStoreAuth(res.data, courseRegId, courseSlugData,
                  lesson, activeModuleData, moduleRegId);
          authenticateUser(authData);
          setDataToFireBase(authData);
        })
        .catch((err) => {
          setDataToFireBase(null);
        });
    if (!lessonsDisplayProps?.lesson?.status ||
       lessonsDisplayProps?.lesson?.status==='watching') {
      const videoDuration=playerRef?.current?.getDuration();
      updateLessonsData(lessonsDisplayProps?.moduleIndex+1,
        lessonsDisplayProps?.lesson?.number,
        lessonsDisplayProps?.lesson?.status, videoDuration);
    }
  };

  // Watch now section starts here

  useEffect(() => {
    checkWatchNowEligibility();
  }, []);

  const getUpcomingLessonsInCurrentWeek = () => {
    const upcomingLessonNumber = courseData?.nextLesson?.number;
    const upcomingLessonStartTime = new Date(lessonsDisplayProps?.lesssonsData[
        upcomingLessonNumber-1]?.start_time);
    const firstday = upcomingLessonStartTime.getDate() -
    upcomingLessonStartTime.getDay();
    const lastday = firstday + 6;
    const firstdayInCurrentWeek =
     new Date(upcomingLessonStartTime.setDate(firstday));
    const lastdayInCurrentWeek =
    new Date(upcomingLessonStartTime.setDate(lastday));
    const lessonStartTime = new Date(lessonsDisplayProps?.lesson?.start_time);
    if (lessonStartTime.getTime() >= firstdayInCurrentWeek.getTime() &&
    lessonStartTime.getTime() <= lastdayInCurrentWeek.getTime() &&
      lessonsDisplayProps?.lessonTimeline!==styles.completed) {
      if (upcomingLessonNumber===lessonsDisplayProps?.lesson?.number) {
        return {
          eligible: true,
          show: true,
        };
      } else {
        return {
          eligible: true,
          show: false,
        };
      }
    }
  };

  const checkCurrentLesson = () => {
    let tempUpcomingLesson = false;
    if (lessonsDisplayProps?.activeModuleData) {
      if (lessonsDisplayProps?.lesson?.number ===
         courseData?.nextLesson?.number) {
        tempUpcomingLesson=true;
      } else {
        tempUpcomingLesson=false;
      }
    }
    return tempUpcomingLesson;
  };

  const triggerWatchNow = () => {
    const {locale, studentId, activeModuleData,
      lessonIndex, activeModule}=lessonsDisplayProps;
    const data = {
      locale,
      studentId,
      activeModuleData,
      'courseSlug': courseData?.attributes.localizedcourseslug,
      'nextLessonNumber': lessonIndex+1,
      activeModule,
      'regId': activeModuleData?.attributes?.registration_id,
    };
    ctaClickAction({
      studentId,
      locale,
      'courseSlug': courseData?.attributes.localizedcourseslug,
      'moduleNumber': parseInt(activeModule),
      'cta': 'Watch Now',
      'action': 'InitiateRescheduleNow',
    });
    rescheduleNextLesson(data);
  };

  // const triggerWatchLater = () => {
  //   const {studentId, locale, activeModule}=lessonsDisplayProps;
  //   setWatchNowEligibility(false);
  //   ctaClickAction({
  //     studentId,
  //     locale,
  //     'courseSlug': courseData?.attributes.localizedcourseslug,
  //     'moduleNumber': parseInt(activeModule),
  //     'cta': 'Watch Later',
  //     'action': 'WatchLater',
  //   });
  // };

  const prevLessonStartTime = dayjs(lessonsDisplayProps?.lesssonsData[
      lessonsDisplayProps?.lessonIndex-1]?.start_time);

  const checkWatchNowEligibility = () => {
    let eligibility=false;
    const data=lessonsDisplayProps;
    if (data.moduleRegistrationId) {
      if (!newTimetableEligibility) {
        const isUpcomingLesson =checkCurrentLesson();
        if (isUpcomingLesson) {
          if (data.activeModule===data.moduleIndex+1) {
            if (data.lessonTimeline!==styles.ongoing) {
              if (data.lessonIndex) {
                if (isUpcomingLesson) {
                  if (dayjs().isAfter(prevLessonStartTime
                      .clone().add(35, 'minutes'))) {
                    eligibility=true;
                  }
                }
              } else if (!data.lessonIndex) {
                eligibility=true;
              }
            }
          }
        }
      } else {
        const isEligibleToShowWatchNow = getUpcomingLessonsInCurrentWeek();
        if (isEligibleToShowWatchNow?.eligible) {
          eligibility=true;
          if (isEligibleToShowWatchNow?.show) {
            setShowWatchNow(true);
          } else {
            setShowWatchNow(false);
          }
        }
      }
    }
    setWatchNowEligibility(eligibility);
  };
  // watch now section ends here

  const getResourceType = (filePath) => (
    filePath?.indexOf('pdf')>-1 && 'pdf' ||
    filePath?.indexOf('ppt')>-1 && 'ppt' ||
    filePath?.indexOf('zip')>-1 && 'zip' ||
    'mp4'
  );

  const closeAddonBanner = () => {
    setShowAddonConfirmationBanner(false);
  };

  const showAddonBanner = () => {
    setShowAddonConfirmationBanner(true);
  };

  const getDataToAddonBanner = (data) => {
    setDataToAddonBanner(data);
  };

  const openFlashCards = () => {
    navigate(`../${lessonsDisplayProps?.moduleIndex+1}/flash-cards/${
      lessonsDisplayProps.lessonIndex+1}`);
  };

  const getLessonResources = (filePath, title, resourceType) => {
    const dataToPass={
      lessonsDisplayProps,
      filePath,
      title,
      resourceType,
      resourceEligibility,
      showAddonBanner,
      triggerVimeoPlayer,
      getDataToAddonBanner,
    };
    lessonsDisplayService.showLessonResource(dataToPass);
  };

  const triggerVimeoPlayer = (videoUri) => {
    setVimeoId(videoUri);
    setShowVimeoPopup(true);
  };

  const closeVimeoPopup = () => {
    setShowVimeoPopup(false);
  };

  const navigateToTimeTablePage = () => {
    navigate(`/app/${lessonsDisplayProps.locale}/timetable`);
    ctaClickAction({
      studentId,
      'locale': lessonsDisplayProps?.locale,
      'cta': 'TimeTable',
      'action': 'RedirectToTimeTablePage',
    });
  };

  const getLessonStartMonth = (startTime) => {
    if (newTimetableEligibility) {
      const tempStartTime = new Date(startTime);
      tempStartTime.setDate(tempStartTime.getDate()-6);
      return dayjs(tempStartTime).format('MMM');
    }
    return dayjs(startTime).format('MMM');
  };

  const getLessonStartDate = (startTime) =>{
    if (newTimetableEligibility) {
      const tempStartTime = new Date(startTime);
      tempStartTime.setDate(tempStartTime.getDate()-6);
      return tempStartTime.getDate();
    }
    return lessonStartTime.getDate();
  };

  return (
    <React.Fragment>
      <VerticalTimelineElement
        className={`${styles.lessonContent}
          ${lessonsDisplayProps.lessonTimeline
    } ${!!lessonsDisplayProps.moduleRegistrationId?'':styles.disabled}`}>
        <InView
          className="lesson-in-view-wrapper"
          delay={2000}
          onChange={(inView) =>
            scrolledToLesson(
                inView, lessonsDisplayProps.lesson,
                lessonsDisplayProps.module, lessonsDisplayProps.moduleIndex)}
          threshold={1}>
          <p className={styles.moduleLabel}>
            Module {
              lessonsDisplayProps.moduleIndex+1}
          </p>
          <p className={styles.lessonName}>
            {lessonsDisplayProps.lessonIndex+1}.&nbsp;
            {lessonsDisplayProps.lesson.lessonname}
          </p>
          {lessonsDisplayProps.lesson.lessondescription &&
    <>
      <div className={`${styles.lessonDescription} ${
  lessonsDisplayProps.showLessonContent[lessonsDisplayProps.lessonIndex+1] ?
    styles.active : ''}`}>
        {htmr(lessonsDisplayProps.lesson.lessondescription)}
      </div>
      <p className={styles.readMoreCta}
        onClick=
          {() => triggerLessonContent({
            [lessonsDisplayProps.lessonIndex+1]:
                !lessonsDisplayProps.showLessonContent[
                    lessonsDisplayProps.lessonIndex+1],
          })}>
        {lessonsDisplayProps.showLessonContent[
            lessonsDisplayProps.lessonIndex+1] ?
    `Read Less`:
    `Read More`}
      </p>
    </>}
          {lessonsDisplayProps?.lesson?.flashcard?.flashcards?.length>0 &&
      <div className={`${styles.flashCard} ${
        !!lessonsDisplayProps.moduleRegistrationId?'':styles.disabled}`}>
        <img src="/dashboard-new/courses/flashcard.png"
          alt="scroll-up"></img>
        <p>Learning is Fun with FLASH CARDS</p>
        <span onClick={openFlashCards}>
          Study More
        </span>
      </div>}
          <div className={styles.lessonResourcesBlock}>
            {watchNowEligibility ?
            <div className={styles.watchNowSection}>
              {/* // <div className={`${styles.lessonResources} ${ */}
              {/* //   styles.watchNextLesson}`}> */}
              {/* <div className={styles.animated}></div>
              <div className={styles.watchNowWrapper}
                onClick={triggerWatchNow}>
                <span className='library-shaw-icon-new'>
                  <i className='icon-new-Play1'></i>
                </span>
                <div className={styles.watchNowFlex}>
                  <div>
                    <p className={styles.watchNowText}>
                      Watch Now
                    </p>
                    <p className={styles.watchTimeText}>
                      Watch Time ~ 45 mins
                    </p>
                  </div>
                  <div>
                    <span className='library-shaw-icon-new'>
                      <i className='icon-new-Open-arrow'></i>
                    </span>
                  </div>
                </div>
              </div>
              {!newTimetableEligibility &&
              <div className={styles.watchLaterWrapper}
                onClick={triggerWatchLater}>
                <span className='library-shaw-icon-new'>
                  <i className='icon-new-Reload'></i></span>
                <p className={styles.watchLaterText}>
                  Watch Later
                </p>
              </div> */}
              <ReactPlayer
                url={`https://player.vimeo.com/video/${lessonsDisplayProps.lesson?.lessonvideo?.uri?.split('/')[2]}`}
                controls={false}
                className={styles.reactPlayer}
                light={videoThumbnail?.[1]?.link}
              />
              <div className={styles.bottomBox}>
                {!newTimetableEligibility ?
                <button onClick={triggerWatchNow}>
                  <span className='library-shaw-icon-new'>
                    <i className='icon-new-Play1'></i>
                  </span>
                      Watch Now
                </button> :
                showWatchNow ?
                     <button onClick={triggerWatchNow}>
                       <span className='library-shaw-icon-new'>
                         <i className='icon-new-Play1'></i>
                       </span>
                        Watch Now
                     </button> :
                     <div className={styles.watchNowFallback}>
                       <div className={`library-shaw-icon-new`}>
                         <i className='icon-new-Lock-filled1'></i>
                       </div>
                       <p>This lesson will be unlocked after previous
                          lesson is watched</p>
                     </div>}
              </div>
            </div>:
            <div
              className={`${styles.lessonResources} ${
              newTimetableEligibility &&(lessonsDisplayProps.lessonTimeline ===
              styles.upcoming) ? styles.hideLessonResources : ''}`}>
              {/* TODO: Link To Recordings
              if completed (Popup?),
          Link to Webinar if ongoing,
          Add To calendar if upcomming */}
              {lessonsDisplayProps.lessonTimeline === styles.upcoming ?
              <div className={styles.upcomingLessonSection}>
                {/* <div onClick={() =>
            trackAddToCalendarEvent(lessonsDisplayProps.attributeslesson)}
          className={`${styles.resourceBlock} ${
            styles.addToCalendarLink} add-to-calendar-link`}> */}
                {/* <div className={styles.resourceInfo}>
              <div className={`${styles.resourceIcon
              } library-shaw-icon-new`}>
                <i className="icon-new-Plus-small"></i>
              </div>
              <div className={styles.singleResource}>
                <p className={styles.resourceType}>
                </p>
                {lessonsDisplayProps.moduleRegistrationId &&
                <div className={styles.infoText}>
                  {dayjs(lessonsDisplayProps.lesson['start_time'])
                      .format('MMM DD, hh:mm a')}
                </div>}
              </div>
            </div>
            <AddToCalendar
              rootClass={`react-add-to-calendar ${
                !lessonsDisplayProps.moduleRegistrationId?'down':''}`}
              event={getEvent(lessonsDisplayProps.module,
                  lessonsDisplayProps.lesson)}
              buttonLabel=
                'Add to Calendar' />
            <i className={`${styles.shawArrow}
              ${styles.arrowRight} arrow-right`}></i> */}
                <ReactPlayer
                  url={`https://player.vimeo.com/video/${lessonsDisplayProps.lesson?.lessonvideo?.uri?.split('/')[2]}`}
                  controls={false}
                  className={styles.reactPlayer}
                  light={videoThumbnail?.[1]?.link}
                />
                <div className={styles.bottomBox}>
                  <div className={`library-shaw-icon-new`}>
                    <i className='icon-new-Lock-filled1'></i>
                  </div>
                  {lessonStartTime ? <>
                    <p>This lesson will be unlocked on {
                      getLessonStartMonth(lessonStartTime)} {
                      getLessonStartDate(lessonStartTime)}
                    {!newTimetableEligibility && <>,{' '}
                      {dayjs(lessonStartTime).format('hh:mm A')}</>}</p>
                    {!newTimetableEligibility &&
                      <p>You can reschedule this lesson on your
                        {' '}<span onClick={navigateToTimeTablePage}>
                            timetable
                        </span></p>} </> : <></>}
                </div>
              </div>:
            lessonsDisplayProps.lessonTimeline === styles.completed ?
            <div className={styles.videoWrapper}>
              <ReactPlayer
                url={`https://player.vimeo.com/video/${lessonsDisplayProps.lesson?.lessonvideo?.uri?.split('/')[2]}`}
                controls={true}
                className={`${styles.reactPlayer} ${styles.completedLesson}`}
                onPlay={() => videoPlayHandler(lessonsDisplayProps?.lesson,
                    lessonsDisplayProps.moduleIndex+1,
                    lessonsDisplayProps?.moduleRegistrationId)}
                onPause={() => videoPauseHandler()}
                ref={playerRef}
                playing={false}
                light={videoThumbnail?.[1]?.link}
              />
            </div>:
            // <div className={`${styles.resourceBlock}
            //   ${styles.lessonLink} lesson-link`}
            // onClick={() => checkLessonEligibility(
            //     lessonsDisplayProps.lessonTimeline,
            // lessonsDisplayProps.lesson,
            //     lessonsDisplayProps.moduleRegistrationId,
            //     lessonsDisplayProps.lessonIndex)}>
                  <div className={styles.ongoingLessonSection}>
                    {/* <div className={styles.resourceInfo}>
                <div
                  className={`${styles.resourceIcon
                  } library-shaw-icon`}>
                  <i
                    className={`${styles.iconYoutube }
                    icon-youtube`}>
                  </i>
                </div>
                <div className={styles.singleResource}>
                  <p className={styles.resourceType}>
                    Join Class
                  </p>
                  {!!lessonsDisplayProps.moduleRegistrationId &&
                <Countdown
                  renderer={TimerRenderer}
                  zeroPadTime={2}
                  date={new Date(lessonsDisplayProps.lesson['start_time'])}>
                </Countdown>}
                </div>
              </div>
              <i className={`${styles.shawArrow}
              ${styles.arrowRight} arrow-right`}></i> */}
                    <ReactPlayer
                      url={`https://player.vimeo.com/video/${lessonsDisplayProps.lesson?.lessonvideo?.uri?.split('/')[2]}`}
                      controls={false}
                      className={styles.reactPlayer}
                      light={videoThumbnail?.[1]?.link}
                    />
                    <div className={styles.bottomBox}>
                      <p>Your Lesson is in progress</p>
                      <button onClick={() => checkLessonEligibility(
                          lessonsDisplayProps.lessonTimeline,
                          lessonsDisplayProps.lesson,
                          lessonsDisplayProps.moduleRegistrationId,
                          lessonsDisplayProps.lessonIndex)}>
                          Join
                      </button>
                    </div>
                  </div>}
              {/* Apply active class here
                to toggle active mode*/}
              {/* <div className={`${styles.resourceBlock}
            ${styles.rescheduleBlock} reschedule-block`}
                onClick={() =>
                  navigateToReschedule(lessonsDisplayProps.moduleRegistrationId,
                lessonsDisplayProps?.lessonIndex+1)}>
                <div className={styles.resourceInfo}>
                  <div
                    className=
                      {`${styles.resourceIcon
                      } library-shaw-icon-new`}>
                    <i className="icon-new-Calendar-filled">
                    </i>
                  </div>
                  <div className={styles.singleResource}>
                    <p className={styles.resourceType}>
                      Reschedule Class
                    </p>
                  </div>
                </div>
                <i className={`${styles.shawArrow}
              ${styles.arrowRight} arrow-right`}></i>
              </div> */}
            </div>}
            {resourceItems?.length>0 &&
            <div
              className={styles.lessonResourceSection}>
              <h1>Lesson Resources</h1>
              {resourceItems.map((item, index)=> {
                const title=item?.link?.link?.title ||
                item?.video?.video?.name;
                const filePath=item?.link?.link?.href ||
                item?.video?.video?.uri;
                const resourceType= getResourceType(filePath);
                return <div
                  key={`resource-${index}`}
                  className={styles.resourceFlexBox}>
                  <div className={styles.flexContent}>
                    <div className={styles.leftItem}>
                      <span className='library-shaw-icon-new'>
                        {resourceType!=='mp4' ?
                          <span className={styles.documentIcon}>
                            <i className='icon-document-filled'></i>
                          </span> :
                        <span className={styles.videoIcon}>
                          <i className='icon-new-Play1'></i>
                        </span>}
                      </span>
                      <span id={isEligibleToViewFile?styles.tooltip:''}>
                        {resourceType!=='mp4' ?
                        <a
                          className={isEligibleToViewFile ?
                        styles.disableVew : ''}
                          onClick={()=>getLessonResources(
                              filePath, title, resourceType)}
                          rel="noopener noreferrer"
                          target="_blank">
                          {title?.includes(resourceType)?title:
                      `${title}.${resourceType}`}
                        </a>:
                        <p className={isEligibleToViewFile ?
                          styles.disableVew : ''}
                        onClick={()=>getLessonResources(
                            filePath, title, resourceType)}>
                          {title?.includes(resourceType)?title:
                      `${title}.${resourceType}`}
                        </p>}
                        <span id={styles.tooltipText}>
                          Complete your lesson to unlock your course materials
                        </span>
                      </span>
                    </div>
                    {resourceType!=='mp4' ?
                    <span id={isEligibleToViewFile?styles.tooltip:styles.view}>
                      {resourceEligibility ?
                      <a
                        className={isEligibleToViewFile ?
                        styles.disableVew : ''}
                        onClick={()=>getLessonResources(
                            filePath, title, resourceType)}
                        rel="noopener noreferrer"
                        target="_blank">
                          View
                      </a> :
                      <div
                        onClick={()=>getLessonResources(
                            filePath, title, resourceType)}
                        className={`${styles.lockIcon} library-shaw-icon-new`}>
                        <i className='icon-new-Lock-filled1'></i>
                      </div>
                      }
                      <span id={styles.tooltipText}>
                          Complete your lesson to unlock your course materials
                      </span>
                    </span> :
                    resourceEligibility ?
                      <button
                        className={isEligibleToViewFile ?
                        styles.disableVew : ''}
                        onClick={()=>getLessonResources(
                            filePath, title, resourceType)}>
                        Play
                      </button> :
                      <div
                        onClick={()=>getLessonResources(
                            filePath, title, resourceType)}
                        className={`${styles.lockIcon} library-shaw-icon-new`}>
                        <i className='icon-new-Lock-filled1'></i>
                      </div>
                    }
                  </div>
                </div>;
              })}
            </div>}
            {lessonsDisplayProps.lessonQuiz &&
        <div className={`${styles.lessonResources}
          ${styles.quizResourceLink}`}>
          <a href={lessonsDisplayProps.lessonQuiz} rel="noreferrer"
            target='_blank'
            className={`${styles.resourceBlock}
            ${styles.quizLink}`}>
            <div className={styles.resourceInfo}>
              <div className={`${styles.resourceIcon
              } library-shaw-icon`}>
                <i className={`${styles.iconBook}
                icon-book`}></i>
              </div>
              <div className={styles.singleResource}>
                <p className={styles.resourceType}>
                  Quiz {
                    lessonsDisplayProps.lessonIndex+1}
                </p>
                <p className={styles.infoText}>
                  Practice&nbsp;•&nbsp;{
        lessonsDisplayProps.lesson?.lessonquiz
            ?.modular_blocks.length || 0}
                </p>
              </div>
            </div>
            <i className={`${styles.shawArrow}
            ${styles.arrowRight} arrow-right`}></i>
          </a>
        </div>}
            {/* weekly assessment section */}
          </div>
          {/* Toolkit thin banner displayed
            btw lesson 2 and 3 */}
          {lessonsDisplayProps.lesson?.number===2?
            <ToolkitThinBanner
              registrationId={lessonsDisplayProps.moduleRegistrationId}
              moduleData={lessonsDisplayProps.module}
              oneClickText={oneClickText}
              locale={lessonsDisplayProps.locale}
              toolkitPurchased={lessonsDisplayProps?.toolkitPurchased}
              moduleNumber={lessonsDisplayProps.moduleIndex+1}
              isEqfCourse={lessonsDisplayProps.isEqfCourse}
              pageKind={'LessonsContentPage'}
              cta={'Get Your Toolkit-Scroll'}
              courseSlug=
                {lessonsDisplayProps.courseData?.attributes.localizedcourseslug}
              moduleSlug={lessonsDisplayProps.module.attributes.moduleslug}
              addonPrices={lessonsDisplayProps?.addonPrices?.toolkit}
              countryCode={countryCode} />:
              ''}
          {/* eCert thin banner displayed btw lesson 6 and 7 */}
          {lessonsDisplayProps.lesson?.number===6?
            <EcertThinBanner
              registrationId={lessonsDisplayProps.moduleRegistrationId}
              moduleData={lessonsDisplayProps.module}
              locale={lessonsDisplayProps.locale}
              oneClickText={oneClickText}
              pageKind={'LessonsContentPage'}
              cta={'Get Your Ecert-Scroll'}
              moduleSlug={lessonsDisplayProps.module.attributes.moduleslug}
              ecertPurchased={lessonsDisplayProps.ecertPurchased}
              isEqfCourse={lessonsDisplayProps.isEqfCourse}
              moduleNumber={lessonsDisplayProps.moduleIndex+1}
              courseSlug={lessonsDisplayProps.courseData?.
                  attributes.localizedcourseslug}
              addonPrices={lessonsDisplayProps?.addonPrices?.cert}
              countryCode={countryCode} />:''}
        </InView>
      </VerticalTimelineElement>
      {lessonsDisplayProps.lessonAssessment &&
      <VerticalTimelineElement
        className={`${
          styles.lessonContent}
          ${lessonsDisplayProps.lessonTimeline===styles.ongoing?styles.upcoming:
            lessonsDisplayProps.lessonTimeline}
            ${!!lessonsDisplayProps.moduleRegistrationId?'':
          styles.disabled}`}>
        <p className={`${styles.moduleLabel}`}>
          Module {lessonsDisplayProps.moduleIndex+1}
        </p>
        <p className={styles.assignmentHeader}>
          {lessonsDisplayProps.lessonAssessment.typeform?.title}</p>
        <div className={`${styles.lessonResources
        } ${styles.weeklyAssessmentResourceLink}

  ${lessonsDisplayProps.isLessonAssignmentEnabled? '' : styles.disabled}`}>
          <div className={`${styles.AssesmentProgressBar} ${lessonsDisplayProps.
              lessonAssessment.result?.percentile === 100?
               styles.AssesmentProgressBorder : ''}`}
          style={{width: `${lessonsDisplayProps.
              lessonAssessment.result?.percentile}%`}}>
          </div>
          <LessonAssessmentItem
            openAssessment={openAssessment}
            lessonIndex={lessonsDisplayProps.lessonIndex}
            moduleIndex={lessonsDisplayProps.moduleIndex}
            enabled={lessonsDisplayProps.isLessonAssignmentEnabled}
            assessmentData={lessonsDisplayProps.lessonAssessment} />
        </div>
        {!!lessonsDisplayProps?.moduleRegistrationId &&
        lessonsDisplayProps.lesssonsData.length ===
          lessonsDisplayProps.lessonIndex+1 &&
        <FinalAssignment
          locale={lessonsDisplayProps.locale}
          moduleData={lessonsDisplayProps.module}
          assignment={lessonsDisplayProps.finalAssignment}
          moduleNumber={lessonsDisplayProps.moduleIndex+1}
          courseName={lessonsDisplayProps.courseData?.attributes?.coursename}
          studentInfo={lessonsDisplayProps.membershipInfo}
          courseSlug={lessonsDisplayProps.courseData?.
              attributes.localizedcourseslug}
          ecertPurchased={lessonsDisplayProps.ecertPurchased}
          pageKind={'LessonsContentPage'}
          cta={'Get Your Ecert-Scroll'}
          isEqfCourse={lessonsDisplayProps.isEqfCourse}
          addonPrices={lessonsDisplayProps?.addonPrices?.cert}
          courseRegId={lessonsDisplayProps.courseData?.id}
          courseData={lessonsDisplayProps.courseData}
          finalAssessmentRetakes=
            {lessonsDisplayProps.finalAssessmentRetakes}
          setFinalAssessmentRetakes=
            {lessonsDisplayProps.setFinalAssessmentRetakes}
          retakePrice={retakePrice}
          registrationData={lessonsDisplayProps?.registrationData}
          totalModules={courseData?.relationships?.
              modules?.data?.length}
        />}
      </VerticalTimelineElement>}
      {showVimeoPopup &&
        <VideoPopup
          videoURI={vimeoId}
          closeVideoPopup={closeVimeoPopup}
        />}
      {showAddonConfirmationBanner &&
      <AddonsConfirmation
        dataToPassToConfirmation={dataToAddonBanner}
        showAddonsPopUp={closeAddonBanner}
      />}
    </React.Fragment>
  );
}

LessonsDisplay.propTypes = {
  scrolledToLesson: PropTypes.any,
  triggerLessonContent: PropTypes.any,
  trackAddToCalendarEvent: PropTypes.any,
  checkLessonEligibility: PropTypes.any,
  navigateToReschedule: PropTypes.any,
  openAssessment: PropTypes.func,
  getEvent: PropTypes.func,
  lessonsDisplayProps: PropTypes.object,
  courseData: PropTypes.object,
  sessionStartTime: PropTypes.any,
  setSessionStartTime: PropTypes.func,
  sessionEndTime: PropTypes.any,
  setSessionEndTime: PropTypes.func,
  setXpObject: PropTypes.func,
  isActiveMember: PropTypes.bool,
  retakePrice: PropTypes.object,
  updateLessonsData: PropTypes.func,
  newTimetableEligibility: PropTypes.bool,
  countryCode: PropTypes.string,
};

// TimerRenderer.propTypes = {
//   formatted: PropTypes.object,
//   completed: PropTypes.bool,
// };
