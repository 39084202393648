import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from '../StartNextModulePopup.module.scss';
import {navigate} from '@reach/router';
import * as trackAction from '../../Shared/TrackActionService';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function StartNextModulePopup(props) {
  const {locale, courseSlug, nextModule, regId, studentId} = props;
  const [showModal, setShowModal] = useState(true);
  const contentRef = useRef(null);
  useEffect(() => {
    trackAction.popUpAction({
      locale,
      studentId,
      'title': 'StartNextModulePopup',
      'pageKind': 'LessonsPage',
      courseSlug,
    });
    const nameRef=contentRef.current.querySelector('#studentName');
    nameRef.textContent = props.studentName;
    const moduleNumberRef=contentRef.current.querySelector('#moduleNo');
    moduleNumberRef.textContent = props.nextModule-1;
  }, []);
  const closeModal = () => {
    setShowModal(false);
  };
  const scheduleNextModule = () => {
    trackAction.ctaClickAction({
      locale,
      studentId,
      'pageKind': 'LessonsPage',
      'cta': 'start next module',
      'action': 'NavigateToSchedulePage',
    });
    const route=`/app/${locale}/register?course_slug=${
      courseSlug}&module=${nextModule}&reg_id=${regId}`;
    navigate(route);
  };
  return (
    showModal ? <div className={styles.startNextModuleModal}>
      <div className={styles.modalBackdrop} />
      <div className={styles.startNextModuleModalInner}>
        <div className={styles.header}>
          <span onClick={closeModal}>
            <img src={`/popups/cross.svg`} alt='close' />
          </span>
        </div>
        <div className={styles.content}>
          <div className={styles.heading} ref={contentRef}>
            <p>Hey <strong id="studentName">John</strong>, you have
            completed Module&nbsp;
            <span id="moduleNo">1</span> successfully.
            </p>
          </div>
          <div className={`${styles.img} library-shaw-icon-new`}>
            <img
              src="/dashboard-new/courses/course-locked.png"
              alt="locked"/>
          </div>
          <div className={styles.subheading}>
            Now you can opt to start next module or take a break.
          </div>
        </div>
        <div className={styles.action}>
          <button
            onClick={scheduleNextModule} >
            start next module
          </button>
        </div>
      </div>
    </div>:<></>
  );
}

StartNextModulePopup.propTypes = {
  regId: PropTypes.string,
  locale: PropTypes.string,
  studentId: PropTypes.string,
  studentName: PropTypes.string,
  courseSlug: PropTypes.string,
  nextModule: PropTypes.number,
};

export default StartNextModulePopup;
