import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import * as purchaseAction from './AddonPurchaseActionService';
import * as trackAction from '../../Shared/TrackActionService';
import courseStyles from '../../../styles/Courses.module.scss';
import AddonsConfirmation from '../Popup/AddonsConfirmation';

// TODO: Make this component re-usable for all types of addons


/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function EcertBanner(props) {
  const studentId = cookie.load('shawacademy_leadId');
  const ecertPrices = props?.addonPrices;
  const courseSlug = props.courseSlug;
  const moduleNumber = props.moduleNumber;
  const regId = props.registrationId;
  const locale = props.locale;
  const purchased = props.ecertPurchased;
  const [disabled, setDisabled] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  // const isConsentEligible = utils.checkPurchaseConsentEligibility();
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const isConsentEligible = true;


  /**
 *
 *
 * @param {*} data
 */
  const purchaseAddon = () => {
    const product = 'CourseEcert';
    const productName = 'CERT';
    const textInfo = 'Digital Certificates';
    const objToPass = {
      currencyCode: ecertPrices?.currency_code,
      activeTierCode: ecertPrices?.default_tier_code,
      addon: 'cert',
      country: ecertPrices?.country_code,
      slug: courseSlug,
      cta: encodeURI(props.cta),
      moduleNumber: parseInt(moduleNumber),
      courseName: props?.moduleData?.attributes.modulename,
      studentId: studentId,
      regId: regId,
      locale: locale,
      isEqfCourse: props.isEqfCourse,
      moduleSlug: props.moduleSlug,
      pageKind: props.pageKind,
    };
    setDataToPassToConfirmation({
      param: 'cert',
      title: 'CourseEcertTwoClickPopup',
      cta: 'Get Your Certificate-modal',
      courseSlug,
      moduleSlug: props.moduleSlug,
      courseName: props?.moduleData?.attributes.modulename,
      moduleNumber: parseInt(moduleNumber),
      locale,
      pageKind: props.pageKind,
      studentId,
      priceInfo: ecertPrices,
      product,
      textInfo,
      productName,
      regId,
    });
    setDisabled(true);
    if (isConsentEligible) {
      setShowConfirmationPopup(true);
      // TODO: tracking
      trackAction.ctaClickAction({
        'studentId': studentId,
        'cta': props.cta,
        'action': 'InitiatePurchaseEcert',
        'pageKind': props.pageKind,
        'locale': props.locale,
        'moduleSlug': props.moduleSlug,
      });
      return;
    }
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': props.cta,
      'action': 'PurchaseEcert',
      'pageKind': props.pageKind,
      'locale': props.locale,
      'moduleSlug': props.moduleSlug,
    });
    purchaseAction.addonPurchaseAction(objToPass);
  };

  const closeModalPopup = () => {
    setShowConfirmationPopup(false);
    setDisabled(false);
  };

  return (
    <div className={`${courseStyles.moduleCertBanner} ${
      (props.registrationId && !purchased) ?
      '' : 'hide'}`}>
      <div className={courseStyles.moduleToolkitHeaderSection}>
        <p className={courseStyles.moduleToolkitHeader}>
          {moduleNumber > 1 ?
          `All Course Digital Certificates` :
          `Unlock Your Course Digital Certificates`}
        </p>
        <p className={courseStyles.moduleToolkitPrice}>
          {ecertPrices?.currency_symbol}
          {ecertPrices?.default_tier_price}
        </p>
      </div>
      <p className={courseStyles.moduleToolkitInfo}>
        Unlock Digital Certificates for your course and gain access to each
        modules final assignment
      </p>
      <div className={courseStyles.moduleToolkitBenefits}>
        <p className={courseStyles.moduleToolkitBenefitsHeader}>
          What you get?
        </p>
        <ul className={courseStyles.moduleToolkitBenefitsBody}>
          <li>
            Weekly Assignments
          </li>
          <li>
            World Class Certification
          </li>
          <li>
            Final Assignment Access
          </li>
        </ul>
      </div>
      <Button className={`${courseStyles.moduleToolkitCta}
      ${disabled?'disabled':''}`}
      onClick={purchaseAddon}>
        Get Your Certificates
      </Button>
      <div className={courseStyles.rightSection}>
        <img src='/dashboard-new/courses/module-ecert-banner.png'
          alt='module-ecert-banner'/>
      </div>
      {showConfirmationPopup &&
      <AddonsConfirmation
        dataToPassToConfirmation={dataToPassToConfirmation}
        showAddonsPopUp={closeModalPopup}/>}
    </div>
  );
}

EcertBanner.propTypes = {
  registrationId: PropTypes.string,
  addonPrices: PropTypes.object,
  courseSlug: PropTypes.string,
  moduleNumber: PropTypes.number,
  registrationId: PropTypes.string,
  locale: PropTypes.string,
  ecertPurchased: PropTypes.bool,
  isEqfCourse: PropTypes.bool,
  moduleSlug: PropTypes.string,
  pageKind: PropTypes.string,
  oneClickText: PropTypes.string,
  cta: PropTypes.string,
  moduleData: PropTypes.object,
  userCountry: PropTypes.string,
};

export default EcertBanner;
