import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../styles/Courses.module.scss';

const SolidBorderWrapper = styled.span`
  width:${(props) => props?.width};
  border-right:none;
  position: absolute;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  left: 0;
`;

const SolidBorder = styled.span`
  border: 1px solid red;
  height: 100%;
  width: ${(props) => props.isSelected?`112px` : `47px`};
  height: 37px;
  position: absolute;
  border-radius: 28px;
`;
const SolidBorderComplete = styled.span`
  width:${(props) => props?.width};
  border:1px solid #eb5b77;
  border-radius:28px 28px 28px 28px;
  height:37px;
  display:inline-block;
  cursor: pointer;
`;
const DashedBorder = styled.span`
  width: 100%;
  border:1px dashed #eb5b77;
  border-left:none;
  border-radius:28px;
  height:37px;
  display:inline-block;
  cursor: pointer;
`;
const DashedBorderComplete = styled.span`
  width:${(props) => props?.width};
  border:1px dashed #eb5b77;
  border-radius:28px 28px 28px 28px;
  height:37px;
  display:inline-block;
  cursor: pointer;
`;

const BorderSpan = styled.span`
display:inline-flex;
align-items:center;
justify-content:center;
color:${(props) => props?.completed?'#fadde3':'#a8abc1'};
width:${(props)=>props.activeModule && props.width?props.width:'47px'};
height:37px;
border-radius:28px;
margin:0 4px;
position:relative;
cursor: pointer;
`;

const VerticalLine = styled.span`
display:inline-block;
width:8px;
position:absolute;
right: -9px;
top: 47%;
border-top:${(props)=>props?.completed?'solid 1px #fadde3':'dashed 1px #ecedef'}
`;

/**
 *
 *
 * @export
 * @return {*}
 */
export default function ModuleSelector({
  activeModule,
  switchModule,
  percentageLessonsComplete,
  moduleIndex,
}) {
  return (
    <BorderSpan
      completed={percentageLessonsComplete === 100?true:false}
      activeModule={activeModule === moduleIndex+1?true:false}
      onClick={() => switchModule(moduleIndex+1)}
      width="112px"
      style={{position: 'relative'}}
    >
      {
        percentageLessonsComplete === 100 ?
        <SolidBorderComplete
          width='100%'
        ></SolidBorderComplete> :
        percentageLessonsComplete === 0 ?
        <DashedBorderComplete
          width='100%'
        ></DashedBorderComplete> :
        <>
          <SolidBorderWrapper
            width={`${percentageLessonsComplete}%`}>
            <SolidBorder isSelected={activeModule === moduleIndex+1}>
            </SolidBorder>
          </SolidBorderWrapper>
          <DashedBorder
          ></DashedBorder>
        </>
      }
      {
            activeModule === moduleIndex+1 ?
            <span
              style={activeModule === moduleIndex+1?{width: '130px'}:{}}
              className={styles.activeModuleName}
            >
              Module&nbsp;
              {moduleIndex+1}
            </span> :
            <span
              style={activeModule === moduleIndex+1?{width: '130px'}:{}}
              className={styles.activeModuleName}
            >
              {moduleIndex+1}
            </span>
      }
      {
        moduleIndex+1<4 &&
        <VerticalLine
          completed={percentageLessonsComplete === 100?true:false}
        />
      }
    </BorderSpan>
  );
}

ModuleSelector.propTypes = {
  activeModule: PropTypes.number,
  switchModule: PropTypes.func,
  percentageLessonsComplete: PropTypes.number,
  moduleIndex: PropTypes.number,
};
