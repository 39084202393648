import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import * as purchaseAction from './AddonPurchaseActionService';
import * as trackAction from '../../Shared/TrackActionService';
import AddonsConfirmation from '../../Shared2/Popup/AddonsConfirmation';
import styles from '../AddonThinBanner.module.scss';

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export default function EcertThinBanner(props) {
  const studentId = cookie.load('shawacademy_leadId');
  const certPrices = props?.addonPrices;
  const courseSlug = props.courseSlug;
  const moduleNumber = props.moduleNumber;
  const regId = props.registrationId;
  const locale = props.locale;
  const purchased = props.ecertPurchased;
  const [disabled, setDisabled] = useState(false);
  const [showConsentPopup, showConfirmationPopup] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  // const isConsentEligible = utils.checkPurchaseConsentEligibility();
  const isConsentEligible = true;

  /**
 *
 *
 * @param {*} data
 */
  function purchaseAddon(data) {
    const product = 'CourseEcert';
    const productName = 'CERT';
    const textInfo = 'Digital Certificates';
    const objToPass = {
      currencyCode: data?.currency_code,
      activeTierCode: data?.default_tier_code,
      addon: 'cert',
      country: data.country_code,
      cta: encodeURI(props.cta),
      slug: courseSlug,
      moduleNumber: parseInt(moduleNumber),
      courseName: props?.moduleData?.attributes?.modulename,
      studentId: studentId,
      regId: regId,
      locale: locale,
      isEqfCourse: props.isEqfCourse,
      moduleSlug: props.moduleSlug,
      pageKind: props.pageKind,
    };
    setDataToPassToConfirmation({
      param: 'cert',
      title: 'CourseEcertTwoClickPopup',
      cta: 'Get Your Certificate-modal',
      courseSlug,
      moduleSlug: props.moduleSlug,
      courseName: props?.moduleData?.attributes.modulename,
      moduleNumber: parseInt(moduleNumber),
      locale,
      pageKind: props.pageKind,
      studentId,
      priceInfo: data,
      product,
      textInfo,
      productName,
      regId,
    });
    setDisabled(true);
    if (isConsentEligible) {
      showConfirmationPopup(true);
      trackAction.ctaClickAction({
        'studentId': studentId,
        'cta': props.cta,
        'action': 'InitiatePurchaseEcert',
        'pageKind': props.pageKind,
        'locale': props.locale,
        'moduleSlug': props.moduleSlug,
      });
      return;
    }
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': props.cta,
      'action': 'PurchaseEcert',
      'pageKind': props.pageKind,
      'locale': props.locale,
      'moduleSlug': props.moduleSlug,
    });
    purchaseAction.addonPurchaseAction(objToPass);
  }

  const closeModalPopup = () => {
    showConfirmationPopup(false);
    setDisabled(false);
  };

  return (
    <div className=
      {`${styles.shThinBanner} ${styles.pinkBanner} ${
          (props.registrationId && !purchased) ? '' : styles.hide}`}>
      <div className={styles.specialOfferSection}>
        <p className={styles.offerText}>
          Unlock All Your Certificates
        </p>
      </div>
      <div className={styles.ctaSection}>
        {isConsentEligible?'':
        <p className={styles.priceText}>
          {certPrices?.currency_symbol} {certPrices?.default_tier_price} only
        </p>}
        <Button
          className={`${styles.getToolkit}
          ${disabled ? 'disabled' : ''}`}
          onClick={() => purchaseAddon(certPrices)}>
          Get Your Certificates
          <p className={styles.oneClickText}>
            {isConsentEligible ? '' : props.oneClickText}
          </p>
        </Button>
      </div>
      {showConsentPopup &&
       <AddonsConfirmation
         dataToPassToConfirmation={dataToPassToConfirmation}
         showAddonsPopUp={closeModalPopup}
         countryCode={countryCode}/>}
    </div>
  );
}

EcertThinBanner.propTypes = {
  registrationId: PropTypes.string,
  addonPrices: PropTypes.object,
  courseSlug: PropTypes.string,
  moduleNumber: PropTypes.number,
  registrationId: PropTypes.string,
  locale: PropTypes.string,
  ecertPurchased: PropTypes.bool,
  isEqfCourse: PropTypes.bool,
  moduleSlug: PropTypes.string,
  pageKind: PropTypes.string,
  oneClickText: PropTypes.string,
  specialOfferText: PropTypes.string,
  cta: PropTypes.string,
  moduleData: PropTypes.object,
  countryCode: PropTypes.string,
};
