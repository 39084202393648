import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig={
  apiKey: process.env.FirebaseApiKey,
  databaseURL: process.env.FirebaseDatabaseURL,
  projectId: process.env.FirebaseProjectId,
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
export const db=firebase?.firestore();
