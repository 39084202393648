import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles/Courses.module.scss';

const UpgradeNowBanner = (props) => {
  const {openUpgradePopup, isWeekTwoComplete, activeModule,
    totalModules, planPrices, pageKind}=props;
  const subscriptionPlanDuration = isWeekTwoComplete ? '3' : '12';
  const frequency = isWeekTwoComplete ? 'quarterly' : 'annual';
  const showTextContent = pageKind==='LessonsPage';

  return (
    <div className={`${styles.upgradeNowBanner} ${
      isWeekTwoComplete ? styles.threeMonthContent : ''}`}>
      {isWeekTwoComplete ? <>
        <div className={styles.header}>
          <h1>What is a 3 months plan?</h1>
          <p>{planPrices?.currencySymbol} {
            planPrices?.[frequency]?.price}/<span>Month</span></p>
        </div>
        <div className={styles.planContent}>
          {showTextContent ?
        <h6>
              A 3-month plan allows you to complete your qualification for the
              lowest price. You have{' '}
          {totalModules-activeModule}{' '}
              remaining modules left to gain your globally recognized
              qualification.
        </h6> :
         <h6 className={styles.threeMonthText}>
              A 3-month plan allows you to complete your qualification
              for the lowest price.</h6>}
          <div className={styles.imageContainer}>
            <img src='/dashboard-new/courses/threeMonthOffer.png'
              alt='module-toolkit-illustration'></img>
          </div>
        </div>
      </>:<>
        <div className={styles.header}>
          <h1>What is a 12 month plan?</h1>
          <p>{planPrices?.currencySymbol} {
              planPrices?.[frequency]?.price}/<span>Month</span></p>
        </div>
        <div className={styles.planContent}>
          <h6>A 12 month plan is the most cost-effective and flexible
              plan. It allows you to become an expert in any field for a
              minimal cost.</h6>
          <div className={styles.imageContainer}>
            <img src='/dashboard-new/courses/twelveMonthOffer.png'
              alt='module-toolkit-illustration'></img>
          </div>
        </div>
      </>}
      <button onClick={()=>openUpgradePopup(
          subscriptionPlanDuration, pageKind)}>
        Buy Now</button>
    </div>
  );
};

UpgradeNowBanner.propTypes = {
  openUpgradePopup: PropTypes.func,
  isWeekTwoComplete: PropTypes.bool,
  activeModule: PropTypes.number,
  totalModules: PropTypes.number,
  planPrices: PropTypes.object,
  pageKind: PropTypes.string,
};

export default UpgradeNowBanner;
