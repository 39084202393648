import React from 'react';
import styles from '../Shared2/LessonAssessment.module.scss';
import PropTypes from 'prop-types';


/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function LessonAssessmentItem(props) {
  const {assessmentData, openAssessment, lessonIndex, enabled,
    moduleIndex} = props;
  return (
    <div className={`${styles.assessmentLink}`}>
      {assessmentData.result ?
      <div className={styles.assessmentType}>
        <div className={styles.resourceInfo}>
          <div className={`${styles.resourceIcon} library-shaw-icon`}>
            <i className={`${styles.iconBook} icon-edit-filled`}></i>
          </div>
          <div
            className={styles.singleResource}>
            <p id={styles.resourceType}>
              Score
            </p>
            <p className={styles.infoText}>
              {assessmentData.result?.percentile}%
            </p>
          </div>
        </div>
        <button className={styles.retakeAssessmentCta}
          onClick={() => openAssessment(assessmentData.typeform, lessonIndex,
              moduleIndex)}>
            Retake
        </button>
      </div>:
      <div className={styles.assessmentType}>
        <div className={styles.resourceInfo}>
          {enabled?
          <div className={`${styles.resourceIcon}
          library-shaw-icon`}>
            <i className={`${styles.iconEdit} icon-edit-filled`}></i>
          </div>:
          <div className={`${styles.resourceIcon
          } library-shaw-icon-new`}>
            <i className={`${styles.iconBook} icon-lock-filled`}></i>
          </div>}
          <div className={styles.singleResource}>
            <p className={styles.resourceTypeQuestion}>
              Test Your Knowledge
            </p>
          </div>
        </div>
        {enabled?
        <button className={`${styles.startNowCta}`}
          onClick={() => openAssessment(assessmentData.typeform,
              lessonIndex, moduleIndex)}>
            Start now
        </button> :
         <span className='library-shaw-icon-new'>
           <i className={`${styles.shawArrow}
         icon-new-right-arrow`}></i>
         </span>}
      </div>}
    </div>
  );
}

LessonAssessmentItem.propTypes = {
  assessmentData: PropTypes.object,
  openAssessment: PropTypes.func,
  lessonIndex: PropTypes.number,
  moduleIndex: PropTypes.number,
  enabled: PropTypes.bool,
};

export default LessonAssessmentItem;
