import {navigate} from 'gatsby';
import add from 'date-fns/add';
import cookie from 'react-cookies';

export const redirectWithDelay = (redirectionUrl, delay = 3000) => {
  setTimeout(() => {
    if (redirectionUrl.startsWith('/')) {
      navigate(redirectionUrl);
    } else {
      window.location = redirectionUrl;
    }
  }, delay);
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getElementRefByClassName = (ref, classToAdd) => {
  return ref.current.querySelector(`.${classToAdd}`);
};

export const getFormattedPrice = (
    priceInSmallestUnitsOfCurrency, isoCurrencyCode, currencySymbol,
) => {
  let decimalisedNumber = priceInSmallestUnitsOfCurrency / 100;
  decimalisedNumber = decimalisedNumber.toFixed(2);
  if (isoCurrencyCode == 'gbp') return '£' + decimalisedNumber;
  else if (isoCurrencyCode == 'eur') return '€' + decimalisedNumber;
  else if (isoCurrencyCode == 'usd') return '$' + decimalisedNumber;
  else if (isoCurrencyCode == 'myr') return 'Rm' + decimalisedNumber;
  else return currencySymbol + ' ' + decimalisedNumber;
};

export const getTrialEndDate = (product) => {
  let trialEndDate = new Date();
  if (product.freeTrialPeriod) {
    const freeTrialPeriod = product.freeTrialPeriod;
    trialEndDate = add(new Date(), {days: freeTrialPeriod});
  }
  return trialEndDate;
};
export const eligibleForSubReactivation = (subscription) => {
  const isChargebeeSubscription = subscription?.attributes?.chargebee;
  const isCancelled = subscription?.attributes?.chargebee?.cancelled_at > 0;
  return (isChargebeeSubscription && isCancelled);
};

export const PAGE_KINDS = {
  PREMIUM_TRIAL_SUBSCRIPTION: 'SubscriptionTrialCheckoutPage',
  ADD_SECOND_CARD: 'AddSecondCardPage',
  THANKYOU_PAGE: 'SubscriptionThankYouPage',
  REDIRECTION_TO_THANKYOU: 'RedirectionToThankYouPage',
};

export const triggerPageViewAction = (studentId, pageKind, otherParams) => {
  const utmSource = getParameterByName('utm_source');
  const utmMedium = getParameterByName('utm_medium');
  const utmCampaign = getParameterByName('utm_campaign');
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'view-page-action',
      'view-page-param': {
        'studentId': studentId,
        'pageKind': pageKind,
        'utmSource': utmSource,
        'utmMedium': utmMedium,
        'utmCampaign': utmCampaign,
        ...otherParams,
      },
    });
  }, 0);
  cookie.save('pageKind', pageKind);
};

export const triggerSubscriptionTrialAction = (
    studentId, pageKind, gateway, gatewayId,
    cardType, cardBrand, plan, coupon, predictedLTV,
    predictedLTV2, coursePicked, trialDays,
) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'subscription-trial-action',
      'subscription-event-param': {
        'identity': studentId,
        'pageKind': pageKind,
        'gateway': gateway,
        'gatewayId': gatewayId,
        'card_type': cardType,
        'card_brand': cardBrand,
        'product_code': plan,
        'coupon_code': coupon,
        'predicted_ltv': predictedLTV,
        'predicted_ltv2': predictedLTV2,
        'courseSlug': coursePicked,
        'trialDays': trialDays,
      },
    });
  }, 0);
  cookie.save('trialStarted', 1);
};

export const triggerSubscriptionChangeAction = (
    studentId, plan, action, pageKind,
    gateway, gatewayId, cardType, cardBrand, trialDays,
) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'subscription-change-action',
      'subscription-change-param': {
        'studentId': studentId,
        'action': action,
        'planId': plan,
        'pageKind': pageKind,
        'gateway': gateway,
        'gatewayId': gatewayId,
        'card_type': cardType,
        'card_brand': cardBrand,
        'trialDays': trialDays,
      },
    });
  }, 0);
};

export const triggerCTAClickAction =
 (studentId, pageKind, ctaLabel, ctaAction) => {
   setTimeout(() => {
     window.dataLayer.push({
       'event': 'cta-click-action',
       'cta-click-param': {
         'studentId': studentId,
         'pageKind': pageKind,
         'cta': ctaLabel,
         'action': ctaAction,
       },
     });
   }, 0);
 };

export const triggerApiErrorAction = (pageKind, apiUrl, status) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'api-error-action',
      'api-error-param': {
        'pageKind': pageKind,
        'api': apiUrl,
        'status': status,
      },
    });
  }, 0);
};

export const triggerCardSubmissionFailure = (
    pageKind, locale, gateway, gatewayId, error,
) => {
  const errorMessage =
    error.getFirstErrorMessage ? error.getFirstErrorMessage() : error.message;
  const failureField =
    error.getFirstErrorField ? error.getFirstErrorField() : null;
  const errorCode = error.sourceError?.response?.data?.meta?.decline_code ||
  error.sourceError?.response?.code;
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'card-submission-failed-event',
      'card-submission-failed-param': {
        'pageKind': pageKind,
        'locale': locale,
        'gateway': gateway,
        'gatewayId': gatewayId,
        'errorMessage': errorMessage,
        'errorCode': errorCode,
        'failureField': failureField,
      },
    });
  }, 0);
};

export const triggerAddonPurchaseFailure = (
    studentId, pageKind, gateway, gatewayId,
    cardType, cardBrand, addonId, error, otherParams,
) => {
  const errorMessage =
  error.getFirstErrorMessage ? error.getFirstErrorMessage() : error.message;
  const failureField =
  error.getFirstErrorField ? error.getFirstErrorField() : null;
  const errorCode = error.sourceError?.response?.data?.meta?.decline_code;
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'addon-purchase-failed',
      'addon-purchase-failed-param': {
        'identity': studentId,
        'pageKind': pageKind,
        'gateway': gateway,
        'gatewayId': gatewayId,
        'card_type': cardType,
        'card_brand': cardBrand,
        'addonId': addonId,
        'quantity': 1,
        'reason': errorMessage,
        'errorCode': errorCode,
        'failureField': failureField,
        ...otherParams,
      },
    });
  }, 0);
};

export const triggerAddonPurchaseAction = (
    studentId, gateway, gatewayId,
    cardType, cardBrand, addonId, couponId, otherParams,
) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'addon-purchase-action',
      'event-param': {
        'identity': studentId,
        'addonId': addonId,
        'couponId': couponId,
        'quantity': 1,
        'gateway': gateway,
        'gatewayId': gatewayId,
        'card_type': cardType,
        'card_brand': cardBrand,
        ...otherParams,
      },
    });
  }, 0);
};
