import React, {useState, useEffect} from 'react';
import {Dropdown, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';
import cookie from 'react-cookies';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import * as timeTableService from './TimeTableService';
import * as apiService from '../../api/index';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import * as trackAction from '../Shared/TrackActionService';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(customParseFormat);


/**
 *
 *
 * @param {*} { courseRegData, handleReschedule, getCourseImage}
 * @return {*}
 */
function EnrolledCourse({courseRegData, handleReschedule, locale,
  getCourseImage, courseFullInfo, newTimetableEligibility, index}) {
  const studentId = cookie.load('shawacademy_leadId');
  const [showGoals, setShowGoals]=useState(false);
  const [dayPickerList, setDayPickerList] = useState([]);
  const [timePickerList, setTimePickerList] = useState([]);
  const [selectedClass, setSelectClass] =
  useState({class: courseRegData?.attributes?.lessons_per_week || 1, id: null});
  const daysArray = require(`dayjs/locale/${locale}`).weekdays;
  const [selectedDay1, setSelectedDay1] = useState(null);
  const [selectedDay2, setSelectedDay2] = useState(null);
  const [selectedDay3, setSelectedDay3] = useState(null);
  const [selectedTime1, setSelectedTime1] = useState(null);
  const [selectedTime2, setSelectedTime2] = useState(null);
  const [selectedTime3, setSelectedTime3] = useState(null);
  const [schedule1, setSchedule1] = useState(null);
  const [schedule2, setSchedule2] = useState(null);
  const [schedule3, setSchedule3] = useState(null);
  const [isDay1Selected, setIsDay1Selected] = useState(false);
  const [isDay2Selected, setIsDay2Selected] = useState(false);
  const [isDay3Selected, setIsDay3Selected] = useState(false);
  const [lessonArray, setLessonsArray] = useState([]);
  const [userAction, setUserAction] =
  useState({showTimeError: false, showButton: false});

  const manageGoals = (index) => {
    setSelectClass({...selectedClass, id: index});
    if (newTimetableEligibility) {
      setSelectClass({class: null, id: index});
      setUserAction({showTimeError: false, showButton: false});
    }
    setShowGoals(true);
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': 'Manage Goals',
      'action': 'ManageGoals',
      'courseSlug': courseRegData?.attributes?.courseslug,
      'moduleNumber': 1,
      'pageKind': 'TimeTablePage',
    });
  };

  const setClassesSelected = (param, index) => {
    setSelectClass({class: param, id: index});
    if (newTimetableEligibility) {
      buildSchedule(param);
      setUserAction({showTimeError: false, showButton: true});
    } else {
      if (param === 1 && schedule1) return;
      if (param === 2 && schedule1 && schedule2) return;
      if (param === 3 && schedule1 && schedule2 && schedule3) return;
      setUserAction({showTimeError: false, showButton: false});
    }
  };

  const closeManageGoals = () => {
    setSelectClass({...selectedClass, id: null});
    setShowGoals(false);
    setUserAction({showTimeError: false, showTimeError: false});
    setSelectedTime1(null); setSelectedTime2(null); setSelectedTime3(null);
    setSchedule1(null); setSchedule2(null); setSchedule3(null);
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': 'Cancel',
      'action': 'CancelManageGoals',
      'courseSlug': courseRegData?.attributes?.courseslug,
      'moduleNumber': 1,
      'pageKind': 'TimeTablePage',
    });
  };

  useEffect(() => {
    if (!newTimetableEligibility) {
      setDayPickerList(timeTableService.buildDayPickerList());
      setTimePickerList(timeTableService.buildTimePickerList());
    }
  }, []);

  useEffect(() => {
    if (!newTimetableEligibility) {
      toggleConfirmationTextState();
    }
  }, [schedule1, isDay1Selected, selectedDay2, schedule2, isDay2Selected,
    schedule3, isDay3Selected, selectedDay3, selectedTime1, selectedTime2,
    selectedTime3, selectedClass]);

  const rescheduleCourse = () => {
    let rescheduleTye = 'increaseGoals';
    setUserAction({showTimeError: false, showButton: false});
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': 'Update',
      'action': 'UpdateSchedule',
      'courseSlug': courseRegData?.attributes?.courseslug,
      'moduleNumber': 1,
      'pageKind': 'TimeTablePage',
    });
    if (selectedClass?.class < courseRegData?.attributes?.lessons_per_week) {
      rescheduleTye = 'decreaseGoals';
    } else if (selectedClass?.class ===
       courseRegData?.attributes?.lessons_per_week) {
      rescheduleTye = 'sameGoals';
    }
    const data = timeTableService.getObjToPass(schedule1, schedule2, schedule3,
        courseRegData, lessonArray, studentId, selectedClass?.class,
        newTimetableEligibility);
    new apiService.default.Schedule(courseRegData?.id).update(data)
        .then((response) => {
          const result = response?.data?.data;
          trackAction.registrationAction({
            'moduleNumber': 1,
            'courseSlug': result?.attributes?.course_slug,
            'facultySlug': result?.attributes?.course?.faculty,
            'locale': locale,
            'rescheduleType': rescheduleTye,
            'Goal': selectedClass?.class,
          });
          setTimeout(() => {
            navigate(`/app/${locale}/v2/registrations/${
              courseRegData?.id}/modules/1`);
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const buildSchedule = (classesSelected) => {
    const nextSunday = dayjs().weekday(7);
    const sunday = dayjs(new Date(new Date(nextSunday).setHours(12, 0, 0)));
    let tempLessonsArray;
    if (classesSelected=== 1) {
      setSchedule1(sunday);
      tempLessonsArray = getLessonsArray(sunday, null, null, 1);
    }
    if (classesSelected === 2) {
      setSchedule1(sunday);
      setSchedule2(sunday);
      tempLessonsArray = getLessonsArray(sunday, sunday, null, 2); ;
    }
    if (classesSelected === 3) {
      setSchedule1(sunday);
      setSchedule2(sunday);
      setSchedule3(sunday);
      tempLessonsArray = getLessonsArray(sunday, sunday, sunday, 3);
    }
    setLessonsArray(tempLessonsArray);
  };

  const getLessonsArray = (scheduleOne, scheduleTwo,
      scheduleThree, classesSelected) => {
    return timeTableService.buildScheduleLessonsArray(scheduleOne,
        scheduleTwo, scheduleThree, courseFullInfo,
        classesSelected, newTimetableEligibility, courseRegData);
  };

  /**
   *
   *
   */
  function toggleConfirmationTextState() {
    if (selectedClass?.class === 1) {
      if (isDay1Selected && selectedTime1) {
        setUserAction({showTimeError: false, showButton: true});
        setLessonsArray(timeTableService.buildScheduleLessonsArray(
            schedule1, schedule2, schedule3, courseFullInfo, 1,
            newTimetableEligibility, null));
        return;
      }
    }
    if (selectedClass?.class === 2) {
      if (isDay1Selected && selectedTime1 && isDay2Selected &&
        selectedTime2) {
        setUserAction(
            timeTableService.getHourDifference(schedule1, schedule2,
                schedule3));
        setLessonsArray(timeTableService.buildScheduleLessonsArray(
            schedule1, schedule2, schedule3, courseFullInfo, 2,
            newTimetableEligibility, null));
        return;
      }
    }
    if (selectedClass?.class === 3) {
      if (isDay1Selected && selectedTime1 && isDay2Selected &&
        selectedTime2 && isDay3Selected && selectedTime3) {
        setUserAction(
            timeTableService.getHourDifference(schedule1, schedule2,
                schedule3));
        setLessonsArray(timeTableService.buildScheduleLessonsArray(
            schedule1, schedule2, schedule3, courseFullInfo, 3,
            newTimetableEligibility, null));
        return;
      }
    }
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} selectedWeekday
   * @param {*} today
   */
  function handleDayOneChange(e, selectedWeekday, today) {
    let selectedDay1;
    if (today < selectedWeekday) {
      selectedDay1 = dayjs().isoWeekday(selectedWeekday);
    } else {
      selectedDay1 = dayjs().add(8, 'days').isoWeekday(selectedWeekday);
    }
    setSelectedDay1(selectedDay1);
    if (selectedTime1) {
      const hours =
        parseInt(dayjs(selectedTime1, ['h:mm A']).format('HH:mm'));
      const schedule1 = selectedDay1
          .set('hour', hours).set('minute', 0).set('second', 0);
      setSchedule1(schedule1);
    }
    // if (schedule1) {
    //   const hours = parseInt(schedule1.format('HH'));
    //   const schedule1 = selectedDay1
    //       .set('hour', hours).set('minute', 0).set('second', 0);
    //   setSchedule1(schedule1);
    // }
    if (e.target.value != 0) {
      setIsDay1Selected(true);
    } else {
      setIsDay1Selected(false);
    }
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} selectedWeekday
   */
  function handleDayTwoChange(e, selectedWeekday) {
    let selectedDay2 = dayjs().isoWeekday(selectedWeekday);
    if (selectedDay1) {
      while (selectedDay2.diff(selectedDay1, 'hours') < 0) {
        selectedDay2 = selectedDay2.add(7, 'day');
      }
    }
    setSelectedDay2(selectedDay2);
    if (selectedTime2) {
      const hours =
        parseInt(dayjs(selectedTime2, ['h:mm A']).format('HH:mm'));
      const schedule2 = selectedDay2
          .set('hour', hours).set('minute', 0).set('second', 0);
      setSchedule2(schedule2);
    }
    if (e.target.value != 0) {
      setIsDay2Selected(true);
    } else {
      setIsDay2Selected(false);
    }
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} selectedWeekday
   * @param {*} today
   */
  function handleDayThreeChange(e, selectedWeekday, today) {
    let selectedDay3;
    if (today < selectedWeekday) {
      selectedDay3 = dayjs().isoWeekday(selectedWeekday);
    } else {
      selectedDay3 = dayjs().add(8, 'days').isoWeekday(selectedWeekday);
    }
    if (selectedDay2) {
      while (selectedDay3.diff(selectedDay2, 'hours') < 0) {
        selectedDay3 = selectedDay3.add(7, 'day');
      }
    }
    // if (selectedDay2) {
    //   if (selectedDay3.diff(selectedDay2, 'hours') < 0) {
    //     if (today < selectedWeekday) {
    //       selectedDay3 = dayjs().isoWeekday(selectedWeekday);
    //     } else {
    //       selectedDay3 = selectedDay3.add(7, 'day');
    //     }
    //   }
    // }
    setSelectedDay3(selectedDay3);
    if (selectedTime3) {
      const hours =
        parseInt(dayjs(selectedTime3, ['h:mm A']).format('HH:mm'));
      const schedule3 = selectedDay3
          .set('hour', hours).set('minute', 0).set('second', 0);
      setSchedule3(schedule3);
    }
    if (e.target.value != 0) {
      setIsDay3Selected(true);
    } else {
      setIsDay3Selected(false);
    }
  }

  const handleDayChange = (e, param, index) => {
    setSelectClass({...selectedClass, id: index});
    const selectedWeekday = daysArray.indexOf(e.target.value);
    const today = dayjs().isoWeekday();
    if (param === 'day1') {
      handleDayOneChange(e, selectedWeekday, today);
    } else if (param === 'day2') {
      handleDayTwoChange(e, selectedWeekday);
    } else {
      handleDayThreeChange(e, selectedWeekday, today);
    }
  };

  /**
   *
   *
   * @param {*} e
   * @param {*} hours
   */
  function handleFirstHourChange(e, hours) {
    if (e.target.value != 0) {
      setSelectedTime1(e.target.value);
      if (selectedDay1) {
        const schedule1 = selectedDay1
            .set('hour', hours).set('minute', 0).set('second', 0);
        setSchedule1(schedule1);
      } else {
        setSchedule1(null);
      }
    } else {
      setSchedule1(null);
      setSelectedTime1(null);
    }
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} hours
   */
  function handleSecondHourChange(e, hours) {
    if (e.target.value != 0) {
      setSelectedTime2(e.target.value);
      if (selectedDay2) {
        const schedule2 = selectedDay2
            .set('hour', hours).set('minute', 0).set('second', 0);
        setSchedule2(schedule2);
      } else {
        setSchedule2(null);
      }
    } else {
      setSchedule2(null);
      setSelectedTime2(null);
    }
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} hours
   */
  function handleThirdHourChange(e, hours) {
    if (e.target.value != 0) {
      setSelectedTime3(e.target.value);
      if (selectedDay3) {
        const schedule3 = selectedDay3
            .set('hour', hours).set('minute', 0).set('second', 0);
        setSchedule3(schedule3);
      } else {
        setSchedule3(null);
      }
    } else {
      setSchedule3(null);
      setSelectedTime3(null);
    }
  }


  const handleHourChange = (e, param, index) => {
    setSelectClass({...selectedClass, id: index});
    const hours = parseInt(dayjs(e.target.value, ['h:mm A']).format('HH:mm'));
    if (param === 'day1') {
      handleFirstHourChange(e, hours);
    } else if (param === 'day2') {
      handleSecondHourChange(e, hours);
    } else {
      handleThirdHourChange(e, hours);
    }
  };

  const navigateToRecordingsPage = () => {
    const urlToNavigate =
    `/app/${locale}/v2/registrations/${courseRegData.id}/modules/1`;
    navigate(urlToNavigate);
  };

  return (
    <div
      key={courseRegData?.attributes?.coursename}
      className={`${styles.box} ${
          showGoals ? styles.boxBackground : ''}`}
    >
      <div className={styles.DropdownStyle}>
        <div className={styles.course}>
          <img
            onClick={navigateToRecordingsPage}
            src={getCourseImage(courseRegData?.attributes?.courseslug)}
            alt=""/>
          <span
            onClick={navigateToRecordingsPage}>
            {courseRegData?.attributes?.coursename}
          </span>
        </div>
        {!newTimetableEligibility &&
         <div className={`${styles.kebabDropdown} library-shaw-icon-new`}>
           <Dropdown>
             <Dropdown.Toggle variant="" id=""
               bsPrefix="down-icon-class">
               <i className="icon-new-Kebab-menu"></i>
             </Dropdown.Toggle>
             <Dropdown.Menu id={styles.item}>
               <Dropdown.Item
                 onClick={()=>handleReschedule(courseRegData)}
               >
                Edit schedule
               </Dropdown.Item>
               <Dropdown.Item
                 onClick={()=>handleReschedule(courseRegData)}
               >
                Restart Module
               </Dropdown.Item>
             </Dropdown.Menu>
           </Dropdown>
         </div>}
      </div>
      {selectedClass.id!==index &&
    <div className={styles.textWrapper}>
      <div className={styles.classPerWeekText}>
        <p>
          <span>
            {courseRegData?.attributes?.lessons_per_week}&nbsp;
          </span>
          {courseRegData?.attributes?.lessons_per_week === 1 ?
            `Class per week` :
            `Classes per week`}
        </p>
      </div>
      <p
        className={styles.manageGoalsText}
        onClick={()=>manageGoals(index)}
      >
        Manage goals
      </p>
    </div>
      }
      {showGoals && selectedClass.id===index &&
    <div className={styles.manageGoalsContainer}>
      <p>How many classes do you want to attend in a week?</p>
      <div className={styles.classTabSwitch}>
        <div className={selectedClass.class === 1 ?
        styles.activeClass : styles.inactiveClass}
        onClick={()=>setClassesSelected(1, index)}>
        1
        </div>
        <div className={selectedClass.class === 2 ?
        styles.activeClass : styles.inactiveClass}
        onClick={()=>setClassesSelected(2, index)}>
        2
        </div>
        <div className={selectedClass.class === 3 ?
         styles.activeClass : styles.inactiveClass}
        onClick={()=>setClassesSelected(3, index)}>
        3
        </div>
      </div>
      {!newTimetableEligibility && <p className={styles.attendClassText}>
          When do you want to attend your classes?
      </p>}
      <div className={styles.scheduleContainer}>
        {!newTimetableEligibility && <>
          <Form.Row className={styles.formRow}>
            <div className={`${styles.fieldContainerLeft} ${
              styles.schedulePageSelect}`}>
              <Form.Label className={`${styles.fieldlabel} ${
                styles.formLabel}`}>
                Day 1
              </Form.Label>
              <Form.Control
                className={styles.shDropdown}
                as="select"
                custom
                onChange={(e)=>handleDayChange(e, 'day1', index)}
              >
                <option value='0'>
                    Select Day 1
                </option>
                { dayPickerList.length && dayPickerList.map((item) => {
                  return <option
                    value={item}
                    key={`d1-${item}`}>
                    {item}
                  </option>;
                })
                }
              </Form.Control>
            </div>
            <div className={`${styles.fieldContainerRight} ${
              styles.schedulePageSelect}`}>
              <Form.Label className={`${styles.fieldlabel} ${
                styles.formLabel}`}>
                  Time
              </Form.Label>
              <Form.Control
                as="select"
                className={styles.shDropdown}
                custom
                onChange={(e)=>handleHourChange(e, 'day1', index)}
              >
                <option value='0'>
                    Time
                </option>
                {timePickerList?.length && timePickerList.map((item) => {
                  return <option
                    value={item}
                    key={`t1-${item}`}>
                    {item}
                  </option>;
                })}
              </Form.Control>
            </div>
          </Form.Row>

          <Form.Row className={selectedClass.class >= 2 ?
            `${styles.formRow}` : styles.hideSection}>
            <div className={`${styles.fieldContainerLeft} ${
              styles.schedulePageSelect}`}>
              <Form.Label className={`${styles.fieldlabel} ${
                styles.formLabel}`}>
                Day 2
              </Form.Label>
              <Form.Control
                as="select"
                custom
                className={styles.shDropdown}
                onChange={(e)=>handleDayChange(e, 'day2', index)}
              >
                <option value='0'>
                  Select Day 2
                </option>
                { dayPickerList.length && dayPickerList.map((item) => {
                  return <option
                    value={item}
                    key={`d1-${item}`}>
                    {item}
                  </option>;
                })
                }
              </Form.Control>
            </div>
            <div className={`${styles.fieldContainerRight} ${
              styles.schedulePageSelect}`}>
              <Form.Label className={`${styles.fieldlabel} ${
                styles.formLabel}`}>
                Time
              </Form.Label>
              <Form.Control
                className={styles.shDropdown}
                as="select"
                custom
                onChange={(e)=>handleHourChange(e, 'day2', index)}
              >
                <option value='0'>
                  Time
                </option>
                {timePickerList?.length && timePickerList.map((item) => {
                  return <option
                    value={item}
                    key={`t1-${item}`}>
                    {item}
                  </option>;
                })}
              </Form.Control>
            </div>
          </Form.Row>

          <Form.Row className={selectedClass.class > 2 ?
         styles.formRow : styles.hideSection}>
            <div className={`${styles.fieldContainerLeft} ${
              styles.schedulePageSelect}`}>
              <Form.Label className={`${styles.fieldlabel} ${
                styles.formLabel}`}>
                Day 3
              </Form.Label>
              <Form.Control
                className={styles.shDropdown}
                as="select"
                custom
                onChange={(e)=>handleDayChange(e, 'day3', index)}
              >
                <option value='0'>
                  Select Day 3
                </option>
                { dayPickerList.length && dayPickerList.map((item) => {
                  return <option
                    value={item}
                    key={`d1-${item}`}>
                    {item}
                  </option>;
                })
                }
              </Form.Control>
            </div>
            <div className={`${styles.fieldContainerRight} ${
              styles.schedulePageSelect}`}>
              <Form.Label className={`${styles.fieldlabel} ${
                styles.formLabel}`}>
                Time
              </Form.Label>
              <Form.Control
                as="select"
                className={styles.shDropdown}
                custom
                onChange={(e)=>handleHourChange(e, 'day3', index)}
              >
                <option value='0'>
                  Time
                </option>
                {timePickerList?.length && timePickerList.map((item) => {
                  return <option
                    value={item}
                    key={`t1-${item}`}>
                    {item}
                  </option>;
                })}
              </Form.Control>
            </div>
          </Form.Row>
        </>}
      </div>
      <div className={userAction?.showTimeError ?
        styles.errorHandler : styles.hideSection}>
        <p>
          Classes should have minimum 2 hours difference if choosen same day.
        </p>
      </div>
      <div className={styles.ctaWrapper}>
        <button
          className={styles.cancelCta}
          onClick={closeManageGoals}>
            Cancel
        </button>
        <button
          className={userAction?.showButton ?
          styles.enableUpdateCta : styles.updateCta}
          onClick={rescheduleCourse}>
            Update
        </button>
      </div>
    </div>
      }
    </div>
  );
}

EnrolledCourse.propTypes = {
  courseRegData: PropTypes.object,
  handleReschedule: PropTypes.func,
  getCourseImage: PropTypes.func,
  locale: PropTypes.string,
  courseFullInfo: PropTypes.object,
  newTimetableEligibility: PropTypes.bool,
  index: PropTypes.any,
};

export default EnrolledCourse;
