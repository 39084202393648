import React, {useEffect, useState} from 'react';
import Accordion from './Accordion';
import {useLocation, navigate} from '@reach/router';
import styles from './courseInfo.module.scss';
import AssetsService from '../Shared/AssetsService';
import htmr from 'htmr';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import {pageViewAction, ctaClickAction} from '../Shared/TrackActionService';
import * as apiService from '../../api';
import HeaderNew from '../Shared/HeaderNew';
import lockIcon from '../../../static/dashboard-new/courses/lock-filled.svg';
import UpsellUnlockPopUp from '../Shared2/Popup/UpsellUnlockPopUp';
import {getDataToSalesOffer} from './AllCoursesService';
import * as myAccountService from '../Account/MyAccountService';
import * as courseService from './AllCoursesService';
import MigratePopup from '../Shared2/Popup/MigratePopup';
import * as commonService from '../Shared2/Agent/CommonService';
import brandEnglishCourses from '../../../data/brandEnglishCourses.json';
import brandNonEnglishCourses from '../../../data/brandNonEnglishCourses.json';

const CourseInfo = ({locale, firstName, credits}) => {
  const location = useLocation();
  const selectedFaculty = location?.state?.faculty;
  const [imagePath, setImagePath] = useState(AssetsService.DUMMY_IMG);
  const [courseData, setCourseData] = useState({});
  const [registrationData, setRegistrationData] = useState([]);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [dataToSalesOffer, setDataToSalesOffer] = useState(null);
  const [studentInfo, setStudentInfo] = useState(null);
  const [subscriptionAttributes, setSubscriptionAttributes] = useState(null);

  const [showMigratePopup, setShowMigratePopup] = useState(false);

  const browserDefaultBack = function() {
    navigate(`/app/${locale}/courses/`, {
      state: {
        faculty: selectedFaculty,
      },
    });
  };

  window.addEventListener('popstate', function(event) {
    browserDefaultBack();
    history.pushState(null, null, window.location.pathname);
  }, false);

  const otherCourses = brandNonEnglishCourses?.nodes?.filter((ele) => {
    return (ele.publish_details.locale !== 'en-gb' &&
      ele.publish_details.locale !== 'en-us');
  });
  const otherLocales = {
    nodes: otherCourses,
  };
  otherLocales.nodes.push(brandEnglishCourses.nodes[0]);
  const allCourseData = getDataToPass(otherLocales);
  const allCoursesInfo = otherLocales;

  const studentId = cookie.load('shawacademy_leadId') || '';
  const courseSlug = location?.pathname?.split('/')?.[4];
  const registeredTags =
    courseService.getListOfRegisteredTags(registrationData);
  const allCourses =
      courseService.getCoursesByLocale(allCoursesInfo, locale);
  const selectedTag =
      courseService.getFacultiesFromCourseSlug(courseSlug, allCourses);
  const coursesByTag =
      courseService.getCoursesByTag(allCourses, {selectedTag: selectedTag[0]});
  const registeredCourseInfo =
      courseService.getCourseRegistrationsMapBySlug(registrationData);
  const courses =
      courseService.checkBySlug(coursesByTag, registeredCourseInfo);
  const course = courses?.filter((course) =>
      course?.courseslug === courseSlug)?.[0];

  useEffect(()=>{
    pageViewAction('CourseInfoPage', locale);
    const studentId=cookie.load('shawacademy_leadId');
    new apiService.default.Registrations(studentId).list()
        .then((res) => {
          setRegistrationData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    myAccountService.getStudentInfoIncluded().then((res) => {
      setStudentInfo(res.studentInfo?.attributes);
      setSubscriptionAttributes(res.subscriptionInfo?.attributes);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(()=>{
    if (courseSlug) {
      const currentCourse=getCoursesByCourseSlug();
      const registeredCourseData=
      checkIfCurrentCourseIsRegistered(currentCourse);
      if (registeredCourseData) {
        const currentRegisteredCourseData={
          ...currentCourse,
          regId: registeredCourseData?.id,
        };
        setCourseData(currentRegisteredCourseData);
      } else {
        setCourseData(currentCourse);
      }
      const assetURL =
      new AssetsService('MY_COURSES').fetchAssetURL(courseSlug);
      setImagePath(assetURL);
    }
  }, [courseSlug, registrationData]);

  useEffect(() => {
    if (studentInfo) {
      if (studentInfo?.membership?.status === 'inactive' ||
        commonService.checkGiftMembership(studentInfo?.membership)) {
        setShowMigratePopup(true);
      }
    }
  }, [studentInfo]);

  const getCoursesByCourseSlug = () => {
    const course = allCourseData.filter((data) => {
      return data?.locale?.indexOf(locale) > -1 &&
      data?.courseslug===courseSlug;
    })?.[0];
    return course;
  };

  /**
   * @return {*}
   */
  function isCourseLocked() {
    const hasUpgraded = cookie.load(`upgrade_${studentId}`);
    const shouldLockFacultiesValue = courseService.shouldLockFaculties(
        subscriptionAttributes, studentInfo?.membership,
        registeredTags, hasUpgraded);
    if (shouldLockFacultiesValue) {
      return !(registeredTags?.indexOf(course?.tags[0]) > -1);
    }
    return false;
  }

  /**
   *
   *
   * @param {*} data
   * @return {*} arrayOfCOurses
   */
  function getDataToPass(data) {
    const arrayToReturn = [];
    for (const node of data.nodes) {
      if (node) {
        for (const item of node.coursecollections) {
          for (const course of item.courses) {
            arrayToReturn.push(course);
          }
        }
      }
    }
    return arrayToReturn;
  };

  const checkIfCurrentCourseIsRegistered = (currentCourse) => {
    return registrationData.filter((data)=>(
      data?.attributes?.courseslug=== currentCourse?.courseslug
    ))?.[0];
  };

  const navigateToPage = () => {
    const regId = courseData?.regId;
    if (isCourseLocked()) {
      handleUpgradeCta();
    } else {
      if (regId) {
        navigate(`/app/${locale}/v2/registrations/${regId}/modules/1`);
      } else {
        const courseSlug = courseData?.courseslug;
        if (locale === 'en') {
          navigate(`/app/${locale}/register?course_slug=${courseSlug}`);
        } else {
          navigate(`/app/${locale}/register?course_slug=${
            courseSlug}-${locale}`);
        }
      }
    }
    ctaClickAction({
      'cta': regId ? 'Continue Learning' : 'Enroll Now',
      'action': regId ? 'continueLearning' : 'enrollNow',
      'pageKind': 'CourseInfoPage',
      locale,
    });
  };

  /**
   */
  function handleHideModal() {
    setShowUnlockModal(false);
  }

  /**
   */
  async function handleUpgradeCta() {
    const studentId=cookie.load('shawacademy_leadId');
    const dataToSalesOffer = await getDataToSalesOffer(studentInfo, studentId);
    setDataToSalesOffer(dataToSalesOffer);
    setShowUnlockModal(true);
    ctaClickAction({
      'studentId': studentId,
      'cta': 'Upgrade',
      'action': 'TriggerSalesOfferModal',
      'pageKind': 'CourseInfoPage',
    });
  }

  return (
    <>
      <div className={styles.courseSlugWrapper}>
        <HeaderNew
          registrationData={registrationData}
          firstName={firstName}
          locale={locale} />
        <div className={styles.rightSection}>
          <div className={styles.rowFlex}>
            <img src={imagePath} alt="" />
            <div className={styles.rightContent}>
              <p className={styles.courseName}>{courseData?.coursename}</p>
              <div className={styles.coursedescription}>
                {courseData?.coursedescription &&
             htmr(courseData?.coursedescription)}</div>

              {studentInfo?.membership?.status === 'cancelled' ?
              (courseData?.regId ?
                  <button onClick={() => navigateToPage()}>
                    <span>Continue Learning</span>
                  </button> :
                <button className={styles.cancelLockBtn}>
                  <span>Enroll Now</span>
                  <img className={styles?.lockIcon}
                    src={lockIcon} alt='lockIcon' />
                </button>) :
              <button onClick={() => navigateToPage()}>
                <span>
                  {courseData?.regId ? 'Continue Learning' : 'Enroll Now'}
                </span>
                {isCourseLocked() ?
                <img className={styles?.lockIcon}
                  src={lockIcon} alt='lockIcon' /> : '' }
              </button>
              }

            </div>
          </div>
          <div className={styles.courseDetailWrapper}>
            <div className={styles.accordionWrapper}>
              {courseData?.modules?.length &&
               courseData?.modules.map((module, index)=>(
                 <Accordion
                   moduleData={module}
                   key={index}
                   index={index}
                 />
               ))
              }
            </div>
          </div>
        </div>
        {
          showUnlockModal &&
          <UpsellUnlockPopUp
            subscriptionInfo={subscriptionAttributes}
            salesOfferData={dataToSalesOffer}
            handleHideModal={handleHideModal}
            showUnlockModal={showUnlockModal}
            source={'CourseInfoPage'}
            courseSlug={courseSlug}
          />
        }
      </div>
      {showMigratePopup &&
        <MigratePopup />}
    </>
  );
};

CourseInfo.propTypes = {
  locale: PropTypes.string,
  credits: PropTypes.number,
  firstName: PropTypes.string,
};

export default CourseInfo;
