import React, {useState} from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import {ctaClickAction} from '../Shared/TrackActionService';
import AddonsConfirmation from '../Shared2/Popup/AddonsConfirmation';

const CertificateBanner = (props) => {
  const {ecertAddonPrice, moduleNumber, locale, pageKind,
    courseRegId, courseSlug, courseName, studentId, moduleSlug} = props;
  const [disabled, setDisabled] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] =
  useState({});

  const purchaseAddon = () => {
    setDataToPassToConfirmation({
      param: 'cert',
      title: 'CourseEcertTwoClickPopup',
      cta: 'Get Your Certificate-modal',
      courseSlug,
      moduleSlug,
      courseName,
      moduleNumber,
      locale,
      pageKind,
      studentId,
      priceInfo: ecertAddonPrice,
      product: 'CourseEcert',
      textInfo: 'Digital Certificates',
      productName: 'CERT',
      regId: courseRegId,
      sourceUrl: `${process.env.ApplicationDomain}/app/en/`,
    });
    setDisabled(true);
    setShowConfirmationPopup(true);
    ctaClickAction({
      studentId,
      'cta': 'Get Your Ecert-Tab',
      'action': 'InitiatePurchaseEcert',
      pageKind,
      locale,
      moduleSlug,
    });
  };

  const closeModalPopup = () => {
    setShowConfirmationPopup(false);
    setDisabled(false);
  };

  return (
    <div className={styles.ecertContainer}>
      <h1>Unlock Your Course Digital Certificates</h1>
      <section>
        <div>
          <h5>Unlock digital certificates for all modules of your course</h5>
          <p>{`${ecertAddonPrice?.currency_symbol} ${
          ecertAddonPrice?.default_tier_price} only`}</p>
          <button onClick={purchaseAddon}
            className={disabled ? styles.disableCta : ''}>
            Get Your Certificates
          </button>
        </div>
        <img src='/images/ecertificate.png' alt='ecert'/>
      </section>
      {showConfirmationPopup &&
      <AddonsConfirmation
        dataToPassToConfirmation={dataToPassToConfirmation}
        showAddonsPopUp={closeModalPopup}/>}
    </div>
  );
};

CertificateBanner.propTypes = {
  moduleNumber: PropTypes.number,
  locale: PropTypes.string,
  ecertAddonPrice: PropTypes.object,
  courseRegId: PropTypes.string,
  courseSlug: PropTypes.string,
  courseName: PropTypes.string,
  studentId: PropTypes.string,
  pageKind: PropTypes.string,
  moduleSlug: PropTypes.string,
};

export default CertificateBanner;


