import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from '../PostPurchasePopUp.module.scss';
import {ctaClickAction} from '../../Shared/TrackActionService';
import {addonPurchaseAction} from '../Banner/AddonPurchaseActionService';
import {Spinner} from 'react-bootstrap';

const PostPurchasePopUp = (props) => {
  const {closeModal, addonPurchased, popupData, addonPrices,
    toolkitPurchased, ecertPurchased, hcdPurchased} = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [disableCta, setDisableCta] = useState(false);
  const [selectedAddonType, setSelectedAddonType] = useState('false');

  const dataToSend = (addon)=> {
    let cta;
    let action;
    if (addon?.type==='toolkit') {
      cta = 'Get Your Toolkit-module';
      action = 'Get Your Course Materials';
    }
    if (addon?.type==='cert') {
      cta = 'Get Your Certificate-module';
      action = 'Get Your Certificate';
    }
    if (addon?.type==='hcd') {
      cta = 'Get Your Diploma-module';
      action ='Get Your Diploma';
    }
    return {cta, action};
  };

  const purchaseAddon = (selectedAddon) => {
    setShowSpinner(true);
    setDisableCta(true);
    setSelectedAddonType(selectedAddon?.type);
    const {cta, action}=dataToSend(selectedAddon);
    let addon=selectedAddon?.type;
    let moduleNumber=parseInt(popupData?.moduleNumber);
    if (addon==='hcd') {
      addon='hard-copy-diploma';
      moduleNumber=null;
    }
    const objToPass = {
      currencyCode: selectedAddon?.currency_code,
      activeTierCode: selectedAddon?.default_tier_code,
      addon,
      country: selectedAddon?.country_code,
      slug: popupData?.courseSlug,
      cta,
      moduleNumber,
      courseName: popupData?.courseName,
      studentId: popupData?.studentId,
      regId: popupData?.regId,
      locale: popupData?.locale,
      isEqfCourse: true,
      moduleSlug: popupData?.moduleSlug,
      pageKind: popupData?.pageKind,
    };
    addonPurchaseAction(objToPass);
    ctaClickAction({
      cta,
      pageKind: popupData?.pageKind,
      action,
      studentId: popupData?.studentId,
      courseSlug: popupData?.courseSlug,
      moduleNumber: popupData?.moduleNumber,
      locale: popupData?.locale,
    });
  };

  const getSubscription = (frequency) => {
    setDisableCta(true);
    setShowSpinner(true);
    setSelectedAddonType('subscription');
    const locale=popupData?.locale;
    const studentId=popupData?.studentId;
    let paymentLink =
    `${process.env.PaymentWebDomain}/payment/subscription/${
      frequency}/?lead_id=${studentId}`;
    if (locale!=='en') {
      paymentLink =
    `${process.env.PaymentWebDomain}/${locale}/payment/subscription/${
      frequency}/?lead_id=${studentId}`;
    }
    ctaClickAction({
      'cta': 'Buy Now',
      'pageKind': popupData?.pageKind,
      'action': `3MonthPlanConfirm`,
      studentId,
      locale,
    });
    window.location.href=paymentLink;
  };

  return (
    <div className={styles.popUpModal}>
      <div className={styles.popUpSection}>
        <div className={styles.close}
          onClick={closeModal}>
          <img src='/images/postpopup-close.png'
            datasrc='/images/postpopup-close.png'/>
        </div>
        <div className={styles.popUpDetails}>
          <div className={styles.popUpContent}>
            <h1>Congratulations 🥳</h1>
            {toolkitPurchased && addonPurchased==='toolkit' &&
              <div className={styles.toolkitContentSection}>
                <p>You have successfully  purchased your course toolkit!
                Your Course Toolkit is packed full
                of course notes, extra slides and bonus
                content to help you achieve your goals!</p>
                <div className={styles.toolKitSteps}>
                  <h6>To access your new course
                    toolkit follow the below steps </h6>
                  <ul>
                    <li>
                      <span
                        className="library-shaw-icon-new">
                        <i className="icon-new-Mark"></i>
                      </span>
                     Step One: Go To “My Course” </li>
                    <li>
                      <span
                        className="library-shaw-icon-new">
                        <i className="icon-new-Mark"></i>
                      </span>
                     Step Two: Click “Continue Learning” </li>
                    <li>
                      <span
                        className="library-shaw-icon-new">
                        <i className="icon-new-Mark"></i>
                      </span>
                     Step Three: Go To “Resources” </li>
                  </ul>
                </div>
              </div>}
            {hcdPurchased && addonPurchased==='hcd' &&
              <div className={styles.hcdContentSection}>
                <p>You have successfully purchased your course
                  Hard Copy Diploma!There is no better way to show the
                  world your achievements than by hanging your physical
                  diploma proudly on your wall!</p>
                <h6>Once you have completed your course,
              your Hard Copy Diploma will be sent directly to your door.</h6>
              </div>}
            {ecertPurchased && addonPurchased==='ecert' &&
              <div className={styles.ecertContentSection}>
                <p>You have successfully purchased your Module eCertificate!
              Now nothing is stopping you when it comes time to
               sit your final module assignment!</p>
              </div>}
          </div>
          <div className={styles.popUpBannerImg}>
            <img src="/images/postpurchase-banner.png"
              datasrc="/images/postpurchase-banner.png"/>
          </div>
        </div>
        <div className={styles.courseCertificate}>
          <h4>Students like you also bought</h4>
          <ul>
            {!hcdPurchased &&
            <li>
              <div className={styles.certficateDetails}>
                <div className={styles.certificateImg}>
                  <img src='/images/hardcopy-certificate.png'
                    datasrc='/images/hardcopy-certificate.png'/>
                </div>
                <p> Hard Copy Diploma</p>
                <div className={styles.ctaBuyNow}>
                  <button
                    className={disableCta?styles.disable:''}
                    onClick={()=>purchaseAddon(addonPrices['hcd'])}>
                    Buy Now</button>
                  <p>(one click purchase)</p>
                  {showSpinner && selectedAddonType==='hcd' &&
                  <div className={styles.loader}>
                    <Spinner animation="border" />
                  </div>}
                </div>
              </div>
            </li>}
            {!ecertPurchased &&
            <li>
              <div className={styles.certficateDetails}>
                <div className={styles.certificateImg}>
                  <img src='/images/postpopup-certificate.png'
                    datasrc='/images/postpopup-certificate.png'/>
                </div>
                <p>eCertificate</p>
                <div className={styles.ctaBuyNow}>
                  <button
                    className={disableCta?styles.disable:''}
                    onClick={()=>purchaseAddon(addonPrices['cert'])}>
                    Buy Now</button>
                  <p>(one click purchase)</p>
                  {showSpinner && selectedAddonType==='cert' &&
                  <div className={styles.loader}>
                    <Spinner animation="border" />
                  </div>}
                </div>
              </div>
            </li>}
            {!toolkitPurchased &&
            <li>
              <div className={styles.certficateDetails}>
                <div className={styles.certificateImg}>
                  <img src='/images/postpopup-toolkitimg.png'
                    datasrc='/images/postpopup-toolkitimg.png'/>
                </div>
                <p>Toolkit</p>
                <div className={styles.ctaBuyNow}>
                  <button
                    className={disableCta?styles.disable:''}
                    onClick={()=>purchaseAddon(addonPrices['toolkit'])}>
                    Buy Now</button>
                  <p>(one click purchase)</p>
                  {showSpinner && selectedAddonType==='toolkit' &&
                  <div className={styles.loader}>
                    <Spinner animation="border" />
                  </div>}
                </div>
              </div>
            </li>
            }
            <li>
              <div className={styles.certficateDetails}>
                <div className={styles.certificateImg}>
                  <img src='/images/postpurchase-3-months.png'
                    datasrc='/images/postpurchase-3-months.png'/>
                </div>
                <p>3 month subscription</p>
                <div className={styles.ctaBuyNow}>
                  <button
                    className={disableCta?styles.disable:''}
                    onClick={()=>getSubscription('quarterly')}>
                    Buy Now</button>
                  <p>(one click purchase)</p>
                  {showSpinner && selectedAddonType==='subscription' &&
                  <div className={styles.loader}>
                    <Spinner animation="border" />
                  </div>}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

PostPurchasePopUp.propTypes = {
  closeModal: PropTypes.func,
  addonPrices: PropTypes.any,
  addonPurchased: PropTypes.string,
  ecertPurchased: PropTypes.bool,
  toolkitPurchased: PropTypes.bool,
  hcdPurchased: PropTypes.bool,
  popupData: PropTypes.object,
};

export default PostPurchasePopUp;
