import React, {Component} from 'react';
import RescheduleClass from './RescheduleClass';
import ChangeSchedule from './ChangeSchedule';
import RestartSchedule from './RestartSchedule';
import PropTypes from 'prop-types';
import styles from './reschedule.module.scss';
import * as trackAction from '../Shared/TrackActionService';
import {Link, Router} from '@reach/router';
import HeaderNew from '../Shared/HeaderNew';
import MigratePopup from '../Shared2/Popup/MigratePopup';
import * as commonService from '../Shared2/Agent/CommonService';
/**
 *
 *
 * @export
 * @class Reschedule
 * @extends {Component}
 */
export default class Reschedule extends Component {
  /**
   *
   *
   * @return {*}
   * @memberof Reschedule
   */

  /**
   * Creates an instance of Reschedule.
   * @param {*} props
   * @memberof Reschedule
   */
  constructor(props) {
    super(props);
    this.state = {
      rescheduleClass: false,
      changeSchedule: false,
      restartSchedule: false,
      hasMounted: false,
      showMigratePopup: false,
    };
  }

  /**
   * For initial api calls
   *
   * @memberof Reschedule
   */
  componentDidMount() {
    this.setState({hasMounted: true});
    trackAction.pageViewAction('RescheduleOptions', this.props.locale);
    // hiding video ask widget
    const videoAskCloseModal =
        document.querySelector('.videoask-embed__hide_button--f6kKj');
      videoAskCloseModal?.click();

      if (this.props?.studentInfo?.membership?.status === 'inactive' ||
        commonService.checkGiftMembership(this.props?.studentInfo?.membership)
      ) {
        this.setState({
          showMigratePopup: true,
        });
      }
  }

  /**
   *
   *
   * @return {*}
   * @memberof Reschedule
   */
  render() {
    const action=this.props['*'];
    return (
      <> {this.state.hasMounted &&
        <div
          className={`shaw-container flex-center ${
            styles.shContainer} ${styles.shReschedule}`}
          style={{minHeight: '100vh', paddingBottom: '35px'}}>
          <HeaderNew
            registrationData={this.props?.registrationData}
            firstName={this.props?.studentInfo?.name?.first_name}
            locale={this.props?.locale} />
          <div className={`${styles.shCourseSchedule} ${styles.shReschedule}`}>
            { !action &&
            <div className={styles.schedulingMenu}>
              <p className={styles.shPageHeading}>
                What would you like to do?</p>
              <div className={styles.scheduleContainer}>
                <div className={styles.singleElementWrap}>
                  <Link to="reschedule-class">
                    <div className={`${styles.box} ${styles.rescheduleBox}`}
                    >
                      <p>Reschedule a class</p>
                      <img src='/schedule/reschedule-arrow.svg' alt=''/>
                    </div>
                  </Link>
                  <p className={styles.info}>
                    If you have missed a class or can&apos;t attend a
                    specific lesson, reschedule here.
                  </p>
                </div>
                <div className={styles.singleElementWrap}>
                  <Link to="reschedule-classes">
                    <div className={`${styles.box} ${styles.changeCourseBox}`}
                    >
                      <p>Change course schedule</p>
                      <img src='/schedule/reschedule-arrow.svg' alt=''/>
                    </div>
                  </Link>
                  <p className={styles.info}>
                    Change your course schedule for all upcoming
                    lessons or from a specific lesson.
                  </p>
                </div>
                <div className={styles.singleElementWrap}>
                  <Link to="restart-module">
                    <div className={`${styles.box} ${styles.restartModuleBox}`}
                    >
                      <p>Restart full Module</p>
                      <img src='/schedule/restart-arrow.svg' alt=''/>
                    </div>
                  </Link>
                  <p className={styles.info}>
                    Restart your module from the beginning.
                  </p>
                </div>
              </div>
            </div> }
            <Router style={{width: '100%'}}>
              <RescheduleClass
                path='reschedule-class'
                regId={this.props.regId}
                locale={this.props.locale}
                leadCreated={this.props.studentInfo?.created_at}
              />
              <ChangeSchedule
                path='reschedule-classes'
                regId={this.props.regId}
                locale={this.props.locale}
              />
              <RestartSchedule
                path='restart-module'
                regId={this.props.regId}
                locale={this.props.locale}
              />
            </Router>
          </div>
        </div>
      }
      {this.state.showMigratePopup &&
        <MigratePopup />}
      </>
    );
  }
}
Reschedule.propTypes = {
  allCoursesList: PropTypes.array,
  regId: PropTypes.string,
  locale: PropTypes.string,
  ['*']: PropTypes.any,
  registrationData: PropTypes.array,
  credits: PropTypes.number,
  studentInfo: PropTypes.object,
};
