import React, {useState, useEffect} from 'react';
import {Router} from '@reach/router';
import PropTypes from 'prop-types';
import Footer from './Shared/Footer';
import LoginPageWrapper from './Authentication/LoginPage';
import Forgot from './Authentication/ForgotPassword';
import ResetPass from './Authentication/ResetPassword';
import PauseMembershipTabView from
  './Account/Cancellation/PauseMembershipTabView';
import AccountTabViewV2 from './Account/v2/AccountTabView';
import SubscriptionTabView from './Account/v2/SubscriptionTabView';
import FamilyTabViewV2 from './Account/v2/FamilyTabView';
import LeaderboardTabView from './Account/v2/LeaderboardTabView';
import Loadable from 'react-loadable';
import BrowseCourses from './Browse';
import CourseInfo from './Browse/CourseInfo';
import RegistrationsLoader from './Loaders/RegistrationDetailLoader';
import CourseTimeTable from './TimeTable';
import CourseSchedule from './Schedule/index';
import Reschedule from './Schedule/Reschedule';
import PrivateRoute from './PrivateRoute';
import * as apiService from '../api';
import cookie from 'react-cookies';
import RegistrationDetailsNew from './RegistrationDetailV2';
import CourseRegistrationDetailViewNew from
  './RegistrationDetailV2/CourseRegistrationDetailViewNew';
import {navigate} from 'gatsby';
import {checkEligibilityForNewDashboard} from './Shared/Utils';
import AccountSekeleton from './Shared2/LoadingSkeleton/AccountSekeleton';
import CancellationSkeleton from
  './Shared2/LoadingSkeleton/CancellationSkeleton';
import ModuleCertificate from './RegistrationDetailV2/ModuleCertificate';
import ModuleECertificate from './Certificate/index';
import englishCoursesJson from '../../data/englishCourses.json';
import nonEnglishCoursesJson from '../../data/nonEnglishCourses.json';
import studentProfileOptions from '../../data/studentProfileOptions.json';

const StudentDashboard = Loadable({
  loader: () => import('./Courses'),
  loading() {
    return <RegistrationsLoader />;
  },
});

const Account = Loadable({
  loader: () => import('./Account/v2'),
  loading() {
    return <AccountSekeleton />;
  },
});

const Cancellation = Loadable({
  loader: () => import('./Account/Cancellation'),
  loading() {
    return <CancellationSkeleton />;
  },
});
const Leaderboard = Loadable({
  loader: () => import('./RegistrationDetailV2/Leaderboard'),
  loading() {
    return <div></div>;
  },
});

const App = () => {
  const locale = 'en';

  const publicModuleCertUrl = '/public/module-certificate';

  const accountPageSelectOption =
    studentProfileOptions.section[0]?.basicprofile?.items;

  const moduleCertificateData={
    englishCourses: englishCoursesJson,
    nonEnglishCourses: nonEnglishCoursesJson,
  };
  const [studentInfo, setStudentInfo] = useState({});
  const [subscriptionId, setSubscriptionId] = useState('');
  const [hasMount, setMount] = useState(false);
  const [registrationData, setRegistrationdata] = useState([]);
  const [isUserEligibleForNewFlow, setNewFlowEligibility] = useState(false);
  const [credits, setCredits] = useState(0);
  const studentId = cookie.load('shawacademy_leadId');

  // /**
  //  *
  //  *
  //  * @param {*} studentAttributes
  //  */
  const checkUserEligibilityForNewDashboard = (studentAttributes) => {
    if (studentAttributes?.address?.country) {
      const countryCode=studentAttributes.address.country;
      if (checkEligibilityForNewDashboard(countryCode)) {
        setNewFlowEligibility(true);
      }
    }
  };


  /**
   *
   *
   * @param {*} regInfo
   */
  const passDataToHeader = (regInfo) => {
    setRegistrationdata(regInfo);
  };

  useEffect(() => {
    setMount(true);
    if (studentId) {
      new apiService.default.Student(studentId).get()
          .then((res) => {
            const studentAttributes=res?.data?.data?.attributes;
            const subscriptionId=res?.data?.data?.relationships
                ?.subscriptions?.data?.[0]?.id;
            cookie.save('country', studentAttributes?.address?.country,
                {path: '/', domain: process.env.CookieDomain});
            setStudentInfo(studentAttributes);
            setSubscriptionId(subscriptionId);
            checkUserEligibilityForNewDashboard(studentAttributes);
          })
          .catch((err) => {
            if (err?.response?.data?.errors[0]?.code == 404) {
              cookie.remove('shawacademy_leadId', {path: '/'});
              cookie.remove('student_name', {path: '/'});
              cookie.remove('shawacademy_leadId', {path: '/',
                domain: process.env.CookieDomain});
              cookie.remove('auth_token', {path: '/',
                domain: process.env.CookieDomain});
              cookie.remove('country');
              navigate(`/app/${locale}/login`);
            }
          });
    }
  }, []);

  const getCreditsFromChild = (data) => {
    setCredits(data);
  };

  return (
    <>
      {hasMount && <div className="app" style={{backgroundColor: '#f6f7f9'}}>
        <Router basepath="/app/:locale">
          <LoginPageWrapper path="/login" />

          <Forgot
            path="/forgot"
          />

          <ResetPass path="/reset" />

          <PrivateRoute path='/register'
            component={CourseSchedule}
            locale={locale}
            studentInfo={studentInfo}
            registrationData={registrationData}
            credits={credits}
            firstName={studentInfo?.name?.first_name}
          />

          <PrivateRoute path='/timetable/*'
            component={CourseTimeTable}
            locale={locale}
            studentInfo={studentInfo}
            credits={credits}
          />

          <PrivateRoute path='/certificate/'
            component={ModuleECertificate}
            locale={locale}
            studentInfo={studentInfo}
            publicCertUrl={publicModuleCertUrl}
            credits={credits}
          />

          <PrivateRoute path='/reschedule/:regId/*'
            component={Reschedule}
            locale={locale}
            registrationData={registrationData}
            credits={credits}
            studentInfo={studentInfo}
          />

          <PrivateRoute path='/*'
            component={StudentDashboard}
            studentInfo={studentInfo}
          />

          {/* TODO: move data out of CMS */}
          <PrivateRoute path='/courses'
            locale={locale}
            studentInfo={studentInfo}
            firstName={studentInfo?.name?.first_name}
            credits={credits}
            component={BrowseCourses}
          >
          </PrivateRoute>

          {/* TODO: move data out of CMS */}
          <PrivateRoute path='/courses/:courseslug'
            component={CourseInfo}
            locale={locale}
            credits={credits}
            firstName={studentInfo?.name?.first_name}/>

          <PrivateRoute path='/v2/registrations/'
            registrationData={registrationData}
            credits={credits}
            firstName={studentInfo?.name?.first_name}
            component={RegistrationDetailsNew}>
            <CourseRegistrationDetailViewNew
              passDataToHeader={passDataToHeader}
              locale={locale}
              passCredits={getCreditsFromChild}
              path=":regId/modules/:moduleNumber/*"
              firstName={studentInfo?.name?.last_name}
              lastName={studentInfo?.name?.last_name}
              // leaderboardActionMappingData={leaderboardData?.
              //     actionmapping?.value}
            />
          </PrivateRoute>

          <PrivateRoute path='/account'
            component={Account}
            registrationData={registrationData}
            passDataToHeader={passDataToHeader}
            passCredits={getCreditsFromChild}
            locale={locale}
            subscriptionId={subscriptionId}
            credits={credits}
            studentInfo={studentInfo}
            firstName={studentInfo?.name?.first_name}
            accountPageSelectOption={accountPageSelectOption}
          >
            <AccountTabViewV2 path='' default />
            <SubscriptionTabView locale={locale} path='/membership' />
            <FamilyTabViewV2 path='/family' />
            <LeaderboardTabView path='/leaderboard' />
          </PrivateRoute>

          <PrivateRoute path='/account/pause'
            component={PauseMembershipTabView}
          />

          <PrivateRoute path='/account/cancellation'
            component={Cancellation}
            locale={locale}
            credits={credits}
            firstName={studentInfo?.name?.first_name}
            registrationData={registrationData}
            studentInfo={studentInfo}
          />

          <PrivateRoute path='/leaderboard'
            registrationData={registrationData}
            component={Leaderboard}
            studentInfo={studentInfo} />

          <PrivateRoute path='/module-certificate'
            component={ModuleCertificate}
            registrationData={registrationData}
            credits={credits}
            firstName={studentInfo?.name?.first_name}
            locale={locale}
            data={moduleCertificateData}
            studentInfo={studentInfo}
          />
        </Router>

        <Footer
          eligibleForNewDashboard={isUserEligibleForNewFlow} />
      </div>}
    </>
  );
};

App.propTypes = {
  data: PropTypes.object,
};

export default App;
