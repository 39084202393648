import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import * as trackAction from '../../Shared/TrackActionService';
import styles from '../../Shared2/ToolkitPopup.module.scss';

const VimeoAddonsPopUp = ({popUpData}) => {
  useEffect(() => {
    trackAction.popUpAction({
      studentId: popUpData?.studentId,
      pageKind: popUpData?.pageKind,
      title: 'Get Your Addons',
      locale: popUpData?.locale,
      courseSlug: popUpData?.courseSlug,
      moduleNumber: popUpData?.moduleNumber,
      variation: 'Default',
    });
  }, []);

  const getIframeUrl = () => {
    const url = `/vimeo-addons`;
    return url;
  };

  return (
    <>
      <div className={styles.toolkitPopup}>
        <div className={styles.modalBackdrop}></div>
        <iframe className={styles.vimeoAddonsIframe}
          src={getIframeUrl()}>
        </iframe>
      </div>
    </>
  );
};

VimeoAddonsPopUp.propTypes = {
  popUpData: PropTypes.object,
};

export default VimeoAddonsPopUp;
