import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './accordion.module.scss';

const Accordion = ({
  moduleData,
  index,
}) => {
  const [setActive, setActiveState] = useState('');
  const [setDisplay, setDisplayState] = useState('none');
  const [moduleOpen, setModuleOpen] = useState(false);
  const content = useRef(null);

  useEffect(() => {
    if (index === 0 && !moduleOpen) {
      setActiveState('active');
      setDisplayState('block');
    }
  });

  const toggleAccordion = () => {
    setModuleOpen(true);
    setActiveState(setActive === '' ? 'active' : '');
    setDisplayState(setActive === 'active' ? 'none' : 'block');
  };

  return (
    <>
      <button
        type="button"
        className={`${styles.accordion} ${setActive}`}
        onClick={toggleAccordion}
      >
        <div className={styles.textWrapper}>
          <p>
            <span>{`Module ${index+1}`}</span>
            <span className={styles.addIcon}>{setActive?'-':'+'}</span>
          </p>
        </div>
      </button>
      <div
        className={styles.accordionContent}
        ref={content}
        style={{display: `${setDisplay}`}}
      >
        <ul>
          {
            moduleData?.lessons.map((lesson, index) => {
              return (
                <li key={index}>
                  <p className={styles.lessonName}>{lesson.lessonname}</p>
                  <p className={styles.lessonDescription}>
                    {lesson?.lessondescription &&
                    lesson.lessondescription.replace(/<[^>]*>?/gm, '')}
                  </p>
                </li>
              );
            })
          }
        </ul>
      </div>
    </>
  );
};

Accordion.propTypes = {
  moduleData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
export default Accordion;
