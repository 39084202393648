import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import AssetsService from '../AssetsService';
import CourseSwitcher from '../../Shared2/Popup/CourseSwitcher';
import * as trackAction from '../TrackActionService';
import headerStyles from '../../../styles/Header.module.scss';
import cookie from 'react-cookies';
// import {studentCurrency} from '../../Shared/Utils';


/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function HeaderNew(props) {
  const [showCourseSwitcher, triggerCourseSwitcher]=useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [coursesToRender, setCoursesToRender]=useState([]);
  const assetsService= new AssetsService('RECORDINGS');

  // const userCurrency = studentCurrency();
  const showCourseSwitcherModal = () => {
    trackAction.popUpAction({
      'title': 'Switch Course',
    });
    triggerCourseSwitcher(true);
  };

  useEffect(() => {
    const leadId = cookie.load('shawacademy_leadId');
    isProfileImageAvailable(leadId);
  }, []);

  useEffect(() => {
    if (props?.registrationData?.length) {
      getCoursesToRender();
    }
  }, [props.registrationData]);

  const isProfileImageAvailable = (leadId) => {
    const url = `https://shaw-profile-pic.s3.amazonaws.com/${leadId}.jpeg`;
    const profilePic = new Image();
    profilePic.onload = () => {
      setProfilePic(url);
    };
    profilePic.onerror = () => {
      setProfilePic(null);
    };
    profilePic.src = url;
  };

  /**
   *
   *
   */
  const getCoursesToRender = () => {
    const courses=props.registrationData;
    courses.map((course) => {
      const courseSlug=course.attributes.localizedcourseslug;
      course['courseImg']=assetsService.fetchAssetURL(courseSlug);
      course['url']=`/app/${props.locale}/v2/registrations/${course.id
      }/modules/${course.activeModule||1}/`;
    });
    setCoursesToRender(courses);
  };

  /**
   *
   *
   * @param {*} courseIndex
   */
  const reorderCourseSwitcher = (courseIndex) => {
    const reorderedCourses=coursesToRender;
    reorderedCourses.splice(0, 0, reorderedCourses.splice(courseIndex, 1)[0]);
    setCoursesToRender(reorderedCourses);
  };


  // first 3 will be displayed - unique regs - module 1
  // all courses on course switcher - with show more
  // once course is selected. push it to first 3 regs

  return (
    <>
      <div className={headerStyles.shawHeader}>
        <div className={headerStyles.shawLogo}>
          <img src='/dashboard-new/header/Logo__mark.png'
            alt='Shaw Academy'/>
        </div>
        <div className={headerStyles.courseSwitcher}>
          {coursesToRender?.length ?
           coursesToRender.slice(0, 3).map((course, index) => (
             <Link to={course.url}
               activeClassName={headerStyles.active}
               key={index} className={headerStyles.singleCourse}>
               <img src={course.courseImg}
                 alt={course.attributes.coursename}/>
               <span></span>
             </Link>
           )): null}
          <div className={`${headerStyles.singleCourse}
            ${headerStyles.moreCourseCta}`}
          onClick={() => showCourseSwitcherModal()}>
            <div className={headerStyles.switcherTrigger}>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
        <div className={headerStyles.routeSwitcher}>
          {/* Time table will be added in the next release  */}
          {/* <Link to="/app/en/" className='route-link'>
            <img src='/dashboard-new/courses/calendar-filled.svg' alt=''/>
            <p className='route-name'>Timetable</p>
            <span></span>
          </Link> */}
          {coursesToRender?.length ?
           coursesToRender.map((course, index) => (
             <Link to={course.url}
               activeClassName={headerStyles.active}
               key={index} className={`${headerStyles.routeLink}
               ${headerStyles.courseLink}`}>
               <img src={course.courseImg}
                 alt={course.attributes.coursename}/>
               <span></span>
             </Link>
           )): null}
          <Link to={`/app/${props?.locale}/`}
            className={headerStyles.routeLink}>
            <div className={`${headerStyles.timeTableIcon}
             library-shaw-icon-new`}>
              <i className='icon-home-filled'></i>
            </div>
            <p className={headerStyles.routeName}>
              My Courses
            </p>
            <span></span>
          </Link>
          <Link to={`/app/${props?.locale}/timetable/`}
            className={headerStyles.routeLink}>
            <div className={`${headerStyles.timeTableIcon}
             library-shaw-icon-new`}>
              <i className='icon-new-Calendar'></i>
            </div>
            <p className={headerStyles.routeName}>
              TimeTable
            </p>
            <span></span>
          </Link>
          <Link to={`/app/${props?.locale}/courses/`}
            className={`${headerStyles.routeLink} ${
              headerStyles.browseWrapper}`}>
            <div
              className={`${headerStyles.browseIcon} library-shaw-icon-new`}>
              <i className='icon-new-Compass-filled1'></i></div>
            <p className={headerStyles.routeName}>
              Browse
            </p>
            <span></span>
          </Link>
          <Link to={`/app/${props?.locale}/account/`}
            className={`${headerStyles.routeLink} ${
              headerStyles.hideOnMobile}`}>
            {/* <span className={headerStyles.creditsBadge}>
              {props?.credits}</span> */}
            <img
              src={profilePic?profilePic:
                '/dashboard-new/courses/user-filled.svg'}
              alt=''
            />
            <p className={headerStyles.routeName}>
              {props?.firstName? props.firstName:
                `Account`}
            </p>
            <span></span>
          </Link>
          <a
            target="__blank"
            href="https://www.shawacademy.com/faq/"
            className={`${headerStyles.routeLink} ${headerStyles.helpIcon}`}>
          </a>
        </div>
        {showCourseSwitcher && <CourseSwitcher
          locale={props?.locale}
          reorderCourseSwitcher={reorderCourseSwitcher}
          closePopup={() => triggerCourseSwitcher(false)}
          coursesToRender={coursesToRender} />}
      </div>
    </>
  );
}


HeaderNew.propTypes = {
  registrationData: PropTypes.array,
  activeMobileSwitcherCourse: PropTypes.object,
  locale: PropTypes.string,
  firstName: PropTypes.string,
};

export default HeaderNew;
