import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Spinner} from 'react-bootstrap';
import styles from '../AddonsConfirmation.module.scss';
import * as purchaseAction from '../Banner/AddonPurchaseActionService';
import * as trackAction from '../../Shared/TrackActionService';

const AddonsConfirmation = ({showAddonsPopUp, dataToPassToConfirmation}) =>{
  const prices = dataToPassToConfirmation?.priceInfo;
  const [showSpinner, setShowSpinner] = useState(false);
  let actionToSend;
  useEffect(() => {
    let titleToSend = 'ToolkitConfirmation';
    actionToSend = 'PurchaseToolkit';
    if (dataToPassToConfirmation?.param === 'cert') {
      titleToSend = 'CertConfirmation';
      actionToSend = 'PurchaseEcert';
    } else if (dataToPassToConfirmation?.param === 'hcd') {
      titleToSend = 'HcdConfirmation';
      actionToSend = 'PurchaseHardCopyDiploma';
    }
    trackAction.popUpAction({
      studentId: dataToPassToConfirmation?.studentId,
      pageKind: dataToPassToConfirmation?.pageKind,
      title: dataToPassToConfirmation?.title || titleToSend,
      locale: dataToPassToConfirmation?.locale,
      courseSlug: dataToPassToConfirmation?.courseSlug,
      moduleNumber: dataToPassToConfirmation?.moduleNumber,
      variation: 'Default',
    });
  }, []);

  const purchaseAddon = () => {
    setShowSpinner(true);
    trackAction.ctaClickAction({
      cta: dataToPassToConfirmation?.cta || 'Buy Now',
      action: actionToSend,
      locale: dataToPassToConfirmation?.locale,
      pageKind: dataToPassToConfirmation?.pageKind,
      courseSlug: dataToPassToConfirmation?.courseSlug,
      moduleNumber: dataToPassToConfirmation?.moduleNumber,
      studentId: dataToPassToConfirmation?.studentId,
    });
    const objToPass = {
      currencyCode: prices?.currency_code,
      activeTierCode: prices?.default_tier_code,
      addon: dataToPassToConfirmation?.param,
      country: prices?.country_code,
      slug: dataToPassToConfirmation?.courseSlug,
      cta: 'Buy Now',
      moduleNumber: dataToPassToConfirmation?.param !== 'hcd'?
      parseInt(dataToPassToConfirmation?.moduleNumber):null,
      courseName: dataToPassToConfirmation?.courseName,
      studentId: dataToPassToConfirmation?.studentId,
      regId: dataToPassToConfirmation?.regId,
      locale: dataToPassToConfirmation?.locale,
      isEqfCourse: true,
      moduleSlug: dataToPassToConfirmation?.moduleSlug,
      pageKind: dataToPassToConfirmation?.pageKind,
      sourceUrl: dataToPassToConfirmation?.sourceUrl,
    };
    purchaseAction.addonPurchaseAction(objToPass);
  };

  const getCardType = () => {
    if (dataToPassToConfirmation?.param==='cert') {
      return styles.certWrapper;
    }
    if (dataToPassToConfirmation?.param==='hcd') {
      return styles.hcdWrapper;
    }
    return styles.toolkitWrapper;
  };

  return <div className={styles.modalContainer}>
    <div className={`${styles.modalWrapper} ${
      getCardType()}`}>
      <div className={styles.modalHeader}>
        <div className={styles.modalClose}
          onClick={() => showAddonsPopUp(false)}>
          <div
            className={' library-shaw-icon-new'}>
            <i className="icon-new-close"></i>
          </div>
        </div>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.title}>
          <p className={`${dataToPassToConfirmation?.param === 'hcd' ?
          styles.hcdTitle : ''}`}>
            {dataToPassToConfirmation?.param == 'hcd' ?
              `Get your courses` :
              `Get Your`}&nbsp;
            {dataToPassToConfirmation?.textInfo}
            {dataToPassToConfirmation?.param === 'hcd' &&
              <span><br />&nbsp;for
                {' '}{prices?.currency_symbol}&nbsp;
                {prices?.default_tier_price}&nbsp;</span>
            }
          </p>
          {dataToPassToConfirmation?.param !== 'hcd' &&
          <p>
            Buy 1 Only At&nbsp;
            {prices?.currency_symbol}&nbsp;
            {prices?.default_tier_price}&nbsp;Get 3 Free
          </p>}
        </div>
        <p className={styles.content}>
          {dataToPassToConfirmation?.param === 'cert' ?
            `Get access to your weekly & final assignments certified by world
            class institutions` :
            dataToPassToConfirmation?.param === 'hcd' ?
          `Your course Hard Copy Diploma allows you to showcase your
          achievements and is delivered straight to your door` :
          `Get your class slides, lesson notes curated by your educator
          and bonus content`}
        </p>
        <div className={styles.ctaWrapper}>
          <button className={showSpinner ? styles.disableButton :
        styles.confirmButton}
          onClick={purchaseAddon}>
            Buy Now
          </button>
          {showSpinner && <div className={styles.loader}>
            <Spinner animation="border" />
          </div>}
        </div>
      </div>
      <div className={styles.footer}>
        <p>
          <span>
            {dataToPassToConfirmation?.param === 'cert' ?
            `By clicking Buy Now, you authorise Shaw Academy to take payment
            right away for your certificate and agree to Shaw Academy’s` :
            dataToPassToConfirmation?.param === 'hcd' ?
            `By clicking Buy Now, you authorise Shaw Academy to take payment
            right away for your hard copy diploma and agree to Shaw Academy’s` :
            `By clicking Buy Now, you authorise Shaw Academy to take payment
            right away for your course materials and agree to Shaw Academy’s`}
          </span>
          <span className={styles.highlight}>
            <a href="https://www.shawacademy.com/terms/" target="__blank">
            &nbsp;Terms & Conditions
            </a>
          </span>
        </p>
      </div>
    </div>
    <div className={styles.overlay}/>
  </div>;
};

AddonsConfirmation.propTypes = {
  showAddonsPopUp: PropTypes.func,
  dataToPassToConfirmation: PropTypes.object,
  headingLabels: PropTypes.object,
  ctaTextLabels: PropTypes.object,
  buyNowLabels: PropTypes.object,
};

export default AddonsConfirmation;
