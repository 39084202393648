import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Leaderboard from '../../RegistrationDetailV2/Leaderboard';

/**
 *
 *
 * @export
 * @class LeaderboardTabView
 * @extends {Component}
 */
export default class LeaderboardTabView extends Component {
  /**
 *
 *
 * @return {*}
 * @memberof LeaderboardTabView
 */
  render() {
    return <>
      <Leaderboard
        registrationData={this.props.registrationData}
        studentInfo={this.props?.studentInfo}
        origin='account'
      />
    </>;
  }
}

LeaderboardTabView.propTypes = {
  registrationData: PropTypes.array,
  studentInfo: PropTypes.object,
};
