import React from 'react';
import {Tab} from 'react-bootstrap';
import CourseCard from './CourseCard';
import PropTypes from 'prop-types';
import browseStyles from './browse.module.scss';

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export default function CourseList(props) {
  const coursesByLocale = props.courses;
  const locale = props.locale;
  const studentRegistrations = props.studentRegistrations;
  return (
    <>
      <Tab.Content className={browseStyles.shCoursesoffered}
        style={{minHeight: 'unset'}}>
        <div>
          <div className={browseStyles.cardList}>
            {coursesByLocale.map((course, i) => (
              <CourseCard
                key={i}
                learnMore={props.learnMore}
                course={course}
                locale={locale}
                registrationData={props?.registrationData}
                registeredTags={props?.registeredTags}
                selectedTag={props?.tagName}
                isUserInTrial={props?.isUserInTrial}
                studentInfo={props?.studentInfo}
                subscriptionAttributes={props?.subscriptionAttributes}
                leadId={props?.leadId}
                studentRegistrations={studentRegistrations}
              />
            ))}
          </div>
        </div>
      </Tab.Content>
    </>
  );
}

CourseList.propTypes = {
  courses: PropTypes.array,
  tagName: PropTypes.object,
  locale: PropTypes.string,
  learnMore: PropTypes.string,
  registrationData: PropTypes.array,
  isUserInTrial: PropTypes.bool,
  registeredTags: PropTypes.array,
  studentInfo: PropTypes.object,
  subscriptionAttributes: PropTypes.object,
  leadId: PropTypes.string,
  studentRegistrations: PropTypes.object,
};
