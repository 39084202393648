import React, {useEffect, useRef} from 'react';
import styles from '../MigratePopup.module.scss';
import {navigate} from 'gatsby';
import {getElementRefByClassName} from '../../../Payments/Utils';
import * as authService from '../../Authentication/AuthService';
import * as trackAction from '../../Shared/TrackActionService';
import cookie from 'react-cookies';

const MigratePopup = () => {
  const countdownRef = useRef(null);

  const logoutUser = () => {
    authService.logout();
    trackAction.ctaClickAction({
      'studentId': cookie.load('shawacademy_leadId'),
      'cta': 'MigrateNow',
      'action': 'logout',
      'pageKind': 'migratePopup',
    });
    sessionStorage.clear();
    navigate(`/signup/`);
  };

  const countdown = (countdownTime) => {
    const countdownTextRef =
      getElementRefByClassName(countdownRef, 'countdown-info');
    const timer = setInterval(() => {
      let seconds = parseInt(countdownTime % 60, 10);
      seconds = seconds < 10 ? '0' + seconds : seconds;
      countdownTextRef.textContent =
        `Redirecting to Upskillist in ${seconds} seconds`;

      if ((countdownTime --) > 0) {
        sessionStorage.setItem('MIGRATE_COUNTER', countdownTime);
      } else {
        sessionStorage.clear();
        logoutUser();
        clearInterval(timer);
      }
    }, 1000);
  };

  useEffect(() => {
    const countdownTime = sessionStorage.getItem('MIGRATE_COUNTER') || 60;
    countdown(countdownTime);
  });

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.migratePopupContentContainer}>
          <div ref={countdownRef} className={styles.migratePopupContent}>
            <p className={`${styles.countdownText} countdown-info`}>
            Loading...
            </p>
            <h3>Your membership with Shaw Academy has ended</h3>
            <p className={styles.subheading}>
              Start your Upskillist subscription today
            </p>
            <button onClick={logoutUser}>Start Now</button>
            <div className={styles.contentBody}>
              <div className={styles.sectionOne}>
                <p className={styles.heading}>
                  Why you should start your account today:
                </p>
                <div className={styles.content}>
                  <ul>
                    <li>Get unlimited access to over 70+ courses</li>
                    <li>
                    All courses are designed by our in-house team of experts
                    </li>
                    <li>20 learning packed hours available per course</li>
                    <li>Flexible learning time tables</li>
                    <li>
                    Continuous assessments and quizzes so you can test yourself!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.migratePopupImage}>
            <img
              src="/images/migratePopup.png"
              alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MigratePopup;
