import * as apiService from '../../../api';

export const listFamilyMembers = async (subscriptionId, leadId) => {
  const membersData = await new apiService.default.AddMember(leadId)
      .listMembers(subscriptionId)
      .then((res) => {
        return res?.data;
      })
      .catch((error)=>{
        console.log(error);
        return error?.response?.data?.errors[0];
      });

  return membersData;
};

export const listLeadAndRegistration = async (member) => {
  const attributes = await new apiService.default.Addons(member.student_id)
      .listLeadAndRegistration()
      .then((res)=>{
        return res?.data?.data?.attributes;
      })
      .catch((error)=>{
        return error?.response?.data?.errors[0];
      });
  return attributes;
};

export const addMemberService = async (firstName, lastName, email,
  subscriptionId, leadId) => {
  const data = {
    'id': leadId,
    'first_name': firstName,
    'last_name': lastName,
    'email': email,
  };
  const memberResponse = await new apiService.default.AddMember(leadId).
      addMember(subscriptionId, data).then((res) => {
        if (res['statusCode'] === 400) throw new Error();
        return res;
      })
      .catch((error) => {
        return error?.response?.data;
      });
  return memberResponse;
};

export const removeMemberService = async (subscriptionId, memberId, leadId) => {
  const isRemoved = await new apiService.default.AddMember(leadId).
      removeMember(subscriptionId, memberId)
      .then((res) => {
        return true;
      }).catch((error) => {
        console.log('error ', error);
        return false;
      });
  return isRemoved;
};
