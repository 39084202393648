import React, {Component} from 'react';
import {Nav, Tab} from 'react-bootstrap';
import LessonsTabView from './LessonsTabView';
import ResourcesTabView from './ResourcesTabView';
import * as apiService from '../../api';
import * as registrationDetailService
  from './RegistrationDetailService';
import * as trackAction from '../Shared/TrackActionService';
import * as utils from '../Shared/Utils';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import AssetsService from '../Shared/AssetsService';
import * as constants from '../../containers/Constants';
import addonPrices from '../../api/AddonPrices.json';
import {getPrices} from '../../api/AddonPrices';
import {navigate} from 'gatsby';
import * as courseService from '../Browse/AllCoursesService';
import CourseRegistrationSkeleton
  from '../Shared2/LoadingSkeleton/CourseRegistrationSkeleton';
import {Steps} from 'intro.js-react';
import AssessmentsTabView from './AssessmentsTabView';
import {Dropdown} from 'react-bootstrap';
import DeleteCourse from '../Shared2/Popup/DeleteCourse';
import courseStyles from '../../styles/Courses.module.scss';
import CourseUnavailable from './CourseUnavailable';
let xTouchStart;
let yTouchStart;
import StickyAddon from '../Shared2/Banner/StickyAddon';
import AssessmentService from './AssessmentService';
import UserIconWithXp from './UserIconWithXp';
import CourseSwitcherMobileView from '../Shared2/CourseSwitcherMobileView';
import 'intro.js/introjs.css';
import googlePlay from '../../../static/images/googlePlay.svg';
import appstore from '../../../static/images/appstore.svg';
// import {countriesEligibleForWatchModule} from
//   '../../../src/containers/Constants';
import {getSubscriptionPlanData, defaultPriceObject}
  from '../Shared2/Service/UpgradeNowService';
const studentId=cookie.load('shawacademy_leadId');
/**
 *
 *
 * @export
 * @class CourseRegistrationDetailViewNew
 * @extends {Component}
 */
export default class CourseRegistrationDetailViewNew extends Component {
  /**
   * Creates an instance of CourseRegistrationDetailViewNew.
   * @param {*} props
   * @memberof CourseRegistrationDetailViewNew
   */
  constructor(props) {
    super(props);

    this.state = {
      touchStart: 0,
      touchEnd: 0,
      activeTab: ['resources', 'assignments'].includes(
          this.props['*'])?this.props['*']:'content',
      showCourseSwitcher: false,
      loading: false,
      hasMount: false,
      coursesOnSwitcher: [],
      initialStep: 0,
      startOnBoarding: false,
      showDeleteModal: false,
      showStickyUnlockModule: false,
      addonPriceMap: {
        toolkit: addonPrices?.['toolkit']?.[0]?.['eu'],
        cert: addonPrices?.['cert']?.[0]?.['eu'],
        hcd: addonPrices?.['hcd']?.[0]?.['eu'],
      },
      experiencePoints: 0,
      hideVideoWidget: false,
      steps: [
        {
          element: '.shaw-tab-switcher',
          intro: `The weekly schedule of each course is listed here`,
          position: 'top',
          tooltipClass: 'tooltips new-tooltips',
        },
        {
          element: '.module-switcher-outer',
          intro: `Switch through modules by using the switcher`,
          position: 'top',
          tooltipClass: 'tooltips new-tooltips',
        },
        {
          element: `.lesson-link, .add-to-calendar-link`,
          intro: `You can check your lessons here`,
          position: 'top',
          tooltipClass: 'tooltips new-tooltips',
        },
        {
          element: '.reschedule-block',
          intro: `You can reschedule to another time here`,
          position: 'top',
          tooltipClass: 'tooltips new-tooltips',
        },
        // {
        //   element: '.leaderboard-banner',
        //   intro: `You can see your position on Leaderboard here`,
        //   position: 'top',
        //   tooltipClass: 'tooltips new-tooltips',
        // },
      ],
      prices: defaultPriceObject,
      // lesson recordings - .lesson-link
      // reschedule class - .reschedule-block
      // module switcher - .module-switcher-outer
      // content  - .shaw-tab-switcher.
      // leaderboard- .leaderboard-banner
    };
    this.courseSelectorPopupRef = React.createRef();
    this.assetsService = new AssetsService('RECORDINGS');
  }


  /**
   *
   *
   * @memberof CourseRegistrationDetailViewNew
   */
  triggerCourseSwitcher() {
    this.setState((prevState) => ({
      ...prevState,
      showCourseSwitcher: !prevState.showCourseSwitcher,
    }));
  }

   /**
    *
    *
    * @param {*} e
    * @memberof CourseRegistrationDetailViewNew
    */
   handleClickOutside = (e) => {
     //  if ( this.state.showCourseSwitcher
     //  ) {
     //    e.stopPropagation();
     //  }
     if (this?.state?.showCourseSwitcher &&
      (this.courseSelectorPopupRef.current &&
      !this.courseSelectorPopupRef.current.contains(e.target))) {
       this.triggerCourseSwitcher();
     }
   };

   /**
    *
    *
    * @param {*} stateValue
    * @return {*}
    * @memberof CourseRegistrationDetailViewNew
    */
   getModuleInfo(stateValue) {
     return registrationDetailService
         .getModuleData(stateValue, this.props.regId);
   }

   /**
    *
    *
    * @memberof CourseRegistrationDetailViewNew
    */
   fetchRegistrationData() {
     const studentId = cookie.load('shawacademy_leadId');
     const {regId} = this.props;
     new apiService.default.Registrations(studentId).list()
         .then((res) => (res.data)).then((res) => {
           const studentInfo = res;
           const registeredTags =
            courseService.getListOfRegisteredTags(res.data);
           this.setState((prevState) => ({
             ...prevState,
             registeredTags,
           }), () => {});
           new apiService.default.Addons(studentId).list()
               .then((res) => {
                 const addonInfo = {
                   getAddonInfo: res.data.data,
                 };
                 const registrationData = registrationDetailService
                     .getCoursesWithAddons(addonInfo, studentInfo);
                 if (!registrationData) {
                   navigate(`/app/${this.props?.locale}/courses`);
                   return;
                 }
                 this.getCoursesToRenderOnCourseSwitcher(registrationData);
                 const courseData = registrationDetailService.
                     getCurrentRegistrationData(registrationData, regId);
                 this.props.passDataToHeader(registrationData);
                 this.setState((prevState) => ({
                   ...prevState,
                   loading: false,
                   //  startOnBoarding: true,
                   studentInfo,
                   courseData,
                   addonInfo: res.data.data,
                 }), () => {
                   this.fetchAssessmentData();
                   this.callXpHandler();
                 });
               }).catch(() => {
                 const registrationData = registrationDetailService
                     .getCoursesWithAddons([], studentInfo);
                 if (!registrationData) {
                   navigate(`/app/${this.props?.locale}/courses`);
                   return;
                 }
                 this.getCoursesToRenderOnCourseSwitcher(registrationData);
                 const courseData = registrationDetailService.
                     getCurrentRegistrationData(registrationData, regId);
                 this.props.passDataToHeader(registrationData);
                 this.setState((prevState) => ({
                   ...prevState,
                   loading: false,
                   studentInfo,
                   //  startOnBoarding: true,
                   courseData,
                   addonInfo: {},
                 }), () => {
                   this.fetchAssessmentData();
                   this.callXpHandler();
                 });
               });
         });
   }

   /**
    *
    *
    * @memberof CourseRegistrationDetailViewNew
    */
   fetchStudentData() {
     const leadId = cookie.load('shawacademy_leadId');
     new apiService.default.Student(leadId).listLeadAndSubscription()
         .then((res) => {
           const leadCountry = res.data.data.attributes.address.country;
           const leadLanguage = res.data.data.attributes.language_id;
           let countryToSend = leadCountry;
           const euCountry = constants.euCountryList;
           const usCountry = constants.lowerUSDCountryList;
           if (usCountry.includes(leadCountry)) {
             countryToSend = '3w';
           }
           if (!addonPrices?.toolkit[0][leadCountry]) {
             countryToSend = 'us';
           }
           if (euCountry.includes(leadCountry)) {
             countryToSend = 'eu';
           }
           this.fetchRetakePrice(countryToSend);
           this.fetchAddonPrice(countryToSend);
           getSubscriptionPlanData(leadCountry)
               .then((res)=>{
                 this.setState({
                   prices: res,
                 });
               });
           const salesOfferData = {
             leadId,
             countryToSend,
             leadLanguage,
           };
           this.setState({
             membershipInfo: res.data.data.attributes,
             subscriptionInfo: res.data?.included?.[0],
             salesOfferData,
           }, ()=> this.callXpHandler());
         });
     // TODO: handling credits
     new apiService.default.Student(leadId).getCreditsBalance()
         .then((res) => {
           const creditsBalance=res.data.data.attributes.credits_balance;
           this.props.passCredits(creditsBalance);
           this.setState((prevState) => ({
             ...prevState,
             credits: creditsBalance,
           }));
         })
         .catch((err) => {
           this.setState((prevState) => ({
             ...prevState,
             credits: 0,
           }));
         });
   }

   /**
    *
    *
    * @memberof CourseRegistrationDetailViewNew
    */
   fetchAssessmentData() {
     const {courseData, addonInfo}=this?.state;
     const moduleData=courseData?.relationships?.modules?.data;
     const firstModuleId=moduleData?.[0]?.attributes['registration_id'];
     const studentId=cookie.load('shawacademy_leadId');
     // Assessments api call made to the first module regId
     if (firstModuleId) {
       new apiService.default.Assessments(studentId)
           .getAssignmentData(firstModuleId)
           .then((res) => {
             // returns assessments for all modules (including final assesments)
             const assessmentData=res?.data?.data;
             if (assessmentData.length) {
               const assessmentService=new AssessmentService(assessmentData);
               assessmentService.setAddonData(addonInfo);
               const courseDataWithAssessments=
               assessmentService.segregateAssessmentData(courseData);
               this.initilizeRetakes(courseDataWithAssessments);
               this.setState((prevState)=>({
                 ...prevState,
                 courseData: courseDataWithAssessments,
               }));
             } else {
               this.initilizeRetakes(courseData);
             }
           })
           .catch((err) => {
           });
     }
   }

   /**
    *
    *
    * @param {*} country
    * @memberof CourseRegistrationDetailViewNew
    */
   fetchAddonPrice(country) {
     const addons = [{
       product: 'module-toolkit',
       type: 'toolkit',
     }, {
       product: 'module-cert',
       type: 'cert',
     }, {
       product: 'course-hard-copy-diploma',
       type: 'hcd',
     }];
     const promises = addons.map((addon)=> {
       return getPrices(addon, country);
     });
     Promise.all(promises).then((res)=> {
       // converting an array of object to object
       const addonPriceMap = res.reduce((acc, cur)=>{
         acc[cur.type]=cur;
         return acc;
       }, {});
       this.setState((prevState) => ({
         ...prevState,
         addonPriceMap,
       }));
     }).catch((err)=>{
     });
   }


   /**
    *
    *
    * @param {*} country
    * @memberof CourseRegistrationDetailViewNew
    */
   fetchRetakePrice(country) {
     new apiService.default.Assessments(studentId)
         .getPricingInfo(country)
         .then((res) => {
           this.setState((prevState) => ({
             ...prevState,
             retakePrice: res.data,
           }));
         })
         .catch((err) => {
         });
   }

   /**
    *
    *
    * @param {*} courseData
    * @memberof CourseRegistrationDetailViewNew
    */
   initilizeRetakes(courseData) {
     const moduleData=courseData?.relationships?.modules?.data;
     const dataToState=[];
     moduleData.forEach((module, moduleIndex) => {
       const finalAssessment=module.attributes?.finalassignment;
       const retakesWithAttempts={
         moduleNumber: moduleIndex+1,
         retakes: finalAssessment?.retakes||0,
         attempts: finalAssessment?.result?.attempts||0,
       };
       dataToState.push(retakesWithAttempts);
     });
     this.setState({
       finalAssessmentRetakes: dataToState,
     });
   }
   /**
    *
    *
    * @memberof CourseRegistrationDetailViewNew
    */
   loadDependencies() {
     this.setState((prevState) => ({
       ...prevState,
       loading: true,
     }));
     this.fetchRegistrationData();
     this.fetchStudentData();
   }

   /**
    *
    *
    * @memberof CourseRegistrationDetailViewNew
    */
   initializeFlags() {
     const onBoardingVariant = utils.getVariantValue('onboardingflag');
     if (onBoardingVariant?.value == 'on') {
       this.setState({
         setShowOnBoarding: true,
       });
     }
   }

   /**
    *
    *
    * @memberof CourseRegistrationDetailViewNew
    */
   triggerAPI = () => {
     this.loadDependencies();
   }


   /**
    *
    *
    * @param {*} course
    * @memberof CourseRegistrationDetailViewNew
    */
   switchCourse = (course) => {
     this.triggerCourseSwitcher();
     navigate(course.url);
   }


   /**
    *
    *
    * @param {*} courses
    * @memberof CourseRegistrationDetailViewNew
    */
   getCoursesToRenderOnCourseSwitcher = (courses) => {
     courses.map((course) => {
       const courseSlug=course.attributes.localizedcourseslug;
       course['courseImg']=this.assetsService.fetchAssetURL(courseSlug);
       course['url']=`/app/${this.props?.locale}/v2/registrations/${course.id
       }/modules/${course.activeModule||1}`;
     });
     this.setState((prevState) => ({
       ...prevState,
       coursesOnSwitcher: courses,
     }));
   };

   /**
    *
    *
    * @memberof CourseRegistrationDetailViewNew
    */
   startOnboardingFlow = () => {
     if (this?.state?.setShowOnBoarding) {
       this.setState((prevState) => ({
         startOnBoarding: true,
         initialStep: 0,
       }));
     }
   }


   /**
    *
    *
    * @param {*} value
    * @memberof CourseRegistrationDetailViewNew
    */
   triggerUnlockModulePopup = (value) => {
     trackAction.ctaClickAction({
       'studentId': cookie.load('shawacademy_leadId'),
       'cta': `Unlock Module 1`,
       'action': 'InitiateUnlockModule',
       'pageKind': `LessonsPage`,
     });
     this.setState({
       showUnlockModulePopup: value,
     });
   }
   /**
   *
   *
   * @return {*}
   * @memberof CourseRegistrationDetailViewNew
   */
   componentDidMount() {
     this.setState((prevState) => ({
       ...prevState,
       hasMount: true,
       // TODO: action should come from props
       action: 'content',
     }));
     this.loadDependencies();
     this.initializeFlags();
     trackAction.pageViewAction('CourseDetailPage', this.props.locale);
     document.addEventListener('click', this.handleClickOutside, true);
     return () => {
       document.removeEventListener('click', this.handleClickOutside, true);
     };
   }


   /**
    *
    *
    * @param {*} prevProps
    * @memberof CourseRegistrationDetailViewNew
    */
   componentDidUpdate(prevProps) {
     if (prevProps.regId !== this.props.regId) {
       this.loadDependencies();
       trackAction.pageViewAction('CourseDetailPage', this.props.locale);
     }
   }

  /**
   *
   *
   * @memberof CourseRegistrationDetailViewNew
   */
  callXpHandler = () => {
    // check for membership status
    if (this?.state?.membershipInfo) {
      const membershipStatus=this?.state?.membershipInfo?.membership?.status;
      const membershipName=
      this?.state?.membershipInfo?.membership?.name.toLowerCase();
      const isActiveMember = !['cancelled', 'inactive'].
          includes(membershipStatus) &&
          (membershipName.indexOf('lifetime') === -1);
      this.setState({
        isActiveMember: isActiveMember,
      });
    }
  };

  /**
   *
   *
   * @memberof CourseRegistrationDetailViewNew
   */
  closeOnBoarding = () => {
    window.scroll(0, 0);
    this.setState(() => ({startOnBoarding: false}));
  };

  /**
   *
   *
   * @param {*} action
   * @param {*} swipe
   * @memberof CourseRegistrationDetailViewNew
   */
  changeTabAction = (action, swipe) => {
    const pageKindObj = {
      'content': 'LessonsPage',
      'assignments': 'AssignmentsPage',
      'resources': 'ResourcesPage',
    };
    if (swipe!== 'none') {
      const tabEventKeys=Object.keys(pageKindObj);
      let index = tabEventKeys.indexOf(action);
      if (swipe === 'left') {
        index = index-1 < 0? 2 : index-1;
        action = tabEventKeys[index];
      } else if (swipe === 'right') {
        index = index+1 > 2 ? 0 : index+1;
        action = tabEventKeys[index];
      }
    }

    trackAction.pageViewAction(pageKindObj[action], this.props.locale);
    this.triggerStickyUnlockModule(false);
    this.setState((prevState) => ({
      ...prevState,
      action,
      activeTab: action,
    }));
  };


  /**
   *
   *
   * @param {*} value
   * @memberof CourseRegistrationDetailViewNew
   */
  triggerDeleteModal = (value) => {
    this.setState({
      showDeleteModal: value,
    });
  }

  /**
   *
   *
   * @param {*} value
   * @memberof CourseRegistrationDetailViewNew
   */
  triggerStickyUnlockModule = (value) => {
    if (this?.state?.showStickyUnlockModule!==value) {
      this.setState({
        showStickyUnlockModule: value,
      });
    }
  }
  /**
   *
   *
   * @param {*} e
   * @memberof CourseRegistrationDetailViewNew
   */
  handleTouchStart = (e) => {
    xTouchStart=e.targetTouches[0].clientX;
    yTouchStart=e.targetTouches[0].clientY;
  }
  /**
   *
   *
   * @param {*} evt
   * @memberof CourseRegistrationDetailViewNew
   */
  handleTouchMove = (evt) => {
    if ( ! xTouchStart || ! yTouchStart ) {
      return;
    }

    const xTouchEnd = evt.touches[0].clientX;
    const yTouchEnd = evt.touches[0].clientY;

    const xDiff = xTouchStart - xTouchEnd;
    const yDiff = yTouchStart - yTouchEnd;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
      if ( xDiff > 0 ) {
        /* left swipe */
        this.changeTabAction(this?.state?.activeTab, 'left');
      } else {
        /* right swipe */
        this.changeTabAction(this?.state?.activeTab, 'right');
      }
    }
    /* reset values */
    xTouchStart = null;
    yTouchStart = null;
  }

  /**
   *
   *
   * @memberof CourseRegistrationDetailViewNew
   */
  showMemeberAreaVisitPopup() {
    if (!sessionStorage.getItem(`membersAreaVisited_${studentId}`)) {
      sessionStorage.setItem(`membersAreaVisited_${studentId}`, true);
    }
  }

  /**
   *
   *
   * @param {*} objToUpdate
   * @param {*} moduleIndex
   * @memberof CourseRegistrationDetailViewNew
   */
  setFinalAssessmentRetakes(objToUpdate, moduleIndex) {
    const updatedRetakes=this?.state?.finalAssessmentRetakes;
    updatedRetakes[moduleIndex]=objToUpdate;
    this.setState({
      finalAssessmentRetakes: updatedRetakes,
    });
  }


  /**
   * @param {*} store
   * @memberof CourseRegistrationDetailViewNew
   */
  handleStoreClick = (store) => {
    trackAction.ctaClickAction({
      'studentId': cookie.load('shawacademy_leadId'),
      'cta': (store === 'iphone') ? 'appStore' : 'playStore',
      'action': 'getNativeApp',
    });
    const expires = new Date(new Date().getTime()+(1*24*60*60*1000));
    cookie.save('phoneAppBannerClicked', true,
        {path: '/', expires, domain: process.env.CookieDomain});
    if (store === 'android') {
      window.open(`https://play.google.com/store/apps/details?id=com.shawacademy.app&referrer=utm_source%3Dmembers-area%26utm_medium%3Dbanner%26utm_content%3D%26utm_campaign%3Drecording-page`
          , 'blank');
    } else if (store === 'iphone') {
      window.open(`https://itunes.apple.com/app/apple-store/id1240131410?pt=118508823&ct=popUpBanner&mt=8`, 'blank');
    }
  }

  /**
   *
   *
   * @param {*} tab
   * @memberof CourseRegistrationDetailViewNew
   */
  handleSelect = (tab) => {
    if (tab==='content') {
      this.setState({
        hideVideoWidget: false,
      });
    } else {
      const videoAskCloseModal =
      document.querySelector('.videoask-embed__hide_button--f6kKj');
      videoAskCloseModal?.click();
      this.setState({
        hideVideoWidget: true,
      });
    }
  }

  /**
   *
   *
   * @return {*}
   * @memberof CourseRegistrationDetailViewNew
   */
  render() {
    const courseName=this?.state?.courseData?.attributes?.coursename;

    const activeModule=this?.state?.courseData?.activeModule||1;
    const moduleData=this?.state?.courseData?.relationships?.modules?.data;
    const activeModuleData=moduleData?.[activeModule-1];

    const userStatus = this?.state?.membershipInfo?.membership?.status;
    const userMembershipName = this?.state?.membershipInfo
        ?.membership?.name?.toLowerCase();
    const userAgent = navigator.userAgent.toLowerCase();
    const iphone = userAgent.indexOf('iphone') > -1 ||
      userAgent.indexOf('ipad') > -1;
    const phoneAppBannerClicked = cookie.load('phoneAppBannerClicked');
    // const userCountry = this?.state?.membershipInfo?.address?.country;
    // const isEligibleForOldStickyAddon =
    //  countriesEligibleForWatchModule.includes(userCountry);
    return (
      this?.state?.hasMount && <>
        {!this?.state?.loading ?
         this?.state?.courseData?
         <div className={courseStyles.shawRecordingsInnerWrap}>
           <Steps
             enabled={this.state.startOnBoarding}
             steps={this.state.steps}
             onExit={() => this.closeOnBoarding()}
             initialStep={this.state.initialStep}
           />
           <div className={`${courseStyles.storeContainer} show-on-mob`}>
             {
               !phoneAppBannerClicked && !iphone?
                <div onClick={() => this.handleStoreClick('android')}>
                  <img className={courseStyles.storeImage}
                    src={googlePlay} alt='Google play' />
                </div>:
                  !phoneAppBannerClicked && iphone ?
                  <div onClick={() => this.handleStoreClick('iphone')}>
                    <img className={courseStyles.storeImage}
                      src={appstore} alt='Apple store' /></div> : null
             }
           </div>
           <div className={`${courseStyles.recordingsCourseSelector
           } show-on-mob`}>
             <CourseSwitcherMobileView
               triggerCourseSwitcher={()=>this.triggerCourseSwitcher()}
               locale={this.props.locale}
               coursesOnSwitcher={this?.state?.coursesOnSwitcher}
               switchCourse={(course)=>this.switchCourse(course)}
               showCourseSwitcher={this?.state?.showCourseSwitcher}
               activeCourse={this?.state?.courseData?.attributes}
               userStatus={userStatus}
               userMembershipName={userMembershipName}
             />
             {!['cancelled', 'inactive'].includes(userStatus) &&
               userMembershipName?.indexOf('lifetime') === -1 &&
                <UserIconWithXp
                  locale={this.props?.locale}
                />}
           </div>
           <div className={courseStyles.courseNameContainer}>
             <div className={courseStyles.courseNameSection}>
               <p className={courseStyles.courseName}>{courseName}</p>
               <Dropdown className={courseStyles.courseOptionBlock}>
                 <Dropdown.Toggle
                   className={`${courseStyles.optionBlockTrigger
                   } library-shaw-icon`}>
                   <i className="icon-more-3-dots"></i>
                 </Dropdown.Toggle>
                 <Dropdown.Menu as="ul"
                   className={courseStyles.courseOptionDropdown}>
                   <Dropdown.Item
                     onClick={() => this.triggerDeleteModal(true)}>
                      Delete Course
                   </Dropdown.Item>
                   <Dropdown.Item
                     target="__blank"
                     href="https://www.shawacademy.com/faq/">
                      Help
                   </Dropdown.Item>
                 </Dropdown.Menu>
               </Dropdown>
             </div>
             <div className={courseStyles.hideOnMobile}>
               {!['cancelled', 'inactive'].includes(userStatus) &&
                 userMembershipName?.indexOf('lifetime') === -1 &&
                  <UserIconWithXp
                    locale={this.props?.locale}
                  />}
             </div>
           </div>
           <div
             onTouchStart={(e) => this.handleTouchStart(e)}
             onTouchMove={(e) => this.handleTouchMove(e)}>
             <Tab.Container
               onSelect={this.handleSelect}
               defaultActiveKey='content'
               activeKey={this?.state?.activeTab}>
               <div className="shaw-tab-switcher-wrapper">
                 <Nav variant="pills"
                   className="shaw-tab-switcher"
                   as="ul">
                   <Nav.Item as="li"
                     onClick={() => this.changeTabAction('content', 'none')}>
                     <Nav.Link className="shaw-tab" eventKey="content">
                       <p>Content</p>
                       <span></span>
                     </Nav.Link>
                   </Nav.Item>
                   <Nav.Item as="li"
                     onClick={() => this.changeTabAction('resources', 'none')}>
                     <Nav.Link className="shaw-tab" eventKey="resources">
                       <p>Resources</p>
                       <span></span>
                     </Nav.Link>
                   </Nav.Item>
                   {/* Need to check this  */}
                   <Nav.Item as="li"
                     onClick={() =>
                       this.changeTabAction('assignments', 'none')}>
                     <Nav.Link
                       className="shaw-tab"
                       eventKey="assignments">
                       <p>Assessment</p>
                       <span></span>
                     </Nav.Link>
                   </Nav.Item>
                 </Nav>
               </div>
               <Tab.Content>
                 <Tab.Pane eventKey="content">
                   <LessonsTabView
                     locale={this.props.locale}
                     moduleNumber={this.props.moduleNumber}
                     triggerApiCalls={this.triggerAPI}
                     startOnboarding={this.startOnboardingFlow}
                     // Change this naming
                     studentInfo={this?.state?.studentInfo}
                     salesOfferData={this?.state?.salesOfferData}
                     credits={this?.state?.credits}
                     courseData={this?.state?.courseData}
                     membershipInfo={this?.state?.membershipInfo}
                     subscriptionInfo={this?.state?.subscriptionInfo}
                     showUnlockModulePopup={this?.state?.showUnlockModulePopup}
                     triggerUnlockModulePopup={this.triggerUnlockModulePopup}
                     triggerStickyUnlockModule={this.triggerStickyUnlockModule}
                     finalAssessmentRetakes=
                       {this?.state?.finalAssessmentRetakes}
                     setFinalAssessmentRetakes={(obj, index) =>
                       this.setFinalAssessmentRetakes(obj, index)}
                     additionParam={this.props['*']}
                     showMemeberAreaVisitPopup=
                       {() => this.showMemeberAreaVisitPopup()}
                     addonPrices={this?.state?.addonPriceMap}
                     isActiveMember = {this?.state?.isActiveMember}
                     retakePrice={this?.state?.retakePrice}
                     changeTabAction={this.changeTabAction}
                     planPrices={this?.state?.prices}
                     registeredTags = {this.state?.registeredTags}
                   />
                 </Tab.Pane>
                 <Tab.Pane eventKey="resources">
                   <ResourcesTabView
                     locale={this.props.locale}
                     courseData={this?.state?.courseData}
                     addonPrices={this?.state?.addonPriceMap}
                     studentInfo={this?.state?.studentInfo}
                   />
                 </Tab.Pane>
                 <Tab.Pane eventKey="assignments">
                   <AssessmentsTabView
                     locale={this.props.locale}
                     studentInfo={this?.state?.membershipInfo}
                     finalAssessmentRetakes=
                       {this?.state?.finalAssessmentRetakes}
                     setFinalAssessmentRetakes={(obj, index) =>
                       this.setFinalAssessmentRetakes(obj, index)}
                     courseData={this?.state?.courseData}
                     addonPrices={this?.state?.addonPriceMap}
                     retakePrice={this?.state?.retakePrice}
                     registrationData={this?.state?.studentInfo?.data}
                   />
                 </Tab.Pane>
               </Tab.Content>
             </Tab.Container>
           </div>
           <div
             className={`${courseStyles.shawModalBackdrop}
             ${this?.state?.showCourseSwitcher ?
           'active': ''}`}></div>
         </div>:<CourseUnavailable
           locale={this.props.locale} /> :
         <CourseRegistrationSkeleton />}
        {this?.state?.showDeleteModal &&
        <DeleteCourse locale={this.props.locale}
          courseToDelete={this?.state?.courseData}
          onHideModal={() => this.triggerDeleteModal(false)} />}

        {
          // activeModuleData &&
          //  this?.state?.membershipInfo?.membership?.slug!==
          // 'investor-lifetime' ?
          //     <StickyAddonToolkit
          //       moduleNumber={activeModule}
          //       toolkitContent={this.props?.toolkitContent}
          //       addonPrices={this?.state?.addonPriceMap}
          //       pageKind={'LessonsPage'}
          //       locale={this.props.locale}
          //       triggerUnlockModulePopup={this.triggerUnlockModulePopup}
          //       showStickyUnlockModule={this?.state?.showStickyUnlockModule}
          //       isEqfCourse={moduleData.length === 4}
          //       courseSlug=
          //         {this?.state?.courseData?.attributes?.localizedcourseslug}
          //       selectedModuleInfo={activeModuleData}
          //       pageData={this?.state?.pageData}
          //       userCountry={this?.state?.membershipInfo?.address?.country}
          //     /> :
          // !this?.state?.hideVideoWidget &&
          activeModuleData &&
              this?.state?.membershipInfo?.membership?.slug!==
              'investor-lifetime' &&
            <StickyAddon
              moduleNumber={activeModule}
              pageKind={'LessonsPage'}
              locale={this.props.locale}
              courseSlug=
                {this?.state?.courseData?.attributes?.localizedcourseslug}
            />
        }
        {/* sticky footer */}
        <div className={courseStyles.stickyFooter}>
          <div className={courseStyles.upskillBanner}>
            <div className={courseStyles.modalHeader}>
              <div className={courseStyles.modalClose}>
                <div
                  className={' library-shaw-icon-new'}>
                  <i className="icon-new-close"></i>
                </div>
              </div>
            </div>
            <div className={courseStyles.upskillLogo}>
              <img src='/dashboard-new/courses/brand-Logo.png'
                datasrc="/dashboard-new/courses/brand-Logo.png"/>
            </div>
            <div className={courseStyles.upskillDetails}>
              <p>we are now &quot;<span>upskillist</span>&quot;</p>
              <ul>
                <li>Your subscription & course progress
                  will be transfered to Upsillist.</li>
                <li>You&apos;ll be asked to re-authenticate
                   your account using a OTP.</li>
              </ul>
              <div className={courseStyles.ctaBtn}>
                <button className={courseStyles.pinkCta}>
                  Move to Upskillist</button>
                <button className={courseStyles.blueCta}>Learn More</button>
              </div>
            </div>
          </div>
        </div>
        {/* <Toaster
          list={this.state.toastList}
          setList={this.setToastList}
          leaderboardActionMappingData=
            {this.props.leaderboardPageData?.actionmapping?.value}
        /> */}
      </>
    );
  }
}


CourseRegistrationDetailViewNew.propTypes = {
  ['*']: PropTypes.any,
  regId: PropTypes.string,
  locale: PropTypes.string,
  moduleNumber: PropTypes.string,
  loading: PropTypes.bool,
  loadDependencies: PropTypes.func,
  passDataToHeader: PropTypes.func,
  passCredits: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  location: PropTypes.any,
};
CourseRegistrationDetailViewNew.defaultProps = {
  location: {
    pathname: '',
  },
};
