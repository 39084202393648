import React from 'react';
import PropTypes from 'prop-types';
import styles from '../GiftFlowBanner.module.scss';
// import * as trackAction from '../../Shared/TrackActionService';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function GiftFlowBanner(props) {
  const {membershipSlug, moduleRegistrationId,
    // triggerSalesOfferModal, locale, studentId
  } = props;
  const isEligible=membershipSlug==='gift-premium'&&!moduleRegistrationId;
  // const openSalesOfferModal = () => {
  //   // triggerSalesOfferModal(true);
  //   trackAction.ctaClickAction({
  //     locale,
  //     studentId,
  //     'pageKind': 'LessonsPage',
  //     'cta': 'Upgrade & learn',
  //     'action': 'TriggerUpgradeNow',
  //   });
  // };

  const dataPoints = [
    `Learn new skills from 100+ courses.`,
    `Save upto 75% on your membership.`,
  ];

  return (
    isEligible?<div className={styles.giftFlowBanner} >
      <div className={styles.imgWrapper}>
        <img src='/dashboard-new/courses/gift-illustration.svg'
          alt='illustration' />
      </div>
      <div className={styles.giftFlowBannerContent}>
        <h4>Subscribe & unlock more content</h4>
        <ul>
          {dataPoints.map((point, index) => (<li key={index}>{point}</li>))}
        </ul>
        <button
        // onClick={openSalesOfferModal}
        >
          Upgrade & learn
        </button>
      </div>
    </div>:<></>
  );
}

GiftFlowBanner.propTypes = {
  locale: PropTypes.string,
  studentId: PropTypes.string,
  membershipSlug: PropTypes.string,
  moduleRegistrationId: PropTypes.string,
  // triggerSalesOfferModal: PropTypes.func,
};

export default GiftFlowBanner;
