import React, {Component} from 'react';
import {Form, Col, Button, Spinner} from 'react-bootstrap';
import * as rescheduleService from './RescheduleService';
import * as apiService from '../../api/index';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import * as trackAction from '../Shared/TrackActionService';
import DatePicker from 'react-date-picker';
import styles from './reschedule.module.scss';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';

dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

/**
 *
 *
 * @export
 * @class ChangeSchedule
 * @extends {Component}
 */
export default class ChangeSchedule extends Component {
  /**
   * Creates an instance of ChangeSchedule.
   * @param {*} props
   * @memberof ChangeSchedule
   */
  constructor(props) {
    super(props);
    this.state = {
      lessonNumbers: [],
      timePickerList: [],
      dayPickerList: [],
      calenderDays: [],
      loadingSpinner: false,
      disableCta: false,
    };
    this.daysArray=require(`dayjs/locale/${props.locale}`).weekdays;
  }

  /**
   * For initial api calls
   *
   * @memberof ChangeSchedule
   */
  componentDidMount() {
    const regId = this.props.regId;
    dayjs.locale(this.props.locale);

    new apiService.default.Schedule(regId).get()
        .then((response) => {
          const responseObj = response.data.data;
          const lessons = responseObj.attributes.course.lessons
              .filter((item) => item.key !=='bonus');
          this.setState({
            courseSlug: responseObj.attributes.course_slug,
            lessons: lessons,
            lessonNumbers: rescheduleService.buildLessonsList(lessons.length),
          });
        })
        .catch((error) => {
          console.log(error);
        });
    this.setState({
      regId: regId,
      leadId: rescheduleService.getLeadId(),
      timePickerList: rescheduleService.buildTimePickerList(),
      dayPickerList: rescheduleService.buildDayPickerList(),
    });
    trackAction.pageViewAction('ChangeSchedule', this.props.locale);
  }

  /**
 * Lesson Change handler for lessons dropdown
 *
 * @param {*} e
 * @memberof ChangeSchedule
 */
  lessonHandler(e) {
    const lessonSelected = parseInt(e.target.value);
    const dateToday = dayjs();
    const minLimit = dayjs(dateToday).add(1, 'd').format('YYYY-MM-DD');
    const maxLimit = dayjs(dateToday).add(1, 'M').format('YYYY-MM-DD');
    if (lessonSelected > 0) {
      this.setState({
        lessonSelected: lessonSelected,
        isLessonSelected: true,
        minLimit: minLimit,
        defaultDate: [].concat(minLimit),
        maxLimit: maxLimit,
      }, this.toggleConfirmationTextState);
    } else {
      this.setState({
        lessonSelected: null,
        isLessonSelected: false,
      });
    }
  }

  /**
 *
 *
 * @param {*} value
 * @memberof ChangeSchedule
 */
  handleFirstDayChange(value) {
    const selectedDay1 = dayjs(value);
    let selectedDay2 = this.state.selectedDay2;
    this.setState({
      defaultDate: [].concat(selectedDay1),
      selectedDay1: selectedDay1,
      selectedWeekday1: selectedDay1.format('dddd'),
    });
    if (this.state.isDay2Selected) {
      while (selectedDay1.diff(selectedDay2, 'hours') > 0) {
        selectedDay2=selectedDay2.add(7, 'days');
      }
      this.setState({
        selectedDay2,
      }, this.toggleConfirmationTextState);
    }
    if (this.state.schedule1) {
      const hours = parseInt(this.state.schedule1.format('HH'));
      const schedule1 = selectedDay1
          .set('hour', hours).set('minute', 0).set('second', 0);
      this.setState({
        schedule1: schedule1,
      }, this.toggleConfirmationTextState);
    }
    this.setState({
      isDay1Selected: true,
    }, this.toggleConfirmationTextState);
  }

  /**
 *
 *
 * @param {*} e
 * @memberof ChangeSchedule
 */
  handleFirstHourChange(e) {
    const hours = parseInt(dayjs(e.target.value, ['h:mm A']).format('HH:mm'));
    if (e.target.value != 0) {
      this.setState({
        time1Selected: e.target.value,
      }, this.toggleConfirmationTextState);
      if (this.state.selectedDay1) {
        const schedule1 = this.state.selectedDay1
            .set('hour', hours).set('minute', 0).set('second', 0);
        this.setState({
          schedule1: schedule1,
        }, this.toggleConfirmationTextState);
      } else {
        this.setState({
          schedule1: null,
        }, this.toggleConfirmationTextState);
      }
    } else {
      this.setState({
        time1Selected: null,
        schedule1: null,
      }, this.toggleConfirmationTextState);
    }
  }

  /**
*
*
* @param {*} e
* @memberof ChangeSchedule
*/
  handleSecondDayChange(e) {
    const selectedWeekday = this.daysArray.indexOf(e.target.value);
    let selectedDay2 = dayjs().isoWeekday(selectedWeekday);
    if (this.state.selectedDay1) {
      while (selectedDay2.diff(this.state.selectedDay1, 'hours') < 0) {
        selectedDay2 = selectedDay2.add(7, 'days');
      }
    }
    this.setState({
      selectedDay2: selectedDay2,
    }, this.toggleConfirmationTextState);
    if (this.state.time2Selected) {
      const hours =
        parseInt(dayjs(this.state.schedule2, ['h:mm A']).format('HH:mm'));
      const schedule2 = selectedDay2
          .set('hour', hours).set('minute', 0).set('second', 0);
      this.setState({
        schedule2: schedule2,
      }, this.toggleConfirmationTextState);
    }
    if (e.target.value != 0) {
      this.setState({
        isDay2Selected: true,
      }, this.toggleConfirmationTextState);
    } else {
      this.setState({
        isDay2Selected: false,
      }, this.toggleConfirmationTextState);
    }
  }

  /**
*
*
* @param {*} e
* @memberof ChangeSchedule
*/
  handleSecondHourChange(e) {
    const hours = parseInt(dayjs(e.target.value, ['h:mm A']).format('HH:mm'));
    if (e.target.value != 0) {
      this.setState({
        time2Selected: e.target.value,
      }, this.toggleConfirmationTextState);
      if (this.state.selectedDay2) {
        const schedule2 = this.state.selectedDay2
            .set('hour', hours).set('minute', 0).set('second', 0);
        this.setState({
          schedule2: schedule2,
        }, this.toggleConfirmationTextState);
      } else {
        this.setState({
          schedule2: null,
        }, this.toggleConfirmationTextState);
      }
    } else {
      this.setState({
        time2Selected: null,
        schedule2: null,
      }, this.toggleConfirmationTextState);
    }
  }

  /**
 * Verifies if all the dropdown has valid values
 *
 * @memberof ChangeSchedule
 */
  toggleConfirmationTextState() {
    const state = this.state;
    if (state.isDay1Selected && state.isDay2Selected &&
      state.time1Selected && state.time2Selected) {
      this.getHourDifference();
      this.buildScheduleLessonsArray();
    } else {
      this.setState({
        showButton: false,
      });
    }
  }

  /**
 * Builds lessons array according to schedule
 *
 * @memberof ChangeSchedule
 */
  buildScheduleLessonsArray() {
    const lessonsToreschedule = [];
    const lessonsNotChanges = [];
    for (let i = 0; i < this.state.lessons.length; i++) {
      if (this.state.lessons[i].number >= this.state.lessonSelected) {
        lessonsToreschedule.push(this.state.lessons[i]);
      } else {
        lessonsNotChanges.push({
          number: this.state.lessons[i]['number'],
          start_time: this.state.lessons[i]['start_time'],
        });
      }
    }
    const lessonLength = lessonsToreschedule.length / 2;

    // TODO Need to change this for odd number of lessons

    const lessons = [];
    let number = this.state.lessonSelected;
    for (let index = 0; index < lessonLength; index++) {
      let day1 = this.state.schedule1.clone();
      day1 = day1.add(index*7, 'days').toISOString();
      let day2 = this.state.schedule2.clone();
      day2 = day2.add(index*7, 'days').toISOString();

      lessons.push({
        number: number,
        start_time: day1,
      });

      lessons.push({
        number: ++number,
        start_time: day2,
      });
      number++;
    }
    if (lessons.length !== lessonsToreschedule.length) {
      lessons.pop();
    }
    this.setState({
      lessons: [...lessonsNotChanges, ...lessons],
    }, this.buildScheduledCalender);
  }

  /**
 * Checks if the two selected schedules
 * have min 2 hours of difference
 *
 * @memberof ChangeSchedule
 */
  getHourDifference() {
    const hourDiff = Math.abs(dayjs.duration(this.state.schedule2
        .diff(this.state.schedule1)).asHours());
    if (Math.round(hourDiff) < 2) {
      this.setState({
        showError: true,
        showButton: false,
      });
    } else {
      this.setState({
        showError: false,
        showButton: true,
      });
    }
  }

  /**
 *
 *
 * @memberof ChangeSchedule
 */
  handleConfirmation() {
    if (!this.state.showError) {
      this.setState({
        loadingSpinner: true,
        disableCta: true,
      });
      let firstDayOfWeek;
      let secondDayOfWeek;
      // default value of Sunday = 0 in moment,
      // need to change it to Sunday = 7
      if (this.state.schedule1.day() === 0) {
        firstDayOfWeek = 7;
      } else {
        firstDayOfWeek = this.state.schedule1.day();
      }
      if (this.state.schedule2.day() === 0) {
        secondDayOfWeek = 7;
      } else {
        secondDayOfWeek = this.state.schedule2.day();
      }
      const requestObj = {
        'data': {
          'type': 'registrations',
          'id': this.props.regId,
          'attributes': {
            'schedule': {
              'first_day_of_week': firstDayOfWeek,
              'second_day_of_week': secondDayOfWeek,
              'first_day_hour': parseInt(this.state.schedule1.format('H')),
              'second_day_hour': parseInt(this.state.schedule2.format('H')),
              'lessons': this.state.lessons,
              'lessons_per_week': 2,
            },
          },
          'relationships': {
            'student': {
              'data': {
                'type': 'student',
                'id': this.state.leadId,
              },
            },
          },
        },
      };
      new apiService.default.Schedule(this.state.regId).update(requestObj)
          .then((response) => {
            const result = response.data.data;
            const locale = this.props.locale;
            const module = rescheduleService.getModule() || '1';
            trackAction.registrationAction({
              'moduleNumber': parseInt(module, 10),
              'courseSlug': result.attributes.course_slug,
              'facultySlug': result.attributes.course.faculty,
              'lessonNumber': parseInt(this.state.lessonSelected, 10),
              'locale': this.props.locale,
              'rescheduleType': 'changeSchedule',
              'Goal': 2,
            });
            setTimeout(() => {
              navigate(`/app/${locale}`);
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              showCommonError: true,
              loadingSpinner: false,
              disableCta: false,
            });
          });
    }
  }

  /**
 *
 *
 * @memberof RescheduleClass
 */
  navigateToParent() {
    navigate('../');
  }

  /**
   *
   *
   * @return {*}
   * @memberof ChangeSchedule
   */
  render() {
    return (
      <>
        <div className={`${styles.changeSchedule} ${
          styles.schedulingScreen}`}>
          <div className={styles.backBtn}
            onClick={this.navigateToParent}
          >
            <img src='/back.svg' alt=''/>
            View Scheduling Options
          </div>
          <div className={`${styles.box} ${styles.changeCourseBox}`}>
            <p>Change course schedule</p>
            <img src='/schedule/reschedule-arrow.svg' alt=''/>
          </div>
          <p className={styles.rescheduleSubHeading}>
            Change your course schedule for all upcoming lessons
            or from a specific lesson.
          </p>
          <Form.Row className={styles.classScheduleRow}>
            <div className={styles.fieldContainer}>
              <Form.Label>
                Classes to reschedule from
              </Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) => this.lessonHandler(e)}
              >
                <option value="0">Select a Day
                </option>
                {
                this.state?.lessonNumbers.map((item, index) => {
                  return <option key={item}
                    value={index+1}>
                    {item}
                  </option>;
                })
                }
              </Form.Control>
            </div>
            <div
              className={`${this.state.isLessonSelected ?
              `${styles.fieldContainer}` : `${styles.hideElement}`}`}>
              <Form.Label>
                Select Restart Date
              </Form.Label>
              <DatePicker
                minDate={new Date(this.state.minLimit)}
                maxDate={new Date(this.state.maxLimit)}
                locale={this.props.locale}
                value={this.state?.defaultDate}
                onChange={(value) => this.handleFirstDayChange(value)}
                clearIcon={null}
              />
            </div>
          </Form.Row>
          <p className={`${this.state.isDay1Selected &&
          this.state.isLessonSelected ? `${styles.rescheduleInfo}` :
          `${styles.hideElement}`}`}>
            Pick a new weekly schedule
          </p>
          <Form.Row className={`${this.state.isDay1Selected &&
          this.state.isLessonSelected ? `${styles.classScheduleRow}` :
          `${styles.hideElement}`}`}>
            <div className={styles.fieldContainer}>
              <Form.Label>
                Day
                &nbsp;1
              </Form.Label>
              <Form.Control
                readOnly={true}
                className={styles.readOnlyForm}
                defaultValue={this.state.selectedWeekday1}>
              </Form.Control>
            </div>
            <div className={styles.fieldContainer}>
              <Form.Label>
                Time&nbsp;1
              </Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) => this.handleFirstHourChange(e)}>
                <option value="0">
                  Pick Time
                </option>
                {this.state.timePickerList.map((item) => {
                  return <option
                    value={item}
                    key={`t1-${item}`}>
                    {item}
                  </option>;
                })}
              </Form.Control>
            </div>
          </Form.Row>
          <Form.Row className={`${this.state.isDay1Selected &&
          this.state.isLessonSelected ? `${styles.classScheduleRow}` :
           `${styles.hideElement}`}`}>
            <div className={styles.fieldContainer}>
              <Form.Label>
                Day
                &nbsp;2
              </Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) => this.handleSecondDayChange(e)}>
                <option value="0">
                  Select a Day
                </option>
                {
                  this.state.dayPickerList.map((item) => {
                    return <option
                      value={item}
                      key={`d2-${item}`}>
                      {item}
                    </option>;
                  })
                }
              </Form.Control>
            </div>
            <div className={styles.fieldContainer}>
              <Form.Label>
                Time
                &nbsp;2
              </Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) => this.handleSecondHourChange(e)}>
                <option value="0">
                  Pick Time
                </option>
                {this.state.timePickerList.map((item) => {
                  return <option
                    value={item}
                    key={`t2-${item}`}>
                    {item}
                  </option>;
                })}
              </Form.Control>
            </div>
          </Form.Row>
          { this.state?.showError ?
          <Col
            style={{marginTop: 'unset'}}
            className={styles.errorText}>
              Second class should have minimum 2 hours difference if
              chosen same day.
          </Col> : null
          }
          <div className={`${this.state.showButton ?
            `${styles.scheduleInfo}` : `${styles.hideElement}`}`}>
            <div className={styles.bottomInfo}>
              <p className={styles.info}>
                Your classes are on every &nbsp;
                <span>
                  {this.state.schedule1?.format('dddd')} at &nbsp;
                  {this.state.schedule1?.format('hh:mm a')} &nbsp;
                </span>
                <span>
                  {this.state.schedule2?.format('dddd')} at &nbsp;
                  {this.state.schedule2?.format('hh:mm a')} &nbsp;
                </span>
              &nbsp; Starting from &nbsp;
                <span className={styles.endText}>
                  {this.state.schedule1?.format('dddd MMMM DD')}
                </span>
              </p>
            </div>
          </div>
          <div className={styles.ctaWrapper}>
            <Button
              id={this.state.disableCta ? styles.disabled : ''}
              className={`${this.state.showButton ? `${styles.shBtn}` :
              `${styles.hideElement}`}`}
              onClick={() => this.handleConfirmation()}>
              Confirm
            </Button>
            {this.state.loadingSpinner &&
            <div className={styles.spinnerWrapper}>
              <Spinner animation="border" />
            </div>}
          </div>
          { this.state?.showCommonError ?
          <Col className={styles.errorText}>
            Something went wrong.
          </Col> : null
          }
        </div>
      </>
    );
  }
}
ChangeSchedule.propTypes = {
  allCoursesList: PropTypes.array,
  regId: PropTypes.string,
  locale: PropTypes.string,
};
