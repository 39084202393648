import React, {useContext, useState} from 'react';
import {Accordion, AccordionContext,
  Button, Card, useAccordionToggle} from 'react-bootstrap';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import * as purchaseAction from './Banner/AddonPurchaseActionService';
import * as utils from '../Shared/Utils';
import * as trackAction from '../Shared/TrackActionService';
import AddonsConfirmation from './Popup/AddonsConfirmation';
import courseStyles from '../../styles/Courses.module.scss';
import VideoPopup from '../Shared2/Popup/VideoPopup';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function ToolkitPack(props) {
  const {activeIndex, activeModuleItems, oneClickText, lessonResourcesText,
    lessonsData, closePopup, courseSlug} = props;

  const studentId = cookie.load('shawacademy_leadId');
  const toolkitPrices = props?.addonPrices;
  const regId = props.registrationId;
  const locale = props.locale;
  const [disabled, setDisabled] = useState(false);
  const [showConsentPopup, showConfirmationPopup] = useState(false);
  const [showVimeoPopup, setShowVimeoPopup]=useState(false);
  const [vimeoId, setVimeoId]=useState(null);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const isConsentEligible = utils.checkPurchaseConsentEligibility();

  /**
 *
 *
 * @param {*} data
 */
  const purchaseAddon = () => {
    const {cta, pageKind, isEqfCourse}=props;
    const moduleSlug=activeModuleItems?.attributes?.moduleslug;
    const product = 'CourseToolkit';
    const productName = 'TOOLKIT';
    const textInfo = 'Course Materials';
    const objToPass = {
      currencyCode: toolkitPrices?.currency_code,
      activeTierCode: toolkitPrices?.default_tier_code,
      addon: 'toolkit',
      country: toolkitPrices?.country_code,
      slug: courseSlug,
      cta: encodeURI(cta),
      moduleNumber: parseInt(activeIndex),
      courseName: activeModuleItems?.attributes?.modulename,
      studentId,
      regId,
      locale,
      isEqfCourse,
      moduleSlug,
      pageKind,
    };
    setDataToPassToConfirmation({
      param: 'toolkit',
      title: 'CourseToolkitTwoClickPopup',
      cta: 'Get your Toolkit-modal',
      courseSlug,
      moduleSlug,
      courseName: activeModuleItems?.attributes?.modulename,
      moduleNumber: parseInt(activeIndex),
      locale,
      pageKind,
      studentId,
      priceInfo: toolkitPrices,
      product,
      textInfo,
      productName,
      regId,
    });
    setDisabled(true);
    if (isConsentEligible) {
      showConfirmationPopup(true);
      trackAction.ctaClickAction({
        studentId,
        cta,
        'action': 'InitiatePurchaseToolkit',
        pageKind,
        locale,
        moduleSlug,
      });
      return;
    }
    purchaseAction.addonPurchaseAction(objToPass);
  };

  const closeModalPopup = () => {
    showConfirmationPopup(false);
    setDisabled(false);
  };
  const ContextAwareToggle = ({children, eventKey, callback}) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <Accordion.Toggle
        as={Card.Header}
        className={isCurrentEventKey ?
          `${courseStyles.resourceTop} active` :
          courseStyles.resourceTop}
        onClick={decoratedOnClick}
      >
        {children}
      </Accordion.Toggle>
    );
  };

  /**
   *
   *
   * @param {*} title
   * @param {*} filePath
   * @param {*} contentType
   * @param {*} resourceType
   *
   */
  function openItem(title, filePath, contentType, resourceType) {
    if (activeModuleItems?.toolkitPurchased) {
      if (resourceType==='mp4') {
        triggerVimeoPlayer(filePath);
      } else {
        window.open(filePath, '_blank');
      }
      const data = {
        studentId,
        courseSlug,
        moduleNumber: activeIndex,
        contentType,
        resourceType: 'Toolkit',
        fileName: title,
      };
      trackAction.downloadClickAction(data);
      return;
    }
  }

  const getResourceType = (filePath) => (
    filePath?.indexOf('pdf')>-1 && 'pdf' ||
    filePath?.indexOf('ppt')>-1 && 'ppt' ||
    filePath?.indexOf('zip')>-1 && 'zip' ||
    'mp4'
  );

  const triggerVimeoPlayer = (videoUri) => {
    setVimeoId(videoUri);
    setShowVimeoPopup(true);
  };

  const closeVimeoPopup = () => {
    setShowVimeoPopup(false);
  };

  return (
    <div className={`${courseStyles.shawToolkitPack}
    ${courseStyles.shawResourceOuterContainer}`}>
      <div className={courseStyles.backText} onClick={closePopup}>
        {`< back`}
      </div>
      <div className={courseStyles.resourceBreadcrumb}>
        {`${activeModuleItems?.attributes?.modulename
        } > Module ${activeIndex} toolkit`}</div>
      <div className={activeModuleItems?.toolkitPurchased ?
        'hide' : courseStyles.toolkitPackBanner}>
        <p className={courseStyles.toolkitHeadline}>
          Unlock All Course Materials
        </p>
        <p className={courseStyles.toolkitInfo}>
          Unlock your lessons notes, Slides & more!
        </p>
        <div className={courseStyles.ctaSection}>
          <div className={courseStyles.buyNowSection}>
            <Button className={`${courseStyles.buyNowCta}
            ${disabled?courseStyles.disabled:''}`}
            onClick={purchaseAddon}
            >
              Buy Now
            </Button>
            {!isConsentEligible && <p className={courseStyles.oneClickText}>
              ({oneClickText})
            </p>}
          </div>
          <p className={courseStyles.toolkitPrice}>
            {`${toolkitPrices?.currency_symbol}
             ${toolkitPrices?.default_tier_price}`}
          </p>
        </div>
        <img src='' alt='' className={courseStyles.toolkitBgIllustration} />
      </div>
      <div className=
        {`${courseStyles.resourceWrap} toolkit-wrap`}>
        <Accordion className={courseStyles.allResources}
          defaultActiveKey={lessonResourcesText}>
          <Card className={courseStyles.resource}>
            <ContextAwareToggle eventKey={lessonResourcesText}>
              <div className={courseStyles.nameSection}>
                <p
                  className={`${courseStyles.resourceIcon}
                   ${courseStyles.lessonResourcesSection}`}
                >
                </p>
                <p className={courseStyles.resourceName}>
                  {lessonResourcesText}</p>
              </div>
              <div className={courseStyles.accessSection}>
                <p className={courseStyles.addIcon}>+</p>
              </div>
            </ContextAwareToggle>
            <Accordion.Collapse
              className={courseStyles.resourceBottom}
              eventKey={lessonResourcesText}>
              <Card.Body>
                {lessonsData?.length && lessonsData.map((lesson, index) => {
                  return (lesson?.resources?.items?.length &&
                    lesson?.resources?.items.map((item, j)=>{
                      const title=item?.link?.link?.title ||
                      item?.video?.video?.name;
                      const filePath=item?.link?.link?.href ||
                      item?.video?.video?.uri;
                      const contentType=lesson?.resources?.slug;
                      const resourceType= getResourceType(filePath);
                      return (
                        <div
                          className={courseStyles.singleResource} key={j}
                          onClick={() =>openItem(
                              title, filePath, contentType, resourceType)}>
                          <p className={courseStyles.singleResourceName}>
                            {title}
                          </p>
                          <div className={activeModuleItems?.toolkitPurchased ?
                          courseStyles.downloadIcon : courseStyles.lockIcon}>
                          </div>
                        </div>
                      );
                    }));
                },
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
      {showConsentPopup &&
      <AddonsConfirmation
        dataToPassToConfirmation={dataToPassToConfirmation}
        showAddonsPopUp={closeModalPopup}
      />}
      {showVimeoPopup &&
        <VideoPopup
          videoURI={vimeoId}
          closeVideoPopup={closeVimeoPopup}
        />
      }
    </div>
  );

  ContextAwareToggle.propTypes = {
    children: PropTypes.any,
    eventKey: PropTypes.any,
    callback: PropTypes.any,
  };
}

ToolkitPack.propTypes = {
  activeModuleItems: PropTypes.object,
  activeIndex: PropTypes.number,
  registrationId: PropTypes.string,
  addonPrices: PropTypes.object,
  courseSlug: PropTypes.string,
  registrationId: PropTypes.string,
  locale: PropTypes.string,
  toolkitPurchased: PropTypes.bool,
  isEqfCourse: PropTypes.bool,
  moduleSlug: PropTypes.string,
  pageKind: PropTypes.string,
  toolkitContent: PropTypes.object,
  cta: PropTypes.string,
  moduleData: PropTypes.object,
  setVideoId: PropTypes.func,
  closePopup: PropTypes.func,
  oneClickText: PropTypes.string,
  lessonsData: PropTypes.array,
  lessonResourcesText: PropTypes.string,
};


export default ToolkitPack;
