import React from 'react';
import courseStyles from '../../../styles/Courses.module.scss';

/**
 *
 *
 * @return {*}
 */
function CourseRegistrationSkeleton() {
  return (
    <div className={`${courseStyles.shawRecordingsInnerWrap}
      ${courseStyles.skeletonWrap}`}>
      <div className={`${courseStyles.recordingsCourseSelector} show-on-mob`}>
        <div className={courseStyles.selectorBox}></div>
        <div className={courseStyles.selectorBox}></div>
      </div>
      <div className={courseStyles.courseNameSection}>
        <p className={courseStyles.courseName}></p>
        <div className={courseStyles.courseOptionBlock}>
          <div className={courseStyles.optionBlockTrigger}>
          </div>
        </div>
      </div>
      <div className={courseStyles.shawTabSwitcher}>
      </div>
      <div className={courseStyles.shawContent}>
        <div className={courseStyles.contentSection}>
          <div className={courseStyles.moduleSwitcher}>
            <div className={courseStyles.moduleSwitcherOuter}>
              <div className={courseStyles.moduleLink}></div>
              <div className={courseStyles.moduleLink}></div>
              <div className={courseStyles.moduleLink}></div>
              <div className={courseStyles.moduleLink}></div>
            </div>
          </div>
          <div className={`${courseStyles.modulesContentWrapper
          } ${courseStyles.verticalTimeline}`}
          >
            <div className={courseStyles.moduleContent}>
              <div className={courseStyles.moduleInfoContainer}>
                <p className={courseStyles.moduleHeader}></p>
                <p className={courseStyles.moduleDesc}></p>
                <p className={courseStyles.moduleDesc}></p>
                <p className={courseStyles.moduleDesc}></p>
                <p className={courseStyles.moduleDesc}></p>
                <p className={courseStyles.moduleDesc}></p>
                <p className={courseStyles.moduleDesc}></p>
              </div>
              {new Array(4).fill(undefined).map((_, index) => (
                <div key={index} className={courseStyles.lessonContent}>
                  <p className={courseStyles.moduleLabel}></p>
                  <p className={courseStyles.lessonName}></p>
                  <p className={courseStyles.lessonDescription}></p>
                  <p className={courseStyles.lessonDescription}></p>
                  <p className={courseStyles.lessonDescription}></p>
                  <p className={courseStyles.readMoreCta}></p>
                  <div className={courseStyles.lessonResourcesBlock}>
                    <div className={courseStyles.lessonResources}></div>
                    <div className={`${courseStyles.lessonResources}
                    ${courseStyles.quizResourceLink}`}
                    >
                      <div className={courseStyles.quizLink}></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={courseStyles.notificationSection}>
          <div className={courseStyles.box}></div>
          <div className={courseStyles.box}></div>
          <div className={courseStyles.box}></div>
        </div>
      </div>
    </div>
  );
}


export default CourseRegistrationSkeleton;
