import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Spinner} from 'react-bootstrap';
import * as apiService from '../../../api';
import cookie from 'react-cookies';
import {ctaClickAction, subscriptionChangeAction}
  from '../../Shared/TrackActionService';
import {navigate} from 'gatsby';
import * as registrationService from
  '../../RegistrationDetailV2/RegistrationDetailService';
import unlockModalStyles from '../UnlockModulePopup.module.scss';
import dayjs from 'dayjs';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function UnlockModulePopup(props) {
  const {isActive, triggerUnlockModulePopup,
    subscriptionInfo, courseData, locale}=props;
  const leadId = cookie.load('shawacademy_leadId');
  const [loadingState, setLoadingState]=useState(false);
  const [disabledCta, setDisabled] = useState(false);

  const chargebeeSub = subscriptionInfo?.attributes?.chargebee ||
  subscriptionInfo?.attributes?.default?.additional_platform_data;
  const currency = chargebeeSub?.currency_code;
  const planAmount = chargebeeSub?.plan_amount / 100;

  const getScheduleData = () => {
    const moduleAttributes=courseData.relationships.modules
        .data[0]?.attributes;
    const lessonOneStartTime=moduleAttributes.lessons[0]['start_time'];
    const lessonTwoStartTime=moduleAttributes.lessons[1]['start_time'];
    const scheduleData = {
      'dayOne': dayjs(lessonOneStartTime).day() === 0 ?
7 : dayjs(lessonOneStartTime).day(),
      'dayTwo': dayjs(lessonTwoStartTime).day() === 0 ?
7 : dayjs(lessonTwoStartTime).day(),
      'timeOne': dayjs(lessonOneStartTime).hour(),
      'timeTwo': dayjs(lessonTwoStartTime).hour(),
    };
    return scheduleData;
  };


  /**
   *
   *
   * @param {*} scheduleData
   * @return {*}
   */
  const getPreviousSchedule = (scheduleData) => {
    const regId=courseData.id;
    const timeToSend=dayjs().clone().subtract(1, 'day').toISOString();
    const moduleData=courseData.relationships.modules?.data;
    const lessonData=moduleData[0].attributes.lessons;
    const filteredLessons=lessonData.filter((lesson) => lesson.key !== 'bonus');
    const lessonsArray = [];
    filteredLessons.forEach((lesson) => {
      const lessonObj = {};
      const startTime=lesson['start_time'];
      lessonObj['number']=lesson.number;
      if (dayjs(startTime) < dayjs()) {
        lessonObj['start_time']=startTime;
      } else {
        lessonObj['start_time']=timeToSend;
      }
      lessonsArray.push(lessonObj);
    });
    const courseSlug=courseData.attributes.localizedcourseslug;
    const dataToSend = {
      ...scheduleData,
      finalSchedule: lessonsArray,
      regId,
      leadId,
      courseSlug,
    };
    const requestObj=
    registrationService.createScheduleToSendToApi(dataToSend, true);
    return requestObj;
  };

  const getSubscriptionPayload = () => {
    const subscriptionPayload={};
    let subId='legacy-code';
    let planId;
    if (subscriptionInfo?.attributes?.chargebee) {
      const sub=subscriptionInfo.attributes.chargebee;
      subId=subscriptionInfo.id;
      planId=sub.plan_id.replace(sub.plan_id.split('_')[3], '0d-trial');
      if (sub.coupon) {
        sub.coupon=sub.coupon.replace(sub.coupon.split('_')[3], '0d-trial');
        subscriptionPayload['coupon_ids']=[sub.coupon];
      }
    } else if (subscriptionInfo?.attributes?.default) {
      const sub=subscriptionInfo.attributes?.default?.additional_platform_data;
      subId=subscriptionInfo.id;
      planId=sub.plan_id.replace(sub.plan_id.split('_')[3], '0d-trial');
      if (sub.coupon) {
        sub.coupon=sub.coupon.replace(sub.coupon.split('_')[3], '0d-trial');
        subscriptionPayload['coupon_ids']=[sub.coupon];
      }
    }
    subscriptionPayload['student_id']=leadId;
    subscriptionPayload['plan_id']=planId;
    return {subId, subscriptionPayload};
  };

  /**
   *
   *
   */
  const unlockModule = () => {
    const regId=courseData?.id;
    const scheduleData=getScheduleData();
    const frequency='monthly';
    const {PaymentWebDomain}=process.env;
    const paymentLink=`${PaymentWebDomain}/payment/subscription/${frequency}`;
    const {subId, subscriptionPayload}=getSubscriptionPayload();
    setLoadingState(true);
    new apiService.default.Subscription(subId)
        .updateSubscription(subscriptionPayload).then(() => {
          const prevSchedule=getPreviousSchedule(scheduleData);
          // TODO: recheck this
          new apiService.default.Schedule(regId).update(prevSchedule)
              .then(() => {
                const scheduleToSend=registrationService
                    .createSchedule(scheduleData, courseData);
                new apiService.default.Schedule(leadId)
                    .create(scheduleToSend)
                    .then(() => {
                      triggerUnlockModulePopup();
                      cookie.save(`module_unlocked_${regId}`, true, {
                        path: '/',
                        domain: process.env.CookieDomain,
                      });
                      subscriptionChangeAction({
                        'studentId': leadId,
                        'action': 'PlanChanged',
                        'planId': subscriptionPayload['plan_id'],
                      });
                      setLoadingState(false);
                      props.triggerApiCall();
                      navigate(`/app/${locale
                      }/v2/registrations/${regId}/modules/2`);
                    }, (err) => {
                      console.log(err);
                      triggerUnlockModulePopup();
                      setLoadingState(false);
                    });
              }).catch((err) => {
                triggerUnlockModulePopup();
                setLoadingState(false);
              });
        }).catch((err) => {
          console.log(err);
          const errBody=err.response.data.meta;
          triggerUnlockModulePopup();
          setLoadingState(false);
          if (err?.response?.data?.errors?.[0]?.code === '101') {
            const objToSend = {};
            objToSend['student_id']=leadId;
            objToSend['purchase_type']='subscription';
            objToSend['plan_id']=subscriptionPayload['plan_id'];
            objToSend['gateway']=errBody.gateway;
            objToSend['payment_intent']=errBody.payment_intent;
            objToSend['source_url']=window.location.href;
            const encodedDataToSend=
            encodeURIComponent(btoa(JSON.stringify(objToSend)));
            window.location.href=`${PaymentWebDomain
            }/payment/authorise/?payload=${encodedDataToSend}`;
          } else {
            window.location.href=(paymentLink);
          }
        });
    ctaClickAction({
      'studentId': leadId,
      'cta': 'Unlock Module',
      'action': 'unlockModule',
      'pageKind': 'LessonsPage',
    });
  };

  const popupItems = [
    `Immediate access to all module 1 classes`,
    `Skip straight to module 2 content`,
    `Get qualified faster than others`,
  ];

  return (
    <div className={unlockModalStyles.UnlockModulePopup}>
      <div className={`${unlockModalStyles.shawModalBackdrop}
        ${isActive?unlockModalStyles.active:''}`}></div>
      <div className={unlockModalStyles.modalContentWrapper}>
        <Modal.Dialog
          className={unlockModalStyles.unlockModal}>
          <div className={unlockModalStyles.unlockBannerImg}>
            <img src="/images/unlock-bg.png" alt="unlock-bg" />
          </div>
          <div className={unlockModalStyles.closeIcon}
            onClick={triggerUnlockModulePopup}>
            <img src='/popups/cross.svg' alt='cross'/>
          </div>
          <div className={unlockModalStyles.unlockModalContainer}>

            <div className={unlockModalStyles.unlockContent}>
              <h2 className={unlockModalStyles.headline}>
                <img src="/images/unlock.png" alt="unlock-bg" />
                Unlock all module 1 classes
              </h2>
              <div className={unlockModalStyles.list}>
                {popupItems.map((item, index) => (
                  <div key={index} className={unlockModalStyles.item}>
                    <img src="/popups/check-img.svg" alt="check" />
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              <p className={unlockModalStyles.unlockInfo}>
                <label className={unlockModalStyles.checkboxCustom}>
                  <span className={unlockModalStyles.checkboxInput}>
                    <input id="privacy" name="privacy"
                      type="checkbox" onChange={()=>{
                        setDisabled(!disabledCta);
                      }}/>
                    <span className={unlockModalStyles.checkboxControl}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          d="M1.73 12.91l6.37 6.37L22.79 4.59"
                        />
                      </svg>
                    </span>
                  </span>
                </label>

                <span> By checking this option you&apos;re authorizing
                  Shaw Academy to the charge immediately. For more info check
                <a href= "https://www.shawacademy.com/terms/" target='_blank'
                  rel="noreferrer">
                  Terms & Conditions</a>
                </span>
              </p>
            </div>
            {chargebeeSub ?
            <div className={unlockModalStyles.oneClick}>
              <p>You&apos;re subscribing to&nbsp;
                {currency ? `${currency}` : ''}
                &nbsp;<span>{planAmount ? `${planAmount} /month` : ''}</span>
                &nbsp;plan.</p>
            </div>: <></>}
            <div className={unlockModalStyles.unlockModuleCta}>
              <Button onClick={unlockModule}
                className={`${loadingState ?
                  unlockModalStyles.disabled : ''}
                  ${disabledCta? '' : unlockModalStyles.disabled}`} >
                Pay & Skip module
                {loadingState &&
                  <Spinner animation="border" className="ml-3" size="sm"/>
                }
              </Button>
            </div>
          </div>
        </Modal.Dialog>
      </div>
    </div>
  );
}

UnlockModulePopup.propTypes = {
  isActive: PropTypes.bool,
  triggerUnlockModulePopup: PropTypes.func,
  courseData: PropTypes.object,
  subscriptionInfo: PropTypes.object,
  locale: PropTypes.string,
  triggerApiCall: PropTypes.func,
};

export default UnlockModulePopup;
