import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Spinner} from 'react-bootstrap';
import {ctaClickAction} from '../../Shared/TrackActionService';
import {addonBuyAction} from '../Banner/AddonPurchaseActionService';
import styles from '../RetakeConfirmation.module.scss';

const RetakeConfirmation = ({closeModal, dataToPass,
  setFinalAssessmentRetakes}) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const purchaseRetake = () => {
    const objectToPass=dataToPass?.objectToPass;
    setShowSpinner(true);
    addonBuyAction(objectToPass)
        .then((res)=> {
          const updatedRetakes=
          dataToPass?.data?.finalAssessmentRetakes.retakes+2;
          setFinalAssessmentRetakes({
            retakes: updatedRetakes,
            attempts: dataToPass?.data?.finalAssessmentRetakes.attempts,
            moduleNumber: objectToPass.moduleNumber,
          }, objectToPass.moduleNumber-1);
          dataToPass?.data?.disableCta();
          closeModal();
        })
        .catch((err)=> {
          setShowSpinner(false);
        });
    ctaClickAction({
      'studentId': objectToPass.studentId,
      'locale': objectToPass.locale,
      'cta': 'Buy Retakes',
      'action': 'buyRetakes',
      'pageKind': dataToPass?.data?.pageKind,
    });
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalHeader}>
          <div className={styles.modalClose}>
            <div
              onClick={closeModal}
              className={' library-shaw-icon-new'}>
              <i className="icon-new-close"></i>
            </div>
          </div>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.title}>
            <p>
              Buy Retakes&nbsp;
              At&nbsp;
              {dataToPass?.data?.retakePrice?.currency_symbol}
              {dataToPass?.data?.retakePrice?.tiers?.[0]?.price}&nbsp;
              only&nbsp;
              and&nbsp;
              get 2 more attempts to complete the assignment.
            </p>
          </div>
          <button
            className={`${styles.confirmButton} ${
              showSpinner ? styles.disableButton : ''}`}
            onClick={purchaseRetake}>
            Buy Now
          </button>
          {showSpinner && <div className={styles.loader}>
            <Spinner animation="border" />
          </div>}
        </div>
        <div className={styles.footer}>
          <p>
            <span>
              By clicking Buy Now, you authorise Shaw Academy to take the
              payment right away for your assignment retakes and agree to
              Shaw Academy&apos;s
            </span>
            <span className={styles.highlight}>
              <a href="https://www.shawacademy.com/terms/" target="__blank">
            &nbsp;Terms & Conditions
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

RetakeConfirmation.propTypes = {
  closeModal: PropTypes.func,
  dataToPass: PropTypes.object,
  setFinalAssessmentRetakes: PropTypes.func,
  finalAssessmentRetakes: PropTypes.object,
};

export default RetakeConfirmation;
