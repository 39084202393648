import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import PeopleJoinedSkeleton
  from '../../Shared2/LoadingSkeleton/Leaderboard/PeopleJoinedSkeleton';
import style from './Leaderboard.module.scss';
import Avatar from '../../../pages/static/Avatar.png';
import flashUp from '../../../../static/images/leaderboard/flash_up.svg';
import upArrow from '../../../../static/images/leaderboard/up_arrow.svg';
import downIcon from '../../../../static/images/leaderboard/down_icon.svg';
import upIcon from '../../../../static/images/leaderboard/icon_up.svg';
import {Accordion, useAccordionToggle, AccordionContext} from 'react-bootstrap';
import goldAvatar from '../../../../static/gold.svg';
import silverAvatar from '../../../../static/silver.svg';
import bronzeAvatar from '../../../../static/bronze.svg';

const pointTableData = [
  {
    'name': 'Get a diploma',
    'xpPoints': 500,
  },
  {
    'name': 'Get a diploma',
    'xpPoints': 500,
  },
  {
    'name': 'Get a diploma',
    'xpPoints': 500,
  },
  {
    'name': 'Get a diploma',
    'xpPoints': 500,
  },
  {
    'name': 'Get a diploma',
    'xpPoints': 500,
  },
  {
    'name': 'Get a diploma',
    'xpPoints': 500,
  },
  {
    'name': 'Get a diploma',
    'xpPoints': 500,
  },
];
const PeopleJoined = ({leaderBoards, leaderboardStatus, isOriginAccount,
  pointTableTabLabel, pointTableKeys, skeletonLoader}) => {
  // Context Aware Toggle
  const ContextAwareToggle = ({children, eventKey, callback}) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <Accordion.Toggle
        className={`${style.accordionContent}
            ${isCurrentEventKey?'twoAccordionOpen':''}`}
        onClick={decoratedOnClick}>
        {children}
      </Accordion.Toggle>
    );
  };

  const getAvatar = (index) => {
    if (index === 0) return goldAvatar;
    if (index === 1) return silverAvatar;
    if (index === 2) return bronzeAvatar;
  };

  const getScoreAvatar = (rank) => {
    if (rank === 1) return goldAvatar;
    if (rank === 2) return silverAvatar;
    if (rank === 3) return bronzeAvatar;
    return flashUp;
  };

  return (
    <>
      {
        !skeletonLoader ?
        <div className={style.countryTabWrapper}>
          <div className={style.innerWrapper}>
            <div className={style.accordionWrapper}>
              <div className={`${style.wrapper} accordionOpen`}>
                <img src={leaderboardStatus?.imgSrc}
                  onError={(e) => e.target.src=Avatar} // recheck
                  alt='' width="45px" height="45px" />
                <div className={style.textWrapper}>
                  {leaderboardStatus?.rank &&<p>#{leaderboardStatus?.rank}</p>}
                  <p>{`${leaderboardStatus?.name?.['first_name']}`}</p>
                  <p>{leaderboardStatus?.score} XP</p>
                </div>
                {leaderboardStatus?.rank && <img
                  src={getScoreAvatar(leaderboardStatus?.rank)}
                  alt='flashup'
                />}
              </div>
              <Accordion
                defaultActiveKey={`userPointTable`} style={{display: 'none'}}>
                <ContextAwareToggle
                  eventKey={`userPointTable`}
                >
                  <React.Fragment>
                    <div style={{visibility: 'hidden'}}>
                      <p className={style.accordionContentTitle}>
                          Summary
                      </p>
                      <p className={style.rankXpWrapper}>
                        <span className={style.rank}>
                            12 Rank
                          <img src={downIcon} alt="downIcon" />
                        </span>
                        <span className={style.xpPoints}>
                      1000 XP
                          <img src={upIcon} alt="upIcon" />
                        </span>
                      </p>
                    </div>
                    <img
                      alt="arrow"
                      className={style.arrow}
                      src={upArrow}
                    />
                  </React.Fragment>
                </ContextAwareToggle>
                <Accordion.Collapse
                  eventKey={`userPointTable`}
                  className={`${style.userPointTable}`}>
                  <React.Fragment>
                    <p className={style.userPointTableTitle}>
                        Total XP earned since beginning
                    </p>
                    {pointTableData.map((data, index) => (
                      <p key={index}>
                        <span>{data.name}</span>
                        <span>{`${data.xpPoints} XP`}</span>
                      </p>
                    ))}
                    <p className={style.totalXp}>
                      <span>Total</span>
                      <span>2.25k XP</span>
                    </p>
                  </React.Fragment>
                </Accordion.Collapse>
              </Accordion>
            </div>
            {leaderBoards.map((user, index)=>(
              user.lead_id !== leaderboardStatus?.lead_id?
              <div key={index} className={style.wrapper}
                style={user.rank===leaderboardStatus.rank?
                {backgroundColor: '#a4ede2'}: {}}>
                <img src={user.imgSrc}
                  onError={(e) => e.target.src=Avatar} // recheck
                  alt='' width="45px" height="45px" />
                <div className={style.textWrapper}>
                  <p className='library-shaw-icon'>
                    {index<3 && <i // has to come from css
                      className={`${style.starIcon} icon-star-filled`}
                      style={index===0?{color: '#f3d179'}:
                        index===1?{color: '#c197d6'}:
                        {color: '#f6bca8'}}
                    ></i>}
                    #{user?.rank}
                  </p>
                  <p>{`${user.name?.['first_name']}`}</p>
                  <p>{`${user.score} XP`}</p>
                </div>
                <div>
                  <p>
                    <img src={getAvatar(index)} />
                  </p>
                </div>
                {/* <img src={data.trending==='up'? bring this back later
                  trendingUp:trendingDown} alt='trend' /> */}
              </div>:null))}
          </div>
          <div className={style.pointTable}>
            <h1>Leaderboard Point Guide</h1>
            {pointTableKeys.map((data, index) => (
              <p key={index}>
                <span>{data}</span>
                <span>{`${pointTableTabLabel.getValue(data)} XP`}</span>
              </p>
            ))}
          </div>
        </div> :
        <PeopleJoinedSkeleton />
      }
    </>
  );
  ContextAwareToggle.propTypes = {
    children: PropTypes.any,
    eventKey: PropTypes.any,
    callback: PropTypes.any,
  };
};

PeopleJoined.propTypes = {
  leaderBoards: PropTypes.array,
  leaderboardStatus: PropTypes.object,
  pointTableKeys: PropTypes.any,
  pointTableTabLabel: PropTypes.any,
  skeletonLoader: PropTypes.bool,
  isOriginAccount: PropTypes.bool,
};
export default PeopleJoined;
