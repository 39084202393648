import React, {Component} from 'react';
import {Form, Col, Container, Button, Spinner} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';
import * as rescheduleService from './RescheduleService';
import * as apiService from '../../api';
import cookie from 'react-cookies';
import Helmet from 'react-helmet';
import * as trackAction from '../Shared/TrackActionService';
import {handleImageError} from '../Shared/Utils';
import AssetsService from '../Shared/AssetsService';
import {checkEligibilityForNewDashboard} from '../Shared/Utils';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import styles from './index.module.scss';
import HeaderNew from '../Shared/HeaderNew';
import {countriesEligibleForNewSchedule} from '../../containers/Constants';
import brandEnglishCourses from '../../../data/brandEnglishCourses.json';
import brandNonEnglishCourses from '../../../data/brandNonEnglishCourses.json';

dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

/**
 *
 * @export
 * @class CourseScheduleClassComponent
 * @extends {Component}
 */
class CourseScheduleClassComponent extends Component {
  /**
   * Creates an instance of CourseScheduleClassComponent.
   * @param {*} props
   * @memberof CourseScheduleClassComponent
   */
  constructor(props) {
    super(props);
    this.state = {
      calenderDays: [],
      timePickerList: [],
      dayPickerList: [],
      hasMounted: false,
      showSpinner: false,
      squareImage: AssetsService.DUMMY_IMG,
      graphqlData: [],
      disableCta: false,
      enableButton: false,
      eligibleForNewSchedule: false,
    };
    this.daysArray=require(`dayjs/locale/${props.locale}`).weekdays;
  }

  /**
   * For initial api calls
   *
   * @memberof CourseScheduleClassComponent
   */
  componentDidMount() {
    this.setState({hasMounted: true});
    const otherCourses = this.props?.nonEnglishCourses?.nodes.filter((ele) => {
      return (ele.publish_details.locale !== 'en-gb' &&
      ele.publish_details.locale !== 'en-us');
    });
    dayjs.locale(this.props.locale);
    const otherLocales = {
      nodes: otherCourses,
    };
    otherLocales.nodes.push(this.props?.englishCourses.nodes[0]);
    const allCoursesInfo = otherLocales;
    this.setSelectedModuleLessons(allCoursesInfo);
    const leadCountry =cookie.load('country');
    setTimeout(() => {
      const leadCreatedAt = this?.props?.studentInfo?.created_at;
      const newScheduleEligibility =
    countriesEligibleForNewSchedule(leadCountry, leadCreatedAt);
      this.setState({
        eligibleForNewSchedule: newScheduleEligibility,
      });
    }, 800);
  }

  /**
   * Sets the first module lessons using response from graphql
   * @param {*} allCoursesList
   */
  setSelectedModuleLessons(allCoursesList) {
    const allCourses=[];
    const selectedModule=rescheduleService.getModule()||1;
    // Filter response based on locale
    for (let i=0; i<allCoursesList.nodes.length; i++) {
      if (allCoursesList.nodes[i].publish_details.locale.
          indexOf(this.props?.locale) > -1) {
        allCourses.push(allCoursesList.nodes[i].coursecollections);
        break;
      }
    }

    const coursePicked = rescheduleService.getCoursePicked();
    if (!coursePicked) {
      navigate(`/app/${this.props?.locale}/courses`);
    }

    let pickedCourseData;
    if (coursePicked) {
      for (let i=0; i<allCourses[0].length; i++) {
        const item = allCourses[0][i].courses;
        let lessonFound = false;
        for (let j=0; j<item.length; j++) {
          const course = item[j];
          if (this.props?.locale !== 'en') {
            course.courseslug = `${course.courseslug}-${course.locale}`;
          }
          if (course.courseslug === coursePicked) {
            pickedCourseData=course;
            lessonFound=true;
            break;
          }
        }
        if (lessonFound) {
          break;
        }
      }
    }
    const squareImage = new AssetsService('RECORDINGS')
        .fetchAssetURL(coursePicked);

    this.setState({
      allCourse: allCourses[0],
      leadId: rescheduleService.getLeadId(),
      classSelected: this?.state?.studentInfo?.address
          ?.country === 'za' ? null : 2,
      pickedCourseData: pickedCourseData,
      firstModule: pickedCourseData?.modules[selectedModule-1],
      firstModuleLessons: pickedCourseData?.modules[selectedModule-1]?.lessons,
      modifiledModuleLessons:
      pickedCourseData?.modules[selectedModule-1]?.lessons?.slice(0, 2),
      timePickerList: rescheduleService.buildTimePickerList(),
      dayPickerList: rescheduleService.buildDayPickerList(),
      squareImage,
    }, () => this.selectClass(2, 'onLoad'));

    setTimeout(() => {
      window.dataLayer.push({
        'event': 'view-page-action',
        'view-page-param': {
          'studentId': cookie.load('shawacademy_leadId') || '',
          'pageKind': 'CourseSchedulePage',
          'locale': this.props?.locale,
          'courseSlug': this.state.pickedCourseData?.courseslug,
        },
      });
    }, 50);
    cookie.save('pageKind', 'CourseSchedulePage', {
      path: '/',
    });
  }
  /**
 * Verifies if all the dropdown has valid values
 *
 * @memberof CourseScheduleClassComponent
 */
  toggleConfirmationTextState() {
    const state = this.state;

    if (state?.classSelected === 1) {
      if (state.isDay1Selected && state.time1Selected) {
        this.buildScheduleLessonsArray();
        this.setState({
          showError: false,
          showButton: true,
        });
        return;
      }
    }
    if (state?.classSelected === 2) {
      if (state.isDay1Selected && state.isDay2Selected &&
        state.time1Selected && state.time2Selected) {
        this.getHourDifference();
        this.buildScheduleLessonsArray();
        return;
      }
    }
    if (state?.classSelected === 3) {
      if (state.isDay1Selected && state.isDay2Selected &&
        state.time1Selected && state.time2Selected &&
        state.isDay3Selected && state.time3Selected) {
        this.getHourDifference();
        this.buildScheduleLessonsArray();
        return;
      }
    }
    this.setState({
      showButton: false,
    });
  }

  /**
 *
 *
 * @param {*} e
 * @memberof CourseScheduleClassComponent
 */
  handleFirstDayChange(e) {
    const selectedWeekday = this.daysArray.indexOf(e.target.value);
    const today = dayjs().isoWeekday();
    let selectedDay1;
    if (today < selectedWeekday) {
      selectedDay1 = dayjs().isoWeekday(selectedWeekday);
    } else {
      selectedDay1 = dayjs().add(8, 'days').isoWeekday(selectedWeekday);
    }
    this.setState({
      selectedDay1: selectedDay1,
    });
    if (this.state.time1Selected) {
      const hours =
        parseInt(dayjs(this.state.time1Selected, ['h:mm A']).format('HH:mm'));
      const schedule1 = selectedDay1
          .set('hour', hours).set('minute', 0).set('second', 0);
      this.setState({
        schedule1: schedule1,
      }, this.toggleConfirmationTextState);
    }
    if (this.state.schedule1) {
      const hours = parseInt(this.state.schedule1.format('HH'));
      const schedule1 = selectedDay1
          .set('hour', hours).set('minute', 0).set('second', 0);
      this.setState({
        schedule1: schedule1,
      }, this.toggleConfirmationTextState);
    }
    if (e.target.value != 0) {
      this.setState({
        isDay1Selected: true,
      }, this.toggleConfirmationTextState);
    } else {
      this.setState({
        isDay1Selected: false,
      }, this.toggleConfirmationTextState);
    }
  }


  /**
   *
   *
   * @param {*} e
   * @memberof CourseScheduleClassComponent
   */
  handleThirdDayChange(e) {
    const selectedWeekday = this.daysArray.indexOf(e.target.value);
    const today = dayjs().isoWeekday();
    let selectedDay3;
    if (today < selectedWeekday) {
      selectedDay3 = dayjs().isoWeekday(selectedWeekday);
    } else {
      selectedDay3 = dayjs().add(8, 'days').isoWeekday(selectedWeekday);
    }
    if (this.state?.selectedDay2) {
      while (selectedDay3.diff(this.state?.selectedDay2, 'hours') < 0) {
        selectedDay3 = selectedDay3.add(7, 'day');
      }
    }
    // this.setState({
    //   selectedDay2: selectedDay2,
    // }, this.toggleConfirmationTextState);
    this.setState({
      selectedDay3: selectedDay3,
    });
    if (this.state?.time3Selected) {
      const hours =
        parseInt(dayjs(this.state?.time3Selected, ['h:mm A']).format('HH:mm'));
      const schedule3 = selectedDay3
          .set('hour', hours).set('minute', 0).set('second', 0);
      this.setState({
        schedule3: schedule3,
      }, this.toggleConfirmationTextState);
    }
    if (this.state.schedule3) {
      const hours = parseInt(this.state.schedule3.format('HH'));
      const schedule3 = selectedDay3
          .set('hour', hours).set('minute', 0).set('second', 0);
      this.setState({
        schedule3: schedule3,
      }, this.toggleConfirmationTextState);
    }
    if (e.target.value != 0) {
      this.setState({
        isDay3Selected: true,
      }, this.toggleConfirmationTextState);
    } else {
      this.setState({
        isDay3Selected: false,
      }, this.toggleConfirmationTextState);
    }
  }

  /**
 *
 *
 * @param {*} e
 * @memberof CourseScheduleClassComponent
 */
  handleFirstHourChange(e) {
    const hours = parseInt(dayjs(e.target.value, ['h:mm A']).format('HH:mm'));
    if (e.target.value != 0) {
      this.setState({
        time1Selected: e.target.value,
      }, this.toggleConfirmationTextState);
      if (this.state.selectedDay1) {
        const schedule1 = this.state.selectedDay1
            .set('hour', hours).set('minute', 0).set('second', 0);
        this.setState({
          schedule1: schedule1,
        }, this.toggleConfirmationTextState);
      } else {
        this.setState({
          schedule1: null,
        }, this.toggleConfirmationTextState);
      }
    } else {
      this.setState({
        time1Selected: null,
        schedule1: null,
      }, this.toggleConfirmationTextState);
    }
  }

  /**
   *
   *
   * @param {*} e
   * @memberof CourseScheduleClassComponent
   */
  handleThirdHourChange(e) {
    const hours = parseInt(dayjs(e.target.value, ['h:mm A']).format('HH:mm'));
    if (e.target.value != 0) {
      this.setState({
        time3Selected: e.target.value,
      }, this.toggleConfirmationTextState);
      if (this.state?.selectedDay3) {
        const schedule3 = this.state?.selectedDay3
            .set('hour', hours).set('minute', 0).set('second', 0);
        this.setState({
          schedule3: schedule3,
        }, this.toggleConfirmationTextState);
      } else {
        this.setState({
          schedule3: null,
        }, this.toggleConfirmationTextState);
      }
    } else {
      this.setState({
        time3Selected: null,
        schedule3: null,
      }, this.toggleConfirmationTextState);
    }
  }

  /**
 *
 *
 * @param {*} e
 * @memberof CourseScheduleClassComponent
 */
  handleSecondDayChange(e) {
    const selectedWeekday = this.daysArray.indexOf(e.target.value);
    let selectedDay2 = dayjs().isoWeekday(selectedWeekday);
    if (this.state.selectedDay1) {
      while (selectedDay2.diff(this.state.selectedDay1, 'hours') < 0) {
        selectedDay2 = selectedDay2.add(7, 'day');
      }
    }
    this.setState({
      selectedDay2: selectedDay2,
    }, this.toggleConfirmationTextState);
    if (this.state.schedule2) {
      const hours = parseInt(this.state.schedule2.format('HH'));
      const schedule2 = selectedDay2
          .set('hour', hours).set('minute', 0).set('second', 0);
      this.setState({
        schedule2: schedule2,
      }, this.toggleConfirmationTextState);
    }
    if (this.state.time2Selected) {
      const hours =
        parseInt(dayjs(this.state.time2Selected, ['h:mm A']).format('HH:mm'));
      const schedule2 = selectedDay2
          .set('hour', hours).set('minute', 0).set('second', 0);
      this.setState({
        schedule2: schedule2,
      }, this.toggleConfirmationTextState);
    }
    if (e.target.value != 0) {
      this.setState({
        isDay2Selected: true,
      }, this.toggleConfirmationTextState);
    } else {
      this.setState({
        isDay2Selected: false,
      }, this.toggleConfirmationTextState);
    }
  }

  /**
 *
 *
 * @param {*} e
 * @memberof CourseScheduleClassComponent
 */
  handleSecondHourChange(e) {
    const hours = parseInt(dayjs(e.target.value, ['h:mm A']).format('HH:mm'));
    if (e.target.value != 0) {
      this.setState({
        time2Selected: e.target.value,
      }, this.toggleConfirmationTextState);
      if (this.state.selectedDay2) {
        const schedule2 = this.state.selectedDay2
            .set('hour', hours).set('minute', 0).set('second', 0);
        this.setState({
          schedule2: schedule2,
        }, this.toggleConfirmationTextState);
      } else {
        this.setState({
          schedule2: null,
        }, this.toggleConfirmationTextState);
      }
    } else {
      this.setState({
        time2Selected: null,
        schedule2: null,
      }, this.toggleConfirmationTextState);
    }
  }

  /**
 * Checks if the two selected schedules
 * have min 2 hours of difference
 *
 * @memberof CourseScheduleClassComponent
 */
  getHourDifference() {
    let extraHourCheck;
    let extraSecondHourCheck;
    const hourDiff = Math.abs(dayjs.duration(this.state.schedule2
        .diff(this.state.schedule1)).asHours());
    if (this.state?.schedule3) {
      extraHourCheck = Math.abs(dayjs.duration(this.state.schedule3
          .diff(this.state.schedule1)).asHours());
      extraSecondHourCheck = Math.abs(dayjs.duration(this.state.schedule3
          .diff(this.state.schedule2)).asHours());
    }
    if (Math.round(hourDiff) < 2 || Math.round(extraHourCheck) < 2 ||
    Math.round(extraSecondHourCheck) < 2) {
      this.setState({
        showError: true,
        showButton: false,
      });
    } else {
      this.setState({
        showError: false,
        showButton: true,
      });
      if (!cookie.load('eventPushed')) {
        window.dataLayer.push({
          'event': 'view-page-action',
          'view-page-param': {
            'studentId': cookie.load('shawacademy_leadId') || '',
            'pageKind': 'CourseSchedulePageClassesShown',
            'locale': this.props?.locale,
            'courseSlug': this.state?.pickedCourseData?.courseslug,
          },
        });
      }
      cookie.save('eventPushed', true);
    }
  }

  /**
 * Creates calender for upcoming schedule
 *
 * @memberof CourseScheduleClassComponent
 */
  // buildScheduledCalender() {
  //   const calenderDays = [];
  //   const dateStart = this.state.selectedDay1;
  //   const dateEnd = dayjs().add(31, 'days');
  //   let day = dateStart;
  //   while (dateEnd.diff(day, 'days') > 0) {
  //     if (this.state.schedule1.day() === day.day()) {
  //       calenderDays.push({
  //         date: day.clone(),
  //         time: this.state.schedule1.clone(),
  //         lessonDay: true,
  //       },
  //       );
  //     } else if (this.state.schedule2.day() === day.day()) {
  //       calenderDays.push({
  //         date: day.clone(),
  //         time: this.state.schedule2.clone(),
  //         lessonDay: true,
  //       },
  //       );
  //     } else {
  //       calenderDays.push({
  //         date: day.clone(),
  //         lessonDay: false,
  //       },
  //       );
  //     }
  //     day = day.clone().add(1, 'days');
  //   }
  //   this.setState({calenderDays: calenderDays});
  // }


  /**
   *
   *
   * @param {*} lessonLength
   * @return {*} modifiedLessonSchedule
   * @memberof CourseScheduleClassComponent
   */
  getLessons(lessonLength) {
    const state = this.state;
    const lessonsToReturn = [];
    let modifiedLessonLength;
    if (lessonLength) {
      if (state?.classSelected === 1) {
        modifiedLessonLength = lessonLength * 2;
        for (let i = 0; i < modifiedLessonLength; i++) {
          let day1 = state.schedule1.clone();
          day1 = day1.add(i*7, 'days').toISOString();
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day1,
            status: 'scheduled',
          });
        }
        return lessonsToReturn;
      }
      if (state?.classSelected === 2) {
        modifiedLessonLength = lessonLength;
        for (let i = 0; i < modifiedLessonLength; i++) {
          let day1 = state.schedule1.clone();
          day1 = day1.add(i*7, 'days').toISOString();
          let day2 = state.schedule2.clone();
          day2 = day2.add(i*7, 'days').toISOString();
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day1,
            status: 'scheduled',
          });
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day2,
            status: 'scheduled',
          });
        }
        return lessonsToReturn;
      }
      if (state?.classSelected === 3) {
        modifiedLessonLength = lessonLength;
        for (let i = 0; i < modifiedLessonLength; i++) {
          let day1 = state.schedule1.clone();
          day1 = day1.add(i*7, 'days').toISOString();
          let day2 = state.schedule2.clone();
          day2 = day2.add(i*7, 'days').toISOString();
          let day3 = state.schedule3.clone();
          day3 = day3.add(i*7, 'days').toISOString();
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day1,
            status: 'scheduled',
          });
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day2,
            status: 'scheduled',
          });
          lessonsToReturn.push({
            number: lessonsToReturn.length + 1,
            start_time: day3,
            status: 'scheduled',
          });
        }
        lessonsToReturn.splice(lessonsToReturn.length - 4,
            lessonsToReturn.length);
        return lessonsToReturn;
      }
    }
  }

  /**
 * Builds lessons array according to schedule
 *
 * @memberof CourseScheduleClassComponent
 */
  buildScheduleLessonsArray() {
    const pickedCourseData = this.state?.pickedCourseData;
    const module = rescheduleService.getModule() || 1;
    const lessonLength =
      Math.ceil(pickedCourseData?.modules[module-1].lessons.length / 2);
    if (lessonLength) {
      const lessons = this.getLessons(lessonLength);
      if (lessons.length !==
        pickedCourseData?.modules[module-1].lessons.length) {
        lessons.pop();
      }
      this.setState({
        lessons: lessons,
      });
    }
    // this.buildScheduledCalender
  }

  /**
 *
 *
 * @memberof CourseScheduleClassComponent
 */
  handleConfirmation() {
    this.setState({
      showSpinner: true,
      disableCta: true,
    });
    trackAction.ctaClickAction({
      'studentId': this.state.leadId,
      'cta': 'Confirm Schedule',
      'action': 'Schedule',
      'courseSlug': this.state.pickedCourseData?.courseslug,
      'moduleNumber': 1,
      'pageKind': 'CourseSchedulePage',
    });
    if (!this.state.showError && this.state.pickedCourseData?.courseslug) {
      let firstDayOfWeek;
      let secondDayOfWeek;
      let tempSchedule;
      if (this.state.schedule1.day() === 0) {
        firstDayOfWeek = 7;
      } else {
        firstDayOfWeek = this.state.schedule1.day();
      }
      if (this.state?.classSelected >= 2) {
        tempSchedule = this.state.schedule2;
        if (this.state.schedule2.day() === 0) {
          secondDayOfWeek = 7;
        } else {
          secondDayOfWeek = this.state.schedule2.day();
        }
      } else {
        tempSchedule = this.state.schedule1;
        secondDayOfWeek = firstDayOfWeek;
      }
      const requestObj = {
        'data': {
          'type': 'registrations',
          'attributes': {
            'course_start_date': this.state.schedule1.toISOString(),
            'course_slug': this.state.firstModule?.moduleslug,
            'schedule': {
              'first_day_of_week': cookie.load('country') === 'gb' ? null :
              firstDayOfWeek,
              'second_day_of_week': cookie.load('country') === 'gb' ? null :
              secondDayOfWeek,
              'third_day_of_week': cookie.load('country') !== 'gb' &&
              this.state?.schedule3 ? this.state?.schedule3?.day() : null,
              'first_day_hour': cookie.load('country') === 'gb' ? null :
              parseInt(this.state.schedule1.format('H')),
              'second_day_hour': cookie.load('country') === 'gb' ? null :
              parseInt(tempSchedule.format('H')),
              'third_day_hour': cookie.load('country') === 'gb' ? null :
              parseInt(this.state?.schedule3?.format('H')),
              'lessons': this.state.lessons,
              'lessons_per_week': this.state?.classSelected,
            },
          },
          'relationships': {
            'student': {
              'data': {
                'type': 'student',
                'id': this.state.leadId,
              },
            },
          },
        },
      };
      new apiService.default.Schedule().create(requestObj)
          .then((response) => {
            this.setState({
              showCommonError: false,
              showSpinner: false,
            });
            const result = response.data.data;
            if (result) {
              const module = rescheduleService.getModule()|| 1;
              const id = rescheduleService.getParam('reg_id') || result.id;
              const loc = this.props?.locale;
              trackAction.registerModuleAction({
                'moduleNumber': parseInt(module, 10),
                'courseSlug': result.attributes.course_slug,
                'facultySlug': result.attributes.course.faculty,
                'locale': this.props?.locale,
                'Goal': this.state?.classSelected,
                'scheduleType': cookie.load('country') === 'gb' ? 'v2' :
                'v1',
              });
              const expires = new Date;
              expires.setFullYear(expires.getFullYear() + 2);
              cookie.save('courseRegistered',
                  1, {
                    path: '/',
                    domain: process.env.CookieDomain,
                    expires,
                  });
              setTimeout(() => {
                let url =
                `/app/${loc}/registrations/${id}/modules/${module}/get-toolkit`;
                if (checkEligibilityForNewDashboard()) {
                  url =
                  `/app/${loc}/v2/registrations/${id
                  }/modules/${module}/get-toolkit`;
                }
                navigate(url);
              }, 1000);
            } else if (response.data.errors[0].code == '409') {
              this.setState({
                showSpinner: false,
              });
              trackAction.triggerApiErrorAction(
                  'CourseSchedulePage',
                  '409');
              navigate(`/app/${this.props?.locale}`);
            } else {
              this.setState({
                showEnrolledError: false,
                showSpinner: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              showCommonError: true,
              showSpinner: false,
              disableCta: false,
            });
          });
    }
  }

  /**
   *
   *
   * @param {*} param
   * @param {string} [triggerFrom='']
   * @memberof CourseScheduleClassComponent
   */
  selectClass(param, triggerFrom = '') {
    if (triggerFrom === 'onLoad' && cookie.load('country') === 'za') return;
    if (!this?.state?.eligibleForNewSchedule) {
      this.setState({classSelected: param, isClassSelected: true},
          this.toggleConfirmationTextState);
    } else {
      this.setState({
        classSelected: param,
        isClassSelected: true,
      });
      this.buildSchedule(param);
    }
    if (triggerFrom !== 'onLoad') {
      trackAction.ctaClickAction({
        'studentId': this.state.leadId,
        'cta': `Select${param}ClassesPerWeek`,
        'action': 'SelectClassesPerWeek',
        'courseSlug': this.state.pickedCourseData?.courseslug,
        'moduleNumber': 1,
        'pageKind': 'CourseSchedulePage',
      });
    }
    this.trimClasses(param);
  }

  /**
   *
   *
   * @param {*} classesSelected
   * @memberof CourseScheduleClassComponent
   */
  buildSchedule(classesSelected) {
    const nextSunday = dayjs().weekday(7);
    const sunday = dayjs(new Date(new Date(nextSunday).setHours(12, 0, 0)));
    if (classesSelected === 1) {
      this.setState({
        schedule1: sunday,
        enableButton: true,
        classSelected: 1,
      }, () => this.buildScheduleLessonsArray());
    } else if (classesSelected === 2) {
      this.setState({
        schedule1: sunday,
        schedule2: sunday,
        enableButton: true,
        classSelected: 2,
      }, () => this.buildScheduleLessonsArray());
    } else if (classesSelected === 3) {
      this.setState({
        schedule1: sunday,
        schedule2: sunday,
        schedule3: sunday,
        enableButton: true,
        classSelected: 3,
      }, () => this.buildScheduleLessonsArray());
    }
  }

  /**
   *
   *
   * @param {*} param
   * @memberof CourseScheduleClassComponent
   */
  trimClasses(param) {
    const firstModuleLessons = this.state?.firstModuleLessons;
    const modifiledLessons = firstModuleLessons?.slice(0, param);
    this.setState({modifiledModuleLessons: modifiledLessons});
  }

  /**
   *
   *
   * @return {*}
   * @memberof CourseScheduleClassComponent
   */
  render() {
    const benefitsList = [
      {
        link: {
          href: '/schedule/calender.svg',
          title: 'Schedule classes as per your preference.',
        },
      },
      {
        link: {
          href: '/schedule/time.svg',
          title: 'Attend the class at your desired time.',
        },
      },
      {
        link: {
          href: '/schedule/questions.svg',
          title: 'Ask questions in the Live Q&A and learn more.',
        },
      },
    ];
    // xp Indicator
    return (
      <>
        <Helmet>
          <title>Course Schedule</title>
        </Helmet>
        {this.state.hasMounted &&
        <Container
          className={`shaw-container flex-space-between ${styles.shContainer}`}
          style={{minHeight: '100vh', paddingBottom: '35px'}}>
          <HeaderNew
            registrationData={this.props?.registrationData}
            firstName={this.props?.firstName}
            locale={this.props?.locale} />
          <div className={styles.shCourseSchedule}>
            <div className={styles.CourseScheduleInfo}>
              <div className={styles.courseDetails}>
                <div className={styles.courseImage}>
                  <img src={this.state.squareImage}
                    onError={handleImageError}/>
                </div>
                <div className={styles.courseNameInfo}>
                  <h1>{this.state.pickedCourseData?.coursename}</h1>
                  <p>Weekly class schedule</p>
                </div>
              </div>
              <div className={styles.classPicker}>
                <h1>How many days per week do you want to attend Classes?</h1>
                <div className={styles.classItems}>
                  <div className={this.state?.classSelected === 1 ?
                  styles.activeItem : styles.items}
                  onClick={() => this.selectClass(1)}>
                    1
                  </div>
                  <div className={this.state?.classSelected === 2 ?
                  styles.activeItem : styles.items}
                  onClick={() => this.selectClass(2)}>
                    2
                  </div>
                  <div className={this.state?.classSelected === 3 ?
                  styles.activeItem : styles.items}
                  onClick={() => this.selectClass(3)}>
                    3
                  </div>
                  <span>- Classes per week</span>
                </div>
              </div>
              <div className={this.state?.isClassSelected ?
                styles.courseSchedulePicker : styles.hide}>
                {!this?.state?.eligibleForNewSchedule &&
                 <div className={styles.itemOne }>
                   <h1 className={styles.shPageHeading}>
                      Pick your class schedule
                   </h1>
                   <div className={styles.schedulePickerKeys}>

                     <Form.Row className={styles.formRow}>
                       <div className={`${styles.fieldContainer}
                       ${styles.schedulePageSelect}`}>
                         <Form.Label className={`${styles.fieldlabel}
                        ${styles.formLabel}`}>
                              DAY 1
                         </Form.Label>
                         <Form.Control
                           className={styles.shDropdown}
                           as="select"
                           custom
                           onChange={(e) => this.handleFirstDayChange(e)}
                         >
                           <option value='0'>
                                Select Day</option>
                           { this.state.dayPickerList.map((item) => {
                             return <option
                               value={item}
                               key={`d1-${item}`}>
                               {item}
                             </option>;
                           })
                           }
                         </Form.Control>
                       </div>
                       <div className={`${styles.fieldContainer}
                       ${styles.schedulePageSelect}`}>
                         <Form.Label className={`${styles.fieldlabel}
                        ${styles.formLabel}`}>
                            Time</Form.Label>
                         <Form.Control
                           as="select"
                           className={styles.shDropdown}
                           custom
                           onChange={(e) => this.handleFirstHourChange(e)}>
                           <option value='0'>
                              Time</option>
                           {this.state.timePickerList.map((item) => {
                             return <option
                               value={item}
                               key={`t1-${item}`}>
                               {item}
                             </option>;
                           })}
                         </Form.Control>
                       </div>
                     </Form.Row>

                     <Form.Row className={this.state?.classSelected >= 2 ?
                      `${styles.formRow} ${styles.mb0}` : styles.hideSection}>
                       <div className={`${styles.fieldContainer}
                      ${styles.schedulePageSelect}`}>
                         <Form.Label className={`${styles.fieldlabel}
                        ${styles.formLabel}`}>
                            DAY 2
                         </Form.Label>
                         <Form.Control
                           as="select"
                           custom
                           className={styles.shDropdown}
                           onChange={(e) => this.handleSecondDayChange(e)}
                         >
                           <option value='0'>
                                Select Day</option>
                           {
                             this.state.dayPickerList.map((item) => {
                               return <option
                                 value={item}
                                 key={`d2-${item}`}>
                                 {item}
                               </option>;
                             })
                           }
                         </Form.Control>
                       </div>
                       <div className={`${styles.fieldContainer}
                      ${styles.schedulePageSelect}`}>
                         <Form.Label className={`${styles.fieldlabel}
                        ${styles.formLabel}`}>
                              Time
                         </Form.Label>
                         <Form.Control
                           className={styles.shDropdown}
                           as="select"
                           custom
                           onChange={(e) => this.handleSecondHourChange(e)}
                         >
                           <option value='0'>
                              Time</option>
                           {this.state.timePickerList.map((item) => {
                             return <option
                               value={item}
                               key={`t2-${item}`}>
                               {item}
                             </option>;
                           })}
                         </Form.Control>
                       </div>
                     </Form.Row>

                     <Form.Row className={this.state?.classSelected > 2 ?
                      styles.formRow : styles.hideSection}>
                       <div className={`${styles.fieldContainer}
                       ${styles.schedulePageSelect}`}>
                         <Form.Label className={`${styles.fieldlabel}
                        ${styles.formLabel}`}>
                              DAY 3
                         </Form.Label>
                         <Form.Control
                           className={styles.shDropdown}
                           as="select"
                           custom
                           onChange={(e) => this.handleThirdDayChange(e)}
                         >
                           <option value='0'>
                              Select Day</option>
                           { this.state.dayPickerList.map((item) => {
                             return <option
                               value={item}
                               key={`d1-${item}`}>
                               {item}
                             </option>;
                           })
                           }
                         </Form.Control>
                       </div>
                       <div className={`${styles.fieldContainer}
                       ${styles.schedulePageSelect}`}>
                         <Form.Label className={`${styles.fieldlabel}
                        ${styles.formLabel}`}>
                            Time</Form.Label>
                         <Form.Control
                           as="select"
                           className={styles.shDropdown}
                           custom
                           onChange={(e) => this.handleThirdHourChange(e)}>
                           <option value='0'>
                                Time</option>
                           {this.state.timePickerList.map((item) => {
                             return <option
                               value={item}
                               key={`t1-${item}`}>
                               {item}
                             </option>;
                           })}
                         </Form.Control>
                       </div>
                     </Form.Row>
                     { this.state?.showEnrolledError ?
                      <Col className={`${styles.textDanger}
                      ${styles.mt3}`}>
                          You are already enrolled to this course.
                      </Col> : null
                     }
                     { this.state?.showCommonError ?
                      <Col className={`${styles.textDanger}
                      ${styles.mt3}`}>
                        Something went wrong.
                      </Col> : null
                     }
                     { this.state?.showError ?
                      <Col className={`${styles.textDanger}
                          ${styles.mt3}`}>
                          Classes should have minimum 2 hours difference
                          if choosen same day.
                      </Col> : null
                     }

                     <div className={styles.btnSpinnerWrapper}>
                       <Button
                         id={this.state.disableCta ? styles.disabled : ''}
                         className={`${this.state.showButton ?
                      styles.shBtn : styles.shDisableBtn}`}
                         onClick={() => this.handleConfirmation()}
                       >Confirm My Schedule</Button>
                       {
                        this.state.showSpinner?
                        <div className={styles.spinnerWrapper}>
                          <Spinner animation="border" role='status'/>
                        </div> : null
                       }
                     </div>
                   </div>
                 </div>}
                {
                  this.state.showButton ?
                    !this?.state?.eligibleForNewSchedule &&
                     <div className={styles.itemTwo}>
                       <h1 className={styles.shPageHeading}>
                          Course Schedule
                       </h1>
                       <div className={styles.shSuccessCard}>
                         <h2 className={styles.title}>
                           <span className={styles.highlighter}></span>
                            Your classes are on every
                         </h2>
                         <div className={styles.shCardContent}>
                           <div className={styles.shDateDayWrapper}>
                             <p className={styles.shDay}>
                               {this.state.schedule1?.format('dddd')}
                             </p>
                             <p className={styles.shTime}>
                               {this.state.schedule1?.format('hh:mm a')}
                             </p>
                           </div>
                           <div className={this.state?.classSelected >= 2 ?
                            styles.shDateDayWrapper : styles.hideSection}>
                             <p className={styles.shDay}>
                               {this.state.schedule2?.format('dddd')}
                             </p>
                             <p className={styles.shTime}>
                               {this.state.schedule2?.format('hh:mm a')}
                             </p>
                           </div>
                           <div className={this.state?.classSelected === 3 ?
                            styles.shDateDayWrapper : styles.hideSection}>
                             <p className={styles.shDay}>
                               {this.state.schedule3?.format('dddd')}
                             </p>
                             <p className={styles.shTime}>
                               {this.state.schedule3?.format('hh:mm a')}
                             </p>
                           </div>
                         </div>
                       </div>
                       <div className={styles.infoSchedule}>
                         <p className={styles.shStartDateWrapper}>
                           <span>starting from - </span>
                           <span className={styles.startDay}>
                             <span className={styles.capital}>
                               {this.state.schedule1?.format('MMM')}
                             </span>
                             <span className={styles.dayOne}>
                               {this.state.schedule1?.format('DD')}
                             </span>
                             {this.state.schedule1?.format('h a')}
                             <span className={styles.mlr2}> | </span>
                             <span className={styles.capital}>
                               {this.state.schedule1?.format('ddd')}
                             </span>
                           </span>
                         </p>
                       </div>
                     </div> :
                  <div className={styles.itemTwo}>
                    {
                      this.state.modifiledModuleLessons &&
                      this.state.modifiledModuleLessons.map((lesson, index)=>(
                        <div key={index} className={styles.lessonameWrapper }>
                          <p className={styles.lessonLabel}>
                            {`Lesson ${index+1}`}</p>
                          <p className={styles.lessonName}>
                            {lesson.lessonname}</p>
                        </div>
                      ))
                    }
                    <div
                      style={{display: this?.state?.eligibleForNewSchedule ?
                         'flex' : 'none'}}
                      className={styles.btnSpinnerWrapper}>
                      <Button
                        id={this.state.disableCta ? styles.disabled : ''}
                        className={`${this.state.enableButton ?
                      styles.shBtn : styles.shDisableBtn}`}
                        onClick={() => this.handleConfirmation()}
                      >Confirm My Schedule</Button>
                      {
                        this.state.showSpinner?
                        <div className={styles.spinnerWrapper}>
                          <Spinner animation="border" role='status'/>
                        </div> : null
                      }
                    </div>
                  </div>
                }
              </div>
              <div className={styles.questionSection}>
                <p className={styles.shPageHeading}>
                  How Classes work?
                </p>
                <div className={styles.helpSection}>
                  {benefitsList.map((item, i) => (
                    <div className={styles.sectionA} key={i}>
                      <img src={item?.link?.href} alt=''
                        className={styles.helpImage}/>
                      <p className={styles.helpText}>{item?.link?.title}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
        }
      </>
    );
  }
}
CourseScheduleClassComponent.propTypes = {
  allCoursesList: PropTypes.array,
  locale: PropTypes.string,
  nonEnglishCourses: PropTypes.object,
  englishCourses: PropTypes.object,
  studentInfo: PropTypes.object,
  registrationData: PropTypes.array,
  credits: PropTypes.number,
  firstName: PropTypes.string,
};

const CourseSchedule = (props) => {
  return (
    <CourseScheduleClassComponent
      nonEnglishCourses={brandNonEnglishCourses}
      englishCourses={brandEnglishCourses}
      {...props}
    />
  );
};
export default CourseSchedule;
