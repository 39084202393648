import React, {Component} from 'react';
import {Form, Container, Col, Spinner} from 'react-bootstrap';
import {Link} from '@reach/router';
// import * as authService from './AuthService';
import * as apiService from '../../api';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {navigate} from 'gatsby';
import './LoginPage.css';
import cookie from 'react-cookies';
import styles from './LoginPage.module.scss';

/**
 *
 *
 * @export
 * @class Forgot
 * @extends {Component}
 */
export default class Forgot extends Component {
  /**
   * Creates an instance of Forgot.
   * @param {*} props
   * @memberof Forgot
   */
  constructor(props) {
    super(props);
    this.state = {
      isInvalidEmail: true,
      apiError: false,
      hasMount: false,
    };
  }

  /**
   *
   *
   * @memberof Forgot
   */
  componentDidMount() {
    setTimeout(() => {
      window.dataLayer.push({
        'event': 'view-page-action',
        'view-page-param': {
          'pageKind': 'ForgotPasswordPage',
          'locale': this.props.locale,
        },
      });
    }, 50);
    cookie.save('pageKind', 'ForgotPasswordPage', {
      path: '/',
    });
    this.setState((prevState) => ({
      ...prevState,
      hasMount: true,
    }));
  }

  /**
   * Validate email and set in state
   *
   * @param {*} e
   * @memberof Forgot
   */
  handleEmailInput(e) {
    const email = e.target.value.trim();
    if (email) {
      this.setState({
        enteredEmail: email,
        // isInvalidEmail: !authService.checkEmailValidity(email),
        isInvalidEmail: false,
      });
    } else {
      this.setState({
        isInvalidEmail: true,
      });
    }
  }

  /**
   *  Handles Api call for forgot password
   *
   * @param {*} event
   * @memberof Forgot
   */
  handleForgotPassword = (event) => {
    event.preventDefault();
    this.setState({
      showLoader: true,
    });
    const requestObj = {email: this.state.enteredEmail};
    new apiService.default.Auth()
        .forgotPasswordRequest(requestObj)
        .then((response) => {
          this.setState({
            showLoader: false,
            resetRequested: true,
          });
          setTimeout(() => {
            navigate(`/app/${this.props.locale}/login`);
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
          const err = error?.response?.data?.errors[0];
          this.setState({
            showLoader: false,
            errorMsg: err?.detail,
          });
        });
  }

  /**
   *
   *
   * @return {*}
   * @memberof Forgot
   */
  render() {
    const locale = this.props.locale;
    const successImg = require('../../../static/group-172.png');
    const {hasMount} = this.state;
    return (
      <>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        {hasMount &&

        <Container className={styles.shFormContainer}>
          <Form className={styles.shForm} style={{'minHeight': '500px'}}
            onSubmit={this.handleForgotPassword}>
            <Form.Text className={styles.title}>
                Forgot password?
            </Form.Text>
            <Form.Text className={styles.subText}>
              { this.state.resetRequested ?
                    <p className={styles.emailSentSuccess}>
                      <img src={successImg}/>
                    You should receive an email shortly with instructions&nbsp;
                    on how to reset your password.
                    </p> :
                  <p className={styles.subText}>
                    Enter your email address below and we will send  you an
                     email with a link to choose a new password.
                  </p>
              }
            </Form.Text>
            { this.state.resetRequested ? null :
            <Col className={styles.col}>
              <Form.Group controlId="formBasicEmail"
              >
                <Form.Label className={styles.label}>
                  Email address</Form.Label>
                <Form.Control
                  className={styles.inputStyle}
                  type="email"
                  placeholder={`abc@example.com`}
                  onChange={(event) => this.handleEmailInput(event)}
                />
              </Form.Group>
              {this.state?.errorMsg ?
                <Col className={styles.errorFont}>
                  {this.state?.errorMsg}</Col> : null
              }
              <button className={styles.shBtn}type="submit"
                disabled={this.state.isInvalidEmail}
              >
                Request password reset
              </button>
              { this.state.showLoader ?
              <Col>
                <Spinner
                  animation="border"
                  size="sm"/>
              </Col>: null
              }
              <div className={styles.flexGroup}>
                <Link to={`/app/${locale}/login`}
                  className={styles.shLink}>Back to Login</Link>
                <Form.Text className={styles.memberWord}>
                  Not a member?&nbsp;
                  <Link to="/" className={styles.shLink}>Sign up</Link>
                </Form.Text>
              </div>
            </Col>
            }
          </Form>
        </Container>
        }
      </>
    );
  }
}
Forgot.propTypes = {
  locale: PropTypes.string,
};
