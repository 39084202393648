// This import loads the firebase namespace.
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/firestore';

/**
   *
   *
   * @param {*} data
   * @param {*} watchedMin
   * @return {*} createdFireStoreData
   */
export const createSessionData=async (data, watchedMin)=> {
  data['create_at'] = firebase.firestore.FieldValue.serverTimestamp();
  data['update_at'] = firebase.firestore.FieldValue.serverTimestamp();

  if (Number.isNaN(watchedMin)) {
    watchedMin = 0;
  }
  data['watched'] = watchedMin;
  const createdFireStoreData=
  await firebase.firestore().collection('video_logs')
      .add(data).then((docRef) => {
        sessionStorage.setItem(
            data['slug'] + '_' + data['lessonNumber'] + '_sessionID',
            docRef.id,
        );
        sessionStorage.setItem(
            data['slug'] + '_' + data['lessonNumber'] + '_watched_min',
            watchedMin.toString(),
        );
        return data;
      })
      .catch((err) => {
        return null;
      });
  return createdFireStoreData;
};

/**
*
*
* @param {*} record
* @param {*} id
* @return {*} updatedFireStoreData
*/
export const updateSessionData=async (record, id)=> {
  record['update_at'] = firebase.firestore.FieldValue.serverTimestamp();
  const updatedFireStoreData=await firebase.firestore().
      collection('video_logs').doc(id).update(record)
      .then((docRef) => {
        return 'docRef';
      })
      .catch((err) => {
        return null;
      });
  return updatedFireStoreData;
};

/**
   *
   *
   * @param {*} param
   * @param {*} dataToFireBase
   * @return {*} dataObjectForFirestore
   */
export const setRequiredData=(param, dataToFireBase)=> {
  return {
    'event_name': param,
    'student_id': dataToFireBase?.leadId,
    'courseSlug': dataToFireBase?.courseSlug,
    'registrations_id': dataToFireBase?.regId,
    'moduleRegistrationId': dataToFireBase?.regId,
    'moduleNumber': dataToFireBase?.moduleNumber,
    'in_classroom': false,
    'lessonNumber': dataToFireBase?.lessonNumber,
    'courseSlug': dataToFireBase?.slug,
    'lesson_id': '',
    'os': navigator['oscpu'] || navigator['platform'],
  };
};

