import React from 'react';
import styles from '../../Account/Cancellation/cancellation.module.scss';

/**
 *
 *
 * @export
 * @return {*}
 */
export default function CancellationSkeleton() {
  return (
    <div className={styles.cancellationContainer}>
      <div className={'step-progress col'}>
        <div className={'multi-step'}>
          <div>
            <div className={`${styles.cancellationReasonContainer} ${
              styles.skeletonWrap}`}>
              <div className={styles.offerCard}></div>
              <div className={styles.reasons}>
                <div className={styles.reasonHeading}></div>
                {
                  [1, 2, 3, 4, 5].map((reason, index) => {
                    return (
                      <div key={index}
                        className={styles.optionContainer}>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
