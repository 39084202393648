import cookie from 'react-cookies';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

export const getLeadId = () => {
  return cookie.load('shawacademy_leadId');
};

export const getCoursePicked = () => {
  const coursePicked =
  new URLSearchParams(window.location.search).get('course_slug') ||
  cookie.load('coursePicked');
  return coursePicked;
};

export const getParam = (param) => {
  const value = new URLSearchParams(window.location.search).get(param);
  return value;
};
export const getModule = () => {
  const module = new URLSearchParams(window.location.search).get('module');
  return module;
};

export const buildTimePickerList = () => {
  let hour = 6;
  const hours = [];
  while (hour <= 22) {
    hours.push(dayjs().set('hour', hour).set('minute', 0).format('h:mm A'));
    hour++;
  }
  return hours;
};

export const buildDayPickerList = () => {
  const days = [];
  let dateStart = dayjs().add(1, 'days');
  const dateEnd = dayjs().add(8, 'days');
  while (dateEnd.diff(dateStart, 'days') > 0) {
    days.push(dateStart.format('dddd'));
    dateStart=dateStart.add(1, 'days');
  }
  return days;
};

export const buildLessonsList = (length) => {
  const lessonNumbersList = Array.from({
    length: length,
  }, (_, index) => `Lesson ${index+1}`);
  return lessonNumbersList;
};
