import React, {useState, useEffect, useRef} from 'react';
import {Nav, Tab} from 'react-bootstrap';
import StarterPack from '../Shared2/StarterPack';
import ToolkitPack from '../Shared2/ToolkitPack';
import PropTypes from 'prop-types';
import VideoPlayer from '../Shared2/Popup/VideoPlayer';
import * as trackAction from '../Shared/TrackActionService';
import courseStyles from '../../styles/Courses.module.scss';
/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function ResourcesTabView(props) {
  const [popupMode, setPopupMode] = useState(false);
  const [currentVideoId, setVideoId] = useState(false);
  const [moduleItems, setModuleItems] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const popupRef = useRef(null);
  const {courseData, locale, addonPrices, studentInfo} = props;
  // const regId=courseData?.id;
  const courseSlug=courseData?.attributes.localizedcourseslug;
  const modules=courseData?.relationships?.modules?.data;
  const isEqfCourse=modules?.length===4;


  // const setPoints = (points) =>{
  //   setXpPoints(points);
  // };

  const triggerMobilePopup = (e) => {
    e.stopPropagation();
    if (window.innerWidth <= 1024) {
      setPopupMode(!popupMode);
    }
  };

  const handleClickOutside = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setPopupMode(false);
    }
  };

  const closeVideoPopup = () => {
    setVideoId(null);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  /**
   *
   *
   * @param {*} index
   * @param {*} data
   */
  const openToolkitItems = (index, data) => {
    setActiveIndex(index + 1);
    setModuleItems(data);
  };

  const setPopup = (vimeoId) => {
    setPopupMode(false);
    trackAction.lessonClickAction({
      lessonType: 'Bonus Lessons',
    });
    setVideoId(vimeoId);
  };

  return (
    <>
      <div className={courseStyles.shawResources}>
        <Tab.Container defaultActiveKey="starter-pack">
          <Nav variant="pills"
            className={courseStyles.resourceSwitcher}
            onClick={triggerMobilePopup}
            as="ul">
            <Nav.Item as="li">
              <Nav.Link
                className={courseStyles.resourceTrigger}
                eventKey="starter-pack"
              >
                <div className={courseStyles.resourceTriggerContent}>
                  <p className={`${courseStyles.resourceThumbnail}
                  ${courseStyles.starterPackIcon}`}>
                    <img src="/dashboard-new/resources/starter-pack-icon.svg"/>
                  </p>
                  <p className={courseStyles.resourceName}>
                    Starter Pack
                  </p>
                </div>
                <p className={courseStyles.freeText}>
                  Free
                </p>
              </Nav.Link>
            </Nav.Item>
            {modules?.length && modules.map((item, i) => (
              <Nav.Item as="li" key={i}
                onClick={() => openToolkitItems(i, item)}>
                <Nav.Link className={courseStyles.resourceTrigger}
                  eventKey={`module-${i+1}-toolkit`}>
                  <div className={courseStyles.resourceTriggerContent}>
                    <div className={`${courseStyles.resourceThumbnail
                    } library-shaw-icon`}>
                      <i className={`${item.toolkitPurchased?
                        'icon-folder':'icon-lock-filled'}`}></i>
                    </div>
                    <div className={courseStyles.resourceName}>
                      <p className={courseStyles.moduleNumber}>
                        {`Module ${i+1}`}
                      </p>
                      <p className={courseStyles.courseMaterialsText}>
                        Course Materials
                      </p>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
            ))
            }
          </Nav>
          <Tab.Content
            ref={popupRef}
            className={`${courseStyles.resourcesWrapper} ${popupMode ?
            courseStyles.popupMode : ''}`}>
            <Tab.Pane eventKey="starter-pack">
              <StarterPack
                closePopup={triggerMobilePopup}
                setVideoId={setPopup}
                courseData={courseData?.attributes}
                courseSlug={courseSlug}
                locale={locale}
                activeIndex={activeIndex}
                studentInfo={studentInfo}
                pageKind={'ResourcesTab'}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={`module-1-toolkit`}>
              <ToolkitPack
                registrationId={moduleItems?.attributes?.registration_id}
                locale={locale}
                setVideoId={setPopup}
                closePopup={triggerMobilePopup}
                courseSlug={courseSlug}
                isEqfCourse={isEqfCourse}
                pageKind={'ResourcesPage'}
                cta={'Get your Toolkit - toolkitComponent'}
                addonPrices={addonPrices?.toolkit}
                activeIndex={activeIndex}
                activeModuleItems={moduleItems}
                oneClickText={`one click purchase`}
                lessonsData={modules?.[0]?.attributes?.lessons}
                lessonResourcesText={`Lesson Resources`}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={`module-2-toolkit`}>
              <ToolkitPack
                registrationId={moduleItems?.attributes?.registration_id}
                locale={locale}
                setVideoId={setPopup}
                closePopup={triggerMobilePopup}
                courseSlug={courseSlug}
                isEqfCourse={isEqfCourse}
                pageKind={'ResourcesPage'}
                cta={'Get your Toolkit - toolkitComponent'}
                addonPrices={addonPrices?.toolkit}
                activeIndex={activeIndex}
                activeModuleItems={moduleItems}
                oneClickText={`one click purchase`}
                lessonsData={modules?.[1]?.attributes?.lessons}
                lessonResourcesText={`Lesson Resources`}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={`module-3-toolkit`}>
              <ToolkitPack
                registrationId={moduleItems?.attributes?.registration_id}
                locale={locale}
                setVideoId={setPopup}
                closePopup={triggerMobilePopup}
                courseSlug={courseSlug}
                isEqfCourse={isEqfCourse}
                pageKind={'ResourcesPage'}
                cta={'Get your Toolkit - toolkitComponent'}
                addonPrices={addonPrices?.toolkit}
                activeIndex={activeIndex}
                activeModuleItems={moduleItems}
                oneClickText={`one click purchase`}
                lessonsData={modules?.[2]?.attributes?.lessons}
                lessonResourcesText={`Lesson Resources`}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={`module-4-toolkit`}>
              <ToolkitPack
                registrationId={moduleItems?.attributes?.registration_id}
                locale={locale}
                setVideoId={setPopup}
                pageKind={'ResourcesPage'}
                cta={'Get your Toolkit - toolkitComponent'}
                closePopup={triggerMobilePopup}
                isEqfCourse={isEqfCourse}
                courseSlug={courseSlug}
                addonPrices={addonPrices?.toolkit}
                activeIndex={activeIndex}
                activeModuleItems={moduleItems}
                oneClickText={`one click purchase`}
                lessonsData={modules?.[3]?.attributes?.lessons}
                lessonResourcesText={`Lesson Resources`}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <div
          className={`${courseStyles.shawModalBackdrop} ${popupMode ?
           courseStyles.active: ''}`}></div>
        {!!currentVideoId &&
      <VideoPlayer
        closePopup={closeVideoPopup}
        vimeoId={currentVideoId} />
        }
      </div>
    </>
  );
}

ResourcesTabView.propTypes = {
  courseData: PropTypes.object,
  addonPrices: PropTypes.object,
  locale: PropTypes.string,
  studentInfo: PropTypes.object,
};

export default ResourcesTabView;
