import React, {useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import cookie from 'react-cookies';
import styles from '../LeaderboardBanner.module.scss';
// import Avatar from '../../../pages/static/Avatar.png';
import PropTypes from 'prop-types';
import {ctaClickAction} from '../../Shared/TrackActionService';

const LeaderboardBanner = ({membershipInfo, locale, toastList,
  changeTabAction, scrollToUpcomingLesson}) => {
  const studentId=cookie.load('shawacademy_leadId');
  const [userData, setUserData] = useState({
    imgSrc: '',
    lead_id: studentId,
    name: {
      'first_name': 'Student',
      'last_name': '.',
    },
  });

  useEffect(()=>{
    loadDependencies();
  }, [toastList]);

  const loadDependencies = () => {
    const userStatus=createUserData(studentId);
    setUserData(userStatus);
    // const {db}=require('../../Shared2/Firebase');
    // const country=getCountry();
    // const loggedUserResponse=
    // db.collection(`lifetime_${country}`) // without limit
    //     .where('lead_id', '==', studentId);
    // loggedUserResponse.get().then((snapshot) => {
    //   snapshot.docs.map((doc) => {
    //     if (doc.exists) {
    //       const userStatus=createUserData(doc.data(), 0);
    //       setUserData(userStatus);
    //     }
    //   });
    // });
  };

  const createUserData = (studentId) => {
    const dataToSend = {
      imgSrc: `https://shaw-profile-pic.s3.amazonaws.com/${studentId}.jpeg`,
    };
    return dataToSend;
  };

  const navigateToAccountPage = () => {
    triggerCtaClickEvent('Upload Your Picture', 'navigateToProfilePage');
    navigate(`/app/${locale}/account`);
  };

  const navigateToResourceTab = () => {
    changeTabAction('resources', 'none');
    triggerCtaClickEvent('Get Study Material', 'navigateToResourceTab');
  };

  const handleStoreClick = (store) => {
    if (store === 'Android') {
      window.open(`https://play.google.com/store/apps/details?id=com.shawacademy.app&referrer=utm_source%3Dmembers-area%26utm_medium%3Dbanner%26utm_content%3D%26utm_campaign%3Drecording-page`
          , 'blank');
    } else if (store === 'Iphone') {
      window.open(`https://itunes.apple.com/app/apple-store/id1240131410?pt=118508823&ct=popUpBanner&mt=8`, 'blank');
    }
    triggerCtaClickEvent('Download the app', `navigateTo${store}Store`);
  };

  const triggerCtaClickEvent = (cta, action) => {
    ctaClickAction({
      studentId,
      cta,
      action,
      'pageKind': 'LessonsPage',
      locale,
    });
  };

  return (
    <div className={styles.profileBannerConatiner}>
      <div className={styles.profileTopSection}>
        {/* <img
          src={userData.imgSrc}
          onError={(e) => e.target.src=Avatar} // recheck
          alt='user'
          width="64px"
          height="64px"
          className={styles.userIcon}
        /> */}
        <div className={styles.userInfoSection}>
          <h1 className={styles.userNameInfo}>
            Welcome {membershipInfo?.name ?
            membershipInfo?.name?.first_name : userData?.name?.['first_name']}
          </h1>
          {/* <p className={styles.userXPInfo}>
            {bannerText.getValue('TOTAL_SCORE')}{' '}
            {userData.score?.toLocaleString()}{' '}
            {bannerText.getValue('XP_TEXT')}</p> */}
        </div>
      </div>
      <div className={styles.profileBottomSection}>
        <div onClick={scrollToUpcomingLesson} className={styles.linkCard}>
          <div className={styles.ctaLinks}>
            <img src="/images/leaderboard/play_icon_lb.png"/>
            <p>Watch More lessons</p>
          </div>
        </div>
        <div onClick={navigateToResourceTab}
          className={`${styles.linkCard} ${
            styles.studySection}`}>
          <div className={styles.ctaLinks}>
            <img src="/images/leaderboard/course_material.png"/>
            <p>Get Study Material</p>
          </div>
        </div>
        <div className={`${styles.linkCard} ${
          styles.downloadSection}`}>
          <div className={styles.ctaLinks}>
            <img src="/images/leaderboard/download_icon.png"/>
            <p>Download the app</p>
          </div>
          <div className={styles.storeImg}>
            <div onClick={()=> handleStoreClick('Iphone')}>
              <img src="/images/leaderboard/app_store.png" />
            </div>
            <div onClick={()=> handleStoreClick('Android')}>
              <img src="/images/leaderboard/play_store.png"/>
            </div>
          </div>
        </div>
        <div className={`${styles.linkCard} ${
          styles.uploadSection}`}>
          <div onClick={navigateToAccountPage}
            className={styles.ctaLinks}>
            <img src="/images/leaderboard/photo_upload.png"/>
            <p>Upload your picture</p>
          </div>
          <div className={styles.storeText}>
            <p>Maximum file size - 1 MB</p>
          </div>
        </div>
      </div>
    </div>
  );
};

LeaderboardBanner.propTypes = {
  membershipInfo: PropTypes.object,
  locale: PropTypes.string,
  toastList: PropTypes.array,
  changeTabAction: PropTypes.func,
  scrollToUpcomingLesson: PropTypes.func,
};

export default LeaderboardBanner;

