import React from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import courseStyles from '../../../styles/Courses.module.scss';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function UnlockModuleBanner(props) {
  const {triggerUnlockModulePopup}=props;
  return (
    <div className={courseStyles.unlockModuleBanner}>
      <div className={courseStyles.unlockModuleBg}>
        <img src='/dashboard-new/courses/unlock-module-bg.svg'
          alt='unlock-module'/>
      </div>
      <div className={courseStyles.unlockModuleBannerContent}>
        <div className={courseStyles.bannerContent}>
          <p className={courseStyles.bannerHeading}>
            Unlock module 1
          </p>
          <p className={courseStyles.bannerInfo}>
            Immediate access to all the below classes
          </p>
        </div>
        <Button className={courseStyles.bannerCta}
          onClick={triggerUnlockModulePopup}
        >
          Unlock
        </Button>
      </div>
    </div>
  );
}

UnlockModuleBanner.propTypes = {
  triggerUnlockModulePopup: PropTypes.func,
};

export default UnlockModuleBanner;
