/**
 *
 *
 * @class AssetsService
 */
class AssetsService {
  /**
   * Creates an instance of AssetsService.
   * @param {*} assetType
   * @memberof AssetsService
   */
  constructor(assetType) {
    this.assetType=assetType;
    // const domain = process.env.ShawWebsiteDomain;
    const assetPathTypes = {
      'MY_COURSES': 'mycourse',
      'RECORDINGS': 'recordings',
      'WEBSITE_WIDE': 'website/wide',
      'WEBSITE_SQUARE': 'website/square',
    };
    this.absPath =
    `/assets/course-cards/${assetPathTypes[this.assetType]}`;
  }

  static DUMMY_IMG = '/images/transparent.svg';

  /**
   *
   *
   * @param {*} courseSlug
   * @return {*}
   * @memberof AssetsService
   */
  fetchAssetURL(courseSlug) {
    const assetURL = `${this.absPath}/${courseSlug}.png`;
    return assetURL;
  }
}

export default AssetsService;
