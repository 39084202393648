import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as typeformEmbed from '@typeform/embed';
import * as trackAction from '../Shared/TrackActionService';
import styles from '../../styles/Courses.module.scss';
import slugData from '../Shared2/Leaderboard/TrackActivity.json';
import * as trackActivityService from
  '../Shared2/Leaderboard/TrackActivityService';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function AssessmentTabItem(props) {
  const {assignmentIndex, assignment, moduleData, moduleAssignment,
    regId, studentId, setXpObject, isActiveMember} = props;
  const [assignmemntDisabled, setAssignmemntDisabled]=useState(true);

  const result=moduleAssignment?.result;
  let title;
  if (assignment?.modular_blocks?.[0]?.typeform) {
    title = assignment?.modular_blocks?.[0]?.typeform?.title;
  } else {
    title = moduleAssignment?.typeform?.title||
    moduleAssignment?.link?.link?.title;
  }

  useEffect(() => {
    const lessons=moduleData?.attributes.lessons.length?
    moduleData?.attributes.lessons : [];
    getAssignmentStatus(assignmentIndex, lessons);
  }, []);

  const openAssignment = () => {
    const {courseSlug, locale, moduleNumber,
      courseName, studentInfo} = props;
    const firstName = studentInfo?.name?.first_name;
    const lastName = studentInfo?.name?.last_name;
    const userName = `${firstName} ${lastName}`;

    let link;
    if (assignment?.modular_blocks?.[0]?.typeform ||
      moduleAssignment?.typeform) {
      link = assignment?.modular_blocks?.[0]?.typeform?.formid ||
      moduleAssignment?.typeform?.formid;
      trackAction.assignmentClickAction({
        'action': 'Start Now',
        studentId,
        courseSlug,
        moduleNumber,
        'facultySlug': moduleData?.attributes.facultyslug,
        'assessmentType': 'Weekly',
        'assessmentId': link,
        locale,
        'assessmentNumber': assignmentIndex,
      });
      const reference = typeformEmbed.makePopup(
          // shawacademy.typeform.com
          `https://form.typeform.com/to/${link}#student_id=${studentId}&course_slug=${encodeURIComponent(courseSlug)}&course_name=${encodeURIComponent(courseName)}&module_slug=${encodeURIComponent(moduleData.attributes?.moduleslug)}&module_name=${encodeURIComponent(moduleData?.attributes?.modulename)}&module_number=${moduleNumber}&course_regid=${regId}&user_name=${userName}&locale=${locale}&sub_brand=${process.env.BrandSlug}&stage=${process.env.AppicationEnv}&assessment_type=weekly&faculty_slug=${moduleData?.attributes.facultyslug}&assessment_number=${assignmentIndex}`,
          {
            hideHeaders: true,
            hideFooters: true,
            autoClose: 0.1,
            onSubmit: function(event) {
              setTimeout(() => {
                const assessmentResponseId=
                assignment?.modular_blocks?.[0]?.typeform?.
                    response?.token;
                trackAction.assignmentCompleteAction({
                  studentId,
                  courseSlug,
                  moduleNumber,
                  'facultySlug': moduleData?.attributes.facultyslug,
                  'assessmentType': 'Weekly',
                  'assessmentId': link,
                  locale,
                  'assessmentNumber': assignmentIndex,
                  assessmentResponseId,
                });
              }, 2000);
              // Get Xp on submit of weekly assignment
              const getXPObject ={
                slug: slugData.completeWeeklyAssignmentSlug,
                additionalParams: {
                  localizedcourseslug: courseSlug,
                  modulenumber: moduleNumber,
                  typeformid: link,
                },
                name: {
                  first_name: firstName,
                  last_name: lastName,
                },
              };
              if (isActiveMember) {
                trackActivityService.SetXp(studentId, getXPObject,
                    locale, 'CourseRegistrationDetailPage').
                    then((response)=>{
                      if (response > 0) {
                        setXpObject({
                          xpObject: {
                            xpPoints: response,
                            action: slugData.completeWeeklyAssignmentSlug,
                          }});
                      }
                    })
                    .catch((err)=>{
                      console.log(err);
                    });
              }
            },
          },
      );
      reference.open();
    } else {
      link = assignment?.link?.link?.href;
      window.open(link);
    }
  };

  /**
   *
   *
   * @param {*} index
   * @param {*} lessons
   */
  const getAssignmentStatus = (index, lessons) => {
    // TODO: move this to parent level
    if (lessons && lessons.length) {
      for (let i = 0; i < lessons.length; i++) {
        if (i === (2 * (index-1)) &&
          (new Date(lessons[i].end_time).getTime() <= new Date().getTime())) {
          setAssignmemntDisabled(false);
        }
      }
    }
  };

  return (
    <Card className={`${styles.singleAssessment} ${
        assignmemntDisabled?styles.disabled:''}`}>
      <div className={`${styles.AssesmentProgressBar}
       ${assignment?.result?.percentile||
              result?.percentile === 100?
               styles.AssesmentProgressBorder : ''}`}
      style={{width: `${assignment?.result?.percentile||
        result?.percentile}%`}}>
      </div>
      <Card.Body
        className={styles.cardBody}>
        <div className={styles.assessmentContentWrapper}>
          <div className={styles.assessmentLeftSection}>
            <div className={`${styles.assessmentInfoIcon
            } library-shaw-icon`}>
              <i className={`${assignment?.result||
              result? 'icon-edit-filled' :
              assignmemntDisabled?'icon-unlocked':'icon-document-filled'}`}>
              </i>
            </div>
            <p className={styles.assessmentName}>
              {title}
            </p>
          </div>
          <div className={styles.assessmentInfoWrapper}>
            {assignment?.result||result?
            <p className={styles.assessmentScore}>
              <span>Score</span>
              <span>{assignment?.result?.percentile||
              result?.percentile}%</span>
            </p>:<p className={styles.questionsInfo}>&nbsp;</p>}
          </div>
        </div>
        <div className={styles.assessmentActionWrapper}>
          {assignment?.result||result?
          <div className={styles.retakeAssessmentCta}
            onClick={openAssignment}>
            Retake
          </div>:<div className={styles.startAssessmentCta}
            onClick={openAssignment}>
            Start now
          </div>}
        </div>
      </Card.Body>
    </Card>
  );
}


AssessmentTabItem.propTypes = {
  locale: PropTypes.string,
  assignment: PropTypes.object,
  studentInfo: PropTypes.object,
  moduleData: PropTypes.object,
  courseName: PropTypes.string,
  assignmentIndex: PropTypes.number,
  moduleNumber: PropTypes.number,
  courseSlug: PropTypes.string,
  regId: PropTypes.string,
  assignmentData: PropTypes.array,
  studentId: PropTypes.string,
  setXpObject: PropTypes.func,
  isActiveMember: PropTypes.bool,
  moduleAssignment: PropTypes.object,
};

export default AssessmentTabItem;
