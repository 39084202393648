import React, {useState, useEffect} from 'react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import * as trackAction from '../../Shared/TrackActionService';
import styles from './account.module.scss';
import {Spinner} from 'react-bootstrap';
import {
  addMemberService,
  listFamilyMembers,
  listLeadAndRegistration,
  removeMemberService,
} from './FamilyService';

const FamilyTabView = ({studentInfoObj,
  subscriptionId, locale}) => {
  const [memberData, setMemberData]=useState([
    {name: '', email: '', id: '', status: ''},
    {name: '', email: '', id: '', status: ''},
    {name: '', email: '', id: '', status: ''},
  ]);
  const [showPopup, setShowPopup]=useState(false);
  const [isAddMemberState, setIsAddMemberState]=
  useState([false, false, false]);
  const [memberAdded, setMemberAdded]=useState([false, false, false]);
  const [showRemoveMemberSpinner, setShowRemoveMemberSpinner]=
  useState([false, false, false]);
  const [showAddMemberSpinner, setShowAddMemberSpinner]=
  useState([false, false, false]);
  const [showWarningMessage, setShowWarningMessage]=useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const studentId=cookie.load('shawacademy_leadId');

  useEffect(() => {
    if (studentInfoObj) {
      populateAddedMembers();
    }
  }, [studentInfoObj]);

  useEffect(() => {
    trackAction.pageViewAction('SharedLearning', locale);
  }, []);

  const populateAddedMembers = async () => {
    const membersData = await listFamilyMembers(subscriptionId, studentId);
    if (membersData && membersData?.length) {
      membersData?.forEach(async (member, index) => {
        const tempMemberObj={};
        tempMemberObj.id=member.student_id;
        tempMemberObj.status=member.status;

        const attributes = await listLeadAndRegistration(member);
        if (attributes && !attributes?.code) {
          tempMemberObj.email = attributes?.email;
          tempMemberObj.name= attributes?.name?.first_name +
          ' ' + attributes?.name?.last_name;
          const tempMemberData=memberData;
          tempMemberData[index]= tempMemberObj;
          setMemberData([...tempMemberData]);
          tempMemberData.forEach((member, index) => {
            if (member.email !== '') {
              const tempIsAddMemberState=isAddMemberState;
              tempIsAddMemberState[index]=false;
              const tempMemberAdded=memberAdded;
              tempMemberAdded[index]=true;
              setIsAddMemberState([...tempIsAddMemberState]);
              setMemberAdded([...tempMemberAdded]);
            } else {
              const tempMemberAdded=memberAdded;
              tempMemberAdded[index]=false;
              setMemberAdded([...tempMemberAdded]);
            }
          });
        }
      });
    }
  };

  const removeMember = async () => {
    if (memberData.length>0) {
      const memberId = memberData[selectedIndex]?.id;
      const tempLoadingSpinner=showRemoveMemberSpinner;
      tempLoadingSpinner[selectedIndex]=true;
      setShowRemoveMemberSpinner([...tempLoadingSpinner]);
      const isMemberRemoved =
        await removeMemberService(subscriptionId, memberId, studentId);
      if (isMemberRemoved) {
        const tempMemberData=memberData;
        tempMemberData[selectedIndex]={
          name: '', email: '', id: '', status: ''};
        setMemberData([...tempMemberData]);
        const tempMemberAdded=memberAdded;
        tempMemberAdded[selectedIndex]=false;
        setMemberAdded([...tempMemberAdded]);
        tempLoadingSpinner[selectedIndex]=false;
        setShowRemoveMemberSpinner([...tempLoadingSpinner]);
        setShowPopup(false);
        trackAction.ctaClickAction({
          studentId,
          'cta': 'Remove',
          'pageKind': 'SharedLearning',
          'action': 'removeChild',
        });
      } else {
        setShowPopup(false);
      }
    }
  };

  const addMember = async (index) => {
    let [firstName, ...lastName] = memberData[index].name?.split(' ');
    if (memberData[index].name === '') {
      setShowWarningMessage('Please enter the member\'s name');
      setTimeout(() => {
        setShowWarningMessage('');
      }, 5000);
      return;
    }
    lastName = lastName.join(' ');
    const tempLoadingSpinner=showAddMemberSpinner;
    tempLoadingSpinner[index]=true;
    setShowAddMemberSpinner([...tempLoadingSpinner]);
    const addMemberData = await addMemberService(firstName, lastName,
        memberData[index].email, subscriptionId, studentId);
    try {
      if (addMemberData && !addMemberData?.errors) {
        tempLoadingSpinner[index]=false;
        setShowAddMemberSpinner([...tempLoadingSpinner]);
        if (addMemberData['status'] === '400') throw new Error();
        const tempIsAddMemberState=isAddMemberState;
        tempIsAddMemberState[index]=false;
        setIsAddMemberState([...tempIsAddMemberState]);
        tempLoadingSpinner[index]=false;
        setShowAddMemberSpinner([...tempLoadingSpinner]);
        const tempMemberAdded=memberAdded;
        tempMemberAdded[index]=true;
        setMemberAdded([...tempMemberAdded]);
        const tempMemberData=memberData;
        tempMemberData[index].status='pending';
        const members = addMemberData['data']['meta_data'].members;
        tempMemberData[index].id = addMemberData['data']['meta_data'].
            members[members.length - 1].lead_id;
        setMemberData([...tempMemberData]);
        trackAction.ctaClickAction({
          studentId,
          'cta': 'Add Member',
          'pageKind': 'SharedLearning',
          'action': 'inviteChild',
        });
      } else {
        const tempMemberData=memberData;
        tempMemberData[index]={
          name: '', email: '', status: ''};
        if (addMemberData?.errors?.[0]) {
          const errorMessage = addMemberData?.errors?.[0]?.detail?.split(':');
          setShowWarningMessage(errorMessage?.[1]);
        } else {
          setShowWarningMessage('Member is already present');
        }
        setTimeout(() => {
          setShowWarningMessage('');
        }, 5000);
        tempLoadingSpinner[index]=false;
        setShowAddMemberSpinner([...tempLoadingSpinner]);
        const tempIsAddMemberState=isAddMemberState;
        tempIsAddMemberState[index]=false;
        setIsAddMemberState([...tempIsAddMemberState]);
        const tempMemberAdded=memberAdded;
        tempMemberAdded[index]=false;
        setMemberAdded([...tempMemberAdded]);
        trackAction.ctaClickAction({
          studentId,
          'cta': 'Add Member',
          'pageKind': 'SharedLearning',
          'action': 'inviteFailed',
        });
      };
    } catch (err) {
      console.log('Error ', err);
      tempLoadingSpinner[index]=false;
      setShowAddMemberSpinner([...tempLoadingSpinner]);
    }
  };

  const handleInput = (e, memberIndex) => {
    const tempEventData = e.target.value;
    const tempMemberData=memberData;
    tempMemberData[memberIndex]={
      ...memberData[memberIndex],
      [e.target.name]: tempEventData,
    };
    setMemberData([...tempMemberData]);
  };

  const onUserInput=(index)=> {
    const tempMemberData=memberData;
    const tempIsAddMemberState=isAddMemberState;
    const regex = new RegExp(
        '^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\\.([a-zA-Z]{2,5})$',
    );
    const result = regex.test(tempMemberData[index].email);
    tempIsAddMemberState[index]=result ? true : false;
    setIsAddMemberState([...tempIsAddMemberState]);
  };

  const showRemoveMemberPopup = (index) => {
    setShowPopup(true);
    setSelectedIndex(index);
    trackAction.popUpAction({
      studentId,
      'title': 'RemoveMember',
      'pageKind': '',
    });
  };

  const familySharingStatusValues = {
    'ACTIVE': 'Active',
    'PENDING': 'Pending',
  };

  return (
    <div className={styles.familyTabWrapper}>
      <div>
        <h1 className={styles.header}>
          Family Sharing
        </h1>
        <p className={styles.description}>
          you can add up to 3 friends/family members to learn for free
        </p>
        <div >
          {memberData.map((member, index)=>(
            <div className={styles.contactWrapper} key={index}>
              <div className={styles.memberNameContainer}>
                <div className={styles.inputName}>
                  <input className={styles.nameText}
                    type='text'
                    value={member.name}
                    name='name'
                    onChange={(e)=>handleInput(e, index)}
                    onKeyUp={()=>onUserInput(index)}
                    disabled={memberAdded[index]}
                    placeholder={`Enter name here`}
                  ></input>
                </div>
                <div key={index} className={styles.email}>
                  <input className={styles.emailText}
                    type='email'
                    value={member.email}
                    name='email'
                    onChange={(e)=>handleInput(e, index)}
                    onKeyUp={()=>onUserInput(index)}
                    disabled={memberAdded[index]}
                    placeholder={`Enter email here`}
                  ></input>
                  <span
                    style={{color: member.status==='Active'?
                      '#27ae60':'#c4d2d9'}}
                    className={styles.status}>
                    {familySharingStatusValues[member?.status.toUpperCase()]}
                  </span>
                </div>
              </div>
              {memberData[index].status==='' ?
              <>
                <button
                  className={`${styles.addUser}
                  ${isAddMemberState[index]?'':styles.disabled}`}
                  onClick={()=>addMember(index)}>
                   +
                </button>
                {showAddMemberSpinner[index] &&
                <Spinner id={styles.addSpinner}
                  animation="border" role="status">
                </Spinner>}
              </> :
                <>
                  <button className={`${styles.icon}
                  ${memberAdded[index]?'':styles.disabled}`}
                  onClick={()=>showRemoveMemberPopup(index)}>
                 -
                  </button>
                </>
              }
            </div>
          ))
          }
        </div>
        <p className={styles.terms}>
          They will lose access to all the skills, courses, and certificates.
        </p>
        {showWarningMessage &&
          <p className={styles.warningMessage}>{showWarningMessage}</p>
        }
      </div>
      {showPopup &&
        <div className={styles.removeMemberPopup}>
          <div className={styles.modalBackdrop}></div>
          <div className={styles.flexContainer}>
            <div
              className={`${styles.popupCloseIcon} library-shaw-icon-new`}
              onClick={()=>setShowPopup(false)}
            >
              <i className='icon-new-Cross-small'></i>
            </div>
            <h1>
              Remove Member
            </h1>
            <p className={styles.removeSubText}>
              They will lose access to all the skills, courses,
              and certificates.
            </p>
            <p className={styles.removeMemberText}>
              Are you sure you want to remove,
              <br></br>
              <span className={styles.removableEmail}>
                {memberData[selectedIndex]?.email}</span>
            </p>
            <div className={styles.ctaFlex}>
              <button
                className={styles.removeCta}
                onClick={()=>removeMember()}
              >
                Remove
              </button>
              <button
                className={styles.cancelCta}
                onClick={()=>setShowPopup(false)}
              >
                Cancel
              </button>
              {showRemoveMemberSpinner[selectedIndex] &&
              <Spinner id={styles.removeMemberSpinner}
                animation="border" role="status">
              </Spinner>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
};

FamilyTabView.propTypes = {
  studentInfoObj: PropTypes.object,
  locale: PropTypes.string,
  subscriptionId: PropTypes.string,
};

export default FamilyTabView;
