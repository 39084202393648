import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from '../RescheduleLessonPopup.module.scss';
import {Spinner} from 'react-bootstrap';
import * as popUpService from './PopUpService';
import * as apiService from '../../../api';
import * as trackAction from '../../Shared/TrackActionService';
import dayjs from 'dayjs';

/**
 *
 *
 * @param {*} { data, locale, activeModuleData, studentId,
 *   closePopupModal, newTimetableEligibility}
 * @return {*}
 */
function RescheduleLessonPopup({locale, activeModuleData, studentId,
  closePopupModal, newTimetableEligibility}) {
  const [flowFailed, setFlowFailed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    trackAction.popUpAction({
      studentId,
      pageKind: 'LessonsPage',
      title: 'Reschedule Now',
      locale,
      courseSlug: activeModuleData?.attributes?.moduleslug,
      moduleNumber: 1,
      variation: 'Default',
    });
  }, []);

  const rescheduleLesson = () => {
    setShowLoader(true);
    trackAction.ctaClickAction({
      cta: 'Watch My 1st Class Now',
      action: 'InitiateRescheduleNow',
      locale,
      pageKind: 'LessonsPage',
      courseSlug: activeModuleData?.attributes?.moduleslug,
      moduleNumber: 1,
      studentId,
    });
    const lessonsArray = popUpService.getLessonsArray(activeModuleData);
    const objToSend =
    popUpService.getObjToSend(lessonsArray, activeModuleData, studentId);
    const regId = activeModuleData?.attributes?.registration_id;
    const webinarDomain = process.env.WebinarDomain;
    const url = `${webinarDomain}/en/webinar?registration_id=${
      regId}&lead_id=${studentId}`;
    new apiService.default.Schedule(regId).update(objToSend)
        .then((response) => {
          const result = response.data.data;
          if (result) {
            trackAction.registrationAction({
              'moduleNumber': 1,
              'courseSlug': result.attributes.course_slug,
              'facultySlug': result.attributes.course.faculty,
              'lessonNumber': 1,
              'locale': locale,
              'rescheduleType': 'PlayNow',
              'Goal': activeModuleData?.attributes?.lessons_per_week,
            });
            setTimeout(() => {
              window.location.href = url;
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          setShowLoader(false);
          setFlowFailed(true);
        });
  };

  return (
    <div className={`${styles.rescheduleLessonPopup} ${
      newTimetableEligibility ? styles.updatedStyle : ''}`}>
      <div className={styles.modalBackdrop}></div>
      <div className={styles.rescheduleLessonPopupInner}>
        <div
          onClick={closePopupModal}
          className={`${styles.close} library-shaw-icon-new`}>
          <i className="icon-new-close"></i>
        </div>
        <section className={styles.heading}>
          {!newTimetableEligibility ?
          <h4>Your First Lesson is scheduled for&nbsp;
            <span>{dayjs(activeModuleData?.attributes?.lessons[0]['start_time'])
                .format('MMM DD')} @&nbsp;
            {dayjs(activeModuleData?.attributes?.lessons[0]['start_time'])
                .format('hh A')}</span>
          </h4> :
          <h4>Watch your first class now</h4>}
        </section>
        <section className={styles.bodySection}>
          <div className={styles.leftSection}>
            {!newTimetableEligibility && <div className={styles.title}>
              <img
                src="/dashboard-new/courses/reschedule-popup-icon-1.png"
                alt="icon"/>
              <h4>Start Immediately</h4>
            </div>}
            {!newTimetableEligibility ?
            <p className={styles.info}>
              Would you prefer to watch your lesson right now?
            </p> :
            <p className={styles.info}>
              You have set a goal of{' '}
              {activeModuleData?.attributes?.lessons_per_week}{' '}
              classes a week.<br/>
              Why not get started and watch your first class now!
            </p>}
            <button className={showLoader ? styles.disable : styles.cta}
              onClick={rescheduleLesson}>
              Watch My 1st Class Now
            </button>
            <p className={styles.subText}>
              Watch time ~30 mins
            </p>
            {showLoader && <div className={styles.loader}>
              <Spinner animation="border" />
            </div>}
            <p className={flowFailed ? styles.errorMessage : styles.hide}>
              Something went wrong!! Please try later.
            </p>
          </div>
          {!newTimetableEligibility &&
          <div className={styles.rightSection}>
            <div className={styles.title}>
              <img
                src="/dashboard-new/courses/reschedule-popup-icon-2.png"
                alt="icon"/>
              <h4>Stick to schedule</h4>
            </div>
            <p className={styles.info}>
              If you are happy with your schedule with lesson 1
              starting on&nbsp;
              {dayjs(activeModuleData?.attributes?.lessons[0]['start_time'])
                  .format('MMMM DD YYYY')}
            </p>
            <button className={styles.cta} onClick={closePopupModal}>
              Continue as scheduled
            </button>
          </div>}
        </section>
      </div>
    </div>
  );
}

RescheduleLessonPopup.propTypes = {
  locale: PropTypes.string,
  activeModuleData: PropTypes.object,
  studentId: PropTypes.string,
  closePopupModal: PropTypes.func,
  newTimetableEligibility: PropTypes.bool,
};

export default RescheduleLessonPopup;
