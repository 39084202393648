import React from 'react';
import browseStyles from '../../Browse/browse.module.scss';

/**
 *
 *
 * @export
 * @return {*}
 */
export default function BrowseSkeleton() {
  return (
    <div className={`${browseStyles.browsePageContainer} ${
      browseStyles.browsePageSkeleton}`}>
      <div className={browseStyles.courseContainer}>
        <div className={browseStyles.course}></div>
        <div className={browseStyles.course}></div>
        <div className={browseStyles.course}></div>
        <div className={browseStyles.course}></div>
      </div>
      <div className={browseStyles.courseBottom}></div>
      <div className={browseStyles.courseContainer}>
        <div className={browseStyles.course}></div>
        <div className={browseStyles.course}></div>
        <div className={browseStyles.course}></div>
        <div className={browseStyles.course}></div>
      </div>
    </div>
  );
}
