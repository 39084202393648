import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';
import htmr from 'htmr';
import {popUpAction} from '../Shared/TrackActionService';

const Popup=({closeToggle, navigateToReschedule, selectedLessonData,
  navigateToWebinar, enableJoinCta, triggerWatchNow,
  activeIndex, disableCta, newTimetableEligibility}) => {
  useEffect(() => {
    if (selectedLessonData?.regId) {
      popUpAction({
        'title': 'lessonStatusPopup',
        'pageKind': 'TimeTablePage',
        'name': selectedLessonData?.lesson,
        'number': selectedLessonData?.number,
      });
    }
  }, [selectedLessonData?.regId]);

  return (
    <div>
      <div className={style.popupContainer}>
        <div className={`${style.closeIcon} library-shaw-icon-new`}
          onClick={closeToggle}>
          <i className='icon-new-Cross-thin'></i>
        </div>
        <div>
          <img className={style.image} src={selectedLessonData?.image}
            alt={selectedLessonData?.moduleName}/>
        </div>
        <div>
          <div className={style.contentWrapper}>
            <div className={style.content}>
              <p className={style.title}>
                {selectedLessonData?.number}. {selectedLessonData?.lesson}.</p>
              <p className={style.lesson}>{selectedLessonData?.moduleName}</p>
              {!newTimetableEligibility &&
              <p className={style.dayTime}>
                <span className={style.icon + ' library-shaw-icon-new'}>
                  <i className="icon-new-Clock"></i>
                </span>
                {selectedLessonData?.day}, {selectedLessonData?.time}
              </p>}
            </div>
            {!newTimetableEligibility &&
            <div className={style.monthAndDate}>
              <p className={style.lessonMonth}>{selectedLessonData?.month}</p>
              <span className={style.lessonDate}>
                {selectedLessonData?.date}</span>
            </div>}
          </div>
          <div className={style.description}>
            {selectedLessonData?.desc &&
            htmr(selectedLessonData?.desc)}
          </div>
          <div className={style.ctaWrapper}>
            {activeIndex!==1 ?
            <button className={style.joinCta}
              onClick={navigateToWebinar}
              disabled={!enableJoinCta}>
              {!enableJoinCta &&
              <div className={`${style.lockFilledIcon} library-shaw-icon-new`}>
                <i className='icon-lock-filled'></i>
                  &nbsp;Join
              </div>}
              <span></span>
            </button> :
            <>
              {!enableJoinCta ?<button className={style.joinCta}
                onClick={triggerWatchNow}
                disabled={disableCta}>
                Play Now</button> :
                <button className={style.joinCta}
                  onClick={navigateToWebinar}>
                  Join</button>}</>}
            {!newTimetableEligibility &&
            <button className={style.rescheduleCta}>
              <span onClick={navigateToReschedule}>
                Reschedule</span>
            </button>}
          </div>
        </div>
      </div>
      <div className={style.overlay}/>
    </div>);
};

Popup.propTypes = {
  closeToggle: PropTypes.func.isRequired,
  navigateToReschedule: PropTypes.func,
  selectedLessonData: PropTypes.object,
  navigateToWebinar: PropTypes.func,
  enableJoinCta: PropTypes.bool,
  activeIndex: PropTypes.number,
  triggerWatchNow: PropTypes.func,
  disableCta: PropTypes.bool,
  newTimetableEligibility: PropTypes.bool,
};

export default Popup;
