import React, {Component} from 'react';
import {Form, Col, Button, Spinner} from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as rescheduleService from './RescheduleService';
import * as apiService from '../../api/index';
import {navigate} from 'gatsby';
import * as trackAction from '../Shared/TrackActionService';
import DatePicker from 'react-date-picker';
import styles from './reschedule.module.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import cookie from 'react-cookies';
import {countriesEligibleForNewSchedule} from '../../containers/Constants';

/**
 *
 *
 * @export
 * @class RescheduleClass
 * @extends {Component}
 */
export default class RescheduleClass extends Component {
  /**
   * Creates an instance of RescheduleClass.
   * @param {*} props
   * @memberof RescheduleClass
   */
  constructor(props) {
    super(props);
    this.state = {
      lessonNumbers: [],
      timePickerList: [],
      loadingSpinner: false,
      disableCta: false,
      eligibleForNewSchedule: false,
    };
    this.selectRef=React.createRef(null);
  }

  /**
   * For initial api calls
   *
   * @memberof RescheduleClass
   */
  componentDidMount() {
    const regId = this.props.regId;
    dayjs.locale(this.props.locale);
    const number = this.props.location.state.number;
    const leadCountry =cookie.load('country');
    const newScheduleEligibility =
    countriesEligibleForNewSchedule(leadCountry, this.props?.leadCreated);
    this.setState({
      eligibleForNewSchedule: newScheduleEligibility,
    });
    new apiService.default.Schedule(regId).get()
        .then((response) => {
          const responseObj = response.data.data;
          const schedule = responseObj.attributes.schedule;
          const lessons = responseObj.attributes.course.lessons
              .filter((item) => item.key !=='bonus');
          this.setState({
            schedule: schedule,
            lessons: lessons,
            lessonNumbers: rescheduleService.buildLessonsList(lessons.length),
          }, ()=>{
            if (number) {
              this.selectRef.current.value=number;
              this.lessonHandler(number, true);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    this.setState({
      regId: regId,
      leadId: rescheduleService.getLeadId(),
      timePickerList: rescheduleService.buildTimePickerList(),
    });
    trackAction.pageViewAction('RescheduleLesson', this.props.locale);
  }

  /**
   *
   *
   * @param {*} e
   * @param {boolean} [isLessonNumber=false]
   * @memberof RescheduleClass
   */
  lessonHandler(e, isLessonNumber=false) {
    const lessonSelected =
     isLessonNumber ? parseInt(e) : parseInt(e.target.value);
    const dateToday = dayjs();
    const minLimit = dayjs(dateToday).add(1, 'd').format('YYYY-MM-DD');
    const maxLimit = dayjs(dateToday).add(1, 'M').format('YYYY-MM-DD');
    if (lessonSelected > 0) {
      this.setState({
        lessonSelected: lessonSelected,
        minLimit: minLimit,
        defaultDate: [].concat(minLimit),
        maxLimit: maxLimit,
        isLessonSelected: true,
      });
    } else {
      this.setState({
        lessonSelected: null,
        isLessonSelected: false,
      });
    }
  }

  /**
 * Time Change handler for lessons dropdown
 *
 * @param {*} e
 * @memberof RescheduleClass
 */
  timeHandler(e) {
    const hours = parseInt(dayjs(e.target.value,
        ['h:mm A']).format('HH'));
    let changedSchedule;
    if (hours !== 0) {
      if (this.state.isDateSelected) {
        changedSchedule = this.state.dateSelected
            .set('hour', hours)
            .set('minute', 0).set('second', 0);
        this.setState({
          changedSchedule: changedSchedule,
        });
      }
      this.setState({
        timeSelected: hours,
        isTimeSelected: true,
      });
    } else {
      this.setState({
        timeSelected: null,
        isTimeSelected: false,
      });
    }
  }

  /**
 * Date Change handler for lessons dropdown
 *
 * @param {*} value
 * @memberof RescheduleClass
 */
  dateHandler(value) {
    const dateSelected = dayjs(value);
    let changedSchedule;
    if (this.state.isTimeSelected) {
      changedSchedule = dateSelected.set('hour', this.state.timeSelected)
          .set('minute', 0).set('second', 0);
      this.setState({
        changedSchedule: changedSchedule,
      });
    }
    this.setState({
      defaultDate: [].concat(dateSelected),
      dateSelected: dateSelected,
      isDateSelected: true,
    });
  }

  /**
 * Confirm reschedule handler
 *
 * @memberof RescheduleClass
 */
  handleConfirmation() {
    this.setState({
      loadingSpinner: true,
      disableCta: true,
    });
    const lessons = this.state.lessons.map((item) => {
      if (item.number === this.state.lessonSelected) {
        item['start_time'] = this.state.changedSchedule.toISOString();
        item['status'] = 'scheduled';
      }
      return {
        number: item.number,
        start_time: item['start_time'],
        status: 'scheduled',
      };
    });
    const requestObj = {
      'data': {
        'type': 'registrations',
        'id': this.state.regId,
        'attributes': {
          'schedule': {
            'first_day_of_week': this.state.schedule.first_day_of_week,
            'second_day_of_week': this.state.schedule.second_day_of_week,
            'first_day_hour': this.state.schedule.first_day_hour,
            'second_day_hour': this.state.schedule.second_day_hour,
            'lessons': lessons,
            'lessons_per_week': 2,
          },
        },
        'relationships': {
          'student': {
            'data': {
              'type': 'student',
              'id': this.state.leadId,
            },
          },
        },
      },
    };
    new apiService.default.Schedule(this.state.regId).update(requestObj)
        .then((response) => {
          const result = response.data.data;
          if (result) {
            const locale = this.props.locale;
            const module = rescheduleService.getModule() || '1';
            trackAction.registrationAction({
              'moduleNumber': parseInt(module, 10),
              'courseSlug': result.attributes.course_slug,
              'facultySlug': result.attributes.course.faculty,
              'lessonNumber': parseInt(this.state.lessonSelected, 10),
              'locale': this.props.locale,
              'rescheduleType': 'rescheduleLesson',
              'Goal': 2,
            });
            setTimeout(() => {
              navigate(`/app/${locale}`);
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            showCommonError: true,
            loadingSpinner: false,
            disableCta: false,
          });
        });
  }

  /**
 *
 *
 * @memberof RescheduleClass
 */
  navigateToParent() {
    navigate('../');
  }

  /**
   *
   *
   * @return {*}
   * @memberof RescheduleClass
   */
  render() {
    return (
      <>
        <div
          className={`${styles.rescheduleClass} ${
            styles.schedulingScreen}`}>
          <div
            style={{display: !this?.state?.eligibleForNewSchedule ?
              'inherit' : 'none'}}
            className={styles.backBtn}
            onClick={this.navigateToParent}>
            <img src='/back.svg' alt=''/>
            View Scheduling Options
          </div>
          <div className={`${styles.box} ${styles.rescheduleBox}`}>
            <p>Reschedule a class</p>
            <img src='/schedule/reschedule-arrow.svg' alt=''/>
          </div>
          <p className={styles.shPageSubHeading}>
            If you have missed a class or can&apos;t attend a
            specific lesson, reschedule here.
          </p>
          <Form.Row className={styles.classScheduleRow}>
            <div className={styles.fieldContainer}>
              <Form.Label>
                Class to reschedule
              </Form.Label>
              <Form.Control
                as="select"
                ref={this.selectRef}
                onChange={(e) => this.lessonHandler(e)}
                custom>
                <option value="0">Select a lesson</option>
                {
                this.state?.lessonNumbers.map((item, index) => {
                  return <option key={item}
                    value={index+1}>
                    {item}
                  </option>;
                })
                }
              </Form.Control>
            </div>
            <div
              className={`${this.state.isLessonSelected ?
              `${styles.fieldContainer}` : `${styles.hideElement}`}`}>
              <Form.Label>
                Select date to attend
              </Form.Label>
              <DatePicker
                minDate={new Date(this.state.minLimit)}
                maxDate={new Date(this.state.maxLimit)}
                locale={this.props.locale}
                value={this.state?.defaultDate}
                onChange={(value) => this.dateHandler(value)}
                clearIcon={null}
              />
            </div>
          </Form.Row>
          <Form.Row className={styles.classScheduleRow}>
            <div
              className={`${this.state.isDateSelected &&
          this.state.isLessonSelected ? `${styles.fieldContainer}` :
           `${styles.hideElement}`}`}>
              <Form.Label>
                Class timing
              </Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) => this.timeHandler(e)}
              >
                <option value="0">
                  Pick Time
                </option>
                {
                this.state.timePickerList?.map((item) => {
                  return <option
                    value={item}
                    key={item}>
                    {item}
                  </option>;
                })
                }
              </Form.Control>
            </div>
          </Form.Row>
          <div
            className={`${this.state.isTimeSelected &&
          this.state.isLessonSelected ? `${styles.scheduleDetails}` :
           `${styles.hideElement}`}`}>
            <p className={`${styles.info} ${styles.classRescheduleInfo}`}>
              Your lesson will be on
             &nbsp;
              <span>
                {this.state.changedSchedule?.format('dddd, MMM DD, YYYY')}
              </span> at <span>
                {this.state.changedSchedule?.format('h:mm A')}
              </span>
            </p>
          </div>
          <div className={styles.ctaWrapper}>
            <Button
              id={this.state.disableCta ? styles.disabled : ''}
              className={`${this.state.isTimeSelected &&
      this.state.isLessonSelected ? `${styles.shBtn}` :
       `${styles.hideElement}`}`}
              onClick={() => this.handleConfirmation()}>
              Confirm
            </Button>
            {this.state.loadingSpinner &&
            <div className={styles.spinnerWrapper}>
              <Spinner animation="border" />
            </div>}
          </div>
          { this.state?.showCommonError ?
          <Col className={styles.errorText}>
            Something went wrong.
          </Col> : null
          }
        </div>
      </>
    );
  }
}
RescheduleClass.propTypes = {
  allCoursesList: PropTypes.array,
  regId: PropTypes.string,
  locale: PropTypes.string,
  location: PropTypes.object,
  leadCreated: PropTypes.string,
};
