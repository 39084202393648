import * as constants from '../../containers/Constants';
import {getPrices} from '../../api/AddonPrices';
// import dayjs from 'dayjs';

export const getAllLabels = (allCourses) => {
  if (allCourses?.length) {
    const labels = [];
    for (const course of allCourses) {
      if (course.label) {
        labels.push(course.label);
      }
    }
    return labels;
  }
};

export const getCoursesByLocale = (allCourses, locale) => {
  const coursesInfo = allCourses.nodes;
  const coursesToFilter = coursesInfo.filter((item) => {
    return item.publish_details.locale.indexOf(locale) > -1;
  });
  return coursesToFilter?.[0]?.coursecollections;
};

export const getCoursesByTag = (allCourses, tagName) => {
  if (allCourses?.length && tagName) {
    const coursesByTag = allCourses.filter((item) => {
      return (item.label === tagName);
    });
    return coursesByTag;
  }
};

export const getCourse = (allCourses, tagName) => {
  if (allCourses?.length && tagName) {
    const coursesByTag = allCourses.filter((item) => {
      return (item.label === tagName);
    });
    return coursesByTag;
  }
};

export const getCourseRegistrationsMapBySlug = (courseRegistrations) => {
  const registeredCourseInfo = {};
  if (courseRegistrations && courseRegistrations.length) {
    for (const courseRegistration of courseRegistrations) {
      const key = courseRegistration.attributes.localizedcourseslug;
      registeredCourseInfo[key] = courseRegistration.id;
    }
    return registeredCourseInfo;
  }
  return {};
};

export const checkBySlug = (coursesByTag, registeredCourseInfo) => {
  if (registeredCourseInfo && coursesByTag?.length) {
    for (const course of coursesByTag?.[0]?.courses) {
      const courseslug = course.courseslug;
      if (registeredCourseInfo[courseslug]) {
        course['registrationId'] = registeredCourseInfo[courseslug];
      }
    }
    return coursesByTag?.[0]?.courses;
  }
  return coursesByTag?.[0]?.courses;
};

export const shouldLockFaculties = (subInfo, studentInfo,
    registeredTags, hasUpgraded) => {
  if (hasUpgraded) {
    return false;
  }
  let subscriptionData = subInfo?.default;
  if (subInfo?.chargebee) {
    subscriptionData = subInfo?.chargebee;
  }
  if (!subscriptionData) return false;
  if (registeredTags?.length === 0) {
    return false;
  }
  if (subscriptionData?.status === 'in_trial' &&
    (constants.lifetimeSlugs.indexOf(studentInfo.slug) === -1)) {
    return true;
  }
  return false;
};

export const getListOfRegisteredTags = (studentRegistrations) => {
  if (studentRegistrations && Array.isArray(studentRegistrations)) {
    const registeredTagsArrayList = [];
    const registeredRawTagList = studentRegistrations?.map((
        studentRegistration) => studentRegistration?.attributes?.tags);
    for (let i = 0; i < registeredRawTagList.length; i++) {
      registeredTagsArrayList.push(registeredRawTagList[i]?.[0]);
    }
    return [...new Set(registeredTagsArrayList)];
  }
  return [];
};

// allCourses
export const getRegisteredTags =
  (courseRegistrations, labelCourseCollection) => {
    const unlockedLabels = [];
    if (courseRegistrations && Array.isArray(courseRegistrations)) {
      const regCoursesTags = getListOfRegisteredTags(courseRegistrations);
      labelCourseCollection.map((labelCourseCollectionItem) => {
        for (let i = 0; i < regCoursesTags.length; i++) {
          const found = labelCourseCollectionItem.courses.filter((course) => {
            return regCoursesTags.indexOf(course?.tags?.[0]) > -1;
          });
          if (found.length > 0) {
            unlockedLabels.push(labelCourseCollectionItem.label);
            break;
          }
        }
      });
    }
    return unlockedLabels;
  };

export const getDataToSalesOffer = async (studentInfo, leadId) => {
  const leadCountry = studentInfo?.address?.country;
  let countryToSend = leadCountry;
  const leadLanguage = studentInfo?.language_id;
  const euCountry = constants.euCountryList;
  const usCountry = constants.lowerUSDCountryList;
  const addons = {
    product: 'module-toolkit',
    type: 'toolkit',
  };
  const getPricesData = await getPrices(addons, leadCountry);
  if (usCountry.includes(leadCountry)) {
    countryToSend = '3w';
  }
  if (!getPricesData) {
    countryToSend = 'us';
  }
  if (euCountry.includes(leadCountry)) {
    countryToSend = 'eu';
  }
  const dataToSalesOffer = {
    leadId,
    countryToSend,
    leadLanguage,
  };
  return dataToSalesOffer;
};

export const getFacultiesFromCourseSlug = (courseSlug, allCourses) => {
  const availableFaculties = [];
  allCourses?.map((course) => {
    for (let i=0; i<course?.courses?.length; i++) {
      if (course?.courses?.[i]?.courseslug === courseSlug) {
        availableFaculties.push(course?.label);
        return course?.label;
      }
    }
  });
  return availableFaculties;
};
