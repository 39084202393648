import React, {useEffect, useState} from 'react';
import * as apiService from '../../api';
import cookie from 'react-cookies';
import styles from './index.module.scss';
import AssetsService from '../Shared/AssetsService';
import {pageViewAction, ctaClickAction} from '../Shared/TrackActionService';
import TimeTableSkeleton from '../Shared2/LoadingSkeleton/TimeTableSkeleton';
import * as constants from '../../containers/Constants';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Popup from './Popup';
import EnrolledCourse from './EnrolledCourse';
import {Line} from 'react-chartjs-2';
import HeaderNew from '../Shared/HeaderNew';
import CourseSwitcherMobileView from '../Shared2/CourseSwitcherMobileView';
import {rescheduleNextLesson} from
  '../RegistrationDetailV2/RegistrationDetailService';
import UpgradeNowBanner from '../Shared2/Banner/UpgradeNowBanner';
import {getSubscriptionPlanData, checkUpgradeNowEligibility,
  defaultPriceObject, openUpgradeNowPopup}
  from '../Shared2/Service/UpgradeNowService';
import MigratePopup from '../Shared2/Popup/MigratePopup';
import * as commonService from '../Shared2/Agent/CommonService';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function TimeTableView(props) {
  const {navigate, locale, studentInfo} = props;
  const oneDay = 24*60*60*1000;
  const [courseReg, setCourseReg] = useState([]);
  const [includedData, setIncludedData] = useState([]);
  const lastMonday=dayjs().get('day')?dayjs().set('day', 1):
  dayjs().set('day', 1).subtract(1, 'week');
  const [startDate, setStartDate] = useState(new Date(lastMonday));
  const [endDate, setEndDate] =
    useState(new Date(startDate.getTime() + 6 * oneDay));
  const [viewDays, setViewDays] = useState([]);
  const [lessonList, setLessonList] = useState([]);
  const [studentId, setStudentId] = useState('');
  const [loading, setLoading] = useState(false);
  const [lessonsPerWeek, setLessonsPerWeek] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLessonData, setSelectedLessonData] = useState({});
  const [enableJoinCta, setEnableJoinCta] = useState(false);
  const [weeksArray, setWeeksArray]=useState([]);
  const [selectedWeek, setSelectedWeek]=useState({});
  const [lessonsCompleted, setLessonsCompleted]=useState(0);
  const [lessonsPerDay, setLessonsPerDay]=useState(0);
  const [activeIndex, setActiveIndex]=useState(1);
  const [daysArray, setDaysArray]=useState([]);
  const [pointBackgroundColor, setPointBackgroundColor]=useState([]);
  const [totalLessonsCompletedInOneWeek, setTotalLessonsCompletedInOneWeek]=
  useState([]);
  const [totalLessonsPerWeek, setTotalLessonsPerWeek]=useState([]);
  const [selectedTab, setSelectedTab]=useState('lessons');
  const [screenWidth, setScreenWidth]=useState(null);
  const [showCourseSwitcher, setShowCourseSwitcher]=useState(false);
  const [selectedCourseInfo, setSelectedCourseInfo]=useState({});
  const [disableCta, setDisableCta]=useState(false);
  const [planPrices, setPlanPrices] = useState(defaultPriceObject);
  const [showMigratePopup, setShowMigratePopup] = useState(false);

  const leadCountry = cookie.load('country');
  const leadCreatedAt = studentInfo?.created_at;
  const newTimetableEligibility =
  constants.countriesEligibleForNewSchedule(leadCountry, leadCreatedAt);
  const showUpgradeNowBanner = cookie.load(`upgrade_${studentId}`);
  const membershipType = studentInfo?.membership?.name.toLowerCase();

  const data = {
    labels: daysArray?.length && daysArray,
    datasets: [
      {
        label: `Actual`,
        fill: false,
        lineTension: 0,
        backgroundColor: constants.graphActualPointColor,
        pointBackgroundColor: pointBackgroundColor,
        pointBorderColor: pointBackgroundColor,
        borderColor: constants.borderColor,
        borderWidth: 2,
        data: totalLessonsCompletedInOneWeek,
      },
      {
        label: `Goal`,
        fill: false,
        lineTension: 0,
        backgroundColor: constants.graphGoalPointColor,
        pointBackgroundColor: constants.graphGoalPointColor,
        pointBorderColor: constants.graphGoalPointColor,
        borderColor: constants.graphGoalPointColor,
        borderWidth: 2,
        pointRadius: [3, 0, 0, 0, 0, 0, 3],
        data: totalLessonsPerWeek,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: lessonsPerDay?.length && Math.max(...lessonsPerDay),
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const getLessonList = (studentId, dateSince, dateUntil) => {
    new apiService.default.Registrations(studentId)
        .lessonSchedule(dateSince, dateUntil)
        .then((res) => (res.data)).then((res) => {
          setLessonList(res.data);
        });
  };
  const getCourseImage = (courseSlug) => {
    const firstRandomImage = new AssetsService('RECORDINGS')
        .fetchAssetURL(courseSlug);
    return firstRandomImage ? firstRandomImage : '';
  };

  useEffect(() => {
    setLoading(true);
    const studentId = cookie.load('shawacademy_leadId');
    setStudentId(studentId);
    new apiService.default.Registrations(studentId).list()
        .then((res) => (res.data)).then((res) => {
          const mergeData = mergeResponseData(res.data, res.included);
          setCourseReg(mergeData);
          setIncludedData(res.included);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    getFourWeeks();
    setScreenWidth(window.innerWidth);
    pageViewAction('TimeTablePage', locale);
    // hiding video ask widget
    const videoAskCloseModal =
      document.querySelector('.videoask-embed__hide_button--f6kKj');
    videoAskCloseModal?.click();
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  useEffect(() => {
    const studentId = cookie.load('shawacademy_leadId');
    const startDateMid = dayjs(startDate).set('hour', 0).set('minute', 0);
    const endDateMid = dayjs(endDate).set('hour', 23).set('minute', 59);
    getLessonList(studentId,
        startDateMid.toISOString(),
        endDateMid.toISOString());
  }, [startDate, endDate]);

  useEffect(() => {
    getViewDays();
  }, [lessonList, courseReg, includedData]);

  useEffect(() => {
    getTotalNumberOfLessonsPerWeek();
  }, [lessonsPerWeek]);

  useEffect(() => {
    changePointBackgroundColor();
  }, [totalLessonsCompletedInOneWeek, totalLessonsPerWeek]);

  useEffect(()=>{
    setSubscriptionPlanPrices();
    if (studentInfo?.membership?.status === 'inactive' ||
      commonService.checkGiftMembership(studentInfo?.membership)) {
      setShowMigratePopup(true);
    }
  }, [studentInfo]);

  const updateWindowWidth = () => {
    const newWidth = window.innerWidth;
    setScreenWidth(newWidth);
  };

  const setSubscriptionPlanPrices = () => {
    const euCountry = constants.euCountryList;
    const usCountry = constants.lowerUSDCountryList;
    let leadCountry=studentInfo?.address?.country || 'us';
    if (usCountry.includes(leadCountry)) {
      leadCountry = '3w';
    }
    if (euCountry.includes(leadCountry)) {
      leadCountry = 'eu';
    }
    getSubscriptionPlanData(leadCountry)
        .then((res)=>{
          setPlanPrices(res);
        })
        .catch((err)=>{
          console.log(err);
        });
  };

  const mergeResponseData = (response, includedData) => {
    if (response?.length && includedData?.length) {
      for (const data of response) {
        for (const includedInfo of includedData) {
          if (data?.attributes?.courseslug === includedInfo?.id) {
            data.attributes['lessons_per_week'] =
                includedInfo?.attributes?.lessons_per_week;
          }
        }
        prepareCourseData(data, includedData);
      }
      return response;
    }
  };

  const prepareCourseData = (
      courseRegistration, includedData) => {
    const modules =courseRegistration?.relationships?.modules?.data;
    const upcomingLessons = [];
    for (const [i, module] of modules.entries()) {
      const filteredRegistration = includedData.filter((item) => {
        return item.id === module.id;
      });
      module['attributes'] = filteredRegistration[0].attributes;
      for (const lesson of module.attributes.lessons) {
        const today = new Date();
        if (lesson?.start_time) {
          courseRegistration['activeModule'] = i + 1;
        }
        if (lesson.start_time && new Date(lesson.start_time) >= today) {
          upcomingLessons.push(lesson);
        }
        module['upcomingLessons'] = upcomingLessons;
      }
    }
  };

  const getCourseInfo = (reg) => {
    if (reg && includedData?.length) {
      if (newTimetableEligibility) {
        if (reg?.activeModule) {
          const activeModuleData=
          reg?.relationships?.modules?.data[reg?.activeModule-1];
          return activeModuleData;
        }
      } else {
        for (const data of includedData) {
          if (data?.id === reg?.attributes?.courseslug) {
            return data;
          }
        }
      }
    }
  };

  const getViewDays = () => {
    const viewDaysArray = [];
    for (let day = 0; day < 7; day++) {
      let classStartDate = new Date(startDate);
      if (day) {
        classStartDate = new Date(startDate.getTime() + day * oneDay);
      }
      viewDaysArray.push({
        day: getDayName(classStartDate),
        dateObj: classStartDate,
        lessons: lessonList?.filter(
            (lesson) =>
              dayjs(classStartDate.toISOString()).isSame(
                  lesson.attributes.start_time, 'day'),
        ),
      });
    }
    const daysArray = viewDaysArray?.length && viewDaysArray.map((day) => (
        day?.day
    ));
    if (props[`*`] === 'upcoming-lesson') {
      const {upcomingDay, upcomingLesson} =
      getUpcomingLessonData(viewDaysArray);
      if (upcomingDay && upcomingLesson &&
         courseReg?.length && includedData?.length) {
        debugger;
        togglePopup( upcomingLesson, upcomingDay);
      }
    }

    setLessonsPerWeek(getLessonPerWeek(viewDaysArray));
    setViewDays(viewDaysArray);
    getLessonsCompleted(viewDaysArray);
    getActualNumberOfLessonsPerDay(viewDaysArray);
    setDaysArray(daysArray);
    checkLessonCompletedPerWeek(viewDaysArray);
  };

  const getUpcomingLessonData = (viewDaysArray) => {
    let upcomingLesson = null;
    let upcomingDay = null;
    if (viewDaysArray.length) {
      upcomingDay = viewDaysArray.filter((day) => {
        if (!upcomingLesson) {
          upcomingLesson = day.lessons.filter((lesson) => {
            const startTime = lesson?.attributes?.start_time;
            if (startTime) {
              return dayjs().isBefore(dayjs(startTime)
                  .clone().add(20, 'minutes'));
            }
            return false;
          })[0];
        }
        return upcomingLesson;
      })?.[0];
    }
    return {
      upcomingDay,
      upcomingLesson,
    };
  };

  const getLessonPerWeek = (lessonsArray) => {
    let classes = 0;
    for (const lesson of lessonsArray) {
      if (lesson?.lessons?.length) {
        classes += lesson?.lessons?.length;
      }
    }
    return classes;
  };

  const getDayName = (day) => {
    return dayjs(day).format('dddd');
  };

  const getMonthFromNumber = (dateObj) => {
    return dayjs(dateObj).format('MMM');
  };

  const showlessonStatusButton = (startTime, day, lesson) => {
    if (dayjs().isSame(day?.dateObj, 'day') &&
        Math.abs(dayjs().diff(startTime, 'minutes')) <= 20 &&
        Math.abs(dayjs().diff(startTime, 'minutes')) >= 0) {
      return (
        <div className={styles.btnWrapper}>
          <a href={
            process.env.WebinarDomain + '/' +
              locale + '/webinar?' +
              'registration_id=' +
              lesson?.attributes?.registration_id +
              '&lead_id=' + studentId}>
            <button type="submit">
              Join Class
            </button>
          </a>
        </div>
      );
    }
    if (dayjs().isAfter(dayjs(startTime).clone().add(20, 'minutes'))) {
      const regId = lesson?.attributes?.registration_id;
      return (
        <div
          onClick={() => navigateToRecordingsPage(regId)}
          className={`${styles.displayBadge}
         library-shaw-icon-new`}>
          <span>
            <i className="icon-new-Done"></i>
          </span>
        </div>
      );
    }
    return (
      <div
        onClick={() => togglePopup(lesson, day)}
        className={`${styles.iconStyle}
      library-shaw-icon-new`}>
        <span>
          <i className="icon-new-Play-31"></i>
        </span>
      </div>
    );
  };

  const handleReschedule = (reg) => {
    ctaClickAction({
      studentId,
      locale,
      action: 'NavigateToRescheduleScreen',
      cta: 'RescheduleCourse',
    });
    const activeModuleIndex = reg?.activeModule - 1;
    const activeModuleRegId = reg?.relationships?.modules?.
        data?.[activeModuleIndex]?.attributes?.registration_id;
    navigate(`/app/${locale}/reschedule/${activeModuleRegId}`);
  };

  const getCurrentCourseData = (courseSlug) => {
    return courseReg.filter((course) => {
      return course?.attributes?.localizedcourseslug === courseSlug;
    });
  };

  const getModuleNumber = (courseData, courseSlug) => {
    const moduleData = courseData?.[0]?.relationships?.modules?.data;
    let activeModule;
    for (let i = 0; i < moduleData?.length; i++) {
      if (moduleData?.[i]?.id === courseSlug) {
        return activeModule = i + 1;
      }
    }
    return activeModule;
  };

  const triggerWatchNow = () => {
    setDisableCta(true);
    const courseSlug = selectedCourseInfo?.[0]?.id;
    const courseData = getCurrentCourseData(courseSlug);
    const activeModule = getModuleNumber(courseData, courseSlug);
    const data = {
      locale,
      studentId,
      'activeModuleData': selectedCourseInfo?.[0],
      courseSlug,
      'nextLessonNumber': selectedLessonData?.number,
      activeModule,
      'regId': selectedLessonData?.regId,
    };
    ctaClickAction({
      studentId,
      locale,
      courseSlug,
      'moduleNumber': parseInt(activeModule),
      'cta': 'Watch Now',
      'action': 'InitiateRescheduleNow',
      'pageKind': 'TimeTablePage',
    });
    rescheduleNextLesson(data);
  };

  const getSelectedCourseIncludedData = (lesson) => {
    return includedData?.filter((data) => (
        data?.attributes?.registration_id ===
         lesson?.attributes?.registration_id
    ));
  };

  const getSelectedCourseInfo = (courseIncludedData) => {
    return courseReg.filter((info) => (
        courseIncludedData?.[0]?.id.includes(info?.attributes?.courseslug)
    ));
  };

  const togglePopup = (lesson, day) => {
    window.scrollTo({top: 0, behaviour: 'smooth'});
    const courseIncludedData = getSelectedCourseIncludedData(lesson);
    const courseInfo = getSelectedCourseInfo(courseIncludedData);
    const regId = courseIncludedData?.[0]?.attributes?.registration_id;
    const currentLessonName = lesson?.attributes?.lessonname;
    const currentLessonNumber = lesson?.attributes?.number;
    const moduleName = lesson?.attributes?.modulename;
    const lessonStartMonth = getMonthFromNumber(day?.dateObj);
    const lessonStartDate = day?.dateObj?.getDate();
    const lessonStartTime =
        dayjs(lesson?.attributes?.start_time).format('hh:mm A');
    const lessonStartDay = day?.day;
    const lessonDesc =courseIncludedData?.[0]?.attributes?.lessons?.
        [currentLessonNumber-1]?.lessondescription;
    if (regId) {
      const courseSlug = courseInfo?.[0]?.attributes?.courseslug;
      const courseImage = getCourseImage(courseSlug);
      setSelectedCourseInfo(courseIncludedData);
      setSelectedLessonData({
        lesson: currentLessonName,
        number: currentLessonNumber,
        moduleName: moduleName,
        desc: lessonDesc,
        month: lessonStartMonth,
        date: lessonStartDate,
        time: lessonStartTime,
        day: lessonStartDay,
        regId,
        image: courseImage,
      });
      const webinarStart = Math.abs(dayjs().diff(
          dayjs(lesson?.attributes?.start_time), 'minutes'));
      if (dayjs().isAfter(dayjs(lesson?.attributes?.start_time)
          .clone().add(20, 'minutes'))) {
        navigateToRecordingsPage(regId);
        setEnableJoinCta(false);
      } else if (webinarStart < 60) {
        setEnableJoinCta(true);
      } else {
        setEnableJoinCta(false);
      }
    }
    setIsOpen(!isOpen);
    if (!isOpen) {
      ctaClickAction({
        'cta': 'LessonCard',
        'pageKind': 'TimeTablePage',
        'action': 'lessonStatus',
        locale,
      });
    }
  };

  const navigateToReschedule = () => {
    const urlToNavigate =
        `/app/${locale}/reschedule/${
          selectedLessonData?.regId}/reschedule-class/`;
    navigate(urlToNavigate, {
      state: {
        'number': selectedLessonData?.number,
      },
    });
    ctaClickAction({
      'cta': 'Reschedule',
      'pageKind': 'TimeTablePage',
      'action': 'rescheduleClass',
      locale,
    });
  };

  const navigateToWebinar = () => {
    const webinarPath = process.env.WebinarDomain;
    const navigateToWebinarUrl = `${webinarPath}/en/webinar?registration_id=${
      selectedLessonData?.regId}&lead_id=${studentId}`;
    window.location.href = navigateToWebinarUrl;
    ctaClickAction({
      'cta': 'JoinWebinar',
      'pageKind': 'TimeTablePage',
      'action': 'joinWebinar',
      locale,
    });
  };

  const navigateToRecordingsPage = (regId) => {
    const urlToNavigate =
        `/app/${locale}/v2/registrations/${regId}/modules/1`;
    navigate(urlToNavigate);
    ctaClickAction({
      'cta': 'WatchRecording',
      'pageKind': 'TimeTablePage',
      'action': 'watchRecording',
      locale,
    });
  };

  const getFourWeeks = () => {
    const tempWeeksArray = [];
    for (let week = 0; week < 4; week++) {
      let daysToBeAddedToStartDate = -7;
      let daysToBeAddedToEndDate = -1;
      if (week) {
        daysToBeAddedToStartDate = daysToBeAddedToStartDate + 7 * week;
        daysToBeAddedToEndDate = daysToBeAddedToEndDate + 7 * week;
      }
      const weekStartDate = new Date(
          new Date(startDate).getTime() + (daysToBeAddedToStartDate * oneDay));
      const weekEndDate = new Date(
          new Date(startDate).getTime() + (daysToBeAddedToEndDate * oneDay));
      const status = checkWeekStatus(weekStartDate, weekEndDate);
      const weekData = {
        startDate: weekStartDate,
        endDate: weekEndDate,
        month: getMonthFromNumber(weekStartDate),
        status,
      };
      tempWeeksArray.push(weekData);
    }
    // TODO: Active index and selected week should be set dynamically
    setActiveIndex(1);
    setSelectedWeek(tempWeeksArray?.[1]);
    setWeeksArray(tempWeeksArray);
    if (props[`*`]) {
      if (props[`*`] === 'next-week' ) {
        showClasses(tempWeeksArray[2], 2);
      } else if (props[`*`] === 'previous-week') {
        showClasses(tempWeeksArray[0], 0);
      }
    }
  };

  const checkWeekStatus = (weekStartDate, weekEndDate) => {
    if (new Date(weekStartDate) <= new Date().getTime() &&
        new Date().getTime() <= new Date(weekEndDate).getTime()) {
      return '';
    }
    if (new Date(weekStartDate).getTime() >= new Date().getTime()) {
      return styles.upcomingWeek;
    }
    return styles.completedWeek;
  };

  const showClasses = (week, index) => {
    setStartDate(new Date(week?.startDate?.getTime()));
    setEndDate(new Date(week?.endDate?.getTime()));
    setActiveIndex(index);
    setSelectedWeek(week);
    if (index === 0) {
      navigate('previous-week');
    } else if (index === 2) {
      navigate('next-week');
    } else {
      navigate(`/app/${locale}/timetable`);
    }
    const cta=`${week?.month?.substr(0, 3)} ${week?.startDate?.getDate()}-${
      week?.endDate?.getDate()}`;
    ctaClickAction({
      studentId,
      locale,
      action: 'dateRangeChanged',
      cta,
    });
  };

  const getLessonsCompleted = (viewDaysArray) => {
    const now = dayjs();
    let tempLessonsCompleted = 0;
    if (viewDaysArray?.length) {
      viewDaysArray.forEach((day) => {
        if (day?.lessons?.length) {
          const lessonsCompletdPerDay = day?.lessons.filter((lesson) => {
            const startTime = dayjs(lesson?.attributes?.['start_time']);
            return now.isAfter(startTime.clone().add(20, 'minutes'));
          }).length;
          tempLessonsCompleted = tempLessonsCompleted + lessonsCompletdPerDay;
        };
      });
    }
    setLessonsCompleted(tempLessonsCompleted);
  };

  const getActualNumberOfLessonsPerDay = (viewDaysArray) => {
    const tempLessonsPerDay = [];
    if (viewDaysArray?.length) {
      viewDaysArray.forEach((day) => {
        if (day?.lessons || day?.lessons?.length) {
          tempLessonsPerDay.push(
              day?.lessons.length,
          );
        };
      });
      setLessonsPerDay(tempLessonsPerDay);
    }
  };

  const checkLessonsCompletedPerDay = (viewDaysArray) => {
    const now = dayjs();
    const templessonsCompletedPerDay = [];
    let tempLessonsCompleted;
    if (viewDaysArray?.length) {
      viewDaysArray.forEach((day) => {
        if (day?.lessons || day?.lessons?.length) {
          tempLessonsCompleted = day?.lessons.filter((lesson) => {
            const startTime = dayjs(lesson?.attributes?.['start_time']);
            return now.isAfter(startTime.clone().add(20, 'minutes'));
          }).length;
        };
        templessonsCompletedPerDay.push(tempLessonsCompleted);
      });
      return templessonsCompletedPerDay;
    }
  };

  const checkLessonCompletedPerWeek = (viewDaysArray) => {
    const lessonsCompletedPerDay = checkLessonsCompletedPerDay(viewDaysArray);
    const totalLessonsCompleted = [];
    let sum = 0;
    let length = 7;
    const currentDay = dayjs().get('day') || 7;
    if (new Date(startDate).getTime() <= new Date().getTime() &&
        new Date().getTime() <= new Date(endDate).getTime()) {
      length = Number(currentDay);
    }
    if (new Date(startDate).getTime() > new Date().getTime()) {
      totalLessonsCompleted.fill([]);
    } else {
      for (let i = 0; i < length; i++) {
        sum = sum + lessonsCompletedPerDay?.[i];
        totalLessonsCompleted.push(sum);
      }
    }
    setTotalLessonsCompletedInOneWeek(totalLessonsCompleted);
  };

  const getTotalNumberOfLessonsPerWeek = () => {
    if (lessonsPerWeek) {
      const daysInOneWeek = 7;
      const interval = lessonsPerWeek / daysInOneWeek;
      let sum = 0;
      const tempTotalLessonsPerWeek = [];
      for (let z = 1; z < 6; z++) {
        sum = sum + interval;
        tempTotalLessonsPerWeek.push(sum);
      }
      tempTotalLessonsPerWeek.unshift(0);
      tempTotalLessonsPerWeek.push(lessonsPerWeek);
      setTotalLessonsPerWeek(tempTotalLessonsPerWeek);
    }
  };

  const changePointBackgroundColor = () => {
    const pointBackgroundColors = [];
    const daysInOneWeek = 7;
    for (let i = 0; i < daysInOneWeek; i++) {
      if (totalLessonsCompletedInOneWeek?.[i] > totalLessonsPerWeek?.[i]) {
        pointBackgroundColors.push(constants.graphActualPointColor);
      } else {
        pointBackgroundColors.push(constants.graphGoalPointColor);
      }
    }
    setPointBackgroundColor(pointBackgroundColors);
  };

  const switchTab = (param) => {
    if (param === 'enrolled') {
      setSelectedTab('enrolled');
    } else {
      setSelectedTab('lessons');
    }
  };

  const triggerCourseSwitcher = () => {
    setShowCourseSwitcher(!showCourseSwitcher);
  };

  const switchCourse = (course) => {
    triggerCourseSwitcher();
    navigate(course.url);
  };

  const openUpgradePopup = (duration, pageKind) => {
    const eventData = {
      studentId,
      locale,
      cta: `Upgrade Now`,
      action: 'InitiateUpgradeNowPopupFromBanner',
      pageKind,
    };
    const navigationData = {
      regId: courseReg?.[0]?.id,
      activeModule: '1',
      duration,
    };
    openUpgradeNowPopup(eventData, navigationData);
  };

  return (
    <>
      {!loading ?
      <div className={styles.timetableContainer}>
        <HeaderNew
          registrationData={courseReg? courseReg : []}
          firstName={studentInfo?.name?.first_name}
          locale={locale} />
        <div className={styles.timeTableWrapper}>
          <div className={styles.recordingsCourseSelector}>
            <CourseSwitcherMobileView
              coursesOnSwitcher={courseReg? courseReg : []}
              locale={locale}
              triggerCourseSwitcher={triggerCourseSwitcher}
              switchCourse={switchCourse}
              showCourseSwitcher={showCourseSwitcher}
              activeCourse={null}
            />
          </div>
          <section className={styles.graphSection}>
            <div>
              <h1>Classes</h1>
              <p className={styles.classPerWeek}>
                <span>{lessonsPerWeek}</span>
                {lessonsPerWeek === 1 ? `Class this week` :
              `Classes this week`}
              </p>
            </div>
            <div>
              {weeksArray?.length && weeksArray.map((week, index)=>{
                return <button
                  key={index}
                  className={`${week.status} ${
                  activeIndex===index?styles.activeWeek:''}`}
                  onClick={()=>showClasses(week, index)}>
                  {week?.month?.substr(0, 3)} {week?.startDate?.getDate()}-{
                week?.endDate?.getDate()}
                </button>;
              })
              }
            </div>
            <div>
              <div>
                <p>{weeksArray?.length && selectedWeek?.month?.substr(0, 3)} {
              selectedWeek?.startDate?.getDate()}-{
                selectedWeek?.endDate?.getDate()}</p>
                {lessonsPerWeek>0 ?
              <div className={styles.classesAttended}>
                <h1>{lessonsCompleted}</h1>
                <div>
                  <p>{lessonsCompleted===1 ?
                  `class attended this week`:
                  `classes attended this week`}</p>
                  <p>{lessonsPerWeek-lessonsCompleted>0 &&
                  lessonsPerWeek-lessonsCompleted} {(
                    lessonsPerWeek-lessonsCompleted)===0?
                   `week complete`:
                  `more classes to go`}</p>
                </div>
              </div> :
              <p className={styles.fallbackMessage}>
                  week complete</p>}
              </div>
              <div>
                <Line
                  data={data}
                  options={options}
                />
              </div>
            </div>
          </section>
          {screenWidth<1023 &&
         <div className={styles.tabSwitcherWrapper}>
           <div className={styles.tabSwitcher}>
             <button
               className={selectedTab==='lessons'?styles.activeTab:''}
               onClick={()=>switchTab('lessons')}>
                Lessons
               <span></span></button>
             <button
               className={selectedTab==='enrolled'?styles.activeTab:''}
               onClick={()=>switchTab('enrolled')}>
                  Enrolled
               <span></span></button>
           </div>
         </div>}
          <div className={styles.itemContainer}>
            <div style={{display: screenWidth<1023 &&
          selectedTab!=='enrolled' && 'none'}}
            className={styles.leftContainer}>
              <p className={styles.title}>
                Enrolled
              </p>
              <div className={styles.cardWrapper}>
                {courseReg?.length ?
                courseReg.map((reg, index) => (<>
                  <EnrolledCourse
                    key={`enrolled-${index}`}
                    courseRegData={reg}
                    locale={locale}
                    courseFullInfo={getCourseInfo(reg)}
                    handleReschedule={handleReschedule}
                    getCourseImage={getCourseImage}
                    newTimetableEligibility={newTimetableEligibility}
                    index={index}
                  />
                  {!index && courseReg?.length>0 && !showUpgradeNowBanner &&
                    membershipType?.indexOf('lifetime') === -1 &&
                     <div id={styles.upgradeNowBannerSection}>
                       <UpgradeNowBanner
                         openUpgradePopup={openUpgradePopup}
                         isWeekTwoComplete=
                           {checkUpgradeNowEligibility(includedData)}
                         activeModule={1}
                         totalModules={4}
                         planPrices={planPrices}
                         pageKind={'TimeTablePage'}
                       />
                     </div>
                  }
                </>
                )):
                  <p>No courses registered</p>
                }
              </div>

            </div>
            <div style={{display: screenWidth<1023 &&
          selectedTab!=='lessons' && 'none'}}
            className={styles.rightContainer}>
              {
                viewDays && viewDays.map((day, index) => (
                  <div className={styles.courseWrapper} key={index}>
                    {!newTimetableEligibility &&
                    <p className={styles.dayname}>
                      {day?.day}
                    </p>
                    }
                    {
                    day?.lessons && day.lessons?.length > 0 ?
                    day?.lessons.map((lesson) => {
                      return <div className={styles.boxWrapper}
                        key={lesson?.attributes?.lessonname}>
                        <div className={styles.scheduleContainer}>
                          {!newTimetableEligibility &&
                          <div className={styles.monthDate}>
                            <p className={styles.month}>
                              {getMonthFromNumber(day?.dateObj)}
                            </p>
                            <p>{day?.dateObj?.getDate()}</p>
                          </div>}
                          <div className={styles.details}>
                            <div className={styles.verticalBorder}
                              style={{backgroundColor: '#aeeff0'}}>
                            </div>
                            <div className={styles.lessonContainer}>
                              <div className={styles.size}
                                onClick={()=>togglePopup(lesson, day)}>
                                <p className={styles.p1}>
                                  <span>{lesson?.attributes?.number}. </span>
                                  {lesson?.attributes?.lessonname}
                                </p>
                                <p className={styles.p2}>
                                  {lesson?.attributes?.modulename}
                                </p>
                                {!newTimetableEligibility &&
                                <p className={styles.p3}>
                                  <span
                                    className={styles.iconStyle +
                                    ' library-shaw-icon-new'}>
                                    <i className="icon-new-Clock"></i>
                                  </span>
                                  <span>
                                    {dayjs(lesson?.attributes?.start_time)
                                        .format('hh:mm A')}
                                  </span>
                                </p>}
                              </div>
                              {showlessonStatusButton(
                                  lesson?.attributes?.start_time, day, lesson)}
                            </div>
                          </div>
                        </div>
                        <div>
                        </div>
                      </div>;
                    }) :
                    !newTimetableEligibility ?
                    <p className={styles.infoMessage}>
                      No classes scheduled
                    </p> : ''
                    }
                  </div>
                ))
              }
            </div>
            <div>
              {
                isOpen && (
                  <Popup
                    closeToggle={()=>{
                      setIsOpen(!isOpen);
                      navigate(`/app/${locale}/timetable`);
                    }}
                    navigateToReschedule={navigateToReschedule}
                    selectedLessonData={selectedLessonData}
                    navigateToWebinar={navigateToWebinar}
                    enableJoinCta={enableJoinCta}
                    activeIndex={activeIndex}
                    triggerWatchNow={triggerWatchNow}
                    disableCta={disableCta}
                    newTimetableEligibility={newTimetableEligibility}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div> : <div className={styles.timetableContainer}>
        <TimeTableSkeleton />
      </div>
      }
      {showMigratePopup &&
       <MigratePopup />}
    </>
  );
}

TimeTableView.propTypes = {
  navigate: PropTypes.func,
  locale: PropTypes.string,
  registrationData: PropTypes.array,
  credits: PropTypes.number,
  studentInfo: PropTypes.object,
};

export default TimeTableView;
