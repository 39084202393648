import * as apiService from '../api';
import cookie from 'react-cookies';

/**
 *
 *
 * @param {*} addonData
 * @param {*} country
 * @return {*}
 */
export const getPrices = (addonData, country) => {
  const leadCountry = country || cookie.load('country');
  return new apiService.default.Addons().
      getPrices(addonData.product, leadCountry).then((res)=> {
        const priceObj = {
          ...res.data,
          default_tier_code: res.data.tiers[0].tier_code,
          default_tier_price: res.data.tiers[0].price,
          ...addonData,
        };
        return priceObj;
      }).catch((err)=> {
      });
};
