import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import * as typeformEmbed from '@typeform/embed';
import * as trackAction from '../Shared/TrackActionService';
import {Dropdown, Popover, Overlay} from 'react-bootstrap';
import RetakeConfirmation from '../Shared2/Popup/RetakeConfirmation';
import styles from '../Shared2/AssessmentsProgress.module.scss';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function AssessmentsProgress(props) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [assessmentsAttempted, setAssessmentsAttempted] = useState(0);
  const [percentageAverage, setPercentageAverage] = useState(0);
  const [assessmentResults, setAssessmentResults] = useState([]);
  const [showLoadingSpinner, toggleLoadingSpinner] = useState(false);
  const [showRetakeConfirmationBanner, setShowRetakeConfirmationBanner]=
  useState(false);
  const ref = useRef(null);
  const studentId=cookie.load('shawacademy_leadId');
  const {courseData, finalAssessmentRetakes, locale} = props;

  useEffect(() => {
    fetchAssessmentResults();
  }, [courseData]);

  /**
   *
   *
   * @param {*} assessmentData
   */
  const checkRetakeEligibility = (assessmentData) => {
    const moduleIndex = assessmentData?.moduleNumber-1;
    if (assessmentData?.attempts > 0) {
      openAssessment(moduleIndex);
    }
  };

  /**
   *
   *
   * @param {*} moduleIndex
   */
  const openAssessment = (moduleIndex) => {
    const {studentInfo}=props;
    const assessmentType='Final';
    const assessmentNumber=5;
    const moduleData=courseData?.relationships.modules.data[moduleIndex];
    const facultySlug=moduleData?.attributes.facultyslug;
    const courseSlug=courseData.attributes?.localizedcourseslug;
    const courseName=courseData.attributes?.coursename;
    let link;
    const firstName = studentInfo?.name?.first_name;
    const lastName = studentInfo?.name?.last_name;
    const userName = `${firstName} ${lastName}`;
    const moduleNumber=moduleIndex+1;
    const finalAssignment=
    moduleData?.attributes?.finalassignment?.modular_blocks[0];
    if (finalAssignment?.typeform) {
      link = finalAssignment.typeform?.formid;
      trackAction.assignmentClickAction({
        'action': 'RetakeAssessmentProgressSection',
        studentId,
        courseSlug,
        moduleNumber,
        facultySlug,
        assessmentType,
        'assessmentId': link,
        locale,
        assessmentNumber,
      });
      const reference = typeformEmbed.makePopup(
          // shawacademy.typeform.com
          `https://form.typeform.com/to/${link}#student_id=${studentId}&course_slug=${encodeURIComponent(courseSlug)}&course_name=${encodeURIComponent(courseName)}&module_slug=${encodeURIComponent(moduleData.attributes.moduleslug)}&module_name=${encodeURIComponent(moduleData?.attributes?.modulename)}&module_number=${moduleNumber}&course_regid=${courseData?.id}&user_name=${userName}&locale=${locale}&assessment_number=5&faculty_slug=${facultySlug}&sub_brand=${process.env.BrandSlug}&stage=${process.env.AppicationEnv}&assessment_type=final`,
          {
            hideHeaders: true,
            hideFooters: true,
            autoClose: 0.1,
            onSubmit: function(event) {
              const updatedRetakes=parseInt(
                finalAssessmentRetakes[moduleIndex]?.retakes)-1;
              const updatedAttempts=
                  parseInt(finalAssessmentRetakes[moduleIndex]?.attempts)+1;
              props.setFinalAssessmentRetakes({
                retakes: updatedRetakes,
                attempts: updatedAttempts,
                moduleNumber: moduleNumber,
              }, moduleNumber-1);
              setTimeout(() => {
                trackAction.assignmentCompleteAction({
                  studentId,
                  courseSlug,
                  moduleNumber,
                  facultySlug,
                  assessmentType,
                  'assessmentId': finalAssignment.typeform.formid,
                  locale,
                  assessmentNumber,
                  'assessmentAttemptNumber': updatedAttempts,
                  'assessmentResponseId': finalAssignment?.result?.metadata?.
                      typeform?.response?.token,
                });
              }, 2000);
            },
          },
      );
      reference.open();
    }
  };

  const fetchAssessmentResults = () => {
    let tempAssessmentsAttempted=0;
    const assessmentResults=
    courseData.relationships.modules.data.reduce((acc, cur) => {
      const result=cur?.attributes?.finalassignment?.result||{};
      if (result?.percentile) {
        ++tempAssessmentsAttempted;
      }
      acc.push(result);
      return acc;
    }, []);
    setAssessmentResults(assessmentResults);
    setAssessmentsAttempted(tempAssessmentsAttempted);
    let tempPercentageSum=0;
    assessmentResults.forEach((module, moduleIndex) => {
      if (module.percentile) {
        tempPercentageSum+=module.percentile;
      }
    });
    const tempPercentageAverage=tempPercentageSum/assessmentResults.length;
    setPercentageAverage(Number(tempPercentageAverage).toFixed(2));
  };

  const triggerAssignmentPopover = (event, data) => {
    setShow(data);
    setTarget(event.target);
  };

  /**
   *
   *
   * @param {*} data
   * @return {*} string
   */
  const getBreakDown = (data) => {
    let breakDown = 'FAIL';
    if (data >= 85) {
      breakDown = 'DISTINCTION';
    } else if (data >= 70) {
      breakDown = 'MERIT';
    } else if (data >= 50) {
      breakDown = 'PASS';
    }
    return breakDown;
  };

  const closeRetakeConfirmationBanner = () => {
    setShowRetakeConfirmationBanner(false);
    toggleLoadingSpinner(false);
  };

  return (
    <div className={styles.assessmentsProgressSection}>
      <div className={styles.moduleProgressSection}>
        <p className={styles.heading}>
          Module Results
        </p>
        <div className={styles.moduleProgressList}>
          {/* Iterate through module data here  */}
          {assessmentResults.map((module, moduleIndex) => (
            <div key={moduleIndex} className={styles.moduleProgress}>
              <p className={styles.moduleIndicator}>
                M{moduleIndex+1}
              </p>
              <div className={styles.moduleScoreProgressBar}>
                {/* pass module percentile here  */}
                <div className={styles.progress}
                  style={{width: `${module.percentile}%`}}></div>
              </div>
              {module.percentile?<p className={styles.moduleScore}>
                {module.percentile}%
              </p>:<span className={`library-shaw-icon-new`}>
                <i className="icon-lock-filled"></i>
              </span>}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.courseProgressSection}>
        <div className={styles.progressContent}>
          <div className={styles.headingSection}>
            <p className={styles.heading}>
              Course Progress
            </p>
            <span
              ref={ref}
              className={styles.infoPopoverTrigger}
              onMouseOver={(e) => triggerAssignmentPopover(e, true)}
              onMouseLeave={(e) => triggerAssignmentPopover(e, false)}>
              <span className="library-shaw-icon-new">
                <i className="icon-new-Info"></i>
              </span>
              <Overlay
                show={show}
                target={target}
                placement="top"
                container={ref.current}>
                <Popover placement="top"
                  className={styles.assignmentPopover}
                  id='assignment-popover'>
                  <p className={styles.title}>
                    Marking Breakdown:
                  </p>
                  <p>85 - 100% Distinction</p>
                  <p>70 - 84% Merit</p>
                  <p>50 - 69% Pass</p>
                  <p>&lt;50% Fail</p>
                </Popover>
              </Overlay>
            </span>
          </div>
          <div className={styles.contentSection}>
            <div className={styles.courseBadgeSection}>
              <div className={styles.badge}>
                <img src={`/dashboard-new/assessments/${getBreakDown(
                    percentageAverage).toLowerCase()}-badge.svg`} alt=""/>
              </div>
              <div className={styles.courseScoreSection}>
                <p>Percentage Average<span>{percentageAverage}%</span></p>
                {assessmentsAttempted===assessmentResults.length&&
                  <p>Grade <span>{getBreakDown(percentageAverage)}</span></p>}
              </div>
            </div>
          </div>
          <p className={styles.disclaimer}>
            *Retake your end of module assignments to achieve distinction grade!
          </p>
        </div>
        <div className={styles.progressIndicator}>
          <div className={styles.progressRing}
            style={assessmentResults.length===4?{}:{}}>
            {/* iterate through final assessments and apply active class
            if assessent score exists */}
            {assessmentResults.map((module, moduleIndex) => (
              <div key={moduleIndex} className={`${styles.quarter
              } ${module.percentile?styles.active:''}`}></div>
            ))}
            <div className={styles.cutout}>
              <h3>{assessmentsAttempted}/{assessmentResults?.length}</h3>
              <p> Modules</p>
            </div>
          </div>
          <Dropdown className={styles.retakeDropdown}>
            <Dropdown.Toggle
              className={styles.retakeDropdownToggle}>
              Retakes
            </Dropdown.Toggle>
            <Dropdown.Menu as="ul"
              alignRight className={styles.retakeDropdownMenu}>
              {assessmentResults.map((module, moduleIndex) => {
                const assessmentData=finalAssessmentRetakes?.[moduleIndex]||[];
                return (
                  <Dropdown.Item key={moduleIndex} as="li"
                    style={assessmentData?.attempts==0&&
                      assessmentData?.retakes==0?{cursor: 'not-allowed'}:{}}
                    onClick={() => checkRetakeEligibility(assessmentData)}>
                    <div className={styles.dropdownModuleInfo}>
                      <h4>Module {moduleIndex+1}</h4>
                      {assessmentData?.attempts > 0 &&
                      <p>Retake available</p>}
                    </div>
                    <div className={`${styles.dropdownModuleScore
                    } library-shaw-icon-new`}>
                      {assessmentData?.attempts > 0 ?
                    <p>{module.percentile}%</p> :
                    <i className="icon-lock-filled"></i>}
                    </div>
                  </Dropdown.Item>);
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className={`${styles.shawModalBackdrop
      } ${showLoadingSpinner?styles.active:''}`}>
        {/* <Spinner className="mt-2" animation="border"/> */}
      </div>
      {showRetakeConfirmationBanner &&
      <RetakeConfirmation
        closeModal={closeRetakeConfirmationBanner}
        dataToPass={dataToPass}
        setFinalAssessmentRetakes={props.setFinalAssessmentRetakes}
        finalAssessmentRetakes={finalAssessmentRetakes} />
      }
    </div>
  );
}

AssessmentsProgress.propTypes = {
  locale: PropTypes.string,
  courseData: PropTypes.object,
  studentInfo: PropTypes.object,
  retakePrice: PropTypes.object,
  finalAssessmentRetakes: PropTypes.array,
  setFinalAssessmentRetakes: PropTypes.func,
};

export default AssessmentsProgress;
