import React, {Component} from 'react';
import {Container, Col, Form} from 'react-bootstrap';
import {Link} from '@reach/router';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import * as trackAction from '../Shared/TrackActionService';
import * as authService from './AuthService';
import * as apiService from '../../api';
import {navigate} from 'gatsby';
import cookie from 'react-cookies';
import styles from './LoginPage.module.scss';
import {GoogleReCaptchaProvider, withGoogleReCaptcha} from
  'react-google-recaptcha-v3';


/**
 *
 *
 * @class LoginPage
 * @extends {Component}
 */
class LoginPage extends Component {
  /**
   * Creates an instance of LoginPage.
   * @param {*} props
   * @memberof LoginPage
   */
  constructor(props) {
    super(props);
    const isLoggedIn = false;
    this.state = {
      email: '',
      password: '',
      isLoggedIn,
      hasMounted: false,
      errors: {
        email: '',
        password: '',
      },
      page: 'loginPage',
    };
  }

  /**
   *
   *
   * @memberof LoginPage
   */
  componentDidMount() {
    const {locale} = this.props;
    const studentId = cookie.load('shawacademy_leadId');
    if (studentId) {
      navigate(`/app/${locale}`);
      return;
    }
    this.setState({hasMounted: true});
    setTimeout(() => {
      window.dataLayer.push({
        'event': 'view-page-action',
        'view-page-param': {
          'pageKind': 'LoginPage',
          locale,
        },
      });
    }, 50);
    cookie.save('pageKind', 'LoginPage', {
      path: '',
    });
  }

  /**
   *
   *
   * @param {*} event
   * @memberof LoginPage
   */
  handleChange = (event) => {
    event.preventDefault();
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  /**
   *
   *
   * @memberof LoginPage
   * @return {*} length of errors
   */
  checkValidation = () => {
    const {errors, password} = this.state;
    // email
    // errors.email = authService.checkEmailValidity(email) ? '' :
    //   'Please enter a valid email address.';
    errors.password = password.length ? '' :
      'Please enter your password.';
    this.setState({errors});
    return !errors['email'] && !errors['password'];
  }

  /**
   *
   *
   * @param {*} event
   * @memberof LoginPage
   */
  authorizeUser = (event) => {
    event.preventDefault();
    if (this.checkValidation()) {
      if (this.props.googleReCaptchaProps?.executeRecaptcha) {
        this.props.googleReCaptchaProps.executeRecaptcha('login')
            .then((token) => {
              const requestObj = {
                email: this.state.email,
                password: this.state.password,
                captcha_token_v3: token,
              };
              new apiService.default.Auth().authenticate(requestObj)
                  .then((response) => {
                    const decodedToken = authService
                        .decodeResponse(response.data.token);
                    const expires = new Date;
                    expires.setFullYear(expires.getFullYear() + 2);
                    cookie.save('shawacademy_leadId',
                        decodedToken.payload.sub, {
                          path: '/',
                          domain: process.env.CookieDomain,
                          expires,
                        });
                    cookie.save('auth_token', response.data.token, {
                      path: '/',
                      domain: process.env.CookieDomain,
                      expires,
                    });
                    trackAction.loggedInAction(this.props.locale);
                    const redirectUrl=
                    authService.getParameterByName('redirect_url');
                    const redirectCookie = cookie.load('redirectUrl');
                    if (redirectCookie) {
                      this.props.navigate(redirectCookie);
                    } else if (redirectUrl) {
                      window.open(redirectUrl, '_self');
                    } else {
                      this.props.navigate(`/app/${this.props.locale}`);
                    }
                  })
                  .catch((error) => {
                    const err = error?.response?.data?.errors[0];
                    this.setState({
                      errorMsg: err?.detail,
                    });
                  });
            });
      }
    } else {
      setTimeout(() => {
        const emptyErrors = {
          email: '',
          password: '',
        };
        this.setState({
          errors: emptyErrors,
        });
      }, 5000);
    }
  }

  /**
   *
   *
   * @param {*} token
   * @memberof LoginPage
   */


  /**
   *
   *
   * @return {*}
   * @memberof LoginPage
   */
  render() {
    const locale = this.props.locale;
    const {email, password} = this.state.errors;

    return (
      <>
        {this.state.hasMounted &&
      <div className={styles.loginWrapper}>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <Container className={styles.shFormContainer}>
          <Form
            noValidate
            className={styles.shForm} onSubmit={this.authorizeUser}>
            <Form.Text className={styles.title}>
                  Login
            </Form.Text>
            <Form.Text className={`${styles.subText} ${styles.freeTrailInfo}`}
              style ={{fontSize: '14px'}}>
                  Enjoy unlimited learning | 4-week FREE course
            </Form.Text>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className={styles.label}>
                    Email address
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                placeholder={`abc@example.com`}
                className={`${styles.inputStyle} invalid`}
              />
              {email && <div className={styles.errorWrapper}>{email}</div>}
            </Form.Group>

            <Form.Group controlId={'formBasicPassword'}>
              <Form.Label className={styles.label}>
                    Password
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                placeholder="********"
                className={ `${styles.inputStyle} invalid`}
              />
              {this.state?.errorMsg ?
                <Col className={styles.errorWrapper}>
                  {this.state?.errorMsg}</Col> : null
              }
              {password && <div className={styles.errorWrapper}>
                {password}</div>}
            </Form.Group>
            <Form.Group className={styles.formRight}>
              <Link to={`/app/${locale}/forgot`}
                className={styles.shLink}>
                Forgot Password?
              </Link>
            </Form.Group>
            <button type="submit" className={styles.shBtn}>
                  Login
            </button>
            <Form.Text className={styles.shFormFooter}>
              Don&apos;t have an account yet? &nbsp;
              <Link to="/" className={styles.shLink}>
                    Join Shaw Academy
              </Link>
            </Form.Text>
          </Form>
        </Container>
      </div>}
      </>
    );
  }
}

const LoginPageCaptchaWrapper = withGoogleReCaptcha(LoginPage);

/**
 *
 *
 * @class LoginPageWrapper
 * @extends {Component}
 */
class LoginPageWrapper extends Component {
  /**
   *
   *
   * @return {*}
   * @memberof LoginPageWrapper
   */
  render() {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.CaptchaKey}
      >
        <LoginPageCaptchaWrapper {...this.props}/>
      </GoogleReCaptchaProvider>
    );
  }
}

export default LoginPageWrapper;

LoginPage.propTypes = {
  navigate: PropTypes.any,
  locale: PropTypes.string,
  googleReCaptchaProps: PropTypes.any,
};
