import cookie from 'react-cookies';
import * as apiService from '../../api';
import {registrationAction} from '../Shared/TrackActionService';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

export const getModuleData = (body, activeRegId) => {
  const selectedKey = body.selectedKey;
  const courseRegistration = body.registrationData.filter((item) => {
    return (item.id === activeRegId);
  });
  return getSelectedModuleInfo(selectedKey, courseRegistration[0]);
};

export const modifiedData = (body) => {
  const registrations = body.data;
  const includedData = body.included;
  return mergeData(registrations, includedData);
};

export const getCoursesWithAddons = (addonInfo, studentInfo) => {
  const registeredCourseInfo =
    mergeData(studentInfo?.data, studentInfo?.included);
  const mergedAddonInfo = getMergedAddonInfo(addonInfo);
  return getCoursesToRender(registeredCourseInfo, mergedAddonInfo);
};

export const moduleLength = (registeredCourses, courseRegId) => {
  if (registeredCourses && registeredCourses.length) {
    for (const course of registeredCourses) {
      if (course.id === courseRegId) {
        return course.relationships.modules.data;
      }
    }
    return [];
  }
};

/**
 *
 *
 * @param {*} key
 * @param {*} courseRegistration
 * @return {*} module
 */
function getSelectedModuleInfo(key, courseRegistration) {
  const modulesData = courseRegistration?.relationships?.modules?.data;
  for (const [i, module] of modulesData.entries()) {
    if (i === key-1) {
      return module;
    }
  }
}


/**
 *
 *
 * @param {*} courseData
 * @param {*} addonData
 * @return {*} modifiedCoureData
 */
function getCoursesToRender(courseData, addonData) {
  const leadId = cookie.load('shawacademy_leadId');
  if (courseData?.length) {
    for (const course of courseData) {
      for (const module of course.relationships.modules.data) {
        if (addonData?.length) {
          for (const addon of addonData) {
            if ((addon.indexOf(module.id) > -1 ||
            addon.indexOf('certificate-') > -1) &&
              (addon.indexOf('cert') > -1 ||
             cookie.load(`cert_${module.id}_${leadId}`))) {
              if (addon === `${module.id}-module-cert` ||
              addon.indexOf('cert') >= 0 ||
              cookie.load(`cert_${module.id}_${leadId}`)) {
                module['ecertPurchased'] = true;
              }
            }
            if ((addon.indexOf(module.id) > -1 ||
            addon.indexOf('toolkit-') > -1 ||
            addon.indexOf(module.attributes.moduleslug) > -1) &&
              (addon.indexOf('toolkit') > -1 ||
            cookie.load(`toolkit_${module.id}_${leadId}`))) {
              if (addon === `${module.id}-module-toolkit` ||
              addon.indexOf('toolkit') >= 0 ||
              cookie.load(`toolkit_${module.id}_${leadId}`)) {
                module['toolkitPurchased'] = true;
              }
            }
            if (addon === '-toolkit') {
              module['toolkitPurchased'] = true;
            }
            if (addon === '-certificate') {
              module['ecertPurchased'] = true;
            }
            if (addon.indexOf('hard-copy-diploma') > -1 ||
            cookie.load(`hcd_${module.id}_${leadId}`)) {
              if (addon === `${module.id}-module-hard-copy-diploma` ||
              addon === `${module.id}-course-hard-copy-diploma` ||
              cookie.load(`hcd_${module.id}_${leadId}`)) {
                course.attributes['hcdPurchased'] = true;
              }
            }
          }
        }
      }
    }
    return courseData;
  }
}

/**
 *
 *
 * @param {*} addonData
 * @return {*} addonArray
 */
function getMergedAddonInfo(addonData) {
  const attributes = addonData?.getAddonInfo?.attributes;
  const finalAddonArray = [];
  if (attributes) {
    if (attributes.chargebee) {
      for (const item of attributes.chargebee.line_items) {
        finalAddonArray.push(item.entity_id);
      }
    }
    if (attributes.razorpay) {
      for (const item of attributes.razorpay.orders) {
        finalAddonArray.push(item.notes.addon_id);
      }
    }
    if (attributes.itunes) {
      for (const item of attributes.itunes.receipts) {
        finalAddonArray.push(item.product_id);
      }
    }
    if (attributes.default) {
      for (const item of attributes.default.items) {
        finalAddonArray.push(`${item.course_slug}-${item.product}`);
      }
    }
  }
  return finalAddonArray;
};

/**
 *
 *
 * @param {*} courseRegistrations
 * @param {*} includedData
 * @return {*} modifiedResponse
 */
function mergeData(courseRegistrations, includedData) {
  if (courseRegistrations && courseRegistrations.length) {
    if (includedData && includedData.length) {
      for (const courseRegistration of courseRegistrations) {
        const moduleRegistrationsData =
          courseRegistration?.relationships?.modules?.data;
        if (moduleRegistrationsData.length > 1) {
          courseRegistration.attributes['hcdAvailable'] = true;
        } else {
          courseRegistration.attributes['hcdAvailable'] = false;
        }
        courseRegistration.attributes['hcdPurchased'] = false;
        for (const registration of moduleRegistrationsData) {
          registration['toolkitPurchased'] = false;
          registration['ecertPurchased'] = false;
          const filteredRegistration = includedData.filter((item) => {
            return item.id === registration.id;
          });
          registration['attributes'] = filteredRegistration[0].attributes;
        }
        const upcomingLessons = [];
        for (const [i, module] of moduleRegistrationsData.entries()) {
          for (const lesson of module.attributes.lessons) {
            const today = new Date();
            if (lesson.start_time) {
              courseRegistration['activeModule'] = i + 1;
            }
            if (lesson.start_time && new Date(lesson.start_time) >= today) {
              upcomingLessons.push(lesson);
            }
            courseRegistration['nextLesson'] = upcomingLessons[0];
          }
        }
      }
      return courseRegistrations;
    }
  }
  return [];
};

// Dashboard V2
export const getCurrentRegistrationData = (registrationData, regId) => {
  return registrationData.filter((registration) =>
    (registration.id === regId))[0];
};

/**
 *
 *
 * @param {*} day
 * @param {*} time
 * @return {*}
 */
function getCourseStartDate(day, time) {
  const currentDay = dayjs().weekday();
  let selectedDate;
  if (day >= currentDay) {
    selectedDate = dayjs().weekday(day).toDate();
  } else {
    const increment = day + 7;
    selectedDate = dayjs().weekday(increment).toDate();
  }
  const newDate = dayjs(selectedDate).set('hour', time)
      .set('minute', 0).set('second', 0);
  return newDate.toISOString();
}

/**
 *
 *
 * @param {*} courseStartDate
 * @param {*} lessonLength
 * @return {*}
 */
function getSchedule(courseStartDate, lessonLength) {
  const arr = [];
  let date;
  let numberOfDayToAdd = 0;
  for (let i = 0, len = lessonLength.length/2; i < len; i++) {
    date = dayjs(courseStartDate).toDate();
    courseStartDate = dayjs(date).add(numberOfDayToAdd, 'd').toDate();
    numberOfDayToAdd = 7;
    arr.push(courseStartDate);
  }
  return arr;
}

/**
 *
 *
 * @param {*} dayOne
 * @param {*} dayTwo
 * @return {*}
 */
function getFinalSchedule(dayOne, dayTwo) {
  const lessonArray = [];
  const unSortedFinalArray = [...dayOne, ...dayTwo];
  const sortedFinalArray = unSortedFinalArray.sort((date1, date2) => {
    return date1.getTime() - date2.getTime();
  });
  sortedFinalArray.forEach((lesson, index) => {
    const lessons = {};
    lessons['number'] = index+1;
    lessons['start_time'] = dayjs(lesson).toISOString();
    lessonArray.push(lessons);
  });
  return lessonArray;
}

export const createSchedule = (scheduleData, regData, activeModule = 0) => {
  const leadId = cookie.load('shawacademy_leadId');
  const dayOne = parseInt(scheduleData['dayOne'], 10);
  const dayTwo = parseInt(scheduleData['dayTwo'], 10);
  let dayOneToCreateSchedule = dayOne;
  let dayTwoToCreateSchedule = dayTwo;
  const timeOne = parseInt(scheduleData['timeOne'], 10);
  const timeTwo = parseInt(scheduleData['timeTwo'], 10);
  const currentDay = dayjs().weekday();
  const lessonLength = regData.relationships.modules
      .data[activeModule].attributes.lessons;
  if (dayOne <= currentDay) {
    dayOneToCreateSchedule = dayOne + 7;
  }
  if (dayOne > dayTwo) {
    dayTwoToCreateSchedule = dayTwo + 7;
  }
  const dayOneStartDate = getCourseStartDate(dayOneToCreateSchedule, timeOne);
  const dayTwoStartDate = getCourseStartDate(dayTwoToCreateSchedule, timeTwo);
  const dayOneSchedule = getSchedule(dayOneStartDate, lessonLength);
  const dayTwoSchedule = getSchedule(dayTwoStartDate, lessonLength);
  const finalSchedule = getFinalSchedule(dayOneSchedule, dayTwoSchedule);
  const dataToSend = {
    finalSchedule,
    dayOne,
    dayTwo,
    timeOne,
    timeTwo,
    leadId,
    courseSlug: regData.attributes['courseslug'],
  };

  let moduleName = 'intermediate';
  if (activeModule >= 2) {
    moduleName = activeModule == 2 ? 'advanced' :
    'proficient';
  }
  const scheduleToSend = createScheduleToSendToApi(dataToSend,
      false, moduleName);
  return scheduleToSend;
};

export const fireStoreAuth = (authInfo, regId, slug, lesson,
    activeModuleData, moduleRegId) => {
  const studentId = cookie.load('shawacademy_leadId');
  return {
    regId: regId,
    moduleRegistrationId: moduleRegId,
    slug: activeModuleData?.activeModuleInfo?.attributes?.moduleslug,
    moduleNumber: activeModuleData?.moduleNumber,
    courseSlug: slug,
    leadId: studentId,
    lessonNumber: lesson.number,
    fireBaseAuth: authInfo?.firebase_auth_token,
    fireStoreAuth: authInfo?.firestore_auth_token,
  };
};

/**
 *
 *
 * @param {*} data
 * @param {*} isFirstModuleSchedule
 * @param {*} moduleName
 * @return {*}
 */
export function createScheduleToSendToApi(data, isFirstModuleSchedule,
    moduleName) {
  const dataToSend = {
    'data': {
      'type': 'registrations',
      'attributes': {
        'course_start_date': data.finalSchedule[0].start_time,
        'course_slug': moduleName+'-'+data.courseSlug,
        'schedule': {
          'first_day_of_week': data.dayOne === 0 ? 7 : data.dayOne,
          'second_day_of_week': data.dayTwo === 0 ? 7 : data.dayTwo,
          'first_day_hour': data.timeOne,
          'second_day_hour': data.timeTwo,
          'lessons': data.finalSchedule,
        },
      },
      'relationships': {
        'student': {
          'data': {
            'type': 'student',
            'id': data.leadId,
          },
        },
      },
    },
  };
  if (isFirstModuleSchedule) {
    dataToSend.data.id = data.regId;
    dataToSend.data.attributes['course_slug'] = data.courseSlug;
  }
  return dataToSend;
}

/**
 *
 *
 * @export
 * @param {*} data
 */
export function rescheduleNextLesson(data) {
  const {activeModuleData, activeModule, locale, studentId,
    courseSlug, nextLessonNumber, regId} = data;
  if (activeModuleData) {
    const updatedLessonArray=
      activeModuleData.attributes?.lessons?.map((lesson)=>{
        if (lesson.number===nextLessonNumber) {
          lesson['start_time']=new Date(new Date().getTime() +
          10000).toISOString();
        }
        return {number: lesson.number, start_time: lesson['start_time']};
      },
      );
    const firstLesson =activeModuleData.attributes?.lessons?.[0];
    const secondLesson =activeModuleData.attributes?.lessons?.[1];
    let firstDayOfTheWeek=new Date(firstLesson.start_time).getDay();
    let secondDayOfTheWeek=new Date(secondLesson.start_time).getDay();
    const firstHourOfTheWeek = new Date(firstLesson.start_time).getHours();
    const secondHourOfTheWeek = new Date(secondLesson.start_time).getHours();
    if (!firstDayOfTheWeek) {
      firstDayOfTheWeek = 7;
    }
    if (!secondDayOfTheWeek) {
      secondDayOfTheWeek = 7;
    }
    const dataToSend = {
      'data': {
        'type': 'registrations',
        'id': regId,
        'attributes': {
          'schedule': {
            'first_day_of_week': firstDayOfTheWeek,
            'second_day_of_week': secondDayOfTheWeek,
            'first_day_hour': firstHourOfTheWeek,
            'second_day_hour': secondHourOfTheWeek,
            'lessons': updatedLessonArray,
            'lessons_per_week': activeModuleData?.attributes?.lessons_per_week,
          },
        },
        'relationships': {
          'student': {
            'data': {
              'type': 'student',
              'id': studentId,
            },
          },
        },
      },
    };
    new apiService.default.Schedule(regId).update(dataToSend)
        .then(() => {
          registrationAction({
            'moduleNumber': parseInt(activeModule),
            courseSlug,
            'facultySlug': activeModuleData?.attributes?.facultyslug,
            'lessonNumber': parseInt(nextLessonNumber),
            locale,
            'rescheduleType': 'playNow',
          });
          const webinarDomain=process.env.WebinarDomain;
          const webinarUrl=`${webinarDomain}/en/webinar?registration_id=${
            regId}&lead_id=${studentId}`;
          window.location.href = webinarUrl;
        }).catch(()=> {
        });
  }
}

/**
 *
 *
 * @export
 * @param {*} module
 * @return {*}
 */
export function getPercentageLessonsComplete(module) {
  const totalLessonsCount = module?.attributes?.lessons?.length;
  const now=dayjs();
  const lessonsCompleted = module?.attributes?.lessons?.filter(
      (lesson, index) => {
        const startTime=dayjs(lesson['start_time']);
        if (now.isAfter(startTime.clone().add(30, 'minutes'))) {
          return true;
        }
        return false;
      });
  const completedLessonsCount = lessonsCompleted?.length;
  const percentageLessonComplete = completedLessonsCount !== 0 &&
      totalLessonsCount !== 0 ?
        (completedLessonsCount*100)/totalLessonsCount : 0;
  return percentageLessonComplete;
}

export const dataToReturn = (addonPrices, courseData,
    addon, locale, moduleNumber) => {
  const addonName = addon === 'hard-copy-diploma' ? 'hcd' : addon;
  const currencyInfo = addonPrices[addonName];
  const studentId=cookie.load('shawacademy_leadId');
  const moduleData=courseData?.relationships?.modules?.data || [];
  const activeModuleData=moduleData[moduleNumber-1];
  const moduleSlug=activeModuleData?.attributes?.moduleslug;
  let title='CourseToolkitTwoClickPopup';
  let cta='Get your Toolkit-modal';
  let product = 'CourseToolkit';
  let productName = 'TOOLKIT';
  let textInfo = 'Course Materials';
  if (addonName==='cert') {
    product = 'CourseEcert';
    productName = 'CERT';
    textInfo = 'Digital Certificates';
    title='CourseEcertTwoClickPopup';
    cta='Get Your Certificate-modal';
  }
  if (addonName==='hcd') {
    product = 'HardCopyDiploma';
    productName = 'HCD';
    textInfo = 'Hard Copy Diploma';
    title='CourseDiplomaConfirmationPopUp';
    cta='Get your Diploma-modal';
  }
  return {
    param: addonName,
    title,
    cta,
    courseSlug: courseData?.attributes?.courseslug,
    moduleSlug,
    courseName: courseData?.attributes?.coursename,
    moduleNumber,
    locale,
    pageKind: 'LessonsPage',
    studentId,
    priceInfo: currencyInfo,
    product,
    textInfo,
    productName,
    regId: courseData?.id,
  };
};

const buildScheduleObject = (currentModuleData, lessonsPerWeek,
    updatedLessonArray) => {
  const tempScheduleObj = {};
  if (lessonsPerWeek>=1) {
    const firstLesson =currentModuleData?.lessons?.[0];
    const firstDayOfTheWeek=new Date(firstLesson.start_time).getDay();
    const firstHourOfTheWeek = new Date(firstLesson.start_time).getHours();
    tempScheduleObj['first_day_of_week']=firstDayOfTheWeek;
    tempScheduleObj['first_day_hour']=firstHourOfTheWeek;
  }
  if (lessonsPerWeek>=2) {
    const secondLesson =currentModuleData?.lessons?.[1];
    const secondDayOfTheWeek=new Date(secondLesson.start_time).getDay();
    const secondHourOfTheWeek = new Date(secondLesson.start_time).getHours();
    tempScheduleObj['second_day_of_week']=secondDayOfTheWeek;
    tempScheduleObj['second_day_hour']=secondHourOfTheWeek;
  }
  if (lessonsPerWeek===3) {
    const thirdLesson =currentModuleData?.lessons?.[2];
    const thirdDayOfTheWeek = new Date(thirdLesson.start_time).getDay();
    const thirdHourOfTheWeek = new Date(thirdLesson.start_time).getHours();
    tempScheduleObj['third_day_of_week']=thirdDayOfTheWeek;
    tempScheduleObj['third_day_hour']=thirdHourOfTheWeek;
  }
  tempScheduleObj['lessons']=updatedLessonArray;
  return tempScheduleObj;
};

export const setUpdatedLessonsData = ({moduleRegId, currentModuleData,
  updatedLessonArray, studentId, lessonsPerWeek}) => {
  const scheduleObject =
   buildScheduleObject(currentModuleData, lessonsPerWeek, updatedLessonArray);
  const payload ={
    'data': {
      'type': 'registrations',
      'id': moduleRegId,
      'attributes': {
        'skip_integration_event': true,
        'schedule': scheduleObject,
      },
      'relationships': {
        'student': {
          'data': {
            'type': 'student',
            'id': studentId,
          },
        },
      },
    },
  };
  new apiService.default.Schedule(moduleRegId).update(payload)
      .catch((err)=>{
        console.log(err);
      });
};
