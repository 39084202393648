import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {navigate} from 'gatsby';
import * as trackAction from '../TrackActionService';
import cookie from 'react-cookies';

/**
 *
 *
 * @export
 * @class Footer
 * @extends {Component}
 */
export default class Footer extends Component {
  /**
   *
   *
   * @param {*} param
   * @return {*}
   * @memberof Footer
   */
  openAppLinks(param) {
    trackAction.ctaClickAction({
      'studentId': cookie.load('shawacademy_leadId'),
      'cta': (param === 'ios') ? 'appStore' : 'playStore',
      'action': 'getNativeApp',
    });
    if (param === 'ios') {
      window.open('https://itunes.apple.com/app/apple-store/id1240131410?pt=118508823&ct=popUpBanner&mt=8', '_blank');
      return null;
    }
    window.open('https://play.google.com/store/apps/details?id=com.shawacademy.app&referrer=utm_source%3Dmembers-area%26utm_medium%3Dbanner%26utm_content%3D%26utm_campaign%3Drecording-page', '_blank');
  }

  /**
   *
   *
   * @param {*} locale
   * @memberof Footer
   */
  languagePicker(locale) {
    navigate(`/app/${locale}`);
  }
  /**
   *
   *
   * @return {*}
   * @memberof Footer
   */
  render() {
    return (
      <>
        <div className="sh-footer-container">
          <div className="sh-internal-wrapper">

            {/* {
              <div className="footer-language-selector-mobile">
                <div className="locale-label">
                  Site Languages: </div>
                <div className="language-selector-wrapper-1">
                  <span onClick={() =>
                    this.languagePicker('en')}>
                      🇬🇧&nbsp;&nbsp;&nbsp;English</span>
                  <span onClick={() =>
                    this.languagePicker('fr')}>
                     🇫🇷&nbsp;&nbsp;&nbsp;Français
                  </span>
                  <span onClick={() =>
                    this.languagePicker('it')}>
                     🇮🇹&nbsp;&nbsp;&nbsp;Italiano
                  </span>
                </div>
                <div className="language-selector-wrapper-2">
                  <span onClick={() =>
                    this.languagePicker('de')}>
                      🇩🇪&nbsp;&nbsp;&nbsp;Deutsch</span>
                  <span onClick={() =>
                    this.languagePicker('pt')}>🇵🇹&nbsp;&nbsp;&nbsp;Português
                  </span>
                  <span onClick={() =>
                    this.languagePicker('es')}>
                      🇪🇸&nbsp;&nbsp;&nbsp;Español</span>
                </div>
              </div>
            } */}

            <div className="sh-social-privacy">
              {/* {
                <div className="footer-language-selector">
                  <div className="language-selector-wrapper-1">
                    <span className="locale-label">
                      Site Languages: </span>
                    <span onClick={() =>
                      this.languagePicker('en')}>🇬🇧&nbsp;&nbsp;English</span>
                    <span onClick={() =>
                      this.languagePicker('fr')}>🇫🇷&nbsp;&nbsp;Français</span>
                    <span onClick={() =>
                      this.languagePicker('it')}>🇮🇹&nbsp;&nbsp;Italiano</span>
                  </div>
                  <div className="language-selector-wrapper-2">
                    <span onClick={() =>
                      this.languagePicker('de')}>🇩🇪&nbsp;&nbsp;Deutsch</span>
                    <span onClick={() =>
                      this.languagePicker('pt')}>🇵🇹&nbsp;&nbsp;Português</span>
                    <span onClick={() =>
                      this.languagePicker('es')}>🇪🇸&nbsp;&nbsp;Español</span>
                  </div>
                </div>} */}
              <div>Copyright © 2023 Shaw Academy, Pvt. Ltd.</div>
              <ul>
                <a href='https://www.shawacademy.com/terms/' target="__blank">
                  Terms of Use |&nbsp;
                </a>
                <a href='https://www.shawacademy.com/privacy/' target="__blank">
                  Privacy Policy
                </a>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Footer.propTypes = {
  eligibleForNewDashboard: PropTypes.bool,
};

