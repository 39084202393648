import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';
import * as dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import style from './Leaderboard.module.scss';
import upArrow from '../../../../static/images/leaderboard/up_arrow.svg';
import closeFilter from
  '../../../../static/images/leaderboard/close_filter.svg';
import cookie from 'react-cookies';
import * as trackAction from '../../Shared/TrackActionService';
import {Tab, Nav} from 'react-bootstrap';
import CountryTab from './CountryTab';
import PeopleJoined from './PeopleJoined';
import * as CONSTANTS from '../../../containers/Constants';
import {KeyValuePair} from '../../Shared';
import MigratePopup from '../../Shared2/Popup/MigratePopup';
import * as commonService from '../../Shared2/Agent/CommonService';

dayjs.extend(weekOfYear);
const Leaderboard = (props) => {
  const studentCountry = props.studentInfo?.address?.country;
  const studentId=cookie.load('shawacademy_leadId');
  const [leaderboardStatus, setLeaderboardStatus] = useState({
    imgSrc: '',
    lead_id: studentId,
    name: {
      'first_name': '',
      'last_name': '',
    },
  });
  const pointsKeyValues = [
    {
      key: 'Finish an end of module assignment',
      value: '500',
    },
    {
      key: 'Add course',
      value: '200',
    },
    {
      key: 'Finish a lesson live',
      value: '200',
    },
    {
      key: 'Watch a lesson live',
      value: '100',
    },
    {
      key: 'Finish a lesson recording',
      value: '100',
    },
    {
      key: 'Add profile picture',
      value: '100',
    },
    {
      key: 'Watch a lesson recording',
      value: '50',
    },
    {
      key: 'Finish a weekly assignment',
      value: '50',
    },
    {
      key: 'Visit course content page',
      value: '20/day',
    },
    {
      key: 'Download a course resource',
      value: '20',
    },
  ];
  const pointTableTabLabel = new KeyValuePair(pointsKeyValues);
  const pointTableKeys = Object.keys(pointTableTabLabel.keyValuePair);
  const [mobilePointsTable, setMobilePointsTable] = useState(false);
  const [mobilePointsAccountTable, setMobilePointsAccountTable] =
    useState(false);
  const [country, setCountry] = useState(studentCountry);
  const [tab, setTab] = useState('people-joined');
  const [showFilter, setShowFilter] = useState(false);
  const [leaderBoards, setLeaderBoards]=useState([]);
  const [filterName, setFilter] = useState('');
  const [isOriginAccount, setIsOriginAccount] = useState(false);
  // skeleton loader
  const [loading, setLoading] = useState(null);
  const [showMigratePopup, setShowMigratePopup] = useState(false);

  useEffect(()=>{
    trackAction.pageViewAction(
        'LeaderBoardsPage', props.locale);

    const country = getCountry();
    const createdAtSubString=studentId.toString().substring(0, 8);
    const createdAt=new Date(parseInt(createdAtSubString, 16)*1000);
    const {year, month, day}=
      getTimeStamps(createdAt);
    const timeStamp=`${year}${month}${day}`;
    getDataFromFirestore(`signupday_${timeStamp}_${country}`);

    if (props?.origin === 'account') {
      setIsOriginAccount(true);
    }
  }, []);

  useEffect(() => {
    if (props.studentInfo) {
      if (props.studentInfo?.membership?.status === 'inactive' ||
        commonService.checkGiftMembership(props.studentInfo?.membership)) {
        setShowMigratePopup(true);
      }
    }
  }, [props.studentInfo]);

  const createUserData = (data, index) => {
    const dataToSend = {
      ...data,
      imgSrc: `https://shaw-profile-pic.s3.amazonaws.com/${data['lead_id']}.jpeg`,
    };
    if (typeof index !== 'undefined') {
      dataToSend.rank=index+1;
    }
    return dataToSend;
  };

  const getDataFromFirestore = (collectionName) => {
    // loader for skeleton
    setLoading(true);

    const {db}=require('../../Shared2/Firebase');
    const response=db.collection(collectionName) // with limit
        .where('score', '>', 0)
        .orderBy('score', 'desc').limit(10);

    // find the score of the current user

    const loggedUserResponse=db.collection(collectionName) // without limit
        .where('lead_id', '==', studentId);

    // query for the first 10 docs
    // if the user leadId exists in the 10 - quit
    // else query for all collections
    let isUserPresent=false;
    let userStatus=null;
    let leaderBoardsToSet;
    response.get().then((snapshot) => {
      if (!snapshot.docs.length) {
        setLeaderBoards([]);

        // loader for skeleton
        setLoading(false);
      } else {
        leaderBoardsToSet=snapshot.docs.map((doc, index) => {
          if (doc.exists) {
            const userLeadId=doc.data()['lead_id'];
            if (userLeadId===studentId) {
              isUserPresent=true;
              userStatus=createUserData(doc.data(), index);
            }
            const alteredData=createUserData(doc.data(), index);
            return alteredData;
          }
        });
        if (isUserPresent) {
          setLeaderboardStatus(userStatus);
        }
        // sets a list of first 10
        if (!isUserPresent) {
          loggedUserResponse.get().then((snapshot) => {
            snapshot.docs.map((doc) => {
              if (doc.exists) {
                userStatus=createUserData(doc.data());
                setLeaderboardStatus(userStatus);
              }
            });
          });
        }

        if (leaderBoardsToSet?.length) {
          setLeaderBoards(leaderBoardsToSet.slice(0, 10));

          // loader for skeleton
          setLoading(false);
        }
      }
    });
  };

  const getCountry = () => {
    let countryToSet=studentCountry || 'ie';
    if (CONSTANTS.euCountryList.includes(countryToSet)) {
      countryToSet='eu';
    } else if (CONSTANTS.latCountryList.includes(countryToSet)) {
      countryToSet='lat';
    } else if (CONSTANTS.leaderBoardsEigibleCountries.includes(countryToSet)) {
    } else {
      countryToSet='row';
    }
    setCountry(countryToSet);
    return countryToSet;
  };

  const getTimeStamps = (date) => {
    let today=new Date();
    if (date) {
      today=date;
    }
    const year = today.getFullYear();
    const month = today.getMonth()+1 < 10 ?
    `0${today.getMonth()+1}` : today.getMonth()+1; // month stars from 0
    const weekNumber = dayjs().isoWeek();

    const dateObj={
      year,
      month,
      weekNumber,
    };
    if (date) {
      dateObj['day']=today.getDate() < 10 ?
      `0${today.getDate()}` : today.getDate();
    }
    return dateObj;
  };

  const closeFilterMenu = () => {
    setShowFilter(false);
    filterHandler('lifetime');
  };

  const filterHandler = (filterName='') => {
    setFilter(filterName);
    let {year, month, weekNumber}=getTimeStamps();
    let day;
    let timeStamp;
    if (filterName==='signupday') {
      const createdAtSubString=studentId.toString().substring(0, 8);
      const createdAt=new Date(parseInt(createdAtSubString, 16)*1000);
      ({year, month, weekNumber, day}=
        getTimeStamps(createdAt));
    }
    switch (filterName) {
      case 'weekly':
        // ${month}
        timeStamp=`${year}${weekNumber}`;
        getDataFromFirestore(`${filterName}_${timeStamp}_${country}`);
        ctaClickHandler('This week', 'ThisWeekFilter');
        break;
      case 'monthly':
        timeStamp=`${year}${month}`;
        getDataFromFirestore(`${filterName}_${timeStamp}_${country}`);
        ctaClickHandler('This month', 'ThisMonthFilter');
        break;
      case 'signupday':
        timeStamp=`${year}${month}${day}`;
        getDataFromFirestore(`${filterName}_${timeStamp}_${country}`);
        break;

      default: getDataFromFirestore(`lifetime_${country}`);
        filterName!=''?ctaClickHandler('overall', 'overallFilter'):null;
        break;
    }
  };

  const ctaClickHandler = (cta, action) =>{
    trackAction.ctaClickAction({
      studentId,
      'cta': cta,
      'action': action,
      'pageKind': 'LeaderBoardsPage',
      'locale': props.locale,
    });
  };

  const tabSwitchHandler = (ctaName, ctaAction, tabName) => {
    ctaClickHandler(ctaName, ctaAction);
    setTab(tabName);
    if (tabName === 'people-joined') {
      filterHandler('signupday');
    } else {
      filterHandler();
    }
  };

  return (
    <>
      <div className={style.leaderboardWrapper}>
        {mobilePointsTable &&
        <div className={style.backdrop}></div>}
        <div className={`shaw-container flex-space-between ${
        isOriginAccount ? style.accountPadding : '' }`}
        style={{minHeight: '100vh'}}>
          <div className={isOriginAccount ? style.shawRecordings :
          `${style.shawRecordings} ${style.accountRecordings}`}>
            <Tab.Container className={style.shawRecordings}
              defaultActiveKey='people-joined'>
              <p className={style.title}>
                {
                isOriginAccount ? '' :
                <img
                  src={upArrow}
                  alt="backArrow"
                  className={style.backArrow}
                  onClick={()=>navigate(-1)}
                />
                }
                <span className={style.customWidth}>
                  Leaderboard
                </span>
                <span
                  className={style.mobilePointTableText}
                  onClick={()=>{
                  isOriginAccount ?
                  setMobilePointsAccountTable(!mobilePointsAccountTable) :
                  setMobilePointsTable(!mobilePointsTable);
                  }}>
                  {
                  mobilePointsAccountTable && isOriginAccount ?
                  'Rankings' :
                  `Points Guide`
                  }
                </span>
              </p>
              {
              mobilePointsAccountTable && isOriginAccount ?
              <div className={style.mobilePointTable}>
                <h1>Leaderboard Point Guide</h1>
                {pointTableKeys.map((data, index) => (
                  <p key={index}>
                    <span>{data}</span>
                    <span>{`${pointTableTabLabel.getValue(data)} XP`}</span>
                  </p>))}
                <div
                  className={style.closeBtn}
                  onClick={()=>setMobilePointsTable(!mobilePointsTable)}>
                  <span>X</span>
                </div>
              </div> :
              <>
                <div className={`${style.wrapper} ${style.flexCol}`}>
                  <Nav variant="pills"
                    className={style.tabSwitch}
                    as="ul"
                    activeKey="people-joined"
                  >
                    <Nav.Item
                      as="li"
                      className={style.tab}
                      onClick={() =>
                        tabSwitchHandler(CONSTANTS.countryMapping[country],
                            'switchTabsLeaderboard', 'country')}
                    >
                      <Nav.Link eventKey="country">
                        <span>{CONSTANTS.countryMapping[country]}</span>
                        <span className={style.border}></span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className={style.tab}
                      onClick={() =>
                        tabSwitchHandler('Joined with me',
                            'switchTabsLeaderboard',
                            'people-joined')}
                    >
                      <Nav.Link eventKey="people-joined">
                        <span>My Class</span>
                        <span className={style.border}></span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {tab==='country'&&<div className={style.filterText}
                    onClick={()=>setShowFilter(!showFilter)}>
                    <img />
                    <span>Filter</span>
                  </div>}
                </div>
                {tab==='country'&&showFilter &&
                <div className={style.filters}>
                  <span
                    onClick={()=>filterHandler('lifetime')}
                    className={filterName==='lifetime'?filterName:''}>
                        Overall
                  </span>
                  <span
                    className={filterName==='weekly'?filterName:''}
                    onClick={()=>filterHandler('weekly')}>
                        This week
                  </span>
                  <span
                    onClick={()=>filterHandler('monthly')}
                    className={filterName==='monthly'?filterName:''}>
                        This month
                  </span>
                  <span
                    onClick={closeFilterMenu}
                    className={style.closeFilter}
                  >
                    <img src={closeFilter} alt="close-filter" />
                  </span>
                </div>
                }
                {
                  !isOriginAccount ?
                  <div className={mobilePointsTable?style.mobilePointTable:
                    `${style.mobilePointTable} ${style.displayNone}`}>
                    <h1>Leaderboard Point Guide</h1>
                    {pointTableKeys.map((data, index) => (
                      <p key={index}>
                        <span>{data}</span>
                        <span>{`${pointTableTabLabel.getValue(data)} XP`}</span>
                      </p>))}
                    <div
                      className={style.closeBtn}
                      onClick={()=>setMobilePointsTable(!mobilePointsTable)}>
                      <span>X</span>
                    </div>
                  </div> : null
                }
                <Tab.Content>
                  <Tab.Pane eventKey="country">
                    <CountryTab
                      leaderBoards={leaderBoards}
                      leaderboardStatus={leaderboardStatus}
                      pointTableTabLabel={pointTableTabLabel}
                      pointTableKeys={pointTableKeys}
                      skeletonLoader={loading}
                      isOriginAccount={isOriginAccount}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="people-joined">
                    <PeopleJoined
                      leaderBoards={leaderBoards}
                      leaderboardStatus={leaderboardStatus}
                      pointTableTabLabel={pointTableTabLabel}
                      pointTableKeys={pointTableKeys}
                      skeletonLoader={loading}
                      isOriginAccount={isOriginAccount}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </>
              }
            </Tab.Container>
          </div>
        </div>
      </div>
      {showMigratePopup &&
        <MigratePopup />}
    </>
  );
};
Leaderboard.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
  registrationData: PropTypes.array,
  origin: PropTypes.string,
  studentInfo: PropTypes.object,
};
export default Leaderboard;
