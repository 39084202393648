import cookie from 'react-cookies';
import * as trackAction from '../Shared/TrackActionService';

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export function addonBannerData(props) {
  const studentId=cookie.load('shawacademy_leadId');
  const moduleSlug=
  props?.activeModuleData?.attributes?.moduleslug;
  const courseSlug=props?.courseData?.attributes?.courseslug;
  const courseName=props?.courseData?.attributes?.coursename;
  const toolkitAddonData=props?.addonPrices?.toolkit;
  const objToPass = {
    param: 'toolkit',
    title: 'ToolkitConfirmationPopup',
    cta: 'Get your Toolkit-lessonPage',
    courseSlug,
    moduleSlug,
    courseName,
    moduleNumber: props?.activeModule,
    locale: props?.locale,
    pageKind: 'LessonsPage',
    studentId,
    priceInfo: toolkitAddonData,
    product: 'CourseToolkit',
    textInfo: 'Course Materials',
    productName: 'TOOLKIT',
    regId: props?.moduleRegistrationId,
  };
  return objToPass;
}

/**
 *
 *
 * @export
 * @param {*} data
 *
 */
export function showLessonResource(data) {
  const {lessonsDisplayProps, filePath, title,
    triggerVimeoPlayer, getDataToAddonBanner, resourceType,
    resourceEligibility, showAddonBanner}=data;
  const number=lessonsDisplayProps.lessonIndex+1;
  const studentId=cookie.load('shawacademy_leadId');
  const dataObj={
    lessonsDisplayProps,
    filePath,
    title,
    number,
  };
  const {dataToTrack}=objectsToPass(dataObj);
  if (resourceEligibility) {
    eligibleToViewFile(filePath, number, resourceType, triggerVimeoPlayer);
    trackAction.downloadClickAction(dataToTrack);
    return;
  }
  getDataToAddonBanner(addonBannerData(lessonsDisplayProps));
  trackAction.ctaClickAction({
    studentId,
    'cta': 'LessonMaterialsLocked',
    'action': `Lesson${number}MaterialsClicked`,
  });
  showAddonBanner();
}

/**
 *
 *
 * @param {*} data
 * @return {*}
 */
function objectsToPass(data) {
  const {lessonsDisplayProps, filePath, title, number}=data;
  const props=lessonsDisplayProps;
  const firstName=props.membershipInfo?.name?.first_name;
  const lastName=props.membershipInfo?.name?.last_name;
  const localizedcourseslug=props.courseData?.attributes.localizedcourseslug;
  const moduleNumber=props.activeModule;
  const contentType=props?.lesson?.resources?.slug;
  const studentId=cookie.load('shawacademy_leadId');
  const dataToPass={
    firstName,
    lastName,
    localizedcourseslug,
    moduleNumber,
    contentType,
    title,
    filePath,
    number,
  };
  const dataToTrack = {
    studentId,
    courseSlug: localizedcourseslug,
    moduleNumber,
    contentType,
    resourceType: 'Toolkit',
    fileName: title,
  };
  return {
    dataToPass,
    dataToTrack,
  };
}

/**
 *
 *
 * @param {*} filePath
 * @param {*} number
 * @param {*} resourceType
 * @param {*} triggerVimeoPlayer
 */
function eligibleToViewFile(
    filePath, number, resourceType, triggerVimeoPlayer) {
  const studentId=cookie.load('shawacademy_leadId');
  if (resourceType==='mp4') {
    triggerVimeoPlayer(filePath);
  } else {
    window.open(filePath);
  }
  trackAction.ctaClickAction({
    studentId,
    'cta': 'LessonMaterialsUnlocked',
    'action': `Lesson${number}MaterialsClicked`,
  });
};

