import React from 'react';
import style from
  '../../../RegistrationDetailV2/Leaderboard/Leaderboard.module.scss';

/**
 *
 *
 * @return {*}
 */
function PeopleJoinedSkeleton() {
  return (
    <div className={`${style.countryTabWrapper} ${style.skeletonWrap}`}>
      <div className={style.innerWrapper}>
        <div className={style.accordionWrapper}>
          <div className={`${style.wrapper} accordionOpen`}>
            <div className={style.textWrapper}>
            </div>
          </div>
        </div>
        {new Array(4).fill(undefined).map((_, index) =>(
          <div key={index} className={style.wrapper}>
          </div>
        ))}
      </div>
      <div className={style.pointTable}>
      </div>
    </div>
  );
};
export default PeopleJoinedSkeleton;
