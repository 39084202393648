import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from '../GetToolkitPopup.module.scss';
import Countdown from 'react-countdown';
import AddonsConfirmation from './AddonsConfirmation';
import * as utils from '../../Shared/Utils';
import * as trackAction from '../../Shared/TrackActionService';
import * as purchaseAction from '../Banner/AddonPurchaseActionService';
import {Spinner} from 'react-bootstrap';

const ToolkitPopup = (props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInitialPopUp, setShowInitialPopUp] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const {closeModal, toolkitPopupData, toolkitPrices} = props;
  const isConsentEligible = utils.checkPurchaseConsentEligibility();

  useEffect(() => {
    trackAction.popUpAction({
      'studentId': toolkitPopupData?.studentId,
      'title': 'Toolkit',
      'courseSlug': toolkitPopupData?.courseSlug,
      'pageKind': 'LessonsPage',
    });
  }, []);

  const confirmPopup = (param) => {
    const cta = 'Get your Toolkit-modal';
    const action = isConsentEligible ?
    'PurchaseToolkitPopup': 'PurchaseToolkit';
    const product = 'CourseToolkit';
    const productName = 'TOOLKIT';
    const textInfo = 'Course Materials';
    const priceInfo = toolkitPrices;
    trackAction.ctaClickAction({
      cta,
      pageKind: toolkitPopupData?.pageKind,
      action,
      studentId: toolkitPopupData?.studentId,
      courseSlug: toolkitPopupData?.courseSlug,
      moduleNumber: toolkitPopupData?.moduleNumber,
      locale: toolkitPopupData?.locale,
    });
    setDataToPassToConfirmation({
      param: 'toolkit',
      title: 'CourseToolkitTwoClickPopup',
      cta: 'Get your Toolkit-modal',
      courseSlug: toolkitPopupData?.courseSlug,
      moduleSlug: toolkitPopupData?.moduleSlug,
      courseName: toolkitPopupData?.courseName,
      moduleNumber: toolkitPopupData?.moduleNumber,
      locale: toolkitPopupData?.locale,
      pageKind: toolkitPopupData?.pageKind,
      studentId: toolkitPopupData?.studentId,
      priceInfo,
      product,
      textInfo,
      productName,
      regId: toolkitPopupData?.regId,
    });
    if (isConsentEligible) {
      setShowConfirmModal(true);
      setShowInitialPopUp(false);
      return;
    }
    purchaseAddon();
  };

  const purchaseAddon = () => {
    setShowSpinner(true);
    const objToPass = {
      currencyCode: toolkitPrices?.currency_code,
      activeTierCode: toolkitPrices?.default_tier_code,
      addon: 'toolkit',
      country: toolkitPrices.country_code,
      slug: toolkitPopupData?.courseSlug,
      cta: 'Get Your Toolkit-modal',
      moduleNumber: parseInt(toolkitPopupData?.moduleNumber),
      courseName: toolkitPopupData?.courseName,
      studentId: toolkitPopupData?.studentId,
      regId: toolkitPopupData?.regId,
      locale: toolkitPopupData?.locale,
      isEqfCourse: true,
      moduleSlug: toolkitPopupData?.moduleSlug,
      pageKind: toolkitPopupData?.pageKind,
    };
    purchaseAction.addonPurchaseAction(objToPass);
  };

  const showFirstAddonsPopUp = () => {
    setShowConfirmModal(false);
    setShowInitialPopUp(true);
  };

  // timer date
  const TimerRenderer = ({formatted}) => {
    return <div className={styles.timerWrapper}>
      <p>&nbsp;{formatted.minutes} Minutes</p>
      <span>&nbsp;:&nbsp;</span>
      <p>{formatted.seconds} Seconds</p>
    </div>;
  };
  return (
    <div className={styles.overlay}>
      {showInitialPopUp &&
      <div className={styles.container}>
        <div className={styles.toolkitContentSection}>
          <div
            onClick={closeModal}
            className={`${styles.closeButton} library-shaw-icon-new`} >
            <i className="icon-new-close"></i>
          </div>
          <div className={styles.leftSection}>
            <div className={styles.leftTopContent}>
              <h1>Get All Your Course Materials</h1>
              <p>Click Get Your Course Materials to learn more about
                  your path to success in 2023! unLOCKING your Course
                  Materials will give you unlimited access to all your
                  course notes, lesson slides and bonus content for this
                  module. Breakfree from LOCKdowns. unLOCK your future now!</p>
            </div>
            <div className={styles.leftMiddleContent}>
              <h3>
                Where our students work:
              </h3>
              <img className={styles.leftlogo} src="/images/toolkit-logo.png" />
            </div>
            <div className={styles.leftBottomContent}>
              <h3>
                What our students say:
              </h3>
              <div className={styles.reviewSection}>
                <div className={styles.reviewLeftSection}>
                  <img src="/images/toolkit-reviewer1.png" />
                  <p>&quot;Thank you so much for everything you have
                    helped me with so far!!&quot;
                  </p>
                </div>
                <div className={styles.reviewRightSection}>
                  <img src="/images/toolkit-reviewer2.png" />
                  <p>&quot;The information is stunningly laid out.&quot;</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.verticalLine}>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.mobileTagLine}>
              <h1>
                Get All Your Course Materials
              </h1>
            </div>
            <div className={styles.rightTopContent}>
              <div className={styles.timerBlock}>
                <h3>Ends In :</h3>
                <Countdown
                  renderer={TimerRenderer}
                  date={Date.now() + 300000}>
                </Countdown>
              </div>
              <img src="/images/toolkit-course-card.png" />
            </div>
            <div className={styles.rightMiddleContent}>
              <h3>
                {toolkitPopupData.courseName}
              </h3>
            </div>
            <div className={styles.rightBottomContent}>
              <h3>
                Course Materials
              </h3>
              {!isConsentEligible &&
                  <h2> 75 % OFF {' '}
                    {toolkitPrices?.currency_symbol}{' '
                    }{toolkitPrices?.default_tier_price}
                  </h2>}
              <h2><span>75% OFF</span></h2>
              <div className={styles.graduatesText}>
                <p>1,957,110 graduates</p>
                <p>&#x2B50; 4.8</p>
              </div>
              <button onClick={confirmPopup} disabled={showSpinner}>
                Get Your Course Materials
              </button>
              {!isConsentEligible && <p className={styles.oneClickText}>
                (One click purchase)
              </p>}
              {showSpinner && <div className={styles.loader}>
                <Spinner animation="border" />
              </div>}
            </div>
          </div>
        </div>
      </div>}
      {showConfirmModal &&
        <AddonsConfirmation
          dataToPassToConfirmation={dataToPassToConfirmation}
          showAddonsPopUp={showFirstAddonsPopUp} />}
    </div>
  );
  TimerRenderer.propTypes = {
    formatted: PropTypes.object,
    completed: PropTypes.bool,
  };
};

ToolkitPopup.propTypes = {
  pageData: PropTypes.object,
  popupData: PropTypes.object,
  commonTexts: PropTypes.object,
  toolkitPrices: PropTypes.object,
  closeModal: PropTypes.func,
  toolkitPopupData: PropTypes.object,
};
export default ToolkitPopup;
