import React from 'react';
import styles from '../../Account/v2/account.module.scss';

/**
 *
 *
 * @export
 * @return {*}
 */
export default function AccountSekeleton() {
  return (
    <div className={`shaw-container flex-space-between ${styles.skeletonWrap}`}>
      <div className={styles.accountContainer}>
        <div className={`${styles.sideBar}`}>
          <div className={styles.sidebarList}>
            {
              [1, 2, 3, 4, 5, 6].map((ele, key) => {
                return <>
                  <div key={key}
                    className={styles.sidebarMenu}>
                    <div className={styles.leftItem}>
                    </div>
                  </div>
                </>;
              })
            }
            <div className={styles.logoutCta}></div>
          </div>
        </div>
        <div className={`${styles.mainComponent}`}>
          <div className={styles.profileHeader}>
            <div className={styles.imgContainer}>
              <div
                className={styles.imgUpload}> </div>
            </div>
            <div className={styles.profileContainer}>
            </div>
          </div>
          <div className={styles.dataLayer}>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
          </div>
          <div className={styles.titleText}>
          </div>
          <div className={styles.dataLayer}>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
          </div>
          <div className={styles.titleText}>
          </div>
          <div className={styles.dataLayer}>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
            <div className={styles.keyValueContainer}>
              <div className={styles.keyText}>
              </div>
              <div className={styles.valueText}>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
