import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {Modal} from 'react-bootstrap';
import videoStyles from '../VideoPlayer.module.scss';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function VideoPlayer(props) {
  const {vimeoId}=props;
  const url=`https://player.vimeo.com/video/${vimeoId}`;
  const popupRef=useRef(null);

  const handleClickOutside = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      props.closePopup();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={videoStyles.videoPlayer}>
      <div className={`${videoStyles.shawModalBackdrop}
      ${!!vimeoId?videoStyles.active:''}`}></div>

      <div className={videoStyles.modalContentWrapper}>

        <Modal.Dialog
          ref={popupRef}
          className={videoStyles.videoPlayerModal}>
          <div className={videoStyles.shawModalVideoClose}
            onClick={props.closePopup}>
            <img src="/popups/cross.svg" alt="close"/>
          </div>
          <ReactPlayer
            width='100%'
            height='100%'
            url={url}
            controls={true}
          />
        </Modal.Dialog>
      </div>
    </div>
  );
}

VideoPlayer.propTypes = {
  vimeoId: PropTypes.string,
  closePopup: PropTypes.func,
  dataToFireBase: PropTypes.object,
};

export default VideoPlayer;
