import React, {useState, useRef, useEffect} from 'react';
import styles from './index.module.scss';
import Helmet from 'react-helmet';
import HeaderNew from '../Shared/HeaderNew';
import {navigate, useLocation} from '@reach/router';
import PropTypes from 'prop-types';
import {SvgLoader, SvgProxy} from 'react-svgmt';
import {jsPDF} from 'jspdf';
import * as apiService from '../../api';
import {pageViewAction, ctaClickAction, downloadClickAction}
  from '../Shared/TrackActionService';
import CertificateBanner from './CertificateBanner';
import CertificateAction from './CertificateAction';
import MigratePopup from '../Shared2/Popup/MigratePopup';
import * as commonService from '../Shared2/Agent/CommonService';

const ModuleECertificate = (props) => {
  const {locale, studentInfo, publicCertUrl} = props;

  const location = useLocation();
  const {registrationData, ecertAddonPrice, moduleNumber,
    courseSlug, courseName, studentId, pageKind,
    moduleSlug, moduleName, courseRegId, formId,
    responseId, totalModules, ecertPurchased} = location?.state;

  const svgRef = useRef(null);
  const [issueYear, setIssueYear] = useState('');
  const [issueMonth, setIssueMonth] = useState('');
  const [showError, setError] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [disableCta, setDisableCta] = useState(true);
  const certificateUrl = encodeURIComponent(
      `${process.env.ShawWebsiteDomain}${publicCertUrl}/` +
    `?form_id=${formId}&response_id=${responseId}`);

  const [showMigratePopup, setShowMigratePopup] = useState(false);

  useEffect(()=>{
    if (ecertPurchased) {
      fetchCertificateData();
    }
    pageViewAction('CertificatePage', locale);
    // hiding video ask widget
    const videoAskCloseModal =
       document.querySelector('.videoask-embed__hide_button--f6kKj');
       videoAskCloseModal?.click();
  }, []);

  useEffect(() => {
    if (studentInfo) {
      if (studentInfo?.membership?.status === 'inactive' ||
        commonService.checkGiftMembership(studentInfo?.membership)) {
        setShowMigratePopup(true);
      }
    }
  }, [studentInfo]);

  const fetchCertificateData = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    new apiService.default.Certificate()
        .getCertificateData(formId, responseId).then((res) => {
          const leadData = ({...res.data, date: today});
          if (!leadData['user_name']) {
            throw new Error;
          }
          setDisableCta(false);
          setLeadData(leadData);
          const dateArray = leadData?.date?.split('/');
          setIssueYear(dateArray[2]);
          setIssueMonth(dateArray[0]);
        }).catch(() => {
          setError(true);
          setDisableCta(true);
        });
  };

  const generatePDFData = () => {
    const data = {
      studentId,
      courseSlug,
      moduleNumber,
      contentType: 'Certificate',
      resourceType: 'Certificate',
      fileName: 'certificate.pdf',
    };
    downloadClickAction(data);
    const certificateRef = svgRef?.current.querySelector('svg');
    const jsPdfInstance = new jsPDF();
    jsPdfInstance.svg(certificateRef).then((res) => {
      res.save('certificate.pdf');
    });
  };

  const navigateToLessonsPage = () => {
    const urlToNavigate = `/app/${locale}/v2/registrations/${
      courseRegId}/modules/${moduleNumber}`;
    navigate(urlToNavigate);
  };

  const addToLinkedInProfile = () => {
    const linkedInUrl = 'https://www.linkedin.com/profile/add?startTask=';
    ctaClickAction({
      studentId,
      'cta': 'LinkedIn',
      'action': 'AddToProfile',
    });
    const urlToNavigate =
      `${linkedInUrl}CERTIFICATION_NAME` +
      `&name=${leadData?.course_name}` +
      `&organizationId=5038433` +
      `&issueYear=${issueYear}&issueMonth=${issueMonth}` +
      `&expirationYear=${parseInt(issueYear) + 5}` +
      `&expirationMonth=${issueMonth}` +
      `&certUrl=${certificateUrl}` +
      `&certId=${responseId}`;
    window.open(urlToNavigate);
  };

  return (
    <>
      {!showError ?
      <div className={styles.mainContainer}>
        <Helmet>
          <title>Certificate</title>
        </Helmet>
        <HeaderNew
          registrationData={registrationData}
          firstName={studentInfo?.name?.first_name}
          locale={locale}
        />
        <div className={styles.certificateContainer}>
          <div className={styles.topSection}>
            <img onClick={navigateToLessonsPage}
              src='/images/back-img.png' alt='back' />
            <h1>{moduleName}</h1>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.leftContainer}>
              {ecertPurchased ?
              <div ref={svgRef} className={styles.svgWrapper}>
                <SvgLoader
                  path={`/images/module-certificate/certificate-${locale}.svg`}>
                  <SvgProxy
                    selector="#resultPath">
                    {leadData['grade']}
                  </SvgProxy>
                  <SvgProxy
                    selector="#datePath">
                    {leadData['date']}
                  </SvgProxy>
                  <SvgProxy
                    selector="#namePath">
                    {leadData['user_name']}
                  </SvgProxy>
                  <SvgProxy
                    selector="#moduleNamePath">
                    {`Module ${moduleNumber} of ${totalModules}: ${courseName}`}
                  </SvgProxy>
                </SvgLoader>
              </div> :
              <img className={styles.blurrImage}
                src={`/images/module-certificate/certificate-${locale}.svg`}
                alt='certificate'/>}
            </div>
            <div className={styles.rightContainer}>
              {!ecertPurchased &&
                <CertificateBanner
                  ecertAddonPrice={ecertAddonPrice}
                  moduleNumber={moduleNumber}
                  courseRegId={courseRegId}
                  courseSlug={courseSlug}
                  courseName={courseName}
                  studentId={studentId}
                  locale={locale}
                  pageKind={pageKind}
                  moduleSlug={moduleSlug}
                />}
              <CertificateAction
                certificateUrl={certificateUrl}
                generatePDFData={generatePDFData}
                addToLinkedInProfile={addToLinkedInProfile}
                disableCta={disableCta}
              />
            </div>
          </div>
        </div>
      </div> : <></>}
      {showMigratePopup &&
        <MigratePopup />}
    </>
  );
};

ModuleECertificate.propTypes = {
  locale: PropTypes.string,
  studentInfo: PropTypes.object,
  credits: PropTypes.number,
  ecertAddonPrice: PropTypes.object,
  moduleNumber: PropTypes.number,
  courseSlug: PropTypes.string,
  courseName: PropTypes.string,
  studentId: PropTypes.string,
  pageKind: PropTypes.string,
  pageData: PropTypes.object,
  moduleSlug: PropTypes.string,
  moduleName: PropTypes.string,
  formId: PropTypes.string,
  responseId: PropTypes.string,
  purchased: PropTypes.bool,
  courseRegId: PropTypes.string,
  publicCertUrl: PropTypes.string,
  totalModules: PropTypes.number,
  ecertPurchased: PropTypes.bool,
};

export default ModuleECertificate;
