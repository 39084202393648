import React, {useEffect} from 'react';
import Helmet from 'react-helmet';
import '../SalesOfferConfirmation.module.scss';
import {popUpAction, ctaClickAction} from '../../Shared/TrackActionService';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';

const StickyAddon = (props) => {
  const {moduleNumber, pageKind, locale, courseSlug}=props;
  const studentId=cookie.load('shawacademy_leadId');
  useEffect(()=>{
    window.VIDEOASK_EMBED_CONFIG = {
      'kind': 'widget',
      'url': 'https://www.videoask.com/f9gneywhj',
      'options': {
        'widgetType': 'VideoThumbnailWindow',
        'text': '',
        'backgroundColor': '#0B3587',
        'position': 'bottom-right',
        'dismissable': true,
        'modalType': 'Fullscreen',
        'widgetZIndex': 100,
      },
    };
    const isVideoaskMessage = (message) =>
      message.origin === 'https://www.videoask.com' && message.data &&
      message.data.type && message.data.type.startsWith('videoask_');

    window.addEventListener('message', (message) => {
      if (!isVideoaskMessage(message)) {
        return;
      }
      if (message?.data?.type === 'videoask_question_submitted') {
        if (message?.data?.optionId ===
          '2c24ff9c-d40f-4e90-a864-ed30757da45d') {
          window.top.postMessage('showToolkitConfirmation', '*');
          ctaClickAction({
            studentId,
            locale,
            'cta': 'Course Materials',
            'action': 'InitiateToolkitPurchase',
          });
        } else if (message?.data?.optionId ===
          '9c3c7980-88ed-4a7b-bfa6-8a1311982560') {
          window.top.postMessage('showCertConfirmation', '*');
          ctaClickAction({
            studentId,
            locale,
            'cta': 'Certificate',
            'action': 'InitiateEcertPurchase',
          });
        } else if (message?.data?.optionId ===
          '51ff5778-a943-4ef5-b617-9d576b9b5c53') {
          window.top.postMessage('showHcdConfirmation', '*');
          ctaClickAction({
            studentId,
            locale,
            'cta': 'Hard Copy Diploma',
            'action': 'InitiateHCDPurchase',
          });
        }
      }
    });
    popUpAction({
      studentId,
      pageKind,
      title: 'Get Your Addons',
      locale,
      courseSlug,
      moduleNumber,
      variation: 'Default',
    });
  }, []);

  return (
    <Helmet>
      <script src="https://www.videoask.com/embed/embed.js"></script>
    </Helmet>
  );
};

StickyAddon.propTypes = {
  moduleNumber: PropTypes.number,
  pageKind: PropTypes.string,
  courseSlug: PropTypes.string,
  locale: PropTypes.string,
};

export default StickyAddon;

