import React, {useEffect, useState} from 'react';
import cookie from 'react-cookies';
import {navigate} from 'gatsby';
import * as typeformEmbed from '@typeform/embed';
import * as trackAction from '../Shared/TrackActionService';
import PropTypes from 'prop-types';
import styles from '../../styles/Courses.module.scss';
import RetakeConfirmation from '../Shared2/Popup/RetakeConfirmation';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function FinalAssignment(props) {
  const {moduleNumber, moduleData, courseName, registrationData,
    courseRegId, assignment, finalAssessmentRetakes,
    pageKind, totalModules} = props;
  const studentId = cookie.load('shawacademy_leadId');
  const courseSlug = props.courseSlug;
  const locale = props.locale;
  const regId = moduleData?.attributes?.registration_id;
  const purchased = props.ecertPurchased;
  const lessons=moduleData?.attributes.lessons;
  const [assignmentEligible, setAssignmentEligible]=useState(false);
  const [showRetakeConfirmationBanner, setShowRetakeConfirmationBanner]=
  useState(false);
  const finalAssignment=assignment?.modular_blocks?.[0];

  useEffect(() => {
    if (regId) {
      getFinalAssignmentStatus(lessons);
    }
  }, []);

  /**
   *
   *
   * @param {*} lessons
   */
  const getFinalAssignmentStatus = (lessons) => {
    // TODO: move this to parent level
    if (lessons && lessons.length) {
      if ((new Date(lessons[lessons.length - 1].end_time).getTime() <=
        new Date().getTime())) {
        setAssignmentEligible(true);
      }
    }
  };

  /**
   *
   *
   */
  const viewEcert = () => {
    const formId=finalAssignment?.typeform?.formid;
    const responseId=
    assignment.result.metadata.typeform.response?.token;
    trackAction.ctaClickAction({
      cta: 'View Certificate',
      action: 'ViewCertifcatePage',
      pageKind: 'AssessmentPage',
      moduleSlug: moduleData?.attributes?.moduleslug,
    });
    navigate(`/app/en/certificate/`, {
      state: {
        formId,
        responseId,
        registrationData,
        ecertAddonPrice: props?.addonPrices,
        moduleNumber,
        courseSlug,
        courseName,
        studentId,
        pageKind,
        moduleSlug: moduleData?.attributes?.moduleslug,
        moduleName: moduleData?.attributes?.modulename,
        courseRegId,
        totalModules,
        ecertPurchased: purchased,
      },
    });
  };

  const openAssignment = (action) => {
    if (assignmentEligible) {
      const {studentInfo}=props;
      const assessmentType='Final';
      const assessmentNumber=5;
      const facultySlug=moduleData?.attributes.facultyslug;
      let link;
      const firstName = studentInfo?.name?.first_name;
      const lastName = studentInfo?.name?.last_name;
      const userName = `${firstName} ${lastName}`;
      if (finalAssignment?.typeform) {
        link = finalAssignment.typeform?.formid;
        trackAction.assignmentClickAction({
          action,
          studentId,
          courseSlug,
          moduleNumber,
          facultySlug,
          assessmentType,
          'assessmentId': link,
          locale,
          assessmentNumber,
        });
        const reference = typeformEmbed.makePopup(
            // shawacademy.typeform.com
            `https://form.typeform.com/to/${link}#student_id=${studentId}&course_slug=${encodeURIComponent(courseSlug)}&course_name=${encodeURIComponent(courseName)}&module_slug=${encodeURIComponent(moduleData?.attributes.moduleslug)}&module_name=${encodeURIComponent(moduleData?.attributes?.modulename)}&module_number=${moduleNumber}&course_regid=${courseRegId}&user_name=${userName}&locale=${locale}&assessment_number=5&faculty_slug=${facultySlug}&sub_brand=${process.env.BrandSlug}&stage=${process.env.AppicationEnv}&assessment_type=final`,
            {
              hideHeaders: true,
              hideFooters: true,
              autoClose: 0.1,
              onSubmit: function(event) {
                const updatedRetakes=parseInt(finalAssessmentRetakes?.
                    retakes)-1;
                const updatedAttempts=
                parseInt(finalAssessmentRetakes?.attempts)+1;
                props.setFinalAssessmentRetakes({
                  retakes: updatedRetakes,
                  attempts: updatedAttempts,
                  moduleNumber: moduleNumber,
                }, moduleNumber-1);
                // setRetakes(updatedRetakes);
                // setAttempts(updatedAttempts);
                setTimeout(() => {
                  trackAction.assignmentCompleteAction({
                    studentId,
                    courseSlug,
                    moduleNumber,
                    facultySlug,
                    assessmentType,
                    'assessmentId': assignment?.metadata?.
                        typeform?.response?.form_id,
                    locale,
                    assessmentNumber,
                    'assessmentAttemptNumber': updatedAttempts,
                    'assessmentResponseId': assignment.result?.metadata?.
                        typeform?.response?.token,
                  });
                }, 2000);
              },
            },
        );
        reference.open();
      } else {
        link = finalAssignment?.link?.link?.href;
        window.open(link);
      }
    }
  };

  const closeRetakeConfirmationBanner = () => {
    setShowRetakeConfirmationBanner(false);
    setDisabled(false);
  };

  return (
    // TODO: This will have 3 states
    // locked, unlocked & completed
    <div className={styles.finalAssignmentBlock}>
      <div>
        <div onClick={() => !assignment?.result && openAssignment('Start Now')}
          className={`${styles.finalAssignmentUnlocked} ${
        (assignmentEligible)?styles.active:''}`}>
          <div className={styles.finalAssignmentUnlockedLeftSection}>
            <p className={styles.finalAssignmentUnlockedHeading}>
              End of Module Assignment&nbsp; 🏆
            </p>
            {assignment?.result?
            <div className={styles.finalAssignmentUnlockedBadgeSection}>
              <div className={styles.finalAssignmentBadge}>
                <img src={`/dashboard-new/assessments/${assignment?.result.
                    breakDown?.toLowerCase()}-badge.svg`} alt=""/>
              </div>
              <p className={styles.finalAssignmentPercentile}>
                {assignment?.result?.percentile}%
              </p>
            </div>:
            <>
              <div className={styles.finalAssignmentUnlockedInfo}>
                <div className={`${styles.finalAssignmentUnlockedIcon
                } library-shaw-icon-new`}>
                  <i className="icon-lock-filled"></i>
                </div>
                <p className={styles.finalAssignmentUnlockedQuestions}>
                  ~80 Questions
                </p>
              </div>
              <span className={styles.finalAssignmentDisclaimer}>
                  Note: After completing the assignment, please visit this
                  section after 15 mins to view your score.
              </span>
            </>}
          </div>
          <div className={styles.finalAssignmentUnlockedRightSection}>
            {assignment?.result ?
          <div className={styles.finalAssignmentCtaSection}>
            <div className={styles.retakeAssignmentCta}
              onClick={() => openAssignment('Retake')}>
                Retake
            </div>
          </div> :
          <i className={styles.arrow}></i>}
          </div>
        </div>
        {assignment?.result&&
        assignment?.result?.breakDown?.toLowerCase() !== 'fail' ?
        <div>
          <div className={styles.viewCertCta} onClick={viewEcert}>
            <div className={styles.certificateWrapper}>
              <img src='/dashboard-new/courses/badge-certificate.svg'></img>
              <p>View certificate</p>
            </div>
            <div className="library-shaw-icon-new">
              <i className="icon-new-Right-arrow-4"></i>
            </div>
          </div>
        </div> : ''}
      </div>
      {/* } */}
      {showRetakeConfirmationBanner &&
      <RetakeConfirmation
        closeModal={closeRetakeConfirmationBanner}
        dataToPass={dataToPass}
        setFinalAssessmentRetakes={props.setFinalAssessmentRetakes}
        finalAssessmentRetakes={finalAssessmentRetakes} />
      }
    </div>
  );
}

FinalAssignment.propTypes = {
  addonPrices: PropTypes.object,
  courseSlug: PropTypes.string,
  moduleNumber: PropTypes.number,
  locale: PropTypes.string,
  courseName: PropTypes.string,
  ecertPurchased: PropTypes.bool,
  isEqfCourse: PropTypes.bool,
  pageKind: PropTypes.string,
  certficateContent: PropTypes.object,
  oneClickText: PropTypes.string,
  cta: PropTypes.string,
  moduleData: PropTypes.object,
  assignment: PropTypes.object,
  studentInfo: PropTypes.object,
  courseData: PropTypes.object,
  retakePrice: PropTypes.object,
  courseRegId: PropTypes.string,
  finalAssessmentRetakes: PropTypes.object,
  setFinalAssessmentRetakes: PropTypes.func,
  isActiveMember: PropTypes.bool,
  registrationData: PropTypes.array,
  totalModules: PropTypes.number,
};

export default FinalAssignment;
