import * as apiService from '../../../api/index';
import {navigate} from 'gatsby';
import {ctaClickAction} from '../../Shared/TrackActionService';

export const getSubscriptionPlanData = (leadCountry) => {
  if (leadCountry) {
    return new apiService.default.Prices().getPrices()
        .then((res)=>{
          const quarterPrices=
          res?.data?.header?.pricesThreeMonth?.prices?.[leadCountry] ||
          res?.data?.header?.pricesThreeMonth?.prices?.default;
          const annualPrices=
          res?.data?.header?.pricesTwelveMonth?.prices?.[leadCountry] ||
          res?.data?.header?.pricesTwelveMonth?.prices?.default;
          const priceList=res?.data?.header?.pricesThreeMonth?.prices;
          return {
            quarterly: {
              price: quarterPrices?.price,
              totalPrice: quarterPrices?.total_price,
            },
            annual: {
              price: annualPrices?.price,
              totalPrice: annualPrices?.total_price,
            },
            currencySymbol: quarterPrices?.cur_symbol,
            priceList,
          };
        });
  }
};

export const checkUpgradeNowEligibility = (moduleData) => {
  const lessonFourEndTime=
  new Date(moduleData?.[0]?.attributes?.lessons[3]?.end_time);
  return lessonFourEndTime<new Date();
};

export const openUpgradeNowPopup = (eventData, navigationData) => {
  const {studentId, locale, cta, action, pageKind}=eventData;
  const {regId, duration, activeModule}=navigationData;
  ctaClickAction({
    studentId,
    locale,
    cta,
    action,
    pageKind,
  });
  const urlToNavigate=`/app/${locale}/v2/registrations/${
    regId}/modules/${activeModule}/sales-offer-confirmation/${duration}`;
  navigate(urlToNavigate);
};

export const defaultPriceObject = {
  quarterly: {
    price: '',
    totalPrice: '',
  },
  annual: {
    price: '',
    totalPrice: '',
  },
  currencySymbol: '',
  priceList: {},
};
