import * as apiService from '../../../api/index';

export const createChargebeeSessionService = (leadId) => {
  const data = {};
  data['student_id'] = leadId;
  let chargebeeInstance = Chargebee.init({
    site: process.env.AppicationEnv === 'staging' ?
      'shawacademy-test' : 'shawacademy',
  });
  chargebeeInstance = Chargebee.getInstance();
  chargebeeInstance.setPortalSession(() => {
    return new Promise((resolve, reject) => {
      const apiServiceObj = new apiService.Subscription();
      return apiServiceObj.createChargebeeSession(data)
          .then((res) => {
            resolve(res.data);
          }).catch((err) => {
            console.log(err);
          });
    });
  });
  const cbPortal = chargebeeInstance.createChargebeePortal();
  cbPortal.open({
    close: function() {
      chargebeeInstance.logout();
    },
  });
};
