import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from '../GetHardCopy.module.scss';
import Countdown from 'react-countdown';
import AddonsConfirmation from './AddonsConfirmation';
import * as utils from '../../Shared/Utils';
import * as trackAction from '../../Shared/TrackActionService';
import * as purchaseAction from '../Banner/AddonPurchaseActionService';
import {Spinner} from 'react-bootstrap';

const HCDPopup = (props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInitialPopUp, setShowInitialPopUp] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const {closeModal, payloadInputs, hcdPrices} = props;
  const isConsentEligible = utils.checkPurchaseConsentEligibility();

  useEffect(() => {
    trackAction.popUpAction({
      'studentId': payloadInputs?.studentId,
      'title': 'HardCopyDiploma',
      'courseSlug': payloadInputs?.courseSlug,
      'pageKind': 'LessonsPage',
    });
  }, []);

  const confirmPopup = () => {
    const cta = 'Get Your Diploma-modal';
    const action ='PurchaseHardCopyDiploma';
    const product = 'HardCopyDiploma';
    const productName = 'HCD';
    const textInfo = 'Hard Copy Diploma';
    const priceInfo = hcdPrices;
    trackAction.ctaClickAction({
      cta,
      pageKind: payloadInputs?.pageKind,
      action,
      studentId: payloadInputs?.studentId,
      courseSlug: payloadInputs?.courseSlug,
      moduleNumber: payloadInputs?.moduleNumber,
      locale: payloadInputs?.locale,
    });
    setDataToPassToConfirmation({
      param: 'hcd',
      title: 'CourseDiplomaConfirmationPopUp',
      cta: 'Get your Diploma-modal',
      courseSlug: payloadInputs?.courseSlug,
      moduleSlug: payloadInputs?.moduleSlug,
      courseName: payloadInputs?.courseName,
      moduleNumber: payloadInputs?.moduleNumber,
      locale: payloadInputs?.locale,
      pageKind: payloadInputs?.pageKind,
      studentId: payloadInputs?.studentId,
      priceInfo,
      product,
      textInfo,
      productName,
      regId: payloadInputs?.regId,
    });
    if (isConsentEligible) {
      setShowConfirmModal(true);
      setShowInitialPopUp(false);
      return;
    }
    purchaseAddon();
  };

  const purchaseAddon = () => {
    setShowSpinner(true);
    const objToPass = {
      currencyCode: hcdPrices?.currency_code,
      activeTierCode: hcdPrices?.default_tier_code,
      addon: 'hard-copy-diploma',
      country: hcdPrices.country_code,
      slug: payloadInputs?.courseSlug,
      cta: 'Get Your Diploma-modal',
      moduleNumber: null,
      courseName: payloadInputs?.courseName,
      studentId: payloadInputs?.studentId,
      regId: payloadInputs?.regId,
      locale: payloadInputs?.locale,
      isEqfCourse: true,
      moduleSlug: payloadInputs?.moduleSlug,
      pageKind: payloadInputs?.pageKind,
    };
    purchaseAction.addonPurchaseAction(objToPass);
  };

  const showFirstAddonsPopUp = () => {
    setShowConfirmModal(false);
    setShowInitialPopUp(true);
  };

  // timer date
  const TimerRenderer = ({formatted}) => {
    return <div className={styles.timerWrapper}>
      <p>&nbsp;{formatted.minutes} Minutes</p>
      <span>&nbsp;:&nbsp;</span>
      <p>{formatted.seconds} Seconds</p>
    </div>;
  };
  return (
    <div className={styles.overlay}>
      {showInitialPopUp && <div className={styles.container}>
        <div className={styles.toolkitContentSection}>
          <div
            onClick={closeModal}
            className={`${styles.closeButton} library-shaw-icon-new`}
          >
            <i className="icon-new-close"></i>
          </div>
          <div className={styles.leftSection}>
            <div className={styles.leftTopContent}>
              <h1>Get Your Hard Copy Diploma
                {!isConsentEligible && <>
                  <br/>
                  {hcdPrices?.currency_symbol}{' '
                  }{hcdPrices?.default_tier_price}
                </>}
              </h1>
              <p>Click Get Your Hard Copy Diploma to show the world in 2023
                that you will not stay LOCKED in! Break free from LOCKdowns…
                unLOCK your future, showcase your success you&apos;re achieving
                in 2023!
              </p>
            </div>
            <div className={styles.leftMiddleContent}>
              <h3>
                Where our students work:
              </h3>
              <img className={styles.leftlogo} src="/images/toolkit-logo.png" />
            </div>
            <div className={styles.leftBottomContent}>
              <h3>
                What our students say:
              </h3>
              <div className={styles.reviewSection}>
                <div className={styles.reviewLeftSection}>
                  <img src="/images/toolkit-reviewer1.png" />
                  <p>&quot;Thank you so much for everything you
                    have helped me with so far!&quot;
                  </p>
                </div>
                <div className={styles.reviewRightSection}>
                  <img src="/images/toolkit-reviewer2.png" />
                  <p>&quot;The information is stunningly laid out.&quot;</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.verticalLine}>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.mobileTagLine}>
              <h1>
                Get Your Hard Copy Diploma
              </h1>
            </div>
            <div className={styles.rightTopContent}>
              <div className={styles.timerBlock}>
                <h3> Ends In :</h3>
                <Countdown
                  renderer={TimerRenderer}
                  date={Date.now() + 300000}>
                </Countdown>
              </div>
              <img src="/images/HCD_web.png" />
            </div>
            <div className={styles.rightMiddleContent}>
              <h3>
                {payloadInputs.courseName}
              </h3>
            </div>
            <div className={styles.rightBottomContent}>
              <h3>
                Course Hard Copy Diploma
              </h3>
              <div className={styles.graduatesText}>
                <p>1,957,110 graduates </p>
                <p>&#x2B50;  4.8</p>
              </div>
              <button onClick={confirmPopup} disabled={showSpinner}>
                Get Your Hard Copy Diploma
              </button>
              {!isConsentEligible && <p className={styles.oneClickText}>
                (One click purchase)
              </p>}
              {showSpinner && <div className={styles.loader}>
                <Spinner animation="border" />
              </div>}
            </div>
          </div>
        </div>
      </div>}
      {showConfirmModal &&
        <AddonsConfirmation
          dataToPassToConfirmation={dataToPassToConfirmation}
          showAddonsPopUp={showFirstAddonsPopUp} />}
    </div>
  );
  TimerRenderer.propTypes = {
    formatted: PropTypes.object,
    completed: PropTypes.bool,
  };
};

HCDPopup.propTypes = {
  hcdPrices: PropTypes.object,
  closeModal: PropTypes.func,
  payloadInputs: PropTypes.object,
};

export default HCDPopup;
