import React, {useState} from 'react';
import {Button, Modal, Spinner} from 'react-bootstrap';
import styles from './reactivation.module.scss';
import PropTypes from 'prop-types';
import * as trackAction from '../../Shared/TrackActionService';
import {
  getStudentInfoIncludedService, updateSubscriptionService,
} from './ProfileService';
import {navigate} from 'gatsby';
import {getSubscriptionData} from './AccountUtils';
import cookie from 'react-cookies';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function ReactivationModal(props) {
  const leadId = cookie.load('shawacademy_leadId');
  const [confimrationSuccess, setConfimrationSuccess] = useState(false);
  const [showConfirmationLoader, setShowConfirmationLoader] = useState(false);
  const reactivateMembership = async () => {
    setShowConfirmationLoader(true);
    const subsInfo = await getStudentInfoIncludedService();
    const subscriptionData = getSubscriptionData(subsInfo?.attributes);

    if (subsInfo) {
      const isReactivated = await updateSubscriptionService(subsInfo, leadId);
      if (isReactivated) {
        trackAction.subscriptionChangeAction({
          planId: subscriptionData?.planId,
          action: 'Reactivated',
        });
        setShowConfirmationLoader(false);
        setConfimrationSuccess(true);
      } else {
        props.handleShowModal();
      }
    }
  };

  const startLearningCta = () => {
    props.handleShowModal();
    navigate(`/app/${props.locale}`);
  };

  return (
    <Modal show={props.showModal} className={styles.reactivationModal}
      onHide={() => props.handleShowModal()} animation={false}>
      <Modal.Header closeButton style={{borderBottom: 0}}>
        <Modal.Title>
          {confimrationSuccess ? '' :
            'Confirm Reactivation' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {confimrationSuccess ?
        `Thank you and welcome back! Your membership
          has been reactivated.` :
          `We are happy to reactivate your membership!
          Your membership will start from today and your
          subscription will be charged immediately.
          Checkout your profile section for more details.`
        }
      </Modal.Body>
      <Modal.Footer className={styles.modalFooterBtn}
        style={{borderTop: 0}}>
        { confimrationSuccess ? <Button variant="danger"
          onClick={startLearningCta}>
            Start Learning
        </Button> : <>
          <Button variant="danger"
            onClick={reactivateMembership}>
              Confirm Reactivation
          </Button>
          {showConfirmationLoader &&
          <Spinner className="mt-2" animation="border"/>}
        </>
        }
      </Modal.Footer>
    </Modal>
  );
};

ReactivationModal.propTypes = {
  handleShowModal: PropTypes.func,
  locale: PropTypes.string,
  showModal: PropTypes.bool,
};

export default ReactivationModal;
