import * as apiService from '../../../api';
import * as trackAction from '../../Shared/TrackActionService';
import cookie from 'react-cookies';

export const SetXp = async (studentId, requestObj, locale='en', pageKind) => {
  let xpPoints=0;
  const activitySlug=requestObj?.slug;
  const courseSlug =requestObj?.additionalParams?.localizedcourseslug?
  requestObj?.additionalParams?.localizedcourseslug:
  null;
  const moduleNumber=requestObj?.additionalParams?.modulenumber?
    requestObj?.additionalParams?.modulenumber:null;
  const lessonNumber=requestObj?.additionalParams?.lessonnumber?
    requestObj?.additionalParams?.lessonnumber:null;
  const country = requestObj?.country||cookie.load('country');
  let leadId = cookie.load('shawacademy_leadId');
  if (!leadId) {
    leadId = studentId;
  }
  let name='Student';
  name = requestObj?.name?.first_name || cookie.load('student_name')||
  cookie.load('shawacademy_name');
  const getXPObject={
    data: {
      type: 'activities',
      attributes: {
        slug: requestObj?.slug,
        country,
        name: {
          first_name: name,
          last_name: requestObj?.name?.last_name,
        },
        additional_params: requestObj?.additionalParams,
      },
      relationships: {
        student: {
          data: {
            type: 'student',
            id: leadId,
          },
        },
      },
    },
  };
  await new apiService.default.Leaderboard(studentId).getXp(getXPObject)
      .then((response) => {
        trackAction.pointsRecieved({
          activitySlug,
          activityPoints: response?.data?.data?.attributes?.score,
          courseSlug,
          moduleNumber,
          lessonNumber,
          locale,
          pageKind,
        });
        xpPoints = response?.data?.data?.attributes?.score;
      })
      .catch((error) => {
        console.log(error);
        xpPoints=0;
      });
  return xpPoints;
};
