import React from 'react';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import * as authService from '../Application/Authentication/AuthService';
import cookie from 'react-cookies';

const PrivateRoute = ({component: Component, location, firstName, ...rest}) => {
  const locale = rest.locale;
  if (!authService.isLoggedIn(firstName) &&
    location.pathname !== `/app/${locale}/login`) {
    if (typeof window !== 'undefined') {
      const redirectURL = encodeURIComponent(location.href);
      const redirectCookie = location.pathname;
      cookie.save('redirectUrl', redirectCookie, {path: '/',
        domain: process.env.CookieDomain});
      navigate(`/app/${locale}/login/?redirect_url=${redirectURL}`);
    }
    return null;
  }
  return <Component {...rest} />;
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  locale: PropTypes.string,
  firstName: PropTypes.string,
};
