
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as apiService from '../../../api';
import cookie from 'react-cookies';
import {checkPurchaseConsentEligibility} from '../../Shared/Utils';
import {popUpAction, ctaClickAction, subscriptionChangeAction}
  from '../../Shared/TrackActionService';
import {SUBSCRIPTION_VERSION} from '../../../containers/Constants';
import styles from '../UpsellUnlockPopUp.module.scss';
import {Modal, Spinner} from 'react-bootstrap';
import unlockIcon from '../../../../static/images/UnlockIcon.svg';

const UpsellUnlockPopUp = ({handleHideModal, salesOfferData,
  subscriptionInfo, showUnlockModal, source, courseSlug}) => {
  const locale=salesOfferData?.leadLanguage;
  const studentId=salesOfferData?.leadId;
  const [showPopup, setShowPopup]=useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner]=useState(false);
  const [disableCta, setDisableCta]=useState(true);
  const [prices, setPrices]=useState({
    quarterly: {
      price: '',
      totalPrice: '',
    },
    biannual: {
      price: '',
      totalPrice: '',
    },
    annual: {
      price: '',
      totalPrice: '',
    },
    currencySymbol: '',
    priceList: {},
  });
  const [selectedOffer, setSelectedOffer]=useState({
    duration: '',
    totalPrice: '',
    frequency: '',
  });
  const isConsentEligible=checkPurchaseConsentEligibility();

  useEffect(()=>{
    const leadCountry=salesOfferData?.countryToSend;
    if (leadCountry) {
      new apiService.default.Prices().getPrices()
          .then((res)=>{
            setDisableCta(false);
            const quarterPrices=
            res?.data?.header?.pricesThreeMonth?.prices?.[leadCountry] ||
            res?.data?.header?.pricesThreeMonth?.prices?.default;
            const biAnnualPrices=
            res?.data?.header?.pricesSixMonth?.prices?.[leadCountry] ||
            res?.data?.header?.pricesSixMonth?.prices?.default;
            const annualPrices=
            res?.data?.header?.pricesTwelveMonth?.prices?.[leadCountry] ||
            res?.data?.header?.pricesTwelveMonth?.prices?.default;
            const priceList=res?.data?.header?.pricesThreeMonth?.prices;
            setPrices({
              quarterly: {
                price: quarterPrices?.price,
                totalPrice: quarterPrices?.total_price,
              },
              biannual: {
                price: biAnnualPrices?.price,
                totalPrice: biAnnualPrices?.total_price,
              },
              annual: {
                price: annualPrices?.price,
                totalPrice: annualPrices?.total_price,
              },
              currencySymbol: quarterPrices?.cur_symbol,
              priceList,
            });
          })
          .catch((err)=>{
            console.log(err);
          });
      popUpAction({
        studentId,
        'title': 'Rev1 Offer Banner - Members Area',
        'pageKind': source,
        'variation': courseSlug,
      });
    }
  }, [salesOfferData]);

  const handlePurchase = (totalPrice, duration, frequency) => {
    setSelectedOffer({
      duration,
      totalPrice,
      frequency,
    });
    if (!isConsentEligible) {
      confirmPurchase(duration, frequency);
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
    ctaClickAction({
      'cta': 'Buy Now',
      'pageKind': source,
      'action': `${duration}MonthPlanConfirm`,
      studentId,
      locale,
    });
  };

  const confirmPurchase = (month, freq) => {
    setCookie(`upgrade_disable`, (5/(24*60))); // cookie set for 5 min
    const duration= month || selectedOffer?.duration;
    const frequency= freq || selectedOffer?.frequency;
    let country=salesOfferData?.countryToSend;
    country = Object.keys(prices?.priceList).includes(country) ? country : 'us';
    const dataToSend = {};
    const couponCodeToSend = [];
    const param = `${frequency}_0d-trial_${country}`;
    const planId =`sub_${SUBSCRIPTION_VERSION}_${param}`;
    const couponId =`disc_${SUBSCRIPTION_VERSION}_${param}`;
    couponCodeToSend.push(couponId);
    dataToSend['student_id'] = studentId;
    dataToSend['plan_id'] = planId;
    dataToSend['coupon_ids'] = couponCodeToSend;
    dataToSend['source_url'] = window.location.href;
    let paymentLink =
    `${process.env.PaymentWebDomain}/payment/subscription/${
      frequency}/?lead_id=${studentId}`;
    if (locale!=='en') {
      paymentLink =
    `${process.env.PaymentWebDomain}/${locale}/payment/subscription/${
      frequency}/?lead_id=${studentId}`;
    }
    setDisableCta(true);
    setShowLoadingSpinner(true);
    if (subscriptionInfo) {
      if (subscriptionInfo?.default || subscriptionInfo?.chargebee) {
        new apiService.default.Prices().switchSubscription(dataToSend)
            .then((res)=>{
              setCookie(`upgrade_${studentId}`, duration);
              setTimeout(function() {
                window.location.href=`/app/${locale}/courses/`;
                setShowLoadingSpinner(false);
              }, 2000);
              if (res['status'] == '200') {
                subscriptionChangeAction({
                  studentId,
                  'action': 'PlanChanged',
                  'planId': dataToSend['plan_id'],
                });
              }
            })
            .catch((error)=>{
              if (error?.response?.data?.errors?.[0]?.code==='101') {
                const objToSend={};
                const metaData=error?.response?.data?.meta;
                objToSend['student_id'] = studentId;
                objToSend['purchase_type'] = 'subscription';
                objToSend['plan_id'] = dataToSend['plan_id'];
                objToSend['coupon_ids'] = dataToSend['coupon_ids'];
                objToSend['gateway'] = metaData?.gateway;
                objToSend['payment_intent'] =metaData?.payment_intent;
                objToSend['source_url'] = window.location.href;
                const encodedDataToSend =
            encodeURIComponent(btoa(JSON.stringify(objToSend)));
                const urlToRedirect =
             `${process.env.PaymentWebDomain}/payment/authorise/?payload=${
               encodedDataToSend}`;
                if (locale !== 'en') {
                  urlToRedirect = `${process.env.PaymentWebDomain}/${
                    locale}/payment/authorise/?payload=${encodedDataToSend}`;
                }
                setTimeout(()=> {
                  window.location.href = urlToRedirect;
                }, 1000);
              } else {
                window.location.href = paymentLink;
              }
              setShowLoadingSpinner(false);
            });
      } else {
        setShowLoadingSpinner(false);
        window.location.href = paymentLink;
      }
    } else {
      setShowLoadingSpinner(false);
      window.location.href = paymentLink;
    }
    ctaClickAction({
      'cta': 'Upgrade now',
      'pageKind': source,
      'action': `Upgrade${selectedOffer?.duration}Month`,
      studentId,
      locale,
    });
  };

  const setCookie = (name, validity) => {
    const expires=
    new Date(new Date().setTime(new Date().getTime()+validity*24*60*60*1000));
    cookie.save(name, true, {
      path: '/',
      domain: process.env.CookieDomain,
      expires,
    });
  };

  return (
    <div>
      {
          !showPopup ?
          <Modal className={styles.unlockModuleUpsellContainer}
            show={showUnlockModal}
            onHide={() => handleHideModal()} animation={false}>
            <Modal.Header closeButton style={{borderBottom: 0}}>
              <Modal.Title>
                <img src={unlockIcon} alt='unlockIcon'
                  className={styles.unlockIcon} />
                <p className={styles.titleText}>Upgrade & Unlock!</p>
                <p className={styles.descriptionText}>
              Your current month to month plan only allows you access
              to the this course, to get unlimited access to all
              100+ courses upgrade to a 3, 6 or 12 month plan and
              transform your skills!
                </p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={styles.priceContainer}>
                <div className={styles.priceBox}>
                  <div className={`${styles.duration} ${styles.duration1}`}>
                    <p>12 month plan</p>
                  </div>
                  <div className={styles.planDetails}>
                    <p className={styles.monthlyPrice}>
                      {prices?.currencySymbol}{prices?.annual?.price} monthly
                    </p>
                    <p className={styles.totalPrice}>
                      {prices?.currencySymbol}
                      {prices?.annual?.totalPrice} yearly
                    </p>
                    <div className={styles.buyNowCta}>
                      <button
                        className={disableCta ? styles.disable:''}
                        onClick={()=>handlePurchase(
                    prices?.annual?.totalPrice, '12', 'annual')}>
                  Upgrade
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.priceBox}>
                  <div className={`${styles.duration} ${styles.duration2}`}>
                    <p>6 month plan</p>
                  </div>
                  <div className={styles.planDetails}>
                    <p className={styles.monthlyPrice}>
                      {prices?.currencySymbol}{prices?.biannual?.price} monthly
                    </p>
                    <p className={styles.totalPrice}>
                      {prices?.currencySymbol}
                      {prices?.biannual?.totalPrice} biannual
                    </p>
                    <div className={styles.buyNowCta}>
                      <button
                        className={disableCta ? styles.disable:''}
                        onClick={()=>handlePurchase(
                    prices?.biannual?.totalPrice, '6', 'biannual')}>
                  Upgrade
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.priceBox}>
                  <div className={`${styles.duration} ${styles.duration3}`}>
                    <p>3 month plan</p>
                  </div>
                  <div className={styles.planDetails}>
                    <p className={styles.monthlyPrice}>
                      {prices?.currencySymbol}{prices?.quarterly?.price} monthly
                    </p>
                    <p className={styles.totalPrice}>
                      {prices?.currencySymbol}
                      {prices?.quarterly?.totalPrice} quarterly
                    </p>
                    <div className={styles.buyNowCta}>
                      <button
                        className={disableCta ? styles.disable:''}
                        onClick={()=>handlePurchase(
                    prices?.quarterly?.totalPrice, '3', 'quarterly')}>
                  Upgrade
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal> :
            <Modal show={showUnlockModal} className={styles.confirmModal}
              onHide={() => handleHideModal()} animation={false}>
              <Modal.Header closeButton style={{borderBottom: 0}}>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className={styles.confirmationMessage}>
                  By clicking confirm you will be authorising
                  Shaw Academy to charge you <span
                    className={styles.highlightedText}>
                    {prices?.currencySymbol} {selectedOffer?.totalPrice}
                  </span> automatically every <span
                    className={styles.highlightedText}>
                    {selectedOffer?.duration}</span> month plan
                  from today, until you cancel your subscription.
                </p>
                <div className={styles.buyNowCta}>
                  <button
                    className={disableCta ? styles.disable:''}
                    onClick={()=>confirmPurchase(null, null)}>
                  Confirm
                  </button>
                  {showLoadingSpinner &&
                  <Spinner className={styles.loadingSpinner}
                    animation="border" role="status">
                  </Spinner>
                  }
                </div>
              </Modal.Body>
            </Modal>
      }
    </div>
  );
};

UpsellUnlockPopUp.propTypes = {
  handleHideModal: PropTypes.func,
  salesOfferData: PropTypes.object,
  subscriptionInfo: PropTypes.object,
  showUnlockModal: PropTypes.bool,
  source: PropTypes.string,
  courseSlug: PropTypes.string,
};


export default UpsellUnlockPopUp;
