import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionContext, Card,
  useAccordionToggle} from 'react-bootstrap';
import cookie from 'react-cookies';
import AssessmentTabItem from './AssessmentTabItem';
import FinalAssignment from './FinalAssignment';
import styles from '../../styles/Courses.module.scss';
import HcdThinBanner from '../Shared2/Banner/HcdThinBanner';
import AssessmentsBadgeHolder from './AssessmentsBadgeHolder';
import AssessmentsProgress from './AssessmentsProgress';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function AssessmentsTabView(props) {
  const {courseData, locale, addonPrices, registrationData,
    setXpObject, isActiveMember, retakePrice}=props;
  const courseSlug=courseData?.attributes.localizedcourseslug;
  const moduleData=courseData?.relationships.modules.data;
  const isEQFCourse=moduleData?.length === 4;
  const studentId = cookie.load('shawacademy_leadId');
  // Context Aware Toggle
  const ContextAwareToggle = ({children, eventKey, callback}) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <Accordion.Toggle
        as={Card.Header}
        className={`${styles.assementModuleHeader}
          ${isCurrentEventKey?styles.active:''}`}
        onClick={decoratedOnClick}>
        {children}
      </Accordion.Toggle>
    );
  };
  return (
    <div className={styles.shawAssessments}>
      <div
        className={styles.flexWrapper}
      >
        <div className={styles.assessmentsAccordionWrapper}>
          <Accordion
            className={styles.assessmentsAccordion}
            defaultActiveKey="module-1">
            {/* Iterrate through assessments here */}
            {moduleData && moduleData.map((module, moduleIndex) => {
              let assignmentIndex=0;
              const lessonsData=module.attributes?.lessons || [];
              const ecertPurchased=module.ecertPurchased;
              const finalAssignment= module.attributes.finalassignment||{};
              const fetchAssessment = (lesson, lessonIndex) => {
                const index=Math.floor(lessonIndex/2);
                let assignment;
                if (!lesson?.assignment ||
                        !lesson?.assignment?.modular_blocks?.length) {
                  assignment=module.attributes?.assignments?.[0]?.
                      modular_blocks?.[index];
                }
                return assignment;
              };
              // TODO: This value has to come from api call res
              return (
                <Card
                  key={moduleIndex}
                  className={styles.assessmentCard}>
                  <ContextAwareToggle eventKey={`module-${moduleIndex+1}`}>
                    <div className={styles.assessmentModuleHeaderInner}>
                      {finalAssignment.result?
                      <div className={styles.assesmentModuleBadgeSection}>
                        <div className={styles.assesmentModuleBadgeIcon}>
                          <img src={`/dashboard-new/assessments/${
                              finalAssignment.result.breakDown?.
                                  toLowerCase()}-badge.svg`} alt=""/>
                        </div>
                        <p className={styles.assesmentModuleBadgeBreakdown}>
                          {finalAssignment.result?
                          finalAssignment.result.breakDown: 'Fail'}
                        </p>
                      </div>:
                        <div className={styles.moduleHeaderImg}>
                          {/* <img src=
                            "/dashboard-new/assessments/assessment-icon.svg"
                          alt=""/> */}
                          <img src={
                            `/dashboard-new/assessments/badge-placeholder.svg`}
                          alt="placeholder"/>
                        </div>}
                      <div className={styles.moduleHeaderContent}>
                        <p className={styles.moduleHeaderName}>
                          Module {moduleIndex+1}
                        </p>
                        {finalAssignment.result?
                        <p className={styles.moduleHeaderInfo}>
                          Percentage : <span>
                            {finalAssignment.result.percentile}%
                          </span>
                        </p>:
                        <p className={styles.moduleHeaderInfo}>
                          Complete the final assignment to get module
                          completion certificate
                        </p>
                        }
                      </div>
                    </div>
                    <div className={styles.assessmentModuleHeaderArrow}>
                      <i></i>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse
                    eventKey={`module-${moduleIndex+1}`}
                    className={styles.assessmentModuleBody}>
                    <Card.Body
                      className={styles.cardBody}
                    >
                      {lessonsData.map((lesson, lessonIndex) => {
                        const isEven=(lessonIndex+1)%2===0;
                        const moduleAssignment=isEven &&
                        fetchAssessment(lesson, lessonIndex);
                        if (isEven && lesson?.assignment &&
                          lesson.assignment?.modular_blocks?.length ||
                          moduleAssignment) {
                          assignmentIndex=assignmentIndex+1;
                          return (
                            <AssessmentTabItem
                              key={lessonIndex}
                              assignmentIndex={assignmentIndex}
                              moduleData={module}
                              locale={locale}
                              moduleNumber={moduleIndex+1}
                              courseName={courseData?.attributes?.coursename}
                              studentInfo={props?.studentInfo}
                              courseSlug={courseSlug}
                              regId={courseData?.id}
                              studentId={studentId}
                              assignment={lesson.assignment}
                              moduleAssignment={moduleAssignment}
                              setXpObject={setXpObject}
                              isActiveMember={isActiveMember}
                            />
                          );
                        }
                      })}
                      <FinalAssignment
                        locale={locale}
                        moduleData={module}
                        assignment={finalAssignment}
                        moduleNumber={moduleIndex+1}
                        courseName={courseData?.attributes?.coursename}
                        studentInfo={props?.studentInfo}
                        isEqfCourse={isEQFCourse}
                        ecertPurchased={ecertPurchased}
                        courseSlug={courseSlug}
                        pageKind={'AssignmentsPage'}
                        cta={'Get Your Ecert-assignmentsPage'}
                        addonPrices={addonPrices?.cert}
                        retakePrice={retakePrice}
                        courseRegId={courseData?.id}
                        courseData={courseData}
                        finalAssessmentRetakes=
                          {props.finalAssessmentRetakes?.[moduleIndex]}
                        setFinalAssessmentRetakes=
                          {props.setFinalAssessmentRetakes}
                        setXpObject={setXpObject}
                        isActiveMember={isActiveMember}
                        registrationData={registrationData}
                        totalModules={courseData?.relationships?.
                            modules?.data?.length}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
          <HcdThinBanner
            registrationId={courseData?.id}
            moduleNumber={courseData?.activeModule || 1}
            moduleSlug={courseData?.attributes?.localizedcourseslug}
            courseName={courseData?.attributes?.coursename}
            hcdPurchased={courseData?.attributes?.hcdPurchased}
            oneClickText={`one click purchase`}
            pageKind={'AssignmentsPage'}
            cta={'Get Your Hard Copy Diploma-assignmentsPage'}
            isEqfCourse={isEQFCourse}
            locale={locale}
            courseSlug={courseData?.attributes.localizedcourseslug}
            addonPrices={addonPrices?.hcd}
          />
        </div>
        <div className={styles.assessmentsProgessWrapper}>
          {moduleData.length===4&&
          <AssessmentsBadgeHolder
            courseData={{...courseData}}/>}
          <AssessmentsProgress courseData={{...courseData}}
            locale={locale}
            studentInfo={props?.studentInfo}
            retakePrice={retakePrice}
            finalAssessmentRetakes={props.finalAssessmentRetakes}
            setFinalAssessmentRetakes={props.setFinalAssessmentRetakes}/>
        </div>
      </div>
    </div>
  );

  ContextAwareToggle.propTypes = {
    children: PropTypes.any,
    eventKey: PropTypes.any,
    callback: PropTypes.any,
  };
}

AssessmentsTabView.propTypes = {
  courseData: PropTypes.object,
  studentInfo: PropTypes.object,
  addonPrices: PropTypes.object,
  retakePrice: PropTypes.object,
  locale: PropTypes.string,
  finalAssessmentRetakes: PropTypes.array,
  setFinalAssessmentRetakes: PropTypes.func,
  setXpObject: PropTypes.func,
  isActiveMember: PropTypes.bool,
  registrationData: PropTypes.array,
};

export default AssessmentsTabView;
