import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from '../../Shared2/ToolkitPopup.module.scss';
import {userProfileConstants} from '../../../../src/containers/Constants';
import * as apiService from '../../../api';
import {Form} from 'react-bootstrap';
import {popUpAction, ctaClickAction} from '../../Shared/TrackActionService';

const RegFormPopup = (props) => {
  const [profileData, setProfileData] =
    useState({'age': '', 'gender': '', 'goals': ''});
  const [disableCta, setDisableCta] = useState(false);

  useEffect(() => {
    popUpAction({
      'title': 'RegForm2',
      'pageKind': 'LessonsPage',
    });
  }, []);

  const handleStateChange = (event) => {
    setProfileData({...profileData, [event.target.name]: event.target.value});
  };

  const submitData = (e) =>{
    e.preventDefault();
    const studentInfo = props.studentInfo;
    setDisableCta(true);
    const dataToSend = {
      'data': {
        'type': 'students',
        'id': props.studentId,
        'attributes': {
          'email': studentInfo?.email,
          'name': {
            'first_name': studentInfo?.name?.first_name,
            'last_name': studentInfo?.name?.last_name,
          },
          'phone': studentInfo?.phone,
          'address': {
            'country': studentInfo?.address?.country,
            'city': studentInfo?.address?.city,
            'region': studentInfo?.address?.region,
            'postal': studentInfo?.address?.postal,
          },
          'timezone': studentInfo?.timezone,
          'language_id': studentInfo?.language_id,
          'profile': {
            'age': profileData?.age,
            'gender': profileData?.gender,
            'goals': profileData?.goals,
            'lessons_per_week': studentInfo?.profile?.lessons_per_week,
            'utm_source': studentInfo?.profile?.utm_source,
            'utm_medium': studentInfo?.profile?.utm_medium,
            'utm_campaign': studentInfo?.profile?.utm_campaign,
            'latest_source': studentInfo?.profile?.latest_source,
            'latest_utm_medium': studentInfo?.profile?.latest_utm_medium,
            'latest_utm_campaign': studentInfo?.profile?.latest_utm_campaign,
            'offer_type': studentInfo?.profile?.offer_type,
            'free_trial_days': studentInfo?.profile?.free_trial_days,
            'course_picked': studentInfo?.profile?.course_picked,
            'industry': studentInfo?.profile?.industry,
            'degree': studentInfo?.profile?.degree,
            'employment': studentInfo?.profile?.employment,
            'predicted_ltv': studentInfo?.profile?.predicted_ltv,
          },
          'segment': studentInfo?.segment,
        },
      },
    };
    new apiService.default.Student(props.studentId).update(dataToSend)
        .then((res)=>{
          if (res) {
            const profileParam = {
              'pageKind': 'RegForm2',
              'age': profileData?.age,
              'gender': profileData?.gender,
              'goals': profileData?.goals,
            };
            window.dataLayer.push({
              'event': 'profile-complete-action',
              'profile-param': profileParam,
            });
            props.closeRegForm('');
          }
        })
        .catch((err) => {
          setDisableCta(false);
          console.log(err);
        });
    ctaClickAction({
      'studentId': props.studentId,
      'cta': 'Submit',
      'action': 'ProfileComplete',
    });
  };

  return (
    <>
      <div className={styles.toolkitPopup}>
        <div className={styles.popUpModal}>
          <div className={styles.popUpSection}>
            <div className={styles.popUpDetails} >
              <Form onSubmit={submitData} className={styles.popUpContent}>
                <h1>Personalise your learning</h1>
                <div className={styles.popUpSections}>
                  <h3>Gender</h3>
                  <div className={styles.gender}>
                    <div className="radio">
                      {userProfileConstants.gender.map((gender)=>{
                        return (<input label={gender.key} key={gender.key}
                          type="radio" id={gender.key}
                          name="gender" onChange={handleStateChange}
                          value={gender.key}
                          required/>);
                      })}
                    </div>
                  </div>
                  <h3>Age</h3>
                  <div className={styles.dropDownSection}>
                    <div className={`library-shaw-icon-new`}>
                      <i className="icon-new-down-filled"></i>
                    </div>
                    <select onChange={handleStateChange} name='age' required>
                      <option value=''
                        selected>
                          - - - Select - - -
                      </option>
                      {userProfileConstants.age.map((ageGroup)=>{
                        return (
                          <option key={ageGroup.key} value={ageGroup.key} >
                            {ageGroup.value}
                          </option>);
                      })}
                    </select>
                  </div>
                  <h3>Educational goals</h3>
                  <div className={styles.dropDownSection}>
                    <div className={`library-shaw-icon-new`}>
                      <i className="icon-new-down-filled"></i>
                    </div>
                    <select name='goals' onChange={handleStateChange} required>
                      <option value=''
                        selected>
                          - - - Select - - -
                      </option>
                      {userProfileConstants.goals.map((goal)=>{
                        return (<option key={goal.key} value={goal.key}>
                          {goal.value}
                        </option>);
                      })}
                    </select>
                  </div>
                  <div className={styles.ctaButtons}>
                    <button type="button"
                      className={styles.skipCta}
                      onClick={()=>props.closeRegForm('skip')}>
                        Skip
                    </button>
                    <button type="submit" className={`${styles.submitCta} 
                    ${disableCta?styles.disable:''}`}>
                      Submit
                    </button>
                  </div>
                  <div className={styles.agree}>
                    <input id="privacy" className="checkbox-custom"
                      name="privacy" type="checkbox" required/>
                    <label htmlFor="privacy" className="checkbox-custom-label" >
                    </label>
                    <span className="checkBox">
                    I agree to receive personalised communications
                    from Shaw Academy for reminder communications,
                    services and promotional offers. I understand I
                     can unsubscribe any time as instructed in
                     the <a target="_blank" rel="noreferrer"
                        href="https://www.shawacademy.com/privacy/">privacy policy.</a></span>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RegFormPopup.propTypes = {
  studentId: PropTypes.string,
  closeRegForm: PropTypes.func,
  studentInfo: PropTypes.object,
};


export default RegFormPopup;
