import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from '../GetEcertPopup.module.scss';
import AddonsConfirmation from './AddonsConfirmation';
import * as utils from '../../Shared/Utils';
import * as trackAction from '../../Shared/TrackActionService';
import * as purchaseAction from '../Banner/AddonPurchaseActionService';
import {Spinner} from 'react-bootstrap';

const GetEcertPopup = (props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInitialPopUp, setShowInitialPopUp] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const {closeModal, payloadInputs, ecertPrices} = props;
  const isConsentEligible = utils.checkPurchaseConsentEligibility();

  const confirmPopup = () => {
    const cta = 'Get Your Certificate-modal';
    const action = 'Get Your Certificate';
    const product = 'CourseEcert';
    const productName = 'CERT';
    const textInfo = 'Digital Certificates';
    const priceInfo = ecertPrices;
    trackAction.ctaClickAction({
      cta,
      pageKind: payloadInputs?.pageKind,
      action,
      studentId: payloadInputs?.studentId,
      courseSlug: payloadInputs?.courseSlug,
      moduleNumber: payloadInputs?.moduleNumber,
      locale: payloadInputs?.locale,
    });
    setDataToPassToConfirmation({
      param: 'cert',
      title: 'CourseEcertTwoClickPopup',
      cta: 'Get Your Certificate-modal',
      courseSlug: payloadInputs?.courseSlug,
      moduleSlug: payloadInputs?.moduleSlug,
      courseName: payloadInputs?.courseName,
      moduleNumber: payloadInputs?.moduleNumber,
      locale: payloadInputs?.locale,
      pageKind: payloadInputs?.pageKind,
      studentId: payloadInputs?.studentId,
      priceInfo,
      product,
      textInfo,
      productName,
      regId: payloadInputs?.regId,
    });

    if (isConsentEligible) {
      setShowConfirmModal(true);
      setShowInitialPopUp(false);
      return;
    }

    purchaseAddon();
  };

  const purchaseAddon = () => {
    setShowSpinner(true);
    const objToPass = {
      currencyCode: ecertPrices?.currency_code,
      activeTierCode: ecertPrices?.default_tier_code,
      addon: 'cert',
      country: ecertPrices.country_code,
      slug: payloadInputs?.courseSlug,
      cta: 'Get Your Certificate-modal',
      moduleNumber: parseInt(payloadInputs?.moduleNumber),
      courseName: payloadInputs?.courseName,
      studentId: payloadInputs?.studentId,
      regId: payloadInputs?.regId,
      locale: payloadInputs?.locale,
      isEqfCourse: true,
      moduleSlug: payloadInputs?.moduleSlug,
      pageKind: payloadInputs?.pageKind,
    };
    purchaseAction.addonPurchaseAction(objToPass);
  };

  useEffect(() => {
    trackAction.popUpAction({
      'studentId': payloadInputs?.studentId,
      'title': isConsentEligible ?
      'CourseEcertTwoClickPopup': 'CourseEcert',
      'courseSlug': payloadInputs?.courseSlug,
      'pageKind': 'LessonsPage',
    });
  }, []);

  const showFirstAddonsPopUp = () => {
    setShowConfirmModal(false);
    setShowInitialPopUp(true);
  };
  return (
    <div className={styles.overlay}>
      {showInitialPopUp && <div className={styles.container}>
        <div className={styles.toolkitContentSection}>
          <div
            onClick={closeModal}
            className={`${styles.closeButton} library-shaw-icon-new`} >
            <i className="icon-new-close"></i>
          </div>
          <div className={styles.leftSection}>
            <div className={styles.leftTopContent}>
              <h1>Get All Your Course Certificates</h1>
              <p>Click Get Your Certificate to learn more about your path to
                success in 2023! Get ready to show the world your success with
                your certificate, on your continued path to unLOCKING your
                future. Break free from LOCKdowns… unLOCK your future now!</p>
            </div>
            <div className={styles.leftMiddleContent}>
              <h3>
                Where our students work:
              </h3>
              <img className={styles.leftlogo} src="/images/toolkit-logo.png" />
            </div>
            <div className={styles.leftBottomContent}>
              <h3>
                What our students say:
              </h3>
              <div className={styles.reviewSection}>
                <div className={styles.reviewLeftSection}>
                  <img src="/images/toolkit-reviewer2.png" />
                  <p>&quot;Thank you so much for everything you have helped
                    me with so far!&quot;</p>
                </div>
                <div className={styles.reviewRightSection}>
                  <img src="/images/toolkit-reviewer1.png" />
                  <p>&quot;The information is stunningly laid out.&quot;
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.verticalLine}>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.mobileTagLine}>
              <h1>
                Get All Your Course Certificates
              </h1>
            </div>
            <div className={styles.rightTopContent}>
              <h3>
              </h3>
              <img src="/images/eCert_Web.png" />
            </div>
            <div className={styles.rightMiddleContent}>
              <h3>
                {payloadInputs.courseName}
              </h3>
            </div>
            <div className={styles.rightBottomContent}>
              <h3>
                Certificate
              </h3>
              {!isConsentEligible &&
                <h2>All 4 Modules Only {' '}
                  {ecertPrices?.currency_symbol}{' '
                  }{ecertPrices?.default_tier_price}
                </h2>}
              <h2><span>75% OFF</span></h2>
              <div className={styles.graduatesText}>
                <p>1,987,212 graduates </p>
                <p>&#x2B50; 4.6</p>
              </div>
              <button onClick={confirmPopup} disabled={showSpinner}>
                Get Your Certificate
              </button>
              {!isConsentEligible && <p className={styles.oneClickText}>
                (One click purchase)
              </p>}
              {showSpinner && <div className={styles.loader}>
                <Spinner animation="border" />
              </div>}
            </div>
          </div>
        </div>
      </div>}
      {showConfirmModal &&
        <AddonsConfirmation
          dataToPassToConfirmation={dataToPassToConfirmation}
          showAddonsPopUp={showFirstAddonsPopUp} />}
    </div>
  );
};

GetEcertPopup.propTypes = {
  ecertPrices: PropTypes.object,
  closeModal: PropTypes.func,
  payloadInputs: PropTypes.object,
};

export default GetEcertPopup;
