import React from 'react';
import EcertBanner from '../Shared2/Banner/EcertBanner';
import HcdBanner from '../Shared2/Banner/HcdBanner';
import OnboardProgressBanner from '../Shared2/Banner/OnboardProgressBanner';
import cookie from 'react-cookies';
import ToolkitBanner from '../Shared2/Banner/ToolkitBanner';
import PropTypes from 'prop-types';
import styles from '../../styles/Courses.module.scss';
import UpgradeNowBanner from '../Shared2/Banner/UpgradeNowBanner';
import {shouldLockFaculties} from '../Browse/AllCoursesService';
import LeaderboardBanner from '../Shared2/Banner/LeaderboardBanner';

/**
 *
 *
 * @export
 * @param {*} { bannerProps, changeTabAction,
 *   membershipInfo,
 *   openUpgradePopup, lessonsData, ecertPurchased, hcdPurchased,
 *   toolkitPurchased,
 *   isWeekTwoComplete,studentId}
 * @return {*}
 */
export default function LessonsTabBannersDisplay({bannerProps,
  membershipInfo, changeTabAction, openUpgradePopup, lessonsData,
  ecertPurchased, hcdPurchased,
  toolkitPurchased, isWeekTwoComplete, totalModules, studentId,
  planPrices, scrollToUpcomingLesson, handleUpsellCta,
  subscriptionInfo, registeredTags}) {
  const userMembershipName = membershipInfo?.membership?.name.toLowerCase();
  const userStatus = membershipInfo?.membership?.status.toLowerCase();
  const showUpgradeNowBanner = cookie.load(`upgrade_${studentId}`);
  /**
   *
   * @return {boolean}
   */
  function showUpsellCta() {
    return shouldLockFaculties(
        subscriptionInfo, membershipInfo?.membership,
        registeredTags, showUpgradeNowBanner);
  }


  return (
    <div className={styles.notificationSection}>
      <div className={styles.upskillBanner}>
        <img src='/dashboard-new/courses/brand-Logo.png'
          datasrc="/dashboard-new/courses/brand-Logo.png"/>
        <p>we are now &quot;<span>upskillist</span>&quot;!</p>
        <ul>
          <li>Your subscription &
            course progress will be transfered to Upsillist.</li>
          <li>You&apos;ll be asked to
             re-authenticate your account using a OTP.</li>
        </ul>
        <div className={styles.ctaBtn}>
          <button className={styles.pinkCta}>Move to Upskillist</button>
          <button className={styles.blueCta}>Learn More</button>
        </div>
      </div>
      { showUpsellCta() &&
      <div className={styles.upsellWrapper}>
        <div className={styles.upsellCopyWrapper}>
          <p>Upgrade to get access</p>
          <p>for all faculties for lifetime</p>
        </div>
        <div className={styles.upsellUpgradeCta}
          onClick={() => handleUpsellCta()}>
          Upgrade
        </div>
      </div>}
      {bannerProps?.activeModule === 1 &&
        bannerProps?.studentInfo?.data.length <= 1 &&
      <OnboardProgressBanner
        courseData={bannerProps?.courseData}
        triggerOnboarding={bannerProps?.triggerOnboarding}
      />}
      {!['cancelled', 'inactive'].includes(userStatus) &&
      userMembershipName?.indexOf('lifetime') === -1 &&
      <LeaderboardBanner
        locale={bannerProps?.locale}
        membershipInfo={membershipInfo}
        changeTabAction={changeTabAction}
        scrollToUpcomingLesson={scrollToUpcomingLesson}
        userCountry={membershipInfo?.address?.country}
      />}
      {/* <ReferralCreditBanner
        pageData={bannerProps?.pageData}
        credits={bannerProps?.credits}
      /> */}
      {/* {
        <UpdateCard />
      } */}
      { userMembershipName?.indexOf('lifetime') === -1 &&
      new Date(lessonsData?.[1]?.end_time) < new Date() &&
      (toolkitPurchased || ecertPurchased || hcdPurchased) &&
      !showUpgradeNowBanner &&
      <UpgradeNowBanner
        openUpgradePopup={openUpgradePopup}
        isWeekTwoComplete={isWeekTwoComplete}
        activeModule={bannerProps?.activeModule}
        totalModules={totalModules}
        planPrices={planPrices}
        pageKind={'LessonsPage'}
        userCountry={membershipInfo?.address?.country}
      />}
      {<ToolkitBanner
        registrationId={bannerProps?.registrationId}
        moduleNumber={bannerProps?.activeModule}
        moduleSlug={bannerProps?.moduleSlug}
        moduleData={bannerProps?.moduleData}
        toolkitPurchased={bannerProps?.toolkitPurchased}
        pageKind={bannerProps?.pageKind}
        cta={bannerProps?.cta1}
        isEqfCourse={bannerProps?.isEqfCourse}
        locale={bannerProps?.locale}
        courseSlug={bannerProps?.courseSlug}
        addonPrices={bannerProps?.addonPrices?.toolkit}
        userCountry={membershipInfo?.address?.country}
      />}
      {<EcertBanner
        registrationId={bannerProps?.registrationId}
        moduleNumber={bannerProps?.activeModule}
        moduleSlug={bannerProps?.moduleSlug}
        moduleData={bannerProps?.moduleData}
        ecertPurchased={bannerProps?.ecertPurchased}
        pageKind={bannerProps?.pageKind}
        cta={bannerProps?.cta2}
        isEqfCourse={bannerProps?.isEqfCourse}
        locale={bannerProps?.locale}
        courseSlug={bannerProps?.courseSlug}
        addonPrices={bannerProps?.addonPrices?.cert}
        userCountry={membershipInfo?.address?.country}
      />}
      {
        bannerProps?.courseData?.attributes?.hcdAvailable &&
        <HcdBanner
          registrationId={bannerProps?.courseData?.id}
          moduleNumber={bannerProps?.activeModule}
          moduleSlug={bannerProps?.moduleSlug}
          moduleData={bannerProps?.moduleData}
          hcdPurchased={bannerProps?.courseData?.attributes?.hcdPurchased}
          pageKind={bannerProps?.pageKind}
          cta={bannerProps?.cta3}
          isEqfCourse={bannerProps?.isEqfCourse}
          locale={bannerProps?.locale}
          courseSlug={bannerProps?.courseSlug}
          addonPrices={bannerProps?.addonPrices?.hcd}
          userCountry={membershipInfo?.address?.country}
        />
      }
    </div>
  );
}

LessonsTabBannersDisplay.propTypes = {
  bannerProps: PropTypes.object,
  // toastList: PropTypes.array,
  membershipInfo: PropTypes.object,
  changeTabAction: PropTypes.func,
  openUpgradePopup: PropTypes.func,
  lessonsData: PropTypes.array,
  toolkitPurchased: PropTypes.bool,
  ecertPurchased: PropTypes.bool,
  hcdPurchased: PropTypes.bool,
  isWeekTwoComplete: PropTypes.bool,
  totalModules: PropTypes.number,
  studentId: PropTypes.string,
  planPrices: PropTypes.object,
  scrollToUpcomingLesson: PropTypes.func,
  handleUpsellCta: PropTypes.func,
  subscriptionInfo: PropTypes.object,
  registeredTags: PropTypes.array,
  userCountry: PropTypes.string,
};
