import React, {useEffect, useState} from 'react';
import cookie from 'react-cookies';
// import * as CONSTANTS from '../../containers/Constants';
import styles from '../../styles/UserIconWithXp.module.scss';
import Avatar from '../../pages/static/Avatar.png';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';

const UserIconWithXp = ({
  uploadedProfilePic, locale}) => {
  const studentId=cookie.load('shawacademy_leadId');
  const [userData, setUserData] = useState({
    imgSrc: uploadedProfilePic ? uploadedProfilePic : '',
    lead_id: studentId,
    name: {
      'first_name': 'Student',
      'last_name': '.',
    },
  });

  useEffect(()=>{
    loadDependencies();
  }, []);

  const loadDependencies = () => {
    const userStatus=createUserData(studentId);
    setUserData(userStatus);
    // const {db}=require('../Shared2/Firebase');
    // const country=getCountry();
    // const loggedUserResponse=
    // db.collection(`lifetime_${country}`) // without limit
    //     .where('lead_id', '==', studentId);
    // loggedUserResponse.get().then((snapshot) => {
    //   snapshot.docs.map((doc) => {
    //     if (doc.exists) {
    //       const userStatus=createUserData(doc.data());
    //       setExperiencePoints && setExperiencePoints(userStatus?.score);
    //       setUserData(userStatus);
    //     }
    //   });
    // })
    //     .catch((err) => console.log('Error ', err));
  };

  const createUserData = (studentId) => {
    const dataToSend = {
      imgSrc: `https://shaw-profile-pic.s3.amazonaws.com/${studentId}.jpeg`,
    };
    return dataToSend;
  };

  // const getCountry = () => {
  //   let countryToSet=cookie.load('country') || 'ie';
  //   if (CONSTANTS.euCountryList.includes(countryToSet)) {
  //     countryToSet='eu';
  //   } else if (CONSTANTS.latCountryList.includes(countryToSet)) {
  //     countryToSet='lat';
  //   } else if (CONSTANTS.leaderBoar
  // dsEigibleCountries.includes(countryToSet)) {
  //   } else {
  //     countryToSet='row';
  //   }
  //   return countryToSet;
  // };

  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.imageContainer}>
        <Link to={`/app/${locale}/account`}>
          <img
            src={userData?.imgSrc}
            onError={(e) => e.target.src=Avatar} // recheck
            alt='user'
            className={styles.userIcon}
          />
        </Link>
      </div>
      {/* <p className={styles.xpPoints}>
      {userData?.score?.toLocaleString()} XP</p> */}
    </div>
  );
};

UserIconWithXp.propTypes = {
  membershipInfo: PropTypes.object,
  locale: PropTypes.string,
  toastList: PropTypes.array,
  pageData: PropTypes.object,
  setExperiencePoints: PropTypes.func,
  uploadedProfilePic: PropTypes.string,
};

export default UserIconWithXp;

