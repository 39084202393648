import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import * as purchaseAction from './AddonPurchaseActionService';
import * as utils from '../../Shared/Utils';
import * as trackAction from '../../Shared/TrackActionService';
import AddonsConfirmation from '../Popup/AddonsConfirmation';
import courseStyles from '../../../styles/Courses.module.scss';

// TODO: Make this component re-usable for all types of addons


/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function ToolkitBanner(props) {
  const studentId = cookie.load('shawacademy_leadId');
  const toolkitPrices = props?.addonPrices;
  const courseSlug = props.courseSlug;
  const moduleNumber = props.moduleNumber;
  const regId = props.registrationId;
  const locale = props.locale;
  const purchased = props.toolkitPurchased;
  const [disabled, setDisabled] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const isConsentEligible = utils.checkPurchaseConsentEligibility();

  /**
 *
 *
 * @param {*} data
 */
  const purchaseAddon = () => {
    const product = 'CourseToolkit';
    const productName = 'TOOLKIT';
    const textInfo = 'Course Materials';
    const objToPass = {
      currencyCode: toolkitPrices?.currency_code,
      activeTierCode: toolkitPrices?.default_tier_code,
      addon: 'toolkit',
      country: toolkitPrices.country_code,
      slug: courseSlug,
      cta: encodeURI(props.cta),
      moduleNumber: parseInt(moduleNumber),
      courseName: props?.moduleData?.attributes.modulename,
      studentId: studentId,
      regId: regId,
      locale: locale,
      isEqfCourse: props.isEqfCourse,
      moduleSlug: props.moduleSlug,
      pageKind: props.pageKind,
    };
    setDataToPassToConfirmation({
      param: 'toolkit',
      title: 'CourseToolkitTwoClickPopup',
      cta: 'Get your Toolkit-modal',
      courseSlug,
      moduleSlug: props.moduleSlug,
      courseName: props?.moduleData?.attributes.modulename,
      moduleNumber: parseInt(moduleNumber),
      locale,
      pageKind: props.pageKind,
      studentId,
      priceInfo: toolkitPrices,
      product,
      textInfo,
      productName,
      regId,
    });
    setDisabled(true);
    if (isConsentEligible) {
      setShowConfirmationPopup(true);
      // TODO: tracking
      trackAction.ctaClickAction({
        'studentId': studentId,
        'cta': props?.cta,
        'action': 'InitiatePurchaseToolkit',
        'pageKind': props?.pageKind,
        'locale': props?.locale,
        'moduleSlug': props?.moduleSlug,
      });
      return;
    }
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': props?.cta,
      'action': 'PurchaseToolkit',
      'pageKind': props?.pageKind,
      'locale': props?.locale,
      'moduleSlug': props?.moduleSlug,
    });
    purchaseAction.addonPurchaseAction(objToPass);
  };

  const closeModalPopup = () => {
    setShowConfirmationPopup(false);
    setDisabled(false);
  };

  return (
    <div className={`${courseStyles.moduleToolkitBanner} ${
      (props.registrationId && !purchased) ?
      '' : 'hide'}`}>
      <div className={courseStyles.moduleToolkitHeaderSection}>
        <p className={courseStyles.moduleToolkitHeader}>
          Unlock Your Course Materials
        </p>
        <p className={courseStyles.moduleToolkitPrice}>
          {toolkitPrices?.currency_symbol}
          {toolkitPrices?.default_tier_price}
        </p>
      </div>
      <p className={courseStyles.moduleToolkitInfo}>
        Upskill today and save on your Course Materials!
      </p>
      <div className={courseStyles.moduleToolkitBenefits}>
        <p className={courseStyles.moduleToolkitBenefitsHeader}>
          What you get?
        </p>
        <ul className={courseStyles.moduleToolkitBenefitsBody}>
          <li>
            Lesson Slides
          </li>
          <li>
            Class Notes
          </li>
          <li>
            Bonus videos
          </li>
        </ul>
      </div>
      <Button className={`${courseStyles.moduleToolkitCta}
      ${disabled ? courseStyles.disabled : ''}`}
      onClick={purchaseAddon}>
        Get Your Course Materials
      </Button>
      <img src='/dashboard-new/courses/module-toolkit-banner.png'
        alt='module-toolkit-banner'
        className={courseStyles.moduleToolkitIllustration}/>
      {showConfirmationPopup &&
      <AddonsConfirmation
        dataToPassToConfirmation={dataToPassToConfirmation}
        showAddonsPopUp={closeModalPopup}/>}
    </div>
  );
}

ToolkitBanner.propTypes = {
  registrationId: PropTypes.string,
  addonPrices: PropTypes.object,
  courseSlug: PropTypes.string,
  moduleNumber: PropTypes.number,
  registrationId: PropTypes.string,
  locale: PropTypes.string,
  toolkitPurchased: PropTypes.bool,
  isEqfCourse: PropTypes.bool,
  moduleSlug: PropTypes.string,
  pageKind: PropTypes.string,
  oneClickText: PropTypes.string,
  cta: PropTypes.string,
  moduleData: PropTypes.object,
};

export default ToolkitBanner;
