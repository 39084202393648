import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {navigate} from 'gatsby';
import * as trackAction from '../../Shared/TrackActionService';
import switcherStyles from '../CourseSwitcherModal.module.scss';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function CourseSwitcher(props) {
  const {coursesToRender, closePopup}=props;
  const courseSwitcherRef = useRef(null);

  const handleClickOutside = (e) => {
    e.stopPropagation();
    if (courseSwitcherRef.current &&
      !courseSwitcherRef.current.contains(e.target)) {
      closePopup();
    }
  };

  const switchCourse = (course, courseIndex) => {
    trackAction.ctaClickAction({
      'cta': course?.attributes?.courseslug,
      'action': 'SwitchCourse',
    });
    props.reorderCourseSwitcher(courseIndex);
    closePopup();
    navigate(course.url);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const addNewCourse = () => {
    trackAction.ctaClickAction({
      'cta': 'Add New Course',
      'action': 'AddCourse',
    });
    navigate(`/app/${props.locale}/courses/`);
  };
  return (
    <div className={switcherStyles.courseSwitcherModal}>
      <div className={`${switcherStyles.shawModalBackdrop}
      ${switcherStyles.active}`}></div>
      <div className={switcherStyles.modalContentWrapper}>
        <Modal.Dialog ref={courseSwitcherRef}>
          <div className={switcherStyles.courseSwitcherHeadingSection}>
            <p className={switcherStyles.courseSwitcherHeading}>
              Switch course
            </p>
            <p className={switcherStyles.addNewCourseCta}
              onClick={() => addNewCourse()}>
              + Add new course
            </p>
          </div>
          <div className={switcherStyles.courseSwitcherBodySection}>
            {coursesToRender?.length && coursesToRender.map((course, index) => (
              <div key={index}
                onClick={() => switchCourse(course, index)}
                className={switcherStyles.singleCourseItem}>
                <div className={switcherStyles.singleCourseItemLeft}>
                  <div className={switcherStyles.courseImage}>
                    <img src={course.courseImg}
                      alt={course.attributes.coursename}/>
                  </div>
                  <p className={switcherStyles.courseName}>
                    {course.attributes.coursename}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Modal.Dialog>
      </div>
    </div>
  );
}
CourseSwitcher.propTypes = {
  locale: PropTypes.string,
  coursesToRender: PropTypes.array,
  closePopup: PropTypes.func,
  reorderCourseSwitcher: PropTypes.func,
};

export default CourseSwitcher;
