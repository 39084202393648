import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import * as purchaseAction from './AddonPurchaseActionService';
import * as utils from '../../Shared/Utils';
import * as trackAction from '../../Shared/TrackActionService';
import AddonsConfirmation from '../Popup/AddonsConfirmation';
import courseStyles from '../../../styles/Courses.module.scss';

// TODO: Make this component re-usable for all types of addons


/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function HcdBanner(props) {
  const studentId = cookie.load('shawacademy_leadId');
  const hcdPrices = props?.addonPrices;
  const courseSlug = props.courseSlug;
  const regId = props.registrationId;
  const locale = props.locale;
  const [purchased, setPurchased] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showConsentPopup, showConfirmationPopup] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const isConsentEligible = utils.checkPurchaseConsentEligibility();

  useEffect(() => {
    const hcdCookie=`hcd_${props.moduleSlug}_${studentId}`;
    if (cookie.load(hcdCookie) || props.hcdPurchased) {
      setPurchased(true);
    }
  }, []);


  /**
 *
 *
 * @param {*} data
 */
  const purchaseAddon = () => {
    const product = 'HardCopyDiploma';
    const productName = 'HCD';
    const textInfo = 'Hard Copy Diploma';
    const objToPass = {
      currencyCode: hcdPrices?.currency_code,
      activeTierCode: hcdPrices?.default_tier_code,
      addon: 'hard-copy-diploma',
      country: hcdPrices?.country_code,
      slug: courseSlug,
      cta: encodeURI(props.cta),
      moduleNumber: null,
      courseName: props?.moduleData?.attributes.modulename,
      studentId: studentId,
      regId: regId,
      locale: locale,
      isEqfCourse: props.isEqfCourse,
      moduleSlug: props.moduleSlug,
      pageKind: props.pageKind,
      userCountry: props.userCountry,
    };
    setDataToPassToConfirmation({
      param: 'hcd',
      title: 'CourseDiplomaConfirmationPopUp',
      cta: 'Get your Diploma-modal',
      courseSlug,
      moduleSlug: props.moduleSlug,
      courseName: props?.moduleData?.attributes.modulename,
      moduleNumber: null,
      locale,
      pageKind: props.pageKind,
      studentId,
      priceInfo: hcdPrices,
      product,
      textInfo,
      productName,
      regId,
    });
    setDisabled(true);
    if (isConsentEligible) {
      showConfirmationPopup(true);
      // TODO: tracking
      trackAction.ctaClickAction({
        'studentId': studentId,
        'cta': props?.cta,
        'action': 'InitiatePurchaseHardCopyDiploma',
        'pageKind': props?.pageKind,
        'locale': props?.locale,
        'moduleSlug': props?.moduleSlug,
      });
      return;
    }
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': props?.cta,
      'action': 'PurchaseHardCopyDiploma',
      'pageKind': props?.pageKind,
      'locale': props?.locale,
      'moduleSlug': props?.moduleSlug,
    });
    purchaseAction.addonPurchaseAction(objToPass);
  };

  const closeModalPopup = () => {
    showConfirmationPopup(false);
    setDisabled(false);
  };

  return (
    <div className={`${courseStyles.moduleHcdBanner} ${
      (regId && !purchased) ?
      '' : 'hide'}`}>
      <div className={courseStyles.moduleToolkitHeaderSection}>
        <p className={courseStyles.moduleToolkitHeader}>
          Get Your Hard Copy Diploma
        </p>
        <p className={courseStyles.moduleToolkitPrice}>
          {hcdPrices?.currency_symbol}
          {hcdPrices?.default_tier_price}
        </p>
      </div>
      <p className={courseStyles.moduleToolkitInfo}>
        Your Hard Copy Diploma is an official document, where a certificate
        of completion is awarded by Austin Peay State University and CPD.
        A hard copy diploma shows clients, colleagues and prospective
        employers that you know what you are doing.
      </p>
      <div className={courseStyles.moduleToolkitBenefits}>
      </div>
      <Button className={`${courseStyles.moduleToolkitCta}
      ${disabled?'disabled':''}`}
      onClick={purchaseAddon}>
        Get Your Hard Copy Diploma
      </Button>
      <div className={courseStyles.rightSection}>
        <img src='/dashboard-new/courses/module-hardcopy-diploma-banner.png'
          alt='module-hardcopy-diploma-banner'/>
      </div>
      {showConsentPopup &&
       <AddonsConfirmation
         dataToPassToConfirmation={dataToPassToConfirmation}
         showAddonsPopUp={closeModalPopup}/>}
    </div>
  );
}

HcdBanner.propTypes = {
  registrationId: PropTypes.string,
  addonPrices: PropTypes.object,
  courseSlug: PropTypes.string,
  registrationId: PropTypes.string,
  locale: PropTypes.string,
  hcdPurchased: PropTypes.bool,
  isEqfCourse: PropTypes.bool,
  moduleSlug: PropTypes.string,
  pageKind: PropTypes.string,
  oneClickText: PropTypes.string,
  cta: PropTypes.string,
  moduleData: PropTypes.object,
  userCountry: PropTypes.string,
};

export default HcdBanner;
