import React, {useEffect, useState, useRef} from 'react';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import htmr from 'htmr';
import cookie from 'react-cookies';
import * as typeformEmbed from '@typeform/embed';
import {VerticalTimeline, VerticalTimelineElement}
  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import UnlockModulePopup from '../Shared2/Popup/UnlockModulePopup';
import UnlockModuleBanner from '../Shared2/Banner/UnlockModuleBanner';
import * as trackAction from '../Shared/TrackActionService';
import RegFormPopup from '../Shared/Popup/RegFormPop';
import AddonsPopUp from '../Shared2/Popup/AddonsPopUp';
import {Router} from '@reach/router';
import PostPurchasePopUp from '../Shared2/Popup/PostPurchasePopUp';
import * as utils from '../Shared/Utils';
import VimeoAddonsPopUp from '../Shared2/Popup/VimeoAddonsPopUp';
import styles from '../../styles/Courses.module.scss';
import SalesOffer from '../Shared2/Popup/SalesOffer';
import HcdThinBanner from '../Shared2/Banner/HcdThinBanner';
import dayjs from 'dayjs';
import LessonsTabBannersDisplay from './LessonsTabBannersDisplay';
import UpsellUnlockPopUp from '../Shared2/Popup/UpsellUnlockPopUp';
import LessonsDisplay from './LessonsDisplay';
import ModuleSelector from './ModuleSelector';
import * as registrationDetailService from './RegistrationDetailService';
import GiftFlowBanner from '../Shared2/Banner/GiftFlowBanner';
import StartNextModulePopup from '../Shared2/Popup/StartNextModulePopup';
import RescheduleLessonPopup from '../Shared2/Popup/RescheduleLessonPopup';
import GetToolkitPopup from '../Shared2/Popup/GetToolkitPopup';
import GetEcertPopup from '../Shared2/Popup/GetEcertPopup';
import GetHCDPopup from '../Shared2/Popup/GetHCDPopup';
import AddonsConfirmation from '../Shared2/Popup/AddonsConfirmation';
// import SalesOfferConfirmation from '../Shared2/Popup/SalesOfferConfirmation';
import StarterPack from '../Shared2/StarterPack';
import VideoPlayer from '../Shared2/Popup/VideoPlayer';
import FlashCard from './Flashcards/FlashCard';
import {countriesEligibleForNewSchedule} from '../../containers/Constants';
import {checkUpgradeNowEligibility, openUpgradeNowPopup}
  from '../Shared2/Service/UpgradeNowService';
import MigratePopup from '../Shared2/Popup/MigratePopup';
import * as commonService from '../Shared2/Agent/CommonService';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function LessonsTabView(props) {
  const {courseData, membershipInfo, studentInfo, changeTabAction,
    subscriptionInfo, locale,
    isActiveMember, addonPrices, retakePrice,
    planPrices, registeredTags} = props;

  const [showLessonContent, triggerLessonContent] = useState({});
  const [goingDown, setGoingDown] = useState(false);
  const [showMigratePopup, setShowMigratePopup] = useState(false);
  const [showToolkitModal, setToolkitModal] = useState(true);
  const [showAddonConfirmation, setShowAddonConfirmation] = useState(false);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});
  const [showEcertModal, setEcertModal] = useState(false);
  const [hidePreviousModal, setHidePreviousModal] = useState(false);
  const [showHcdModal, setShowHcdModal] = useState(false);
  const [addonsVimeoPopup, setAddonsVimeoPopup] = useState(false);
  const [showUnlockModulePopup, setShowUnlockModulePopup] = useState(false);
  const [eligibleForUnlockModule, setUnlockModuleEligibilty] = useState(false);
  const [showSalesOfferModal, triggerSalesOfferModal] = useState(false);
  const [activeModule, setActiveModule] =
  useState(courseData?.activeModule || 1);
  const [sessionEndTime, setSessionEndTime] = useState(undefined);
  const [sessionStartTime, setSessionStartTime] = useState(undefined);
  const [eligibleForNextModule, setElgiblityForNextModule]=useState(false);
  const [showUpsellModal, setShowUpsellModal] = useState(false);
  const moduleData=courseData?.relationships?.modules?.data || [];
  const activeModuleData=moduleData[activeModule-1];
  const toolkitPurchased=activeModuleData?.toolkitPurchased;
  const ecertPurchased=activeModuleData?.ecertPurchased;
  const isEqfCourse=moduleData.length===4;
  const utmMedium=membershipInfo?.profile?.utm_medium;
  const moduleRef = useRef([]);
  const nextLessonRef=useRef(null);
  const countryCode = membershipInfo?.address?.country;
  const [firstUpcomingLesson, setFirstUpcomingLesson]=useState({});
  const [popupMode, setPopupMode] = useState(false);
  const [currentVideoId, setVideoId] = useState(false);
  const studentId=cookie.load('shawacademy_leadId');
  const isUpgraded = cookie.load(`upgrade_${studentId}`);
  const courseSlug= courseData?.
      attributes.localizedcourseslug;
  const leadCreatedAt = membershipInfo?.created_at;
  const newTimetableEligibility =
  countriesEligibleForNewSchedule(countryCode, leadCreatedAt);
  const popupDataToSend = {
    'moduleSlug': activeModuleData?.attributes?.moduleslug,
    'courseName': activeModuleData?.attributes?.modulename,
    isEqfCourse,
    'courseSlug': courseData?.
        attributes.localizedcourseslug,
    'moduleNumber': activeModule,
    'regId': courseData?.id,
    locale,
    'pageKind': 'LessonsPage',
    studentId,
  };

  useEffect(() => {
    if (membershipInfo.membership.status === 'inactive' ||
    commonService.checkGiftMembership(membershipInfo.membership)) {
      setShowMigratePopup(true);
      setToolkitModal(false);
      return;
    }
    if (toolkitPurchased || props?.additionParam==='get-toolkit' ||
    props?.additionParam==='lessons/1/reschedule-now' ||
    props?.additionParam.indexOf('sales-offer') > -1 ||
    props?.additionParam.indexOf('post-purchase') > -1) {
      setToolkitModal(false);
      setShowHcdModal(false);
      triggerSalesOfferModal(false);
    }
    if ((toolkitPurchased && !ecertPurchased) && !['get-toolkit',
      'lessons/1/reschedule-now', 'sales-offer']
        .includes(props?.additionParam) &&
        props?.additionParam.indexOf('sales-offer') === -1 &&
        props?.additionParam.indexOf('post-purchase') === -1) {
      setEcertModal(true);
      setShowHcdModal(false);
      triggerSalesOfferModal(false);
    }
    if (toolkitPurchased && ecertPurchased &&
      !courseData?.attributes?.hcdPurchased &&
      props?.additionParam.indexOf('sales-offer') === -1) {
      setShowHcdModal(true);
      triggerSalesOfferModal(false);
    }
    if (props?.additionParam.indexOf('post-purchase') > -1) {
      setShowHcdModal(false);
    }
    if (RegExp('\/flash-cards').test(window.location.pathname)) {
      setToolkitModal(false);
      setShowHcdModal(false);
      setEcertModal(false);
      triggerSalesOfferModal(false);
    }
    // checkSalesOfferModalEligibility();
    checkOngoingLessonExistence();
    initializeFlags();
    getFirstUpcomingLesson();
  }, [membershipInfo]);

  useEffect(() => {
    if (eligibleForNextModule) {
      setEcertModal(false);
      setShowHcdModal(false);
      triggerSalesOfferModal(false);
    }
  }, [eligibleForNextModule]);

  useEffect(() => {
    if (props.showUnlockModulePopup) {
      setShowUnlockModulePopup(true);
    }
  }, [props.showUnlockModulePopup]);

  useEffect(() => {
    if (cookie.load(`modalShow_${popupDataToSend?.regId}`)) {
      setToolkitModal(false);
    }
    if (cookie.load(`modalShow_cert_${popupDataToSend?.regId}`)) {
      setEcertModal(false);
    }
    if (cookie.load(`modalShow_salesOffer`)) {
      triggerSalesOfferModal(false);
    }
    checkUnlockModuleEligibility();
  }, [courseData]);

  useEffect(()=>{
    window.addEventListener('message', getMessage);
    return (() => {
      window.removeEventListener('message', getMessage);
    });
  }, [courseData, addonPrices]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 250 && !goingDown ) {
        setGoingDown(true);
      }
      if (currentScrollY < 250 && goingDown) {
        setGoingDown(false);
      }
      if (currentScrollY > 10600) {
        setGoingDown(false);
      }
    };
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => window.removeEventListener('scroll', handleScroll);
  }, [goingDown]);

  useEffect(()=> {
    switchModule(courseData?.activeModule || 1);
  }, [courseData, nextLessonRef?.current]);

  const handleUpsellCta = () => {
    setShowUpsellModal(true);
    trackAction.ctaClickAction({
      'studentId': studentId,
      'cta': 'Upgrade',
      'action': 'TriggerSalesOfferModal',
      'pageKind': 'LessonsPage',
    });
  };

  /**
   */
  function handleHideUpsellModal() {
    setShowUpsellModal(false);
  }

  const switchModule = (moduleNumber) => {
    setActiveModule(moduleNumber);
    if (moduleNumber===courseData?.activeModule && nextLessonRef?.current) {
      const nextLessonElementPosition =
       nextLessonRef?.current?.getBoundingClientRect().top + window.scrollY;
      const stickyElementHeight = 156;
      window.scrollTo({
        left: 0,
        top: nextLessonElementPosition - stickyElementHeight,
        behavior: 'smooth',
      });
    } else {
      const rect = moduleRef?.current[moduleNumber-1]?.getBoundingClientRect().
          top + window.scrollY;
      const topSectionHeight = 156;
      window.scrollTo({
        left: 0,
        top: rect - topSectionHeight,
        behavior: 'smooth',
      });
    }
    trackAction.ctaClickAction({
      studentId,
      'cta': `Module ${moduleNumber}`,
      'courseSlug': courseData?.attributes.localizedcourseslug,
      moduleNumber,
      'facultySlug': activeModuleData?.attributes?.facultyslug,
      'action': 'AutoScroll',
      locale,
    });
  };

  const getLessonTimeline = (lesson) => {
    const now=dayjs();
    const startTime=dayjs(lesson['start_time']);

    if (now.isAfter(startTime.clone().add(30, 'minutes'))) {
      return styles.completed;
    } else {
      if (now.isBefore(startTime.clone().subtract(30, 'minutes'))) {
        return styles.upcoming;
      }
      return styles.ongoing;
    }
  };

  const initializeFlags = () => {
    const addonsPopupFlag = utils.getVariantValue('addonspopup');
    setAddonsVimeoPopup(setExperimentValue(addonsPopupFlag));
  };

  const setExperimentValue = (variantName) => {
    if (variantName?.value === 'videopopup') {
      return true;
    }
    return false;
  };

  const triggerOnboarding = () => {
    trackAction.ctaClickAction({
      studentId,
      'cta': 'Lets Go',
      'action': 'Start OnBoarding',
      'pageKind': 'LessonsPage',
      locale,
    });
    props.startOnboarding();
  };
  /**
   *
   *
   */
  // const checkSalesOfferModalEligibility = () => {
  //   const firstModuleLessons=moduleData?.[0]?.attributes?.lessons;
  //   const membershipName = membershipInfo?.membership?.name.toLowerCase();
  //   if (firstModuleLessons && membershipName?.indexOf('lifetime') < 0) {
  //     firstModuleLessons.forEach((lesson) => {
  //       if (lesson?.number === 3 &&
  //         (new Date() > new Date(lesson['start_time']))) {
  //         setToolkitModal(false);
  //         triggerSalesOfferModal(true);
  //       }
  //     });
  //   }
  // };

  /**
   *
   *
   */
  function triggerApiCall() {
    props.triggerApiCalls();
  }


  /**
   *
   *
   * @param {*} regId
   */
  const navigateToWebinar = (regId) => {
    triggerCTAClickAction('watchWebinar', 'joinClass');
    const webinarDomain=process.env.WebinarDomain;
    const webinarUrl=`${webinarDomain}/en/webinar?registration_id=${
      regId}&lead_id=${studentId}`;
    window.location.href = webinarUrl;
  };

  /**
   *
   *
   */
  const triggerUnlockModulePopup = () => {
    setShowUnlockModulePopup(!showUnlockModulePopup);
    props.triggerUnlockModulePopup(!showUnlockModulePopup);
  };


  /**
   *
   *
   * @param {*} action
   * @param {*} cta
   */
  const triggerCTAClickAction = (action, cta) => {
    trackAction.ctaClickAction({
      studentId,
      cta,
      action,
      'pageKind': 'LessonsPage',
      'courseSlug': courseData?.attributes.localizedcourseslug,
      'moduleNumber': activeModule,
      locale,
    });
  };

  /**
   *
   *
   * @param {*} moduleRegId
   * @param {*} lessonNumber
   */
  const navigateToReschedule = (moduleRegId, lessonNumber) => {
    triggerCTAClickAction('rescheduleAction', 'changeClass');
    const urlToNavigate=
    `/app/${locale}/reschedule/${moduleRegId}/reschedule-class/`;
    navigate(urlToNavigate, {
      state: {
        'number': lessonNumber,
      },
    });
  };


  /**
   *
   *
   * @param {*} lesson
   */
  const trackAddToCalendarEvent = (lesson) => {
    trackAction.ctaClickAction({
      studentId,
      'cta': 'Add to Calendar',
      'action': 'AddToCalendar',
      'pageKind': 'LessonsPage',
      'locale': locale,
      'lessonNumber': lesson?.number,
    });
  };

  const checkUnlocked = (lesson) => {
    const currentTime = new Date().getTime();
    const minutes=30;
    return new Date(lesson?.start_time).getTime()+minutes*60*1000 < currentTime;
  };


  const openAssessment = (assessmentInfo, lessonIndex, moduleIndex) => {
    const courseSlug = props?.courseData?.attributes.localizedcourseslug;
    const firstName = membershipInfo?.name?.first_name;
    const lastName = membershipInfo?.name?.last_name;
    const userName = `${firstName} ${lastName}`;
    const assessmentNumber=Math.ceil((lessonIndex+1)/2);


    let link;
    if (assessmentInfo?.formid) {
      link = assessmentInfo?.formid;
      trackAction.assignmentClickAction({
        'action': 'Start Now',
        studentId,
        courseSlug,
        activeModule,
        'facultySlug': activeModuleData?.attributes?.facultyslug,
        'assessmentType': 'Weekly',
        'assessmentId': link,
        locale,
        assessmentNumber,
        'pageKind': 'LessonsTab',
      });
      const reference = typeformEmbed.makePopup(
          // shawacademy.typeform.com
          `https://form.typeform.com/to/${link}#student_id=${studentId}&course_slug=${encodeURIComponent(courseSlug)}&course_name=${encodeURIComponent(activeModuleData?.attributes?.modulename)}&module_slug=${encodeURIComponent(activeModuleData?.attributes?.moduleslug)}&module_name=${encodeURIComponent(activeModuleData?.attributes?.modulename)}&module_number=${moduleIndex+1}&course_regid=${courseData?.id}&user_name=${userName}&locale=${locale}&sub_brand=${process.env.BrandSlug}&stage=${process.env.AppicationEnv}&assessment_type=weekly&faculty_slug=${activeModuleData?.attributes?.facultyslug}&assessment_number=${assessmentNumber}`,
          {
            hideHeaders: true,
            hideFooters: true,
            autoClose: 0.1,
            onSubmit: function(event) {
              setTimeout(() => {
                trackAction.assignmentCompleteAction({
                  'studentId': cookie.load('shawacademy_leadId'),
                  courseSlug,
                  activeModule,
                  'facultySlug': activeModuleData?.attributes?.facultyslug,
                  'assessmentType': 'Weekly',
                  'assessmentId': link,
                  locale,
                  'assessmentNumber': index,
                  'pageKind': 'LessonsTab',
                });
              }, 2000);
            },
          },
      );
      reference.open();
    } else {
      link = assignment?.link?.link?.href;
      window.open(link);
    }
  };


  /**
   *
   *
   * @param {*} lessonTimeline
   * @param {*} lesson
   * @param {*} regId
   * @param {*} lessonIndex
   */
  const checkLessonEligibility = (lessonTimeline, lesson, regId,
      lessonIndex) => {
    if (lessonTimeline === styles.ongoing) {
      navigateToWebinar(regId);
    }
    return;
  };

  /**
   *
   *
   */
  const closePopupModal = () => {
    let elgibleForOnboarding=true;
    if (props?.additionParam==='sales-offer') {
      elgibleForOnboarding=false;
    }
    const urlToNavigate =
    `/app/${locale}/v2/registrations/${courseData?.id}/modules/${activeModule}`;
    navigate(urlToNavigate);
    if (elgibleForOnboarding) {
      triggerOnboarding();
    }
  };

  const closeConfirmation = () => {
    setShowAddonConfirmation(false);
  };

  const closeSalesOfferModal = () => {
    triggerSalesOfferModal(false);
    const expires = new Date;
    expires.setMinutes(expires.getMinutes() + 10);
    cookie.save('modalShow_salesOffer',
        true, {
          path: '/',
          domain: process.env.CookieDomain,
          expires,
        });
  };

  /**
   *
   *
   * @param {*} info
   */
  const getMessage = (info) => {
    const {addonPrices, courseData} = props;
    if (info?.data === 'privacy_policy') {
      window.open(`${process.env.ShawWebsiteDomain}/privacy/`, '__blank');
      return;
    }
    if (info?.data === 'continue_scheduled') {
      closePopupModal();
    }
    if (info?.data === 'showToolkitConfirmation') {
      setHidePreviousModal(true);
      setDataToPassToConfirmation(
          registrationDetailService.dataToReturn(
              addonPrices, courseData, 'toolkit', locale, activeModule,
              countryCode));
      setShowAddonConfirmation(true);
    }
    if (info?.data === 'showCertConfirmation') {
      setHidePreviousModal(true);
      setDataToPassToConfirmation(
          registrationDetailService.dataToReturn(
              addonPrices, courseData, 'cert', locale, activeModule));
      setShowAddonConfirmation(true);
    }
    if (info?.data === 'showHcdConfirmation') {
      setHidePreviousModal(true);
      setDataToPassToConfirmation(
          registrationDetailService.dataToReturn(
              addonPrices, courseData, 'hard-copy-diploma', locale, null));
      setShowAddonConfirmation(true);
    }
    if (info?.data === 'closeIframe') {
      setHidePreviousModal(true);
      openRescheduleNowPopup();
    }
  };

  /**
   *
   *
   */
  const openRescheduleNowPopup = () => {
    const urlParam = `lessons/1/reschedule-now`;
    const urlToNavigate =
    `/app/${locale}/v2/registrations/${courseData?.id}/modules/${
      activeModule}/${urlParam}`;
    navigate(urlToNavigate);
  };

  const closePostPurchasePopup = () => {
    const urlToNavigate =`/app/${locale}/v2/registrations/${
      courseData?.id}/modules/${
      activeModule}`;
    navigate(urlToNavigate);
  };

  const openWatchNowPopUp = () => {
    const lessonOne = activeModuleData?.attributes?.lessons[0];
    if (new Date(lessonOne?.start_time) > new Date()) {
      const urlParam = `lessons/1/reschedule-now`;
      const urlToNavigate =
    `/app/${locale}/v2/registrations/${courseData?.id}/modules/${
      activeModule}/${urlParam}`;
      navigate(urlToNavigate);
    }
    setToolkitModal(false);
    setEcertModal(false);
    setShowHcdModal(false);
    props.showMemeberAreaVisitPopup();
  };

  const scrollTop = () =>{
    trackAction.ctaClickAction({
      studentId,
      'cta': `Back to Top`,
      'action': 'AutoScroll',
      locale,
    });
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  /**
   *
   *
   * @param {*} moduleInfo
   * @param {*} lessonInfo
   * @return {*}
   */
  const getEvent = (moduleInfo, lessonInfo) => {
    const moduleRegistrationId=moduleInfo.attributes['registration_id'];
    const moduleName=moduleInfo.attributes['modulename'];
    const webinarPath=process.env.WebinarDomain;
    const utmMedium='calendar';
    const utmCampaign='add-to-calendar';
    const utmSource='activelessoncard';
    const obj = {
      title: `${moduleName}`,
      description: `Lesson Name: ${lessonInfo.lessonname} \n\nLesson Description: ${lessonInfo.lessondescription} \n\nJoin your class at :- https://webinar.shawacademy.com/en/webinar?registration_id=${moduleRegistrationId}&lead_id=${studentId}&utm_medium=calendar&utm_campaign=add-to-calendar&utm_source=activelessoncard \n\nYou can reschedule your lesson:- ${process.env.ShawWebsiteDomain}/app/${locale}/reschedule/${moduleRegistrationId}?utm_medium=calendar&utm_campaign=add-to-calendar&utm_source=activelessoncard`,
      location:
      `${webinarPath}/en/webinar?registration_id=${moduleRegistrationId
      }&lead_id=${studentId}&utm_medium=
      ${utmMedium}&utm_campaign=${utmCampaign}
      &utm_source=${utmSource}`,
      startTime: lessonInfo?.start_time,
      endTime:
      new Date(dayjs(lessonInfo.start_time).add(60, 'minutes')).toISOString(),
    };
    return obj;
  };


  /**
   *
   *
   * @param {*} courseData
   * @return {*}
   * @memberof RegistrationItem
   */
  const recentlySignedUp = () => {
    const registrationDate=courseData?.attributes?.registered_on;
    if (registrationDate) {
      const now=dayjs();
      return now < dayjs(registrationDate).add(10, 'days');
    }
    return false;
  };
  /**
   *
   *
   * @memberof RegistrationItem
   */
  const checkUnlockModuleEligibility = () => {
    let chargebeeSub=subscriptionInfo?.attributes?.chargebee;
    if (subscriptionInfo?.attributes?.default) {
      chargebeeSub =
      subscriptionInfo?.attributes?.default?.additional_platform_data;
    }
    const moduleInfo=courseData?.relationships.modules.data;
    if (locale === 'en' && moduleInfo?.length > 1 &&
    !cookie.load(`module_unlocked_${courseData?.id}`)) {
      if (recentlySignedUp()) {
        if (studentInfo?.data.length === 1 &&
        membershipInfo?.membership.status === 'in_trial') {
          if (chargebeeSub && chargebeeSub?.plan_id?.indexOf('sub_') > -1) {
            if (chargebeeSub.billing_period === 4 && !chargebeeSub.coupon) {
              setUnlockModuleEligibilty(true);
            }
          }
        }
      }
    } else {
      props.triggerStickyUnlockModule(false);
    }
  };

  const scrolledToLesson = (inView, lesson, module, moduleIndex) => {
    if (inView) {
      const dataToSend={
        lessonName: lesson?.lessonname,
        moduleSlug: module?.attributes?.moduleslug,
        courseSlug: courseData?.attributes.localizedcourseslug,
        moduleNumber: moduleIndex+1,
        studentId,
        moduleName: module?.attributes?.modulename,
      };
      if (moduleIndex+1===2 && eligibleForUnlockModule) {
        props.triggerStickyUnlockModule(true);
      }
      console.log(dataToSend);
    }
  };

  const checkOngoingLessonExistence = () => {
    let moduleCompleted=true;
    activeModuleData?.attributes?.lessons.forEach((lesson)=>{
      const lessonTimeline=getLessonTimeline(lesson);
      if (lessonTimeline===styles.ongoing) {
        moduleCompleted=false;
      }
      if (lessonTimeline === styles.upcoming) {
        moduleCompleted=false;
      }
    });
    if (moduleCompleted) {
      const nextModuleRegId =
        moduleData?.[activeModule]?.attributes?.registration_id;
      if (isActiveMember && activeModule !== moduleData.length &&
         !nextModuleRegId) {
        setElgiblityForNextModule(true);
      }
    }
  };

  const bannerProps = {
    activeModule,
    studentInfo,
    courseData: props?.courseData,
    triggerOnboarding: triggerOnboarding,
    credits: props?.credits,
    registrationId: activeModuleData?.attributes?.registration_id,
    moduleSlug: activeModuleData?.attributes?.moduleslug,
    moduleData: activeModuleData,
    toolkitPurchased: toolkitPurchased,
    pageKind: 'LessonsPage',
    cta1: 'Get Your Toolkit-Tab',
    cta2: 'Get Your Ecert-Tab',
    cta3: 'Get Your HCD-Tab',
    isEqfCourse: isEqfCourse,
    locale: locale,
    courseSlug: courseData?.attributes.localizedcourseslug,
    addonPrices: addonPrices,
    ecertPurchased: ecertPurchased,
    hcdPurchased: courseData?.attributes?.hcdPurchased,
  };

  const closeRegForm = (param) => {
    if (param === 'skip') {
      trackAction.ctaClickAction({
        studentId,
        'cta': 'Skip',
        'action': 'profileUpdateSkipped',
        locale,
      });
    }
    const urlToNavigate =
  `/app/${locale}/v2/registrations/${courseData?.id}/modules/${activeModule}`;
    navigate(urlToNavigate);
    setToolkitModal(true);
  };

  const updateLessonsData = (moduleNumber, lessonNumber,
      watchStatus, videoDuration) => {
    const currentModuleData=
    courseData?.relationships?.modules?.data?.[moduleNumber-1]?.attributes;
    const moduleRegId=currentModuleData?.registration_id;
    const lessonsPerWeek = currentModuleData?.lessons_per_week;
    const updatedLessonArray=[];
    let tempLessonObject={};
    currentModuleData?.lessons.forEach((lesson, index)=> {
      tempLessonObject = {
        number: lesson?.number,
        start_time: lesson?.start_time,
        end_time: lesson?.end_time,
      };
      if (lesson?.status) {
        tempLessonObject['status']=lesson?.status;
      }
      if (index+1===lessonNumber) {
        const videoDurationLimit=videoDuration*1000*0.8;
        const endTime=new Date(lesson?.start_time).getTime()+
        videoDuration*1000;
        tempLessonObject['status']='watching';
        tempLessonObject['end_time']=new Date(endTime).toISOString();
        setTimeout(()=>{
          updatedLessonArray[lessonNumber-1]['status']='watched';
          registrationDetailService.setUpdatedLessonsData(dataToSend);
        }, videoDurationLimit);
      }
      updatedLessonArray.push(tempLessonObject);
    });
    const dataToSend = {
      moduleRegId,
      currentModuleData,
      updatedLessonArray,
      studentId,
      lessonsPerWeek,
    };
    if (!watchStatus) {
      registrationDetailService.setUpdatedLessonsData(dataToSend);
    }
  };

  const openSalesOfferConfirmationPopup = (duration, pageKind) => {
    const eventData = {
      studentId,
      locale,
      'cta': `Upgrade Now`,
      'action': 'InitiateUpgradeNowPopupFromBanner',
      pageKind,
    };
    const navigationData = {
      regId: courseData?.id,
      activeModule,
      duration,
    };
    openUpgradeNowPopup(eventData, navigationData);
  };

  // const closeSalesOfferConfirmationPopup = () => {
  //   const urlToNavigate =
  //   `/app/${locale}/v2/
  // registrations/${courseData?.id}/modules/${activeModule}`;
  //   navigate(urlToNavigate);
  // };

  const getFirstUpcomingLesson = () => {
    const upcomingLessons =
    activeModuleData?.attributes?.lessons.filter((lesson)=>(
      new Date(lesson?.start_time).getTime()> new Date().getTime()
    ));
    setFirstUpcomingLesson(upcomingLessons[0]);
  };

  const scrollToUpcomingLesson = () => {
    if (nextLessonRef?.current) {
    nextLessonRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'});
    }
    trackAction.ctaClickAction({
      studentId,
      'cta': 'Watch More Lessons',
      'action': 'scrollToUpcomingLesson',
      locale,
    });
  };

  const triggerMobilePopup = (e) => {
    e.stopPropagation();
    if (window.innerWidth <= 1024) {
      setPopupMode(!popupMode);
    }
  };

  const setPopup = (vimeoId) => {
    setPopupMode(false);
    trackAction.lessonClickAction({
      lessonType: 'Bonus Lessons',
    });
    setVideoId(vimeoId);
  };

  const closeVideoPopup = () => {
    setVideoId(null);
  };

  const closeFlashCards = () => {
    navigate('../../');
  };

  return (
    <div>
      <div className={styles.shawContent}>
        <div className={styles.contentSection}>
          <div className={styles.moduleSwitcher}>
            <div className={`module-switcher-outer ${
              styles.moduleSwitcherOuter}`}>
              {moduleData.map((module, moduleIndex) => {
                const percentageLessonsComplete = registrationDetailService
                    .getPercentageLessonsComplete(module);
                return <ModuleSelector
                  key={moduleIndex}
                  activeModule={activeModule}
                  switchModule={switchModule}
                  percentageLessonsComplete={percentageLessonsComplete}
                  moduleIndex={moduleIndex}
                />;
              })}
            </div>
          </div>
          <VerticalTimeline
            layout="1-column-left"
            animate={false}
            className={styles.modulesContentWrapper}>
            {moduleData.map((module, moduleIndex) => {
              const lesssonsData=module.attributes?.lessons || [];
              const assessmentData=module.attributes?.assignments || [];
              const finalAssignment=module.attributes?.finalassignment;
              const moduleRegistrationId=module.attributes['registration_id'];
              const nextModuleRegId=moduleData[moduleIndex+1]
                  ?.attributes['registration_id'];
              const ecertPurchased=module.ecertPurchased;
              return (<div
                ref={(el) => (moduleRef.current[moduleIndex]=el)}
                key={moduleIndex}
                className={styles.moduleContent}>
                <VerticalTimelineElement
                  className={`${styles.moduleInfoContainer} ${
                    !!moduleRegistrationId?styles.completed:
                    styles.upcoming
                  } ${!!moduleRegistrationId?'':styles.disabled}`}>
                  <div className={styles.moduleHeaderWrapper}>
                    <p className={styles.moduleHeader}>
                      Module {moduleIndex+1}
                    </p>
                    {!moduleRegistrationId &&
                  <div className={`${styles.moduleHeaderUnlock
                  } library-shaw-icon-new`}>
                    <i className="icon-new-Lock-filled"></i>
                  </div>}
                  </div>
                  <GiftFlowBanner
                    locale={locale}
                    studentId={studentId}
                    membershipSlug={membershipInfo?.membership?.slug}
                    // triggerSalesOfferModal={triggerSalesOfferModal}
                    // triggerSalesOfferModal={openSalesOfferModal}
                    moduleRegistrationId={moduleRegistrationId} />
                  <div className={styles.moduleDesc}>
                    {module.attributes.moduledescription &&
                    htmr(module.attributes.moduledescription)}
                  </div>
                  {/* Hardcoded unlock module to module 2 for now */}
                  {moduleIndex+1 === 1 && eligibleForUnlockModule &&
                  !nextModuleRegId && <UnlockModuleBanner
                    triggerUnlockModulePopup={triggerUnlockModulePopup}/>}
                  {moduleIndex+1===4?
                  <HcdThinBanner
                    registrationId={courseData?.id}
                    moduleNumber={activeModule}
                    moduleSlug={activeModuleData?.attributes?.moduleslug}
                    courseName={courseData?.attributes.coursename}
                    hcdPurchased={courseData?.attributes?.hcdPurchased}
                    oneClickText={`one click purchase`}
                    pageKind={'LessonsContentPage'}
                    cta={'Get Your Hard Copy Diploma-Scroll'}
                    isEqfCourse={isEqfCourse}
                    locale={locale}
                    courseSlug={courseData?.attributes.localizedcourseslug}
                    addonPrices={addonPrices?.hcd}
                  />:''}
                  {moduleIndex===0 &&
                  courseData?.attributes?.starterpack?.items ?
                  <div
                    className={styles.lessonResourceSection}>
                    <div className={styles.starterPackLabel}>
                      <h1>Starter Pack</h1>
                      <p>Free</p>
                    </div>
                    <div className={styles.resourceFlexBox}>
                      <div className={styles.flexContent}>
                        <div className={`${styles.leftText}
                        ${styles.lessonTabStarter}`}>
                          <StarterPack
                            closePopup={triggerMobilePopup}
                            setVideoId={setPopup}
                            courseData={courseData?.attributes}
                            courseSlug={courseSlug}
                            locale={locale}
                            pageKind={'LessonsTab'}
                            activeIndex={1}
                            studentInfo={studentInfo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>:<></>}
                </VerticalTimelineElement>
                {lesssonsData.map((lesson, lessonIndex) => {
                  const lessonTimeline=!!moduleRegistrationId?
                  getLessonTimeline(lesson):styles.upcoming;
                  const lessonQuiz=
                  lesson?.lessonquiz?.modular_blocks?.[0]?.link?.link?.href;
                  // DONOT TOUCH
                  const lessonAssessment=!(lessonIndex+1 & 1)&&assessmentData?.
                      [0]?.modular_blocks?.[((lessonIndex+1)/2)-1];
                  const isLessonAssignmentEnabled=checkUnlocked(lesson);
                  const lessonsDisplayProps = {
                    membershipInfo,
                    lessonIndex,
                    lessonTimeline,
                    activeModule,
                    lessonQuiz,
                    lessonAssessment,
                    locale,
                    isLessonAssignmentEnabled,
                    moduleRegistrationId,
                    lesson,
                    module,
                    moduleIndex,
                    showLessonContent,
                    toolkitPurchased,
                    isEqfCourse,
                    courseData,
                    addonPrices,
                    ecertPurchased,
                    lesssonsData,
                    finalAssignment,
                    finalAssessmentRetakes:
                      props.finalAssessmentRetakes?.[moduleIndex],
                    setFinalAssessmentRetakes:
                      props.setFinalAssessmentRetakes,
                    activeModuleData,
                    studentId,
                    registrationData: studentInfo?.data,
                  };
                  return (
                    <div key={lesson.uid}
                      ref={(ele)=>{
                        if (ele && lesson?.uid===firstUpcomingLesson?.uid) {
                          nextLessonRef.current=ele;
                        }
                      }}>
                      <LessonsDisplay
                        lessonsDisplayProps={lessonsDisplayProps}
                        getEvent={(module, lesson) => getEvent(module, lesson)}
                        scrolledToLesson={(
                            inView, lesson, module, moduleIndex) =>
                          scrolledToLesson(inView, lesson, module, moduleIndex)}
                        triggerLessonContent=
                          {(showContent) => triggerLessonContent(showContent)}
                        trackAddToCalendarEvent={(lesson) =>
                          trackAddToCalendarEvent(lesson)}
                        checkLessonEligibility={(lessonTimeline, lesson,
                            moduleRegistrationId, lessonIndex) =>
                          checkLessonEligibility(
                              lessonTimeline, lesson, moduleRegistrationId,
                              lessonIndex)}
                        navigateToReschedule={navigateToReschedule}
                        openAssessment={openAssessment}
                        courseData={courseData}
                        sessionEndTime={sessionEndTime}
                        setSessionEndTime={setSessionEndTime}
                        sessionStartTime={sessionStartTime}
                        setSessionStartTime={setSessionStartTime}
                        // ongoingLessonExists={ongoingLessonExists}
                        retakePrice={retakePrice}
                        updateLessonsData={updateLessonsData}
                        newTimetableEligibility={newTimetableEligibility}
                        countryCode={countryCode}
                      />
                    </div>);
                })}
              </div>);
            })}
          </VerticalTimeline>
        </div>
        {
          showUpsellModal &&
          <UpsellUnlockPopUp
            subscriptionInfo={subscriptionInfo?.attributes}
            salesOfferData={props?.salesOfferData}
            handleHideModal={handleHideUpsellModal}
            showUnlockModal={showUpsellModal}
            source={'LessonsPage'}
            courseSlug={courseData.attributes.localizedcourseslug}
          />
        }
        <LessonsTabBannersDisplay
          membershipInfo={membershipInfo}
          bannerProps={bannerProps}
          changeTabAction={changeTabAction}
          openUpgradePopup={openSalesOfferConfirmationPopup}
          lessonsData={moduleData?.[0]?.attributes?.lessons}
          toolkitPurchased={toolkitPurchased}
          ecertPurchased={ecertPurchased}
          hcdPurchased={courseData?.attributes?.hcdPurchased}
          isWeekTwoComplete={checkUpgradeNowEligibility(moduleData)}
          activeModule={activeModule}
          totalModules={moduleData?.length}
          studentId={studentId}
          planPrices={planPrices}
          scrollToUpcomingLesson={scrollToUpcomingLesson}
          handleUpsellCta={handleUpsellCta}
          subscriptionInfo={subscriptionInfo?.attributes}
          registeredTags={registeredTags}
        />
        <div onClick={scrollTop} className={`${styles.contentScrollUp} ${
          goingDown ?'': styles.hide}`}>
          <img src="/dashboard-new/courses/scrollup.svg"
            alt="scroll-up"></img>
          <p>Back to top</p>
        </div>
      </div>
      {/* popups go here  */}
      {showUnlockModulePopup &&
      <UnlockModulePopup
        isActive={showUnlockModulePopup}
        locale={locale}
        triggerApiCall={triggerApiCall}
        courseData={courseData}
        subscriptionInfo={subscriptionInfo}
        triggerUnlockModulePopup={triggerUnlockModulePopup} />}
      {
      membershipInfo?.membership?.slug !== 'investor-lifetime' ?
      <Router>
        {['partner', 'partners'].includes(utmMedium) &&
        !membershipInfo?.profile?.age && !membershipInfo?.profile?.gender ?
        <RegFormPopup path="get-toolkit"
          closeRegForm={closeRegForm}
          studentId={studentId}
          studentInfo={membershipInfo}/> :
          (!hidePreviousModal &&
          countryCode !== 'af' &&
          !addonsVimeoPopup) ?
          <AddonsPopUp path="get-toolkit"
            toolkitPopupData={popupDataToSend}
            addonPrices={addonPrices}
            closeModal={() => openRescheduleNowPopup()}/> :
            addonsVimeoPopup && !hidePreviousModal &&
            countryCode !== 'af' &&
            <VimeoAddonsPopUp path="get-toolkit"
              popUpData={popupDataToSend}/>
        }
        <PostPurchasePopUp path="post-purchase/:addonPurchased"
          addonPrices={addonPrices}
          toolkitPurchased={toolkitPurchased}
          ecertPurchased={ecertPurchased}
          hcdPurchased={courseData?.attributes?.hcdPurchased}
          closeModal={closePostPurchasePopup}
          popupData={popupDataToSend}/>
        <GetToolkitPopup path="resources/buy"
          toolkitPrices={addonPrices['toolkit']}
          toolkitPopupData={popupDataToSend}
          closeModal={() => closePopupModal()}
          locale={locale} />
        <GetEcertPopup path="assignments/buy"
          ecertPrices={addonPrices['cert']}
          payloadInputs={popupDataToSend}
          closeModal={() => closePopupModal()}
          locale={locale} />
        <RescheduleLessonPopup path="lessons/1/reschedule-now"
          locale={locale}
          studentId={studentId}
          activeModuleData={activeModuleData}
          closePopupModal={() => closePopupModal()}
          newTimetableEligibility={newTimetableEligibility}
        />
        {/* <SalesOffer path="/sales-offer"
          subscriptionInfo={subscriptionInfo}
          salesOfferData={props.salesOfferData}
          closeModal={() => closePopupModal()}/> */}
        {/* <SalesOfferConfirmation
        path="/sales-offer-confirmation/:planDuration"
          closeModal={closeSalesOfferConfirmationPopup}
          salesOfferData={props.salesOfferData}
          subscriptionInfo={subscriptionInfo}
          headingLabels={headingLabels}
          ctaTextLabels={ctaTextLabels}
          descriptionLabels={descriptionLabels}
          planPrices={planPrices}/> */}
        <FlashCard path="/flash-cards/:lessonNumber"
          closeFlashCards={closeFlashCards}
          moduleData={moduleData}
          courseSlug={courseData?.attributes.localizedcourseslug}
          moduleNumber={props.moduleNumber}
          studentId={studentId}
          locale={locale}
        />
      </Router> : ''}
      {showMigratePopup &&
        <MigratePopup />}
      {showToolkitModal && membershipInfo?.membership?.slug !==
      'investor-lifetime' &&
      <GetToolkitPopup
        toolkitPrices={addonPrices['toolkit']}
        toolkitPopupData={popupDataToSend}
        closeModal={() => openWatchNowPopUp()}
        locale={locale} />}
      {showEcertModal && membershipInfo?.membership?.slug !==
      'investor-lifetime' &&
      <GetEcertPopup
        ecertPrices={addonPrices['cert']}
        payloadInputs={popupDataToSend}
        closeModal={() => openWatchNowPopUp()}
        locale={locale} />}
      {toolkitPurchased && ecertPurchased &&
      !courseData?.attributes?.hcdPurchased &&
      showHcdModal &&
      membershipInfo?.membership?.slug !== 'investor-lifetime' &&
      <GetHCDPopup
        hcdPrices={addonPrices['hcd']}
        payloadInputs={popupDataToSend}
        closeModal={() => openWatchNowPopUp()}
        locale={locale} />}
      {showSalesOfferModal && props.salesOfferData && !isUpgraded &&
      membershipInfo?.membership?.slug !== 'investor-lifetime' &&
      !(membershipInfo?.membership?.status === 'active' &&
      membershipInfo?.membership?.slug === 'premium') &&
      <SalesOffer
        subscriptionInfo={subscriptionInfo}
        salesOfferData={props.salesOfferData}
        closeModal={() => closeSalesOfferModal()}/>}
      {eligibleForNextModule && <StartNextModulePopup
        locale={locale}
        regId={courseData?.id}
        studentId={studentId}
        studentName={membershipInfo?.name?.['first_name']}
        courseSlug={courseData.attributes.localizedcourseslug}
        nextModule={courseData.activeModule+1} />}
      {showAddonConfirmation &&
      <AddonsConfirmation
        dataToPassToConfirmation={dataToPassToConfirmation}
        showAddonsPopUp={closeConfirmation}
      />}
      <div>
        {!!currentVideoId &&
      <VideoPlayer
        closePopup={closeVideoPopup}
        vimeoId={currentVideoId} />
        }
      </div>
    </div>
  );
}

LessonsTabView.propTypes = {
  additionParam: PropTypes.string,
  courseData: PropTypes.object,
  membershipInfo: PropTypes.object,
  subscriptionInfo: PropTypes.object,
  studentInfo: PropTypes.object,
  salesOfferData: PropTypes.object,
  addonPrices: PropTypes.object,
  locale: PropTypes.string,
  credits: PropTypes.number,
  triggerApiCalls: PropTypes.func,
  startOnboarding: PropTypes.func,
  showMemeberAreaVisitPopup: PropTypes.func,
  triggerStickyUnlockModule: PropTypes.func,
  triggerUnlockModulePopup: PropTypes.func,
  showUnlockModulePopup: PropTypes.bool,
  finalAssessmentRetakes: PropTypes.array,
  setFinalAssessmentRetakes: PropTypes.func,
  isActiveMember: PropTypes.bool,
  retakePrice: PropTypes.object,
  changeTabAction: PropTypes.func,
  planPrices: PropTypes.object,
  moduleNumber: PropTypes.string,
  registeredTags: PropTypes.array,
};

export default LessonsTabView;
