import React from 'react';
import {Nav} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import browseStyles from './browse.module.scss';
import lockIcon from '../../../static/dashboard-new/courses/lock-filled.svg';
/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export default function Tags(props) {
  const tagName = props.tagInfo;
  const tagSlug =
  String(tagName).toLowerCase().replace('&', '-').replace(/\s+/g, '');
  const {activeTag} = props;
  return (
    <DropdownItem className={browseStyles.tags}
      onClick={() => props.filterCourses(tagName)}>
      <Nav.Item>
        <div className={`${browseStyles.faculty} ${tagName === activeTag ?
           browseStyles.active : ''} `}>
          <span
            className={`library-shaw-icon`}>
            <i className={`icon-${tagSlug}`}></i>
          </span>
          {tagName}
        </div>
        {
          props?.isLocked(tagName) &&
          <img className={browseStyles?.lockIcon}
            src={lockIcon} alt='lockIcon' />
        }
      </Nav.Item>
    </DropdownItem>
  );
}

Tags.propTypes = {
  tagInfo: PropTypes.string,
  activeTag: PropTypes.string,
  filterCourses: PropTypes.func,
  isLocked: PropTypes.func,
  isUserInTrial: PropTypes.bool,
};
