import * as apiService from '../../../api/index';

export const updateStudentService = async (inputEmail, email,
  studentInfo, phone, phoneState, leadId, language) => {
  const requestObj = {
    'data': {
      'type': 'students',
      'id': leadId,
      'attributes': {
        'email': inputEmail || email,
        'name': {
          'first_name': studentInfo?.name?.first_name,
          'last_name': studentInfo?.name?.last_name,
        },
        'phone': phone || phoneState,
        'language_id': language,
      },
    },
  };

  const response =
    await new apiService.default.Student(leadId).update(requestObj)
        .then((response) => {
          return response.data.data.attributes;
        })
        .catch((error) => {
          console.log(error);
          return error?.response?.data?.errors[0];
        });

  return response;
};

export const updateStudentPassword = async (oldPassword, newPassword,
  leadId) => {
  const requestObj= {
    'current_password': oldPassword,
    'new_password': newPassword,
  };
  const response = await new apiService.default.Student(leadId)
      .updatePassword(requestObj)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error?.response?.data?.errors[0];
      });
  return response;
};
