import React, {Component} from 'react';
import styles from './account.module.scss';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import {parsePhoneNumberWithError, ParseError} from 'libphonenumber-js';
import * as trackAction from '../../Shared/TrackActionService';
import {updateStudentPassword, updateStudentService} from './AccountService';
import {getStudentData} from './ProfileService';
import {navigate} from 'gatsby';

/**
 *
 *
 * @export
 * @class AccountTabView
 * @extends {Component}
 */
export default class AccountTabView extends Component {
  /**
     * Creates an instance of AccountTabView.
     * @param {*} props
     * @memberof AccountTabView
     */
  constructor(props) {
    super(props);
    this.state = {
      studentInfo: this.props?.studentInfo,
      email: this.props?.studentInfo?.email,
      country: this.props.studentInfo?.address?.country,
      phone: '',
      language: 'en',
    };
  }

  /**
   * For initial api calls
   * @memberof AccountTabView
   */
  componentDidMount() {
    trackAction.pageViewAction(
        'AccountInfoPage', this.props.locale);
    const leadId = cookie.load('shawacademy_leadId');
    this.setState({leadId});
    getStudentData(leadId).then((updatedStudentData) => {
      this.setState({
        studentInfo: updatedStudentData,
        email: updatedStudentData?.email,
        country: updatedStudentData?.address?.country,
        phone: updatedStudentData?.phone,
        language: this.props.locale,
      });
    });
  }

  /**
   *
   *
   * @param {*} fieldName
   * @memberof AccountTabView
   */
  handleEditableFields(fieldName) {
    this.setState((prevState) => ({
      isLanguageEdit: fieldName==='language'? !prevState.isLanguageEdit : false,
      isPasswordEdit: fieldName === 'password'? !prevState.isPasswordEdit:false,
      isPhoneEdit: fieldName === 'phone' ? !prevState.isPhoneEdit : false,
      isEmailEdit: fieldName === 'email' ? !prevState.isEmailEdit : false,
    }));
  }

  /**
   * Save email field change in state
   * @param {*} e
   * @memberof AccountTabView
   */
  handleEmailChange(e) {
    if (e.target.value.trim()) {
      this.setState({inputEmail: e.target.value.trim()});
    }
  }

  /**
   * Save old password in state
   * @param {*} e
   * @memberof AccountTabView
   */
  oldPasswordChange(e) {
    if (e.target.value.trim()) {
      this.setState({oldPassword: e.target.value.trim()});
    }
  }

  /**
   * Save new password in state
   * @param {*} e
   * @memberof AccountTabView
   */
  newPasswordChange(e) {
    if (e.target.value.trim()) {
      this.setState({newPassword: e.target.value.trim()});
    }
  }

  /**
   * Update password
   * @memberof AccountTabView
   */
  async updatePassword() {
    if (this.state.oldPassword && this.state.newPassword) {
      const response = await updateStudentPassword(
          this.state.oldPassword, this.state.newPassword, this.state?.leadId);
      if (response?.code) {
        this.setState({
          errorMsg: response.detail,
          invalidPasswordError: true,
        });
      } else {
        this.setState({invalidPasswordError: false});
      }
    }
    this.handleEditableFields();
  }

  /**
   * @param {*} isValid
   * @param {*} number
   * @memberof AccountTabView
   */
  phoneNumberChanged = (isValid, number) => {
    if (isValid) {
      let tel;
      try {
        tel = parsePhoneNumberWithError(number).format('E.164');
      } catch (error) {
        if (error instanceof ParseError) {
          // Not a phone number, non-existent country, etc.
          console.log(error.message);
        }
      }
      const isNumberValid = !!tel;
      this.setState({
        tel,
        isNumberValid,
      });
    } else {
      this.setState({
        isNumberValid: false,
      });
    }
  }

  /**
   * @memberof AccountTabView
   */
  async updateStudentInfo() {
    let phone;
    if (this.state.tel && this.state.isNumberValid) {
      phone = this.state.tel;
    } else if (this.state.tel && !this.state.isNumberValid) {
      this.setState({
        showPhoneError: true,
      });
    }
    if (this.state.inputEmail || phone || this?.state?.language) {
      const studentInfo = await updateStudentService(this.state.inputEmail,
          this.state.email, this.state.studentInfo, phone,
          this.state.phone, this.state?.leadId, this.state?.language);
      if (studentInfo?.code) {
        this.setState({
          errorMsg: studentInfo.detail,
          showError: true,
        });
      } else {
        this.setState({
          studentInfo: studentInfo,
          email: studentInfo?.email,
          phone: studentInfo?.phone,
          country: studentInfo?.address.country,
          language: studentInfo?.language_id,
          showPhoneError: false,
        });
      }
      if (this.props.locale !== studentInfo?.language_id) {
        navigate(`/app/${studentInfo?.language_id}`);
      }
    }
    this.handleEditableFields();
  }

  /**
   *
   *
   * @param {*} e
   * @memberof AccountTabView
   */
  handleLanguageChange(e) {
    this.setState({
      language: e.target.value,
    });
  }

  /**
   *
   *
   * @memberof AccountTabView
   */
  handleCancelLanguageChange() {
    this.handleEditableFields('language');
    this.setState({
      language: this.props.locale,
    });
  }

  /**
 *
 *
 * @return {*}
 * @memberof AccountTabView
 */
  render() {
    const state = this.state;
    const languageKeyValues = {
      'en': 'English',
      'fr': 'Français',
      'de': 'Deutsch',
      'es': 'Español',
      'it': 'Italiano',
      'pt': 'Português',
    };

    return <>
      <div className={styles.heading}>
        Account & Settings
      </div>
      <div className={styles.infoRow}>
        <label className={styles.labelText}>
          Email address
        </label>
        <div className={styles.infoText}>
          {!state.isEmailEdit ? <p>{state?.email}</p>:
            <input type='email'
              placeholder="email@example.com"
              className={styles.inputField}
              onChange={(e) => this.handleEmailChange(e)}
              defaultValue={state?.email}
            />
          }
          <div>
            {state.isEmailEdit ?
              <button className={styles.editCta}
                onClick={() => this.updateStudentInfo()}
              > Save
              </button> :
              <button className={styles.editCta}
                onClick={() => this.handleEditableFields('email')}
              > Edit
              </button>}
            {state.isEmailEdit &&
          <span onClick={() => this.handleEditableFields('email')}
            className={styles.closeIcon}>X</span>}
          </div>
        </div>
      </div>
      <div className={styles.infoRow}>
        <label className={styles.labelText}>
          Password
        </label>
        <div className={styles.infoText}>
          {!state.isPasswordEdit ? '**********' :
            <div>
              <input type='password'
                placeholder={`Old Password`}
                onChange={(e) => this.oldPasswordChange(e)}
                className={styles.inputField} />
              <input type='password'
                placeholder={`New Password`}
                onChange={(e) => this.newPasswordChange(e)}
                className={styles.inputField} />
            </div>
          }
          {state.isPasswordEdit ?
              <button className={styles.editCta}
                onClick={() => this.updatePassword()}
              > Save
              </button> :
              <button className={styles.editCta}
                onClick={() => this.handleEditableFields('password')}
              > Change Password
              </button>}
          {state.isPasswordEdit &&
          <span onClick={() => this.handleEditableFields('password')}
            className={styles.closeIcon}>X</span>}
        </div>
        { state?.invalidPasswordError ?
          <div className={styles.errorMsg}>
            {state?.errorMsg}
          </div> : null
        }
      </div>
      <div className={styles.infoRow}>
        <label className={styles.labelText}>
          Language
        </label>
        <div className={styles.infoText}>
          {
            !state.isLanguageEdit ?
            languageKeyValues[this.state.language] :
            <select
              value={this.state.language}
              onChange={(e)=>this.handleLanguageChange(e)}>
              {Object.entries(languageKeyValues).map(([key, value])=> (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          }
          <div>
            {/* {state.isLanguageEdit ?
              <button className={styles.editCta}
                onClick={() => this.updateStudentInfo()}>
                Save
              </button> :
              <button className={styles.editCta}
                onClick={() => this.handleEditableFields('language')}
              > Edit
              </button>} */}
            {/* {state.isLanguageEdit &&
          <button onClick={()=>this.handleCancelLanguageChange()}
            style={{marginLeft: '8px'}}
            className={styles.editCta}>
              Cancel</button>} */}
          </div>
        </div>
      </div>
      <div className={styles.infoRow}>
        <label className={styles.labelText}>
          Phone Number
        </label>
        <div className={styles.infoText}>
          {state?.phone &&
          <IntlTelInput
            containerClassName="intl-tel-input"
            inputClassName={`tel-input form-control ${styles.inputField}`}
            name="tel"
            defaultValue={state?.phone}
            disabled={!state.isPhoneEdit}
            defaultCountry={state?.country}
            autoPlaceholder="aggressive"
            allowExtensions={true}
            autoHideDialCode={false}
            nationalMode={false}
            autoFormat={false}
            formatOnInit={true}
            onPhoneNumberChange={this.phoneNumberChanged}
            autoComplete="off"
          />}
          <div>
            {state.isPhoneEdit ?
              <button className={styles.editCta}
                onClick={() => this.updateStudentInfo()}
              > Save
              </button> :
              <button className={styles.editCta}
                onClick={() => this.handleEditableFields('phone')}
              > Edit
              </button>}
            {state.isPhoneEdit &&
          <span onClick={() => this.handleEditableFields('phone')}
            className={styles.closeIcon}>X</span>}
          </div>
        </div>
        {state?.showPhoneError ?
            <div className={styles.errorMsg}>
            Invalid Number
            </div> : null
        }
      </div>
    </>;
  }
}

AccountTabView.propTypes = {
  studentInfo: PropTypes.object,
  locale: PropTypes.string,
};
