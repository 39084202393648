import React, {Component} from 'react';
import {Form, Button, Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import * as myAccountService from '../MyAccountService';
import loader from '../../../../static/loading.gif';
import Alert from 'react-bootstrap/Alert';
import * as apiService from '../../../api';
import styles from './cancellation.module.scss';
import * as trackAction from '../../Shared/TrackActionService';
import dayjs from 'dayjs';
import DatePicker from 'react-date-picker';
import MigratePopup from '../../Shared2/Popup/MigratePopup';
import * as commonService from '../../Shared2/Agent/CommonService';

/**
 *
 *
 * @export
 * @class PauseMembershipTabView
 * @extends {Component}
 */
export default class PauseMembershipTabView extends Component {
  /**
   * Creates an instance of PauseMembershipTabView.
   * @param {*} props
   * @memberof PauseMembershipTabView
   */
  constructor(props) {
    super(props);
    this.state = {
      exceedDateError: false,
      pauseBtnDisabled: true,
      pauseMemberhipLoader: false,
      genericError: false,
      showMigratePopup: false,
    };
  }

  /**
   *
   *
   * @memberof PauseMembershipTabView
   */
  componentDidMount() {
    trackAction.pageViewAction(
        'pausePage', this.props.locale);
    const leadId = cookie.load('shawacademy_leadId');
    const dateToday = dayjs();

    const minLimit = dayjs(dateToday).add(1, 'd').format('YYYY-MM-DD');
    const maxLimit = dayjs(dateToday).add(1, 'M').format('YYYY-MM-DD');

    myAccountService.getStudentInfoIncluded()
        .then((response) => {
          if (response?.studentInfo?.attributes?.
              membership?.status === 'inactive' ||
            commonService.checkGiftMembership(
              response?.studentInfo?.attributes?.membership,
            )) {
            this.setState({
              showMigratePopup: true,
            });
          }
          const subscriptionInfo = response?.subscriptionInfo;
          this.setState({
            subscriptionInfo,
          });
        })
        .catch((error) => {
          console.log(error);
        });

    this.setState({
      leadId: leadId,
      minLimit: minLimit,
      maxLimit: maxLimit,
    });
  }

  /**
   *
   *
   * @param {*} value
   * @memberof PauseMembershipTabView
   */
  handleDateChange(value) {
    const date = value;
    const day = new Date(date).getDay();
    this.setState({
      changedDate: date,
      defaultDate: [].concat(date),
      day: day,
      pauseBtnDisabled: false,
    });
  }

  /**
   * Handles pause membership
   *
   * @memberof PauseMembershipTabView
   */
  handlePauseMembership() {
    this.setState({pauseMemberhipLoader: true});
    this.setState({genericError: false});
    const resumeOn = new Date(this.state.changedDate).toISOString();

    const subscriptionInfo = this.state?.subscriptionInfo;
    const subId = subscriptionInfo?.id;
    const planId = subscriptionInfo?.attributes?.chargebee?.plan_id ||
      subscriptionInfo?.attributes?.default?.additional_platform_data?.plan_id;
    const requestData = {
      student_id: this.state.leadId,
      resume_on: resumeOn,
    };
    new apiService.Subscription(subId)
        .pauseSubscription(requestData)
        .then((response) => {
          trackAction.subscriptionChangeAction({
            action: 'Paused',
            planId: planId,
          });
          const expires = new Date;
          expires.setDate(new Date().getDate()+1);
          cookie.save('membershipPaused', 'true',
              {
                path: '/',
                domain: process.env.CookieDomain,
                expires,
              });
          if (this.props.origin === 'account') {
            this.props.hidePauseMembership();
          } else {
            // redirection
            this.props.navigate(`/app/${this.props.locale}`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.data?.errors?.[0]?.status == 400) {
            this.setState({
              exceedDateError: true,
              pauseMemberhipLoader: false,
            });
          } else {
            this.setState({pauseMemberhipLoader: false});
            this.setState({genericError: true});
          }
        });
  }

  /**
   *
   *
   * @memberof PauseMembershipTabView
   */
  handleBackClick() {
    if (this.props.origin === 'account') {
      this.props.hidePauseMembership();
    } else {
      this.props.navigate(`/app/${this.props.locale}/account/membership`);
    }
  }

  /**
   *
   *
   * @return {*}
   * @memberof PauseMembershipTabView
   */
  render() {
    return (
      <>
        <div className={this.props.origin === 'account' ?
        `${styles.noMargin} ${styles.pauseContainer}` :
        `${styles.pauseContainer}`}>
          <Form>
            <Row onClick={()=> this.handleBackClick()}
              className={styles.backCta}>
              <span> &#8592; </span> Back
            </Row>
            <Form.Text className={styles.heading}>
              Pause Membership
            </Form.Text>

            <Form.Text className={styles.subText}>
              You can pause your subscription for up to 30 days. The
              remaining days from your current month will be carried over.
            </Form.Text>

            <Form.Group controlId="formBasicEmail">
              <Form.Label className={styles.dateLabel}>
                Select Resume Date</Form.Label>
              <DatePicker
                value={this.state?.defaultDate}
                locale={this.props.locale}
                minDate={new Date(this.state.minLimit)}
                maxDate={new Date(this.state.maxLimit)}
                clearIcon={null}
                onChange={(value) => this.handleDateChange(value)}
              />
            </Form.Group>

            <Col className={styles.pauseConfirmText}>
              <p>
                Your subscription will resume on
              </p>
              { this.state.changedDate ?
            <p>
              <div
                className="bold-font primary-font-color">
                {dayjs(this.state.changedDate)?.format('dddd D MMM YYYY')}
              </div>
            </p> :
            <p>- - -</p>
              }
            </Col>

            <Form.Text className={styles.subText}>
              While your paused, you will not be billed and you cannot
              progress further in your course. If you want to unpause your
              membership you can do so from the profile page.
            </Form.Text>

            <Col style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <Button
                className={styles.pauseCta}
                disabled={this.state.pauseBtnDisabled}
                onClick={() => this.handlePauseMembership()}
              >
                Confirm Pause
                {this.state.pauseMemberhipLoader &&
              <img style={{maxWidth: 40 + 'px'}}
                src={loader} alt="pauseMembershipLoader" /> }
              </Button>
            </Col>
            {this.state.genericError && <Alert variant="danger"
              style={{color: 'red'}}>
            Something went wrong
            </Alert>}
            {this.state.exceedDateError &&
          <Alert variant="danger" style={{color: 'red'}}>
            Max day 28 days from Today
          </Alert>}
          </Form>
        </div>
        {this.state.showMigratePopup &&
          <MigratePopup />}
      </>
    );
  }
}

PauseMembershipTabView.propTypes = {
  triggerPauseMembershipTabView: PropTypes.func,
  studentData: PropTypes.any,
  locale: PropTypes.string,
  navigate: PropTypes.any,
  origin: PropTypes.string,
  hidePauseMembership: PropTypes.func,
};
