const checkSupportedFeatures = (supportedFeaturesObject, supportedFeatures) => {
  if (supportedFeatures?.indexOf('REACTIVATE') !== -1) {
    supportedFeaturesObject['showReactivateCta'] = true;
  }
  if (supportedFeatures?.indexOf('UPGRADE') !== -1) {
    supportedFeaturesObject['showUpgradeCta'] = true;
  }
  if (supportedFeatures?.indexOf('CANCEL') !== -1) {
    supportedFeaturesObject['showCancelCta'] = true;
  }
  if (supportedFeatures?.indexOf('PAUSE') !== -1) {
    supportedFeaturesObject['showPauseCta'] = true;
  }
  if (supportedFeatures?.indexOf('RESUME') !== -1) {
    supportedFeaturesObject['showResumeCta'] = true;
  }
  if (supportedFeatures?.indexOf('FAMILY_INVITE') !== -1) {
    supportedFeaturesObject['showFamilyTab'] = true;
  }

  return supportedFeaturesObject;
};

export const getSubscriptionData = (subscriptionAttributes) => {
  let planId = '';
  let subsType = '';
  let billingPeriod;
  let billingPeriodUnit;
  let showSubscriptionTabView = false;
  let supportedFeaturesObject = {
    showReactivateCta: false,
    showResumeCta: false,
    showFamilyTab: false,
    showUpgradeCta: false,
    showCancelCta: false,
    showPauseCta: false,
  };
  if (subscriptionAttributes?.default) {
    showSubscriptionTabView = true;
    planId = subscriptionAttributes?.default?.
        additional_platform_data?.plan_id;
    billingPeriod = subscriptionAttributes?.default?.
        additional_platform_data?.billing_period;
    billingPeriodUnit = subscriptionAttributes?.default?.
        additional_platform_data?.billing_period_unit;
    supportedFeaturesObject = checkSupportedFeatures(supportedFeaturesObject,
      subscriptionAttributes?.default?.supported_features);
  } else if (subscriptionAttributes?.chargebee) {
    showSubscriptionTabView = true;
    planId = subscriptionAttributes?.chargebee?.plan_id;
    billingPeriod = subscriptionAttributes?.chargebee?.billing_period;
    billingPeriodUnit = subscriptionAttributes?.chargebee?.billing_period_unit;
    supportedFeaturesObject = checkSupportedFeatures(supportedFeaturesObject,
      subscriptionAttributes?.chargebee?.supported_features);
  }

  // SubType
  if (billingPeriod === 4 && billingPeriodUnit === 'week') {
    subsType = 'monthly';
  } else if (billingPeriod === 4 && billingPeriodUnit === 'month') {
    subsType = 'quarterly';
  } else if (billingPeriod === 6) {
    subsType = 'biannual';
  } else if (billingPeriod === 12) {
    subsType = 'annual';
  } else {
    subsType = 'monthly';
  }
  return {
    planId,
    subsType,
    showSubscriptionTabView,
    ...supportedFeaturesObject,
  };
};
