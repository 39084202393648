import React, {useState, useEffect} from 'react';
import cookie from 'react-cookies';
import {Button} from 'react-bootstrap';
import htmr from 'htmr';
import PropTypes from 'prop-types';
import AssetsService from '../Shared/AssetsService';
import courseStyles from '../../styles/Courses.module.scss';
import * as trackAction from '../Shared/TrackActionService';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function StarterPack(props) {
  const {closePopup,
    locale, activeIndex,
    pageKind, courseData} = props;
    // studentInfo,
  const [imagePath, setImagePath] = useState(AssetsService.DUMMY_IMG);

  // const userStatus = studentInfo?.membership?.status;
  // const userMembershipName = studentInfo?.membership?.name.toLowerCase();

  useEffect(() => {
    const slug = courseData?.courseslug;
    const assetURL = new AssetsService('MY_COURSES').fetchAssetURL(slug);
    setImagePath(assetURL);
  }, [courseData]);
  /**
   *
   *
   * @param {*} data
   */
  function openFile(data) {
    // Get XP for download-resources
    const studentId=cookie.load('shawacademy_leadId');
    if (data?.link) {
      window.open(data.link?.link?.href, '__blank');
      const objToPass = {
        studentId,
        courseSlug: props?.courseSlug,
        moduleNumber: activeIndex,
        contentType: courseData?.starterpack?.slug,
        resourceType: 'StarterPack',
        fileName: data?.link?.link?.title,
        pageKind,
      };
      trackAction.downloadClickAction(objToPass);
    } else {
      const vimeoURI=data.video.video.uri;
      const vimeoId=vimeoURI.split('/')[2];
      props.setVideoId(vimeoId);
      trackAction.ctaClickAction({
        'cta': 'Watch Recording',
        'action': 'watchRecording',
        pageKind,
        locale,
      });
    }
  }

  return (
    <div className={`${courseStyles.shawStarterPack}
    ${courseStyles.shawResourceOuterContainer}`}
    >
      <div className={courseStyles.backText}
        onClick={closePopup}
      >
        {`< back`}
      </div>
      <div className={courseStyles.starterPackBackground}>
        <img src={imagePath} alt=''/>
      </div>
      <div className={courseStyles.starterPackContent}>
        <p className={courseStyles.starterPackHeader}>
          {courseData?.coursename}
        </p>
        <div className={courseStyles.starterPackInfo}>
          {courseData?.coursedescription &&
            htmr(courseData?.coursedescription)
          }
        </div>
        {courseData?.starterpack?.items?.length &&
        courseData?.starterpack?.items.map((item, i) => (
          (item.link ?
          (<div className={courseStyles.starterPackFiles} key={i}>
            <p>{item?.link?.link?.title}</p>
            <div className={courseStyles.buttonWrapper}>
              <Button onClick={() => openFile(item)}>
                Download for free
              </Button>
              {/* {
                !['cancelled', 'inactive'].includes(userStatus) &&
                userMembershipName?.indexOf('lifetime') === -1 &&
                <span
                  className={`library-shaw-icon`}>
                  <i className={`icon-new-Info`}></i>
                  <span className={courseStyles.tooltip}>
                    Download to earn 20 XP
                  </span>
                </span>
              } */}
            </div>
          </div>):
          (<div className={courseStyles.starterPackFiles} key={i}>
            <p>{item?.video?.video?.name}</p>
            <div className={courseStyles.buttonWrapper}>
              <Button onClick={() => openFile(item)}>
                Watch Recording
              </Button>
              {/* <span
                className={`library-shaw-icon`}>
                <i className={`icon-new-Info`}></i>
                <span className={courseStyles.tooltip}>
                  Download to earn XP
                </span>
              </span> */}
            </div>
          </div>)
          )
        ),
        )}
      </div>
    </div>
  );
}

StarterPack.propTypes = {
  closePopup: PropTypes.func,
  setVideoId: PropTypes.func,
  courseSlug: PropTypes.string,
  locale: PropTypes.string,
  activeIndex: PropTypes.number,
  studentInfo: PropTypes.object,
  pageKind: PropTypes.string,
  courseData: PropTypes.object,
};

export default StarterPack;
