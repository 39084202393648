import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import styles from './CourseSwitcherMobileView.module.scss';
import cookie from 'react-cookies';
import AssetsService from '../Shared/AssetsService';
import Avatar from '../../pages/static/Avatar.png';

const CourseSwitcherMobileView = (props) => {
  const {locale, coursesOnSwitcher, switchCourse,
    triggerCourseSwitcher, showCourseSwitcher, activeCourse} = props;
  const [activeCourseUrl, setActiveCourseUrl]=useState(AssetsService.DUMMY_IMG);
  const leadId=cookie.load('shawacademy_leadId');
  useEffect(()=>{
    if (activeCourse) {
      const courseImage=new AssetsService('RECORDINGS').fetchAssetURL(
          activeCourse.localizedcourseslug);
      setActiveCourseUrl(courseImage);
    }
  }, [activeCourse]);

  return (
    <div className={styles.courseContainer}>
      <div style={{display: 'flex'}}>
        <div className={`${styles.courseSelectorTrigger}
     ${showCourseSwitcher ? styles.active : ''}`}>
          <div className={styles.selectorWrap}
            onClick={triggerCourseSwitcher}>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
        <section className={`${styles.courseSelectorPopup}
     ${showCourseSwitcher ? styles.active : ''}`}>
          <p className={styles.popupHeader}>
            Select your course to begin
          </p>
          <div className={styles.courseListSection}>
            {coursesOnSwitcher &&
            coursesOnSwitcher.map((course, index) => (
              <div
                onClick={() =>switchCourse(course)}
                className={styles.singleCourse}
                key={index}>
                <div className={styles.courseContent}>
                  <img
                    src={course.courseImg}
                    alt={course.attributes.coursename} />
                  <p className={styles.courseName}>
                    {course.attributes.coursename}
                  </p>
                </div>
                <i className={`shaw-arrow ${styles.shawArrow}
                 ${styles.arrowRight} arrow-right`}></i>
              </div>
            ))}
            <Link to={`/app/${locale}/courses`}
              className={styles.singleCourse}>
              <div className={styles.courseContent}>
                <div className={styles.addCourse}>+</div>
                <p className={styles.courseName}>
                  Add new course
                </p>
              </div>
            </Link>
          </div>
        </section>
        {activeCourse && <div className={styles.currentSelectedCourse}>
          <img src={activeCourseUrl}
            alt={activeCourse.courseName}/>
        </div>}
      </div>
      {!(!['cancelled', 'inactive'].includes(props.userStatus) &&
      props.userMembershipName?.indexOf('lifetime') === -1) &&
      <div className={styles.bannerWrapper}>
        <div className={styles.imageContainer}>
          <Link to={`/app/${locale}/account`}>
            <img
              src={`https://shaw-profile-pic.s3.amazonaws.com/${leadId}.jpeg`}
              onError={(e) => e.target.src=Avatar}
              alt='user'
              className={styles.userIcon}
            />
          </Link>
        </div>
      </div> }
    </div>
  );
};


CourseSwitcherMobileView.propTypes = {
  triggerCourseSwitcher: PropTypes.func,
  locale: PropTypes.string,
  showCourseSwitcher: PropTypes.bool,
  coursesOnSwitcher: PropTypes.array,
  switchCourse: PropTypes.func,
  activeCourse: PropTypes.object,
  userStatus: PropTypes.any,
  userMembershipName: PropTypes.any,
};

export default CourseSwitcherMobileView;
