import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AddonsConfirmation from './AddonsConfirmation';
import styles from '../AddonsPopUp.module.scss';
import * as trackAction from '../../Shared/TrackActionService';

const AddonsPopUp = ({closeModal, toolkitPopupData, addonPrices}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInitialPopUp, setShowInitialPopUp] = useState(true);
  const [dataToPassToConfirmation, setDataToPassToConfirmation] = useState({});

  const confirmPopup = (param) => {
    let cta = 'Get Your Toolkit-modal';
    let action = 'Get Your Course Materials';
    let product = 'CourseToolkit';
    let productName = 'TOOLKIT';
    let textInfo = 'Course Materials';
    let priceInfo = addonPrices['toolkit'];
    if (param === 'cert') {
      action = 'Get Your Certificate';
      product = 'CourseEcert';
      productName = 'CERT';
      textInfo = 'Digital Certificates';
      cta = 'Get Your Certificate-modal';
      priceInfo = addonPrices['cert'];
    } else if (param === 'hcd') {
      action = 'Get Your Diploma';
      product = 'HardCopyDiploma';
      productName = 'HCD';
      textInfo = 'Hard Copy Diploma';
      cta = 'Get Your Diploma-modal';
      priceInfo = addonPrices['hcd'];
    }
    trackAction.ctaClickAction({
      cta,
      pageKind: toolkitPopupData?.pageKind,
      action,
      studentId: toolkitPopupData?.studentId,
      courseSlug: toolkitPopupData?.courseSlug,
      moduleNumber: toolkitPopupData?.moduleNumber,
      locale: toolkitPopupData?.locale,
    });
    setShowConfirmModal(true);
    setShowInitialPopUp(false);
    setDataToPassToConfirmation({
      param,
      courseSlug: toolkitPopupData?.courseSlug,
      moduleSlug: toolkitPopupData?.moduleSlug,
      courseName: toolkitPopupData?.courseName,
      moduleNumber: toolkitPopupData?.moduleNumber,
      locale: toolkitPopupData?.locale,
      pageKind: toolkitPopupData?.pageKind,
      studentId: toolkitPopupData?.studentId,
      priceInfo,
      product,
      textInfo,
      productName,
      regId: toolkitPopupData?.regId,
    });
  };

  const showFirstAddonsPopUp = () => {
    setShowConfirmModal(false);
    setShowInitialPopUp(true);
  };

  useEffect(() => {
    trackAction.popUpAction({
      studentId: toolkitPopupData?.studentId,
      pageKind: toolkitPopupData?.pageKind,
      title: 'Get Your Addons',
      locale: toolkitPopupData?.locale,
      courseSlug: toolkitPopupData?.courseSlug,
      moduleNumber: toolkitPopupData?.moduleNumber,
      variation: 'Default',
    });
  }, []);


  return (
    <div className={styles.overlay}>
      {showInitialPopUp && <div className={styles.modalWrapper}>
        <div className={styles.modalHeader}>
          <div className={styles.modalClose}>
            <div
              onClick={() => closeModal()}
              className={' library-shaw-icon-new'}>
              <i className="icon-new-close"></i>
            </div>
          </div>
          <p className={styles.heading}>
            Get ahead with your Course Addons!
          </p>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.addonWrapper}>
            <div className={styles.addons}>
              <p className={styles.title}>
                Course Materials
              </p>
              <div className={styles.ratings}>
                {new Array(5).fill(undefined).map((_, index) => (
                  <div className={'library-shaw-icon'} key={index}>
                    <i className="icon-star-filled"></i>
                  </div>
                ))}
              </div>
              <p className={styles.students}>
                  284,341 Students
              </p>
              <p className={styles.status}>
                Got Their&nbsp;
                Course Materials
              </p>
              <button
                className={styles.getCourseCTA}
                onClick={() => confirmPopup('toolkit')}
              >
                Get Your Course Materials
              </button>
            </div>
            <div className={styles.addons}>
              <p className={styles.title}>
                Module Certificate
              </p>
              <div className={styles.ratings}>
                {new Array(5).fill(undefined).map((_, index) => (
                  <div className={'library-shaw-icon'} key={index}>
                    <i className="icon-star-filled"></i>
                  </div>
                ))}
              </div>
              <p className={styles.students}>234,941 Students</p>
              <p className={styles.status}>
                Got Their&nbsp;
                Module Certificate
              </p>
              <button
                className={styles.getCourseCTA}
                onClick={() => confirmPopup('cert')}>
                Get Your Certificate
              </button>
            </div>
            <div className={styles.addons}>
              <p className={styles.title}>
                Hard Copy Diploma
              </p>
              <div className={styles.ratings}>
                {new Array(5).fill(undefined).map((_, index) => (
                  <div className={'library-shaw-icon'} key={index}>
                    <i className="icon-star-filled"></i>
                  </div>
                ))}
              </div>
              <p className={styles.students}>214,529 Students</p>
              <p className={styles.status}>
                Got Their&nbsp;
                Hard Copy Diploma
              </p>
              <button
                className={styles.getCourseCTA}
                onClick={() => confirmPopup('hcd')}
              >
                Get Your Diploma
              </button>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <p>
            Get the most out of your course here at Shaw Academy and unlock
            your Extra Addons to get the advantage over your classmates
          </p>
        </div>
      </div>}
      {showConfirmModal &&
        <AddonsConfirmation
          closeModal={closeModal}
          dataToPassToConfirmation={dataToPassToConfirmation}
          showAddonsPopUp={showFirstAddonsPopUp} />}
    </div>);
};

AddonsPopUp.propTypes = {
  closeModal: PropTypes.func,
  showAddonsPopUp: PropTypes.func,
  toolkitPopupData: PropTypes.object,
  addonPrices: PropTypes.object,
};

export default AddonsPopUp;
