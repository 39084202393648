/**
 *
 *
 * @class AssessmentService
 */
class AssessmentService {
  /**
   * Creates an instance of AssessmentService.
   * @param {*} assessmentData
   * @memberof AssessmentService
   */
  constructor(assessmentData) {
    this.assessmentData=assessmentData;
  }

  /**
   *
   *
   * @memberof AssessmentService
   */
  appendAssessmentDataToCourse() {
    const moduleData=this.courseData?.relationships.modules.data;
    const assessmentData=this.assessmentData?.[0]?.assessments || [];
    moduleData.forEach((module, moduleIndex) => {
      // iterate through assessment data
      assessmentData.forEach((assessment) => {
        const finalAssesmentFormId=module.attributes.finalassignment
            ?.modular_blocks?.[0]?.typeform?.formid;
        if (!module.attributes.finalassignment['result'] &&
          finalAssesmentFormId===assessment.reference_key) {
          const assessmentWithScore=
            this.calculateAssessmentScore(assessment);
          module.attributes.finalassignment['result']=assessmentWithScore;
          const retakes=this.calculateRetakes(assessment, moduleIndex);
          module.attributes.finalassignment['retakes']=retakes;
        } else {
          const weeklyAssignments=module.attributes
              .assignments[0].modular_blocks;
          weeklyAssignments.forEach((assignment) => {
            if (assignment?.typeform?.formid===assessment.reference_key) {
              const assessmentWithScore=
                this.calculateAssessmentScore(assessment);
              assignment['result']=assessmentWithScore;
            }
          });
        }
      });
      const lessonsData=module.attributes?.lessons || [];
      lessonsData.forEach((lesson, index)=>{
        const isEven=(index+1)%2===0;
        if (isEven && lesson.assignment &&
           lesson.assignment?.modular_blocks.length) {
          assessmentData.forEach((assessment)=>{
            if (assessment.reference_key===
              lesson.assignment?.modular_blocks?.[0]?.typeform?.formid) {
              const assessmentWithScore=
                this.calculateAssessmentScore(assessment);
              lesson.assignment['result']=assessmentWithScore;
            }
          });
        }
      });
    });
  }


  /**
   *
   *
   * @param {*} assessment
   * @param {*} moduleIndex
   * @return {*} retake count of the assessment
   * @memberof AssessmentService
   */
  calculateRetakes(assessment, moduleIndex) {
    const {addonData}=this;
    const attempts=assessment.attempts;
    const courseSlug=this.courseData?.attributes.localizedcourseslug;
    const moduleItems=addonData?.attributes?.default?.items;
    let retakeCount=0;
    if (moduleItems) {
      const eligibleAddons=['module-cert', 'module-assessment-retake'];
      const retakePurchasesCount=moduleItems?.filter((item) => {
        if (item.eqf_slug===courseSlug&&
          item.module_number===moduleIndex+1) {
          return eligibleAddons.includes(item.product);
        }
        return false;
      }).length;
      retakeCount=parseInt((retakePurchasesCount)*2)-parseInt(attempts);
    }
    const chargebeeObject=addonData?.attributes?.chargebee?.line_items;
    if (chargebeeObject) {
      const chargebeeCertObject = chargebeeObject.filter((item)=> {
        const chargebeeItem=[];
        if ((item.entity_id).indexOf('cert')>-1) {
          return chargebeeItem.push(item);
        }
      });
      if (chargebeeCertObject.length>0) {
        retakeCount=retakeCount+2;
      }
    }
    return retakeCount;
  }

  /**
   *
   *
   * @param {*} addonData
   * @memberof AssessmentService
   */
  setAddonData(addonData) {
    this.addonData=addonData;
  }


  /**
   *
   *
   * @param {*} studentScore
   * @param {*} totalScore
   * @return {*} percentile
   * @memberof AssessmentService
   */
  getPercentile(studentScore, totalScore) {
    let percentile=Number(((studentScore/totalScore)*100).toFixed(2));
    percentile=percentile === 'NaN' ? '' : percentile > 100 ? 100 : percentile;
    return percentile;
  };

  /**
   *
   *
   * @param {*} data
   * @return {*} string
   */
  getBreakDown(data) {
    let breakDown = 'FAIL';
    if (data >= 85) {
      breakDown = 'DISTINCTION';
    } else if (data >= 70) {
      breakDown = 'MERIT';
    } else if (data >= 50) {
      breakDown = 'PASS';
    }
    return breakDown;
  };


  /**
   *
   *
   * @param {*} assessment
   * @return {*} assessment with score
   * @memberof AssessmentService
   */
  calculateAssessmentScore(assessment) {
    const assessmentWithScore={...assessment};
    const studentScore = assessment.top_score;
    const totalScore = assessment.total_marks;
    const percentile=this.getPercentile(studentScore, totalScore);
    assessmentWithScore['percentile']=percentile;
    assessmentWithScore['breakDown']=this.getBreakDown(percentile);
    assessmentWithScore['result']=percentile>50;
    return assessmentWithScore;
  }

  /**
   *
   *
   * @param {*} courseData
   * @return {*}
   * @memberof AssessmentService
   */
  segregateAssessmentData(courseData) {
    this.courseData=courseData;
    this.appendAssessmentDataToCourse();
    const courseDataWithAssessments={...this.courseData};
    return courseDataWithAssessments;
  }
}

export default AssessmentService;
