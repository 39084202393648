import * as apiService from '../../../api/index';
import * as myAccountService from '../MyAccountService';
import {getSubscriptionData} from './AccountUtils';
import slugData from '../../Shared2/Leaderboard/TrackActivity.json';
import cookie from 'react-cookies';
import * as trackActivityService from
  '../../Shared2/Leaderboard/TrackActivityService';

export const uploadProfilePicService = async (selectedFile, leadId) => {
  const formData = new FormData();
  formData.append('avatar', selectedFile);
  const isUploadSuccessful = await new apiService.default.Student(leadId)
      .uploadProfilePic(formData)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  return isUploadSuccessful;
};

export const updateStudentData =
  async (userInfo, enteredName, profileObj, leadId) => {
    const fullNameSplit = enteredName?.split(' ');
    const fName = fullNameSplit ? fullNameSplit[0] : null;
    const lName = fullNameSplit && fullNameSplit.length > 1 ?
      fullNameSplit?.slice(1)?.join(' ') : ' ';
    const requestObj = {
      'data': {
        'type': 'students',
        'id': leadId,
        'attributes': {
          'email': userInfo?.email,
          'name': {
            'first_name': fName,
            'last_name': lName,
          },
          'phone': userInfo?.phone,
          'address': {
            'country': userInfo?.address?.country,
            'city': userInfo?.address?.city,
            'region': userInfo?.address?.region,
            'postal': userInfo?.address?.postal,
          },
          'timezone': userInfo?.timezone,
          'profile': profileObj,
        },
      },
    };

    const studentAttributes =
      await new apiService.default.Student(leadId)
          .update(requestObj)
          .then((response) => {
            return response.data.data.attributes;
          })
          .catch((error) => {
            console.log(error);
            return error.response.data.errors[0];
          });
    return studentAttributes;
  };

export const getStudentData = async (leadId) => {
  const updatedData = await new apiService.default.Student(leadId).get()
      .then((res) => {
        const studentAttributes=res.data.data.attributes;
        return studentAttributes;
      })
      .catch((err) => {
        console.log('error ', err);
        return false;
      });
  return updatedData;
};

export const getStudentInfoIncludedService = async () => {
  const subsData = await myAccountService.getStudentInfoIncluded()
      .then((response) => {
        const subsInfo = response.subscriptionInfo;
        return subsInfo;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  return subsData;
};

export const updateSubscriptionService = async (subsInfo, leadId) => {
  const requestObj = {};
  requestObj['student_id'] = leadId;
  const subscriptionData = getSubscriptionData(subsInfo?.attributes);
  requestObj['plan_id'] = subscriptionData?.planId;
  const subsType = subscriptionData?.subsType;

  if (subsInfo?.attributes?.chargebee?.coupon) {
    requestObj['coupon_ids'] =
        [subsInfo.attributes?.chargebee?.coupon];
  }

  const isUpdateSuccessful = await new apiService.Subscription(subsInfo.id)
      .updateSubscription(requestObj)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log(error);
        const domain = process.env.PaymentWebDomain;
        const paymentLinkToRedirect =
            `${domain}/payment/subscription/${subsType}`;
        if (error.response.data.errors[0].status == 400 ||
            error.response.data.errors[0].status == 402) {
          window.open(paymentLinkToRedirect, '_self');
        }
        return false;
      });

  return isUpdateSuccessful;
};

export const resumeMembershipService = async (subsInfo, leadId) => {
  const requestObj = {
    student_id: leadId,
  };
  const isResumed = await new apiService.default.Subscription(subsInfo?.id)
      .resumeMembership(requestObj)
      .then((response) => {
        cookie.remove('membershipPaused', {path: '/',
          domain: process.env.CookieDomain});
        const expires = new Date;
        expires.setDate(new Date().getDate()+1);
        cookie.save('membershipResumed', 'true',
            {
              path: '/',
              domain: process.env.CookieDomain,
              expires,
            });
        return true;
      })
      .catch((error) => {
        console.log(error);
        const domain = process.env.PaymentWebDomain;
        const subscriptionData = getSubscriptionData(subsInfo?.attributes);
        const subsType = subscriptionData?.subsType;
        const paymentLinkToRedirect =
            `${domain}/payment/subscription/${subsType}`;
        if (error.response.data.errors[0].status == 400 ||
            error.response.data.errors[0].status == 402) {
          window.open(paymentLinkToRedirect, '_self');
        }
        return false;
      });
  return isResumed;
};

export const setXpService = async (getXPObject, locale, leadId) => {
  const xpResponse = await trackActivityService.SetXp(
      leadId, getXPObject, locale, 'MyProfilePage')
      .then((response)=>{
        return response;
      })
      .catch((err)=>{
        console.log(err);
        return false;
      });
  return xpResponse;
};

export const xpToastlistHandler = async (firstName, lastName, locale,
  membershipStatus, membershipName, toastList, leadId) => {
  const getXPObject ={
    slug: slugData.addProfilePicSlug,
    additionalParams: {},
    name: {
      first_name: firstName,
      last_name: lastName,
    },
  };
  if (!['cancelled', 'inactive'].includes(membershipStatus) &&
        (membershipName.indexOf('lifetime') === -1)) {
    const updatedXp = await setXpService(getXPObject, locale, leadId);
    if (updatedXp && updatedXp > 0) {
      // update the toastList state
      const updatedToastList=[...toastList];
      updatedToastList.push({
        xpPoints: updatedXp,
        action: slugData.addProfilePicSlug,
        createdAt: Date.now(),
      });
      return updatedToastList;
    }
  }
  return false;
};

