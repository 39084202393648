import React, {Component} from 'react';
import styles from './account.module.scss';
import * as myAccountService from '../MyAccountService';
import dayjs from 'dayjs';
import * as trackAction from '../../Shared/TrackActionService';
import PropTypes from 'prop-types';
import {Link} from '@reach/router';
import cookie from 'react-cookies';
import Helmet from 'react-helmet';
import * as utils from '../../Shared/Utils';
import * as constants from '../../../containers/Constants';
import {getPrices} from '../../../api/AddonPrices';
import {createChargebeeSessionService} from './SubscriptionService';
import PauseMembershipTabView from '../Cancellation/PauseMembershipTabView';
import ReactivationModal from './ReactivationModal';
import {Button, Spinner} from 'react-bootstrap';
import {getStudentInfoIncludedService,
  resumeMembershipService} from './ProfileService';
import SalesOffer from '../../Shared2/Popup/SalesOffer';
import {getSubscriptionData} from './AccountUtils';

/**
 * @export
 * @class SubscriptionTabView
 * @extends {Component}
 */
export default class SubscriptionTabView extends Component {
  /**
     * Creates an instance of SubscriptionTabView.
     * @param {*} props
     * @memberof SubscriptionTabView
     */
  constructor(props) {
    super(props);
    this.state = {
      showUpgradePopup: false,
      showPauseMembership: false,
      showModal: false,
      resumeMemberhipLoader: false,
    };
  }

  /**
   * @memberof SubscriptionTabView
   */
  componentDidMount() {
    trackAction.pageViewAction('MembershipManagementPage', this.props.locale);
    myAccountService.getStudentInfoIncluded().then((res) => {
      this.setState({
        subscriptionAttributes: res.subscriptionInfo?.attributes,
        studentInfo: res.studentInfo?.attributes,
        leadId: res.studentInfo.id,
        subscriptionData: getSubscriptionData(res.subscriptionInfo?.attributes),
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  /**
   * @return {*}
   * @memberof SubscriptionTabView
   */
  checkIfPauseMembershipEligible() {
    const {subscriptionAttributes} = this.state;
    let subscriptionKey;
    if (subscriptionAttributes?.chargebee) {
      subscriptionKey = subscriptionAttributes?.chargebee;
    } else if (subscriptionAttributes?.default) {
      subscriptionKey = subscriptionAttributes?.default;
    }
    if (this.state.subscriptionData?.showPauseCta) {
      if (cookie.load('membershipPaused') === 'true' ||
          subscriptionKey?.status === 'paused' ||
          subscriptionKey?.status === 'non_renewing' ||
          (subscriptionAttributes?.chargebee &&
            subscriptionKey?.cancelled_at > 0) ||
          (subscriptionAttributes?.default &&
            subscriptionKey?.status === 'cancelled')) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * @return {*}
   * @memberof SubscriptionTabView
   */
  checkIfCancelMembershipEligible() {
    const {subscriptionAttributes} = this.state;
    if (this.state.subscriptionData?.showCancelCta) {
      if (subscriptionAttributes?.chargebee) {
        const chargebeeSubInfo = subscriptionAttributes?.chargebee;
        if (chargebeeSubInfo?.status === 'non_renewing' ||
            chargebeeSubInfo?.cancelled_at > 0) {
          return false;
        }
        return true;
      } else if (subscriptionAttributes?.default) {
        const defaultSubInfo = subscriptionAttributes?.default;
        if (defaultSubInfo?.status === 'non_renewing' ||
            defaultSubInfo?.status === 'cancelled') {
          return false;
        }
        return true;
      }
    }
    return false;
  }

  /**
   * @memberof SubscriptionTabView
   */
  async handleUpgradeCta() {
    const studentInfo = this.state.studentInfo;
    const leadCountry = studentInfo?.address?.country;
    let countryToSend = leadCountry;
    const leadLanguage = studentInfo?.language_id;
    const euCountry = constants.euCountryList;
    const usCountry = constants.lowerUSDCountryList;
    const leadId = this.state?.leadId;
    const addons = {
      product: 'module-toolkit',
      type: 'toolkit',
    };
    const getPricesData = await getPrices(addons, leadCountry);
    if (usCountry.includes(leadCountry)) {
      countryToSend = '3w';
    }
    if (!getPricesData) {
      countryToSend = 'us';
    }
    if (euCountry.includes(leadCountry)) {
      countryToSend = 'eu';
    }
    const dataToSalesOffer = {
      leadId,
      countryToSend,
      leadLanguage,
    };
    this.setState({
      dataToSalesOffer,
      // showUpgradePopup: true,
    });
    trackAction.ctaClickAction({
      'studentId': cookie.load('shawacademy_leadId'),
      'cta': 'Upgrade',
      'action': 'TriggerSalesOfferModal',
      'pageKind': 'MembershipManagementPage',
    });
  }

  /**
   * @return {*} boolean
   * @memberof SubscriptionTabView
   */
  checkIfChargebeeSubscriber() {
    const {subscriptionAttributes} = this.state;
    if (subscriptionAttributes?.chargebee ||
      subscriptionAttributes?.default?.platform === 'chargebee') {
      return true;
    }
    return false;
  }

  /**
   * @memberof SubscriptionTabView
   */
  openChargbeeSelfServicePortal = function() {
    if (this.checkIfChargebeeSubscriber()) {
      createChargebeeSessionService(this.state?.leadId);
    } else {
      const domain = process.env.PaymentWebDomain;
      const locale = this.props.locale;
      if (locale === 'en') {
        window.open(
            `${domain}/payment/subscription/?product_code=sc-1m-ft`, '_self');
      } else {
        window.open(`${domain}/${locale
        }/payment/subscription/?product_code=sc-1m-ft`, '_self');
      }
    }
  };

  /**
   * @memberof SubscriptionTabView
   */
  pauseMembershipRedirect() {
    trackAction.ctaClickAction({
      'studentId': cookie.load('shawacademy_leadId'),
      'cta': 'pauseButton',
      'action': 'pause',
    });
    this.setState({showPauseMembership: true});
  }

  /**
   * @memberof SubscriptionTabView
   */
  closeSalesOfferModal() {
    this.setState({showUpgradePopup: false});
  }

  /**
   * @memberof SubscriptionTabView
   */
  handleShowReactivateModal() {
    this.setState({showModal: !this.state.showModal});
  }

  /**
   * @memberof SubscriptionTabView
   */
  async resumeMembership() {
    this.setState({resumeMemberhipLoader: true});
    const leadId = cookie.load('shawacademy_leadId');
    trackAction.ctaClickAction({
      'studentId': leadId,
      'cta': 'resumeButton',
      'action': 'Resumed',
    });
    const subsInfo = await getStudentInfoIncludedService();
    const subscriptionData = getSubscriptionData(subsInfo?.attributes);
    const planId = subscriptionData?.planId;
    if (subsInfo) {
      const isResumed = await resumeMembershipService(subsInfo, leadId);
      if (isResumed) {
        trackAction.subscriptionChangeAction({
          action: 'Resumed',
          planId: planId,
        });
        this.setState({
          resumeMemberhipLoader: false,
          showResume: false,
        });
        this.props.navigate(`/app/${this.props.locale}`);
        // TODO Update registration details
      } else {
        this.setState({resumeMemberhipLoader: false});
      }
    }
  }

  /**
   * @param {*} statusString
   * @memberof SubscriptionTabView
   */
  hidePauseMembership() {
    trackAction.pageViewAction('MembershipManagementPage', this.props.locale);
    this.setState({showPauseMembership: false});
  }
  /**
 * @return {*}
 * @memberof SubscriptionTabView
 */
  render() {
    const isUpgradedDisabled = cookie.load(`upgrade_disable`);
    const subInfoShortened = this.state?.subscriptionAttributes?.default;
    const studentInfo = this.state?.studentInfo;
    const nextBilling =
      subInfoShortened?.additional_platform_data?.next_billing_at &&
      dayjs(subInfoShortened?.additional_platform_data?.next_billing_at * 1000)
          .format('MMM DD, YYYY').toString();
    const membershipStatus = this.state?.studentInfo?.membership?.status;
    const isMembershipPaused = this.state.subscriptionData?.showResumeCta &&
      (cookie.load('membershipPaused') === 'true' ||
      membershipStatus === 'paused');
    return <>
      <Helmet>
        <script src="https://js.chargebee.com/v2/chargebee.js"></script>
      </Helmet>
      {!this.state.showPauseMembership &&
        <div className={styles.subscriptionContainer}>
          <div className={styles.heading}>
            Subscription & Purchases{' '}
            <a href="https://www.shawacademy.com/terms/"
              target="__blank">(Terms of Use)</a>
          </div>
          <div className={styles.subscriptionHeader}>
            <div>
              <p className={styles.planHeading}>
                Subscription plan</p>
              {studentInfo?.membership?.slug !== 'lifetime' &&
              subInfoShortened?.plan_data?.billing_period ? <div>
                <p className={styles.planType}>
                  {subInfoShortened?.plan_data?.billing_period}
               - {subInfoShortened?.plan_data?.billing_period_unit + ' plan'}
                </p>
                {(studentInfo?.membership?.status === 'in_trial' ||
                studentInfo?.membership?.status === 'active') &&
                <p className={styles.nextBilling}>
                  Next billing date -
                  {nextBilling}</p>} </div> :
              <p className={styles.planType}>
                {this.state?.studentInfo?.membership?.name}</p>}
            </div>
            {studentInfo?.membership?.slug !== 'lifetime' &&
            <div className={styles.headerCta}>
              <button
                disabled={isUpgradedDisabled}
                className={isUpgradedDisabled?styles.disabledCta:''}
                onClick={() => this.handleUpgradeCta()}>
                Upgrade
              </button></div>}
          </div>
          <div className={styles.infoRow}>
            <label className={styles.labelText}>
              Shaw Credit</label>
            <div className={styles.infoText}>
              {utils.studentCurrency().cur_symbol} {this.props.credits}
              <button
                style={{display: 'none'}}
                className={styles.editCta}>
                Earn more
              </button>
            </div>
          </div>
          {studentInfo?.membership?.slug !== 'lifetime' &&
          <div className={styles.infoRow}>
            <label className={styles.labelText}>
                Payment method</label>
            <div className={styles.infoText}>
                View your payment details
              <button className={styles.editCta}
                onClick={() => this.openChargbeeSelfServicePortal()}
              >View</button>
            </div>
          </div>}
          {studentInfo?.membership?.slug !== 'lifetime' &&
          <div className={styles.infoRow}>
            <label className={styles.labelText}>
                Payment invoices
            </label>
            <div className={styles.infoText}>
                Access your invoices
              <button className={styles.editCta}
                onClick={() => this.openChargbeeSelfServicePortal()}>
                  View Statement</button>
            </div>
          </div>}
          <div className={styles.ctaFooter}>
            {this.state.subscriptionData?.showPauseCta &&
            this.checkIfPauseMembershipEligible() ?
            <button className={styles.pauseCta}
              onClick={() => this.pauseMembershipRedirect()}>
                Pause Subscription
            </button> : <div> </div>
            }
            {this.checkIfCancelMembershipEligible() ?
              <Link className={styles.cancelCta}
                to="cancellation">
                Cancel Subscription
              </Link> : <div> </div>}
          </div>
        </div>
      }
      {this.state.showUpgradePopup &&
      <div className={styles.salesofferAccountContainer}>
        <SalesOffer
          subscriptionInfo={this.state.subscriptionAttributes}
          salesOfferData={this.state.dataToSalesOffer}
          closeModal={() => this.closeSalesOfferModal()}/>
      </div>}
      {this.state.showPauseMembership &&
        <PauseMembershipTabView
          origin='account'
          hidePauseMembership={()=>this.hidePauseMembership()}/>
      }
      {this.state.subscriptionData?.showReactivateCta &&
        (membershipStatus === 'cancelled' ||
          membershipStatus === 'non_renewing') &&
          <Button onClick={() => this.handleShowReactivateModal()}>
            Reactivate</Button>
      }
      {isMembershipPaused &&
        <div className={styles.contentFlex}>
          <Button onClick={() => this.resumeMembership()}>
            Resume Now</Button>
          {this.state.resumeMemberhipLoader &&
            <Spinner className="ml-4 mt-2" animation="border" size="sm"/>}
        </div>}
      <ReactivationModal
        showModal={this.state.showModal}
        handleShowModal={() => this.handleShowReactivateModal()}
        locale={this.props.locale}/>
    </>;
  }
}

SubscriptionTabView.propTypes = {
  locale: PropTypes.string,
  credits: PropTypes.any,
  showSidebar: PropTypes.bool,
  navigate: PropTypes.any,
};
