import React, {Component} from 'react';
import {Form, Container, Col} from 'react-bootstrap';
import * as apiService from '../../api';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';
import './LoginPage.css';
import cookie from 'react-cookies';
import styles from './LoginPage.module.scss';

/**
 *
 * @export
 * @class ResetPass
 * @extends {Component}
 */
export default class ResetPass extends Component {
  /**
   * Creates an instance of ResetPass.
   * @param {*} props
   * @memberof ResetPass
   */
  constructor(props) {
    super(props);

    this.state = {
      passwordMatch: false,
      hasMount: false,
    };
  }

  /**
   *
   *
   * @memberof ResetPass
   */
  componentDidMount() {
    setTimeout(() => {
      window.dataLayer.push({
        'event': 'view-page-action',
        'view-page-param': {
          'pageKind': 'ResetPasswordPage',
          'locale': this.props.locale,
        },
      });
    }, 50);
    cookie.save('pageKind', 'ResetPasswordPage', {
      path: '/',
    });
    this.setState((prevState) => ({
      ...prevState,
      hasMount: true,
    }));
  }

  /**
   * Handles 1st password entered by user
   *
   * @param {*} e
   * @memberof ResetPass
   */
  handlePassword(e) {
    const password = e.target.value.trim();
    if (password) {
      this.setState({
        newPassword: password,
      });
    }
  }

  /**
   * Handles 2nd password entered by user
   *
   * @param {*} e
   * @memberof ResetPass
   */
  handleRePassword(e) {
    const password = e.target.value.trim();
    if (password && password === this.state.newPassword) {
      this.setState({
        passwordMatch: false,
      });
    } else {
      this.setState({
        passwordMatch: true,
      });
    }
  }

  /**
   * Handle reset password API call
   *
   * @param {*} event
   * @memberof ResetPass
   */
  handleResetPassword(event) {
    event.preventDefault();
    if (this.state.newPassword) {
      const token = new URLSearchParams(window.location.search).get('token');
      const requestObj = {
        'new_password': this.state.newPassword,
        'token': token};
      new apiService.default.Auth().resetPasswordRequest(requestObj)
          .then((response) => {
            this.setState({
              resetSuccess: true,
            });
            setTimeout(() => {
              navigate(`/app/${this.props.locale}/login`);
            }, 5000);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              resetFailure: true,
            });
          });
    }
  }

  /**
   *
   *
   * @return {*}
   * @memberof ResetPass
   */
  render() {
    const successImg = require('../../../static/group-172.png');
    const warningImg = require('../../../static/group-2226.png');
    const {hasMount} = this.state;
    return (
      <>
        {hasMount &&
        <Container className={styles.shFormContainer}>
          {/* <Helmet className={styles.title}>
            <title >Reset Password</title>
          </Helmet> */}
          <Form className={styles.shForm}
            onSubmit={(e) => this.handleResetPassword(e)}>
            <Form.Text className={styles.title}>
              Password Reset
            </Form.Text>
            <Form.Text className={styles.subText}>
              { this.state.resetSuccess || this.state.resetFailure ?
                <div className={styles.resetSuccess}>
                  {this.state.resetSuccess ?
                  <p>
                    <img src={successImg} width={35}/>
                    Your password has been changed successfully.
                  </p> :
                  <p>
                    <img src={warningImg}/>
                    Invalid password reset request please request a new one
                  </p>}
                </div> :
                <p className={styles.subText}>
                  You have requested a password reset.&nbsp;
                  You can pick a new password using the form below.
                </p>
              }
            </Form.Text>
            { this.state.resetSuccess || this.state.resetFailure? null :
            <Col className={styles.col}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className={styles.label}>
              New Password
                </Form.Label>
                <Form.Control className={styles.inputStyle}
                  type="password" placeholder="password"
                  onChange={(event) => this.handlePassword(event)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className={styles.label}>
                  Re-enter your new password</Form.Label>
                <Form.Control className={styles.inputStyle} type="password"
                  onChange={(event) => this.handleRePassword(event)}
                  placeholder="confirm password" />
                { this.state.passwordMatch ?
              <p className={styles.resetErrorMsg}>
                Passwords do not match
              </p> : null
                }
              </Form.Group>
              <button type="submit" className={styles.shBtn}
                disabled={this.state.passwordMatch}
              >
            Reset your password
              </button>
            </Col>
            }
          </Form>
        </Container>
        }
      </>
    );
  }
}
ResetPass.propTypes = {
  locale: PropTypes.string,
};
