import {Link} from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Courses.module.scss';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function CourseUnavailable(props) {
  return (
    <div className={styles.shawCoursesNotAvailable}>
      <p className={styles.heading}>
        Course you&apos;re looking for was either deleted or not available ☹️
      </p>
      <p className={styles.info}>
        To access this page, enroll to a new course or switch to a different
        course you have already enrolled to
      </p>
      <Link to={`/app/${props.locale}/courses/`}
        className={styles.addCourseCta}>
        Add a new course
      </Link>
    </div>
  );
}

export default CourseUnavailable;

CourseUnavailable.propTypes = {
  locale: PropTypes.string,
};
