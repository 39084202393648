import React, {useState, useEffect, useRef} from 'react';
import {Tab} from 'react-bootstrap';
import CourseList from './CourseList';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as courseService from './AllCoursesService';
import * as apiService from '../../api';
import * as trackAction from '../Shared/TrackActionService';
import cookie from 'react-cookies';
import './index.scss';
import {navigate, useLocation} from '@reach/router';
import browseStyles from './browse.module.scss';
import HeaderNew from '../Shared/HeaderNew';
import CourseSwitcherMobileView from '../Shared2/CourseSwitcherMobileView';
import BrowseSkeleton from '../Shared2/LoadingSkeleton/BrowseSkeleton';
import TabList from './TagList';
import * as myAccountService from '../Account/MyAccountService';
import UpsellUnlockPopUp from '../Shared2/Popup/UpsellUnlockPopUp';
import MigratePopup from '../Shared2/Popup/MigratePopup';
import * as commonService from '../Shared2/Agent/CommonService';
import brandEnglishCourses from '../../../data/brandEnglishCourses.json';
import brandNonEnglishCourses from '../../../data/brandNonEnglishCourses.json';

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export default function BrowseCourses(props) {
  const location = useLocation();
  const otherCourses = brandNonEnglishCourses?.nodes?.filter((ele) => {
    return (ele.publish_details.locale !== 'en-gb' &&
    ele.publish_details.locale !== 'en-us');
  });
  const otherLocales = {
    nodes: otherCourses,
  };
  otherLocales.nodes.push(brandEnglishCourses.nodes[0]);
  const allCoursesInfo = otherLocales;
  const locale = props.locale;
  const allCourses =
  courseService.getCoursesByLocale(allCoursesInfo, locale);
  const [selectedTag, setSelectedTag] = useState('Photography');
  const [showLoadingSpinner, triggerLoadingSpinner] = useState(true);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [dataToSalesOffer, setDataToSalesOffer] = useState(null);
  const [studentRegistrations, setStudentRegistrations] = useState({});
  const [hasMount, triggerMount] = useState(false);
  const [showCourseSwitcher, setShowCourseSwitcher]=useState(false);
  const [studentInfo, setStudentInfo] = useState(null);
  const [subscriptionAttributes, setSubscriptionAttributes] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const textRef = useRef(null);
  const studentId = cookie.load('shawacademy_leadId');
  // xp Indicator
  const userStatus = studentInfo?.membership?.status;
  const isUpgraded = cookie.load(`upgrade_${studentId}`);

  const [showMigratePopup, setShowMigratePopup] = useState(false);

  const registeredCourseInfo =
    courseService.getCourseRegistrationsMapBySlug(studentRegistrations);

  const registeredTagLabels =
    courseService.getRegisteredTags(studentRegistrations, allCourses);
  const registeredTags =
    courseService.getListOfRegisteredTags(studentRegistrations);
  const isUserInTrial = userStatus === 'in_trial' &&
      registeredTags?.length !== 0 && !isUpgraded;
  const getAllLabels =
    courseService.getAllLabels(allCourses);
  const coursesByTag =
  courseService.getCoursesByTag(allCourses, selectedTag);
  const checkIfCourseRegistered =
  courseService.checkBySlug(coursesByTag, registeredCourseInfo);

  useEffect(() => {
    let tagName=getAllLabels[0];
    if (location?.state?.faculty) {
      tagName=location.state.faculty;
    }
    filterCoursesByTagName(tagName, 'onLoad');
    triggerMount(true);
    setTimeout(() => {
      window.dataLayer.push({
        'event': 'view-page-action',
        'view-page-param': {
          'studentId': cookie.load('shawacademy_leadId') || '',
          'pageKind': 'BrowseCoursesPage',
          'locale': props.locale,
        },
      });
    }, 50);
    cookie.save('pageKind', 'BrowseCoursesPage', {
      path: '/',
    });
    // hiding video ask widget
    const videoAskCloseModal =
       document.querySelector('.videoask-embed__hide_button--f6kKj');
       videoAskCloseModal?.click();
       new apiService.default.Registrations(studentId).list()
           .then((res) => {
             setStudentRegistrations(res.data.data);
             triggerLoadingSpinner(false);
           })
           .catch((err) => {
             // TODO: Better Error handling talk to tejas
             console.log(err);
           });

       myAccountService.getStudentInfoIncluded().then((res) => {
         setSubscriptionAttributes(res.subscriptionInfo?.attributes);
         setStudentInfo(res.studentInfo?.attributes);
         setLeadId(res.studentInfo.id);
       }).catch((err) => {
         console.log(err);
       });
  }, []);

  useEffect(() => {
    if (studentInfo) {
      if (studentInfo?.membership?.status === 'inactive' ||
        commonService.checkGiftMembership(studentInfo?.membership)) {
        setShowMigratePopup(true);
      }
    }
  }, [studentInfo]);

  // useEffect(()=>{
  //   new apiService.default.Registrations(studentId).list()
  //       .then((res) => {
  //         console.log('DATA HERE: ', res.data);
  //         // setRegistrationData(res.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  // }, []);
  /**
   *
   *
   * @param {*} tagName
   * @param {*} param
   */


  /**
   */
  function handleHideModal() {
    setShowUnlockModal(false);
  }

  /**
   *
   */
  const handleUpgradeCta = async () => {
    const dataToSalesOffer = await courseService.getDataToSalesOffer(
        studentInfo, leadId);
    setDataToSalesOffer(dataToSalesOffer);
    setShowUnlockModal(true);
    trackAction.ctaClickAction({
      'studentId': leadId,
      'cta': 'Upgrade',
      'action': 'TriggerSalesOfferModal',
      'pageKind': 'BrowseCoursesPage',
    });
  };

  /**
   *
   * @return {boolean}
   */
  function showUpsellCta() {
    const studentId = cookie.load('shawacademy_leadId');
    const hasUpgraded = cookie.load(`upgrade_${studentId}`);
    return courseService.shouldLockFaculties(
        subscriptionAttributes, studentInfo?.membership,
        registeredTags, hasUpgraded);
  }


  /**
   *
   * @param {*} tagName
   * @param {*} param
   */
  function filterCoursesByTagName(tagName, param) {
    setSelectedTag(tagName);

    const studentId = cookie.load('shawacademy_leadId') || '';
    if (param === 'onClick') {
      trackAction.ctaClickAction({
        'studentId': studentId,
        'cta': tagName,
        'action': 'changeFaculty',
        'pageKind': 'BrowseCoursesPage',
        'locale': props.locale,
      });
    }
  }

  const triggerCourseSwitcher = () => {
    setShowCourseSwitcher(!showCourseSwitcher);
  };

  const switchCourse = (course) => {
    triggerCourseSwitcher();
    navigate(course.url);
  };

  const closeText = () => {
    if (textRef?.current) {
      textRef.current.style.display='none';
    }
  };

  return (
    hasMount &&
    <>
      <div className={browseStyles.browsePageContainer}>
        <Helmet>
          <title>All Courses</title>
        </Helmet>
        <HeaderNew
          registrationData={studentRegistrations}
          firstName={props?.firstName}
          locale={locale} />
        <div className={`${browseStyles.shContainer} ${browseStyles.w100}`}>
          {!showLoadingSpinner && <div className={browseStyles.allCourses}>
            <div className={browseStyles.recordingsCourseSelector}>
              <CourseSwitcherMobileView
                coursesOnSwitcher={studentRegistrations}
                locale={props?.locale}
                triggerCourseSwitcher={triggerCourseSwitcher}
                switchCourse={switchCourse}
                showCourseSwitcher={showCourseSwitcher}
                activeCourse={null}
              />
            </div>
            <div className={browseStyles.topContainer}>
              <h1 className={browseStyles.heading}>Courses</h1>
              { showUpsellCta() &&
              <span className={browseStyles.upsellTriggerCta}
                onClick={() => handleUpgradeCta()}>
              Upgrade to get access to all faculties</span>
              }
              { showUnlockModal &&
              <UpsellUnlockPopUp
                subscriptionInfo={subscriptionAttributes}
                salesOfferData={dataToSalesOffer}
                handleHideModal={handleHideModal}
                showUnlockModal={showUnlockModal}
                source={'BrowseCoursesPage'}
                courseSlug={'faculty-upsell'}
              />
              }
              <div className={browseStyles.xpIndicator}>
                <div ref={textRef} className={browseStyles.subText}>
                  <p>
                    Choose from one of our Popular courses below or you can
                    search by course, skill or faculty that you are most
                    interested</p>
                  <div onClick={closeText}
                    className='library-shaw-icon-new'>
                    <i className='icon-new-close'></i>
                  </div>
                </div>
                {/* {!['cancelled', 'inactive'].includes(userStatus) &&
            userMembershipName?.indexOf('lifetime') === -1 ?
            <XpIndicator
              coursesPage
              points={`200`}
              action={`Make sure to finish your lessons to get lots of extra
                points each time.Add a new course to earn`}
              trackActionObject={{
                'pageKind': 'BrowseCoursesPage',
                'locale': locale,
                'action': 'BrowseXpIndicatorClosed',
              }}/>
              } */}
              </div>
              <div className={browseStyles.topSectionWrapper}>
                <div className={browseStyles.filterDropDown}>
                  <div className={browseStyles.facultySwitcher}>
                    <TabList
                      tags={getAllLabels}
                      filterCoursesByTagName={filterCoursesByTagName}
                      isUserInTrial={isUserInTrial}
                      registeredTagLabels={registeredTagLabels}
                      subscriptionAttributes={subscriptionAttributes}
                      registeredTags={registeredTags}
                      studentInfo={studentInfo}
                      selectedTag={selectedTag}
                    />
                  </div>
                </div>

              </div>
            </div>
            <Tab.Container defaultActiveKey="Photography">
              <h1 className={browseStyles.facultyName}>
                {selectedTag}</h1>
              <CourseList
                courses={checkIfCourseRegistered}
                learnMore={`Learn More`}
                tagName={selectedTag}
                locale={locale}
                isUserInTrial={isUserInTrial}
                registeredTags={registeredTags}
                studentInfo={studentInfo}
                subscriptionAttributes={subscriptionAttributes}
                leadId={leadId}
                studentRegistrations={studentRegistrations}
              />
            </Tab.Container>
          </div>}
          {showLoadingSpinner &&
        <BrowseSkeleton />}
        </div>
      </div>
      {showMigratePopup &&
        <MigratePopup />}
    </> );
};

BrowseCourses.propTypes = {
  locale: PropTypes.string,
  studentInfo: PropTypes.object,
  credits: PropTypes.number,
  firstName: PropTypes.string,
  isUserInTrial: PropTypes.bool,
};
