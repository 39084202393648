import React, {useEffect, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import Tags from './TagItem';
import PropTypes from 'prop-types';
import browseStyles from './browse.module.scss';
import {shouldLockFaculties} from './AllCoursesService';
import cookie from 'react-cookies';
import {useLocation} from '@reach/router';

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*}
 */
export default function TagList(props) {
  const location = useLocation();
  const tags = props.tags;
  const activeTag = props.selectedTag;
  const chooseFacultyText = 'Choose faculty';
  const [selectedDropdownDisplayValue, setSelectedDropdownDisplayValue] =
  useState(chooseFacultyText);

  /**
   *
   *
   * @param {*} tagName
   */
  function getCourses(tagName) {
    props.filterCoursesByTagName(tagName, 'onClick');
    setSelectedDropdownDisplayValue(tagName);
  };

  useEffect(() => {
    if (location?.state?.faculty) {
      setSelectedDropdownDisplayValue(location.state.faculty);
    }
  }, []);

  /**
   * @param {*} item
   * @return {*}
   */
  function isLocked(item) {
    const studentId = cookie.load('shawacademy_leadId');
    const hasUpgraded = cookie.load(`upgrade_${studentId}`);
    const shouldLockFacultiesValue = shouldLockFaculties(
      props?.subscriptionAttributes, props?.studentInfo?.membership,
      props?.registeredTags, hasUpgraded);
    if (shouldLockFacultiesValue) {
      return !(props.registeredTagLabels?.indexOf(item) > -1);
    }
    return false;
  }

  return (
    <>
      <Dropdown className={browseStyles.facultydrop}>
        <Dropdown.Toggle title={selectedDropdownDisplayValue}
          className={browseStyles.shFacultyDropdown}>
          {selectedDropdownDisplayValue}
          <span
            className={`library-shaw-icon`}>
            <i className={`icon-new-Down`}></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={browseStyles.facultyDropdownContainer}>
          <Dropdown.Item className={browseStyles.facultyDropdownInnerSection}>
            {tags.map((item, i) => (item &&
          <Tags key={i}
            tagInfo={item}
            activeTag={activeTag}
            filterCourses={getCourses}
            isLocked={isLocked}
            isUserInTrial={props?.isUserInTrial}
          />
            ))}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

TagList.propTypes = {
  tags: PropTypes.array,
  filterCoursesByTagName: PropTypes.func,
  tagInfo: PropTypes.string,
  isUserInTrial: PropTypes.bool,
  registeredTagLabels: PropTypes.array,
  subscriptionAttributes: PropTypes.object,
  registeredTags: PropTypes.array,
  studentInfo: PropTypes.object,
  selectedTag: PropTypes.string,
};
