
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import * as apiService from '../../../api';
import cookie from 'react-cookies';
// import {KeyValuePair} from '../../Shared';
import {checkPurchaseConsentEligibility} from '../../Shared/Utils';
import {popUpAction, ctaClickAction, subscriptionChangeAction}
  from '../../Shared/TrackActionService';
import {SUBSCRIPTION_VERSION} from '../../../containers/Constants';
import styles from '../SalesOffer.module.scss';
import {Spinner} from 'react-bootstrap';
import Countdown from 'react-countdown';

const SalesOffer = ({closeModal, salesOfferData, subscriptionInfo}) => {
  const locale=salesOfferData?.leadLanguage;
  const studentId=salesOfferData?.leadId;
  const ref=useRef(null);
  const [showPopup, setShowPopup]=useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner]=useState(false);
  const [disableCta, setDisableCta]=useState(true);
  const [prices, setPrices]=useState({
    quarterly: {
      price: '',
      totalPrice: '',
    },
    biannual: {
      price: '',
      totalPrice: '',
    },
    annual: {
      price: '',
      totalPrice: '',
    },
    currencySymbol: '',
    priceList: {},
  });
  const [selectedOffer, setSelectedOffer]=useState({
    duration: '',
    totalPrice: '',
    frequency: '',
  });
  const isConsentEligible=checkPurchaseConsentEligibility();
  // timer date
  const nextDate=(dayIndex) => {
    const today = new Date();
    today.setDate(today.getDate() + (
      dayIndex - 1 - today.getDay() + 5) % 5 + 1);
    return today;
  };

  useEffect(()=>{
    const leadCountry=salesOfferData?.countryToSend;
    if (leadCountry) {
      new apiService.default.Prices().getPrices()
          .then((res)=>{
            setDisableCta(false);
            const quarterPrices=
            res?.data?.header?.pricesThreeMonth?.prices?.[leadCountry] ||
            res?.data?.header?.pricesThreeMonth?.prices?.default;
            const biAnnualPrices=
            res?.data?.header?.pricesSixMonth?.prices?.[leadCountry] ||
            res?.data?.header?.pricesSixMonth?.prices?.default;
            const annualPrices=
            res?.data?.header?.pricesTwelveMonth?.prices?.[leadCountry] ||
            res?.data?.header?.pricesTwelveMonth?.prices?.default;
            const priceList=res?.data?.header?.pricesThreeMonth?.prices;
            setPrices({
              quarterly: {
                price: quarterPrices?.price,
                totalPrice: quarterPrices?.total_price,
              },
              biannual: {
                price: biAnnualPrices?.price,
                totalPrice: biAnnualPrices?.total_price,
              },
              annual: {
                price: annualPrices?.price,
                totalPrice: annualPrices?.total_price,
              },
              currencySymbol: quarterPrices?.cur_symbol,
              priceList,
            });
          })
          .catch((err)=>{
            console.log(err);
          });
      popUpAction({
        studentId,
        'title': 'Rev1 Offer Banner - Members Area',
        'pageKind': 'LessonsPage',
        'variation': 'Default',
      });
    }
  }, [salesOfferData]);

  const handlePurchase = (totalPrice, duration, frequency) => {
    setSelectedOffer({
      duration,
      totalPrice,
      frequency,
    });
    if (!isConsentEligible) {
      confirmPurchase(duration, frequency);
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
    ctaClickAction({
      'cta': 'Buy Now',
      'pageKind': 'MyCoursesPage',
      'action': `${duration}MonthPlanConfirm`,
      studentId,
      locale,
    });
  };

  const confirmPurchase = (month, freq) => {
    setCookie(`upgrade_disable`, (5/(24*60))); // cookie set for 5 min
    const duration= month || selectedOffer?.duration;
    const frequency= freq || selectedOffer?.frequency;
    let country=salesOfferData?.countryToSend;
    country = Object.keys(prices?.priceList).includes(country) ? country : 'us';
    const dataToSend = {};
    const couponCodeToSend = [];
    const param = `${frequency}_0d-trial_${country}`;
    const planId =`sub_${SUBSCRIPTION_VERSION}_${param}`;
    const couponId =`disc_${SUBSCRIPTION_VERSION}_${param}`;
    couponCodeToSend.push(couponId);
    dataToSend['student_id'] = studentId;
    dataToSend['plan_id'] = planId;
    dataToSend['coupon_ids'] = couponCodeToSend;
    dataToSend['source_url'] = window.location.href;
    let paymentLink =
    `${process.env.PaymentWebDomain}/payment/subscription/${
      frequency}/?lead_id=${studentId}`;
    if (locale!=='en') {
      paymentLink =
    `${process.env.PaymentWebDomain}/${locale}/payment/subscription/${
      frequency}/?lead_id=${studentId}`;
    }
    setDisableCta(true);
    setShowLoadingSpinner(true);
    if (subscriptionInfo) {
      if (subscriptionInfo?.default ||
          subscriptionInfo?.attributes?.default ||
        subscriptionInfo?.attributes?.chargebee) {
        new apiService.default.Prices().switchSubscription(dataToSend)
            .then((res)=>{
              setCookie(`upgrade_${studentId}`, duration);
              setTimeout(function() {
                window.location.href =
                `${process.env.ApplicationDomain}/app/${locale}`;
                setShowLoadingSpinner(false);
              }, 2000);
              if (res['status'] == '200') {
                subscriptionChangeAction({
                  studentId,
                  'action': 'PlanChanged',
                  'planId': dataToSend['plan_id'],
                });
              }
            })
            .catch((error)=>{
              if (error?.response?.data?.errors?.[0]?.code==='101') {
                const objToSend={};
                const metaData=error?.response?.data?.meta;
                objToSend['student_id'] = studentId;
                objToSend['purchase_type'] = 'subscription';
                objToSend['plan_id'] = dataToSend['plan_id'];
                objToSend['coupon_ids'] = dataToSend['coupon_ids'];
                objToSend['gateway'] = metaData?.gateway;
                objToSend['payment_intent'] =metaData?.payment_intent;
                objToSend['source_url'] = window.location.href;
                const encodedDataToSend =
            encodeURIComponent(btoa(JSON.stringify(objToSend)));
                const urlToRedirect =
             `${process.env.PaymentWebDomain}/payment/authorise/?payload=${
               encodedDataToSend}`;
                if (locale !== 'en') {
                  urlToRedirect = `${process.env.PaymentWebDomain}/${
                    locale}/payment/authorise/?payload=${encodedDataToSend}`;
                }
                setTimeout(()=> {
                  window.location.href = urlToRedirect;
                }, 1000);
              } else {
                window.location.href = paymentLink;
              }
              setShowLoadingSpinner(false);
            });
      } else {
        window.location.href = paymentLink;
      }
    } else {
      window.location.href = paymentLink;
    }
    ctaClickAction({
      'cta': 'Upgrade now',
      'pageKind': 'MyCoursesPage',
      'action': `Upgrade${selectedOffer?.duration}Month`,
      studentId,
      locale,
    });
  };

  const setCookie = (name, validity) => {
    const expires=
    new Date(new Date().setTime(new Date().getTime()+validity*24*60*60*1000));
    cookie.save(name,
        true, {
          path: '/',
          domain: process.env.CookieDomain,
          expires,
        });
  };

  const scrollToTop = () => {
    ref.current.scrollIntoView();
  };

  const closeUpgradeNowPopup = () => {
    setShowPopup(false);
    setShowLoadingSpinner(false);
  };

  const TimerRenderer = ({formatted}) => {
    return <div className={styles.timerWrapper}>
      <div className={styles.textBox}>
        <p>{formatted.days}</p>
        <p>DAYS</p>
      </div>
      <span>:</span>
      <div className={styles.textBox}>
        <p>{formatted.hours}</p>
        <p>HOURS</p>
      </div>
      <span>:</span>
      <div className={styles.textBox}>
        <p>{formatted.minutes}</p>
        <p>MINUTES</p>
      </div>
      <span>:</span>
      <div className={styles.textBox}>
        <p>{formatted.seconds}</p>
        <p>SECONDS</p>
      </div>
    </div>;
  };

  return (
    <div>
      <div className={styles.overlay}>
        <div className={styles.container}>
          <div
            ref={ref}
            className={styles.toolkitContentSection}>
            <div className={styles.closeButtonWrappper}>
              <div onClick={closeModal}
                className={`${styles.closeButton} library-shaw-icon-new`} >
                <i className="icon-new-close"></i>
              </div>
            </div>
            <div className={styles.firstSection} id="firstSection">
              <div className={styles.logoTop}>
                <img
                  src="/images/logo/white-logo.png" />
              </div>
              <h1 className={styles.heading}>unLOCK your success</h1>
              <Countdown
                renderer={TimerRenderer}
                zeroPadTime={2}
                date={nextDate(5)}>
              </Countdown>
              <div className={styles.boxContainer}>
                <div className={styles.leftBox}>
                  <div className={styles.topBanner}>
                    <h2>
                    Best Value Investment
                    </h2>
                  </div>
                  <div className={styles.boxContent}>
                    <p>12 Months</p>
                    <p>{prices?.currencySymbol}{
                    prices?.annual?.price}/m</p>
                    <p>{prices?.currencySymbol}{
                    prices?.annual?.totalPrice}</p>
                    <p>every 12 months</p>
                    <div className={styles.buyNowCta}>
                      <button
                        className={disableCta ? styles.disable:''}
                        onClick={()=>handlePurchase(
                    prices?.annual?.totalPrice, '12', 'annual')}>
                  Buy Now
                      </button>
                      {!isConsentEligible && showLoadingSpinner &&
                       selectedOffer?.frequency==='annual' &&
                  <Spinner className={styles.loadingSpinner}
                    animation="border" role="status">
                  </Spinner>
                      }
                    </div>
                    <p>12 Months for the price of 4</p>
                    {!isConsentEligible &&<p>(One Click Purchase)</p>}
                    {!isConsentEligible &&<p>By clicking above I agree to Shaw Academy’s <a href="https://www.shawacademy.com/terms/">terms and conditions</a></p>}
                  </div>
                </div>
                <div className={styles.centerBox}>
                  <div className={styles.topBanner}>
                    <h2>
                    Greater Convenience
                    </h2>
                  </div>
                  <div className={styles.boxContent}>
                    <p>6 Months</p>
                    <p>{prices?.currencySymbol}{
                    prices?.biannual?.price}/m</p>
                    <p>{prices?.currencySymbol}{
                    prices?.biannual?.totalPrice}</p>
                    <p>every 6 months</p>
                    <div className={styles.buyNowCta}>
                      <button
                        className={disableCta ? styles.disable:''}
                        onClick={()=>handlePurchase(
                    prices?.biannual?.totalPrice, '6', 'biannual')}>
                  Buy Now
                      </button>
                      {!isConsentEligible && showLoadingSpinner &&
                      selectedOffer?.frequency==='biannual' &&
                  <Spinner className={styles.loadingSpinner}
                    animation="border" role="status">
                  </Spinner>
                      }
                    </div>
                    <p>6 Months for the price of 3.5</p>
                    {!isConsentEligible &&<p>(One Click Purchase)</p>}
                    {!isConsentEligible &&<p>By clicking above I agree to Shaw Academy’s <a href="https://www.shawacademy.com/terms/">terms and conditions</a></p>}
                  </div>
                </div>
                <div className={styles.rightBox}>
                  <div className={styles.topBanner}>
                    <h2>
                    Get Qualified
                    </h2>
                  </div>
                  <div className={styles.boxContent}>
                    <p>3 Months</p>
                    <p>{prices?.currencySymbol}{
                    prices?.quarterly?.price}/m</p>
                    <p>{prices?.currencySymbol}{
                    prices?.quarterly?.totalPrice}</p>
                    <p>every 3 months</p>
                    <div className={styles.buyNowCta}>
                      <button
                        className={disableCta ? styles.disable:''}
                        onClick={()=>handlePurchase(
                    prices?.quarterly?.totalPrice, '3', 'quarterly')}>
                  Buy Now
                      </button>
                      {!isConsentEligible && showLoadingSpinner &&
                       selectedOffer?.frequency==='quarterly' &&
                  <Spinner className={styles.loadingSpinner}
                    animation="border" role="status">
                  </Spinner>
                      }
                    </div>
                    <p>3 Months for the price of 2</p>
                    {!isConsentEligible &&<p>(One Click Purchase)</p>}
                    {!isConsentEligible &&<p>By clicking above I agree to Shaw Academy’s <a href="https://www.shawacademy.com/terms/">terms and conditions</a></p>}
                  </div>
                </div>
              </div>
              {/* <div className={styles.firstSectionRating}>
                <div className={styles.ratingContent}>
                  <img className={styles.fbImage}
                    src="/images/facebook-icon.png" />
                  <div className={`library-shaw-icon-new`}>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                  </div>
                  <p>15,354 reviews</p>
                </div>
                <div className={`${styles.ratingContent}
                 ${styles.centerRating}`}>
                  <img className={styles.appleImage}
                    src="/images/app-store.png" />
                  <div className={`library-shaw-icon-new`}>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                  </div>
                  <p>2,819 reviews</p>
                </div>
                <div className={styles.ratingContent}>
                  <img src="/images/google-play-store.png" />
                  <div className={`library-shaw-icon-new`}>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                    <i className="icon-star-filled"></i>
                  </div>
                  <p>10,094 reviews</p>
                </div>
              </div> */}
              <div className={styles.firstSectionBottomImage}>
                <div className={styles.firstSectionTagline}>
                  <div className={styles.taglineContent}>
                    <img src="/images/play.png" />
                    <p>Access 100+ courses</p>
                  </div>
                  <div className={`${styles.taglineContent}
                   ${styles.centerRating}`}>
                    <img src="/images/recognised.png" />
                    <p>International Recognised</p>
                  </div>
                  <div className={styles.taglineContent}>
                    <img src="/images/graduates.png" />
                    <p>20 Million Graduates</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.secondSection}>
              <h1>
              Be the success story you need to be in 2023
              </h1>
              <h2>
              UnLOCK your future
              </h2>
              <div className={styles.workFlowBackgroud}>
                <div className={styles.workFlowContainer}>
                  <div className={styles.workFlowContent}>
                    <div className={styles.pathRow}>
                      <div className={styles.set}>
                        <div className={styles.circle}>
                          <div className={styles.number}>01</div>
                        </div>
                        <div className={styles.line}>|</div>
                        <div className={styles.text}>
                          <div className={styles.pathHeader}>Pick a Plan</div>
                          <div className={styles.pathInfo}>
                            The more you learn the
                          less you pay with our
                          discounted plans!
                          </div>
                        </div>
                      </div>
                      <div className={styles.set}>
                        <div className={styles.circle}>
                          <div className={styles.number}>02</div>
                        </div>
                        <div className={styles.line}>|</div>
                        <div className={styles.text}>
                          <div className={styles.pathHeader}>Attend
                          your classes</div>
                          <div className={styles.pathInfo}>Keep
                          attending to learn the skills
                          you need to secure your future..
                          </div>
                        </div>
                      </div>
                      <div className={styles.set}>
                        <div className={styles.circle}>
                          <div className={styles.number}>03</div>
                        </div>
                        <div className={styles.line}>|</div>
                        <div className={styles.text}>
                          <div className={styles.pathHeader}>Apply
                          your skills</div>
                          <div className={styles.pathInfo}>Take
                          quizzes & assignments to get
                          ready for what life throws at you next..
                          </div>
                        </div>
                      </div>
                      <div className={styles.set}>
                        <div className={styles.circle}>
                          <div className={styles.number}>04</div>
                        </div>
                        <div className={styles.line}>|</div>
                        <div className={styles.text}>
                          <div className={styles.pathHeader}>Get
                          your certificate</div>
                          <div className={styles.pathInfo}>The more you
                          learn the less you pay with our
                          discounted plans!.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.ctaButton}>
                <button className={styles.scrollToTopCta} onClick={scrollToTop}>
                SELECT A PLAN TO ACHIEVE YOUR GOAL
                </button>
              </div>
            </div>
            <div className={styles.thirdSection}>
              <h1>
              Why continue my transformation?
              </h1>
              <h2>
              Right now, you are in a cocoon…
              </h2>
              <ul className={styles.listElement}>
                <li>
                Before you started this journey, you were a caterpillar.
                </li>
                <li>
                Now you’re in a cocoon, transforming your future,
                by learning the necessary skills today, to change tomorrow.
                </li>
                <li>
                While the world is locking down again, use the time
                wisely to transform into a butterfly.
                </li>
                <li>
                Break free from 2022 and be the success
                story you need to be in 2023.
                </li>
                <li>
                Be a butterfly!
                </li>
              </ul>
              <button className={styles.scrollToTopCta} onClick={scrollToTop}>
              SELECT A PLAN TO ACHIEVE YOUR GOAL
              </button>
            </div>
            <div className={styles.fourthSection}>
              <h1>
              Where our students work
              </h1>
              <img src="/images/toolkit-selectOffer-partnerCompany.png" />
              <h3>
              Our amazing butterflies… sorry, students…
              work at some of the biggest
              global companies, where is next for you in 2023?
              </h3>
              <button className={styles.scrollToTopCta} onClick={scrollToTop}>
              SELECT A PLAN TO ACHIEVE YOUR GOAL
              </button>
            </div>
            <div className={styles.fifthSection}>
              <h1>
              Over 20 Million Successful Graduates
              </h1>
              <h2>
              Join Them In 2023
              </h2>
              <div className={styles.reviewerName}>
                <img src="/images/toolkit-selectOffer-studentV1.png" />
                <h4 >
                Faye Liwanag
                </h4>
              </div>
              <div className={styles.reviewerContent}>
                <h4>
                A bridge between me and my future!
                </h4>
                <p>Thank you for having this platform easily accessible.</p>
                <p>It has been the bridge for those who are not able to go to
                universities to fulfil what they want
                to become due to circumstances.
                </p>
                <p>Definitely a
                  jumpstart to who someone can become tomorrow!</p>
              </div>
              <div className={styles.reviewerLink}>
                <button onClick={scrollToTop}>
              SELECT A PLAN TO MAKE YOUR OWN SUCCESS STORY
                </button>
              </div>
              <div className={styles.reviewerName}>
                <img src="/images/toolkit-selectOffer-studentV2.png" />
                <h4 >
                Holly Fowler
                </h4>
              </div>
              <div className={styles.reviewerContent}>
                <h4>
                LOVE SHAW ACADEMY and will be staying for a long time !
                </h4>
                <p>The best ever place to build your
                educational skills and knowledge!
                </p>
                <p>I highly recommend Shaw academy above all others
                it’s at your own pace and with the best ever support
                network ever thank you to all at Shaw academy you have made
                me so happy and I will be staying for a year or more as
                I want to take so many of your courses now so excited for
                my future and new business also!
                </p>
                <p>LOVE SHAW ACADEMY</p>
              </div>
              <div className={styles.reviewerLink}>
                <button onClick={scrollToTop}>
              SELECT A PLAN TO MAKE YOUR OWN SUCCESS STORY
                </button>
              </div>
              <div className={styles.reviewerName}>
                <img src="/images/toolkit-selectOffer-studentV3.png" />
                <h4 >
                Michael Healy
                </h4>
              </div>
              <div className={styles.reviewerContent}>
                <h4>
                Absolutely blown away by the skills and…
                </h4>
                <p>Absolutely blown away by the skills and
                   education Shaw academy
                offer 💁 it’s mind blowing and I have never loved
                learning so much in my life!
                </p>
                <p>Both my partner and I are both doing diploma
                courses with them and will
                be staying to follow our paths and education with
                them. I cannot
                express the knowledge you will gain from becoming
                a student with us all at Shaw academy it really is above all
                others online and are always there to help 🙏
                </p>
                <p>It has been the bridge for those who are not able to go to
                universities to fulfil what they want
                to become due to circumstances.
                </p>
              </div>
              <div className={styles.reviewerLink}>
                <button onClick={scrollToTop}>
              SELECT A PLAN TO MAKE YOUR OWN SUCCESS STORY
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* internal Popup */}
      {showPopup &&
      <div className={styles.overlay}>
        <div className={styles.containers}>
          <div className={styles.internalPopup}>
            <div
              onClick={closeUpgradeNowPopup}
              className={`${styles.closeButton} library-shaw-icon-new`} >
              <i className="icon-new-close"></i>
            </div>
            <div className={styles.imageSection}>
              <div className={styles.leftContentSection}>
                <h3 className={styles.heading}>By Clicking upgrade now,
             you are authorizing Shaw Academy to charge you {
                prices?.currencySymbol}{selectedOffer?.totalPrice}
                {' '}automatically every {selectedOffer?.duration} months
                {' '}from today, until you cancel your subscription.</h3>
                <div className={styles.upgradeNowCtaWrapper}>
                  <button
                    className={disableCta ? styles.disable:''}
                    onClick={()=>confirmPurchase(null, null)}>Upgrade now
                  </button>
                  {showLoadingSpinner &&
                  <Spinner className={styles.loadingSpinner}
                    animation="border" role="status">
                  </Spinner>
                  }
                </div>
                <button
                  className={styles.cancelCta}
                  onClick={closeUpgradeNowPopup}>Cancel</button>
              </div>
              <img className={styles.shawLogo}
                src="/images/toolkit-selectOffer-popup.png" />
            </div>
          </div>
        </div>
      </div>
      }

    </div>
  );
  TimerRenderer.propTypes = {
    formatted: PropTypes.object,
    completed: PropTypes.bool,
  };
};

SalesOffer.propTypes = {
  closeModal: PropTypes.func,
  salesOfferData: PropTypes.object,
  subscriptionInfo: PropTypes.object,
};


export default SalesOffer;
